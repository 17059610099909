import { IZone } from "interfaces/zone.interface";

export const FETCH_ZONES = "FETCH_ZONES";
export const FETCH_ZONES_SUCCESS = "FETCH_ZONES_SUCCESS";

export const CREATE_ZONE = "CREATE_ZONE";
export const CREATE_ZONE_SUCCESS = "CREATE_ZONE_SUCCESS";

export const UPDATE_ZONE = "UPDATE_ZONE";
export const UPDATE_ZONE_SUCCESS = "UPDATE_ZONE_SUCCESS";

export const DELETE_ZONE = "DELETE_ZONE";
export const DELETE_ZONE_SUCCESS = "DELETE_ZONE_SUCCESS";

export const RESET_ZONE_ACTIONS = "RESET_ZONE_ACTIONS";

export interface resetDevicesActionType {
  type: typeof RESET_ZONE_ACTIONS;
}
export interface fetchZonesActionType {
  type: typeof FETCH_ZONES;
}
export interface fetchZonesSuccessActionType {
  type: typeof FETCH_ZONES_SUCCESS;
  payload: IZone[];
}

export interface createZoneActionType {
  type: typeof CREATE_ZONE;
}
export interface createZoneSuccessActionType {
  type: typeof CREATE_ZONE_SUCCESS;
  payload: IZone;
}

export interface updateZoneActionType {
  type: typeof UPDATE_ZONE;
}
export interface updateZoneSuccessActionType {
  type: typeof UPDATE_ZONE_SUCCESS;
  payload: IZone;
}

export interface deleteZoneActionType {
  type: typeof DELETE_ZONE;
}
export interface delateZoneSuccessActionType {
  type: typeof DELETE_ZONE_SUCCESS;
  payload: string;
}

export type zonesActionTypes =
  | fetchZonesActionType
  | fetchZonesSuccessActionType
  | resetDevicesActionType
  | createZoneActionType
  | createZoneSuccessActionType
  | updateZoneActionType
  | updateZoneSuccessActionType
  | deleteZoneActionType
  | delateZoneSuccessActionType;
