import { Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import ResizeDetector from "react-resize-detector";
import _ from "lodash";

import {
  ChartTypes,
  IHistoryStagesPlotData
} from "interfaces/sleephistory.interface";
import { getLangKey } from "utils/common/locale.util";
import { getLocalizedShortMonths } from "utils/dashboard/graph.util";
import { getMinuteString, secondsToTime } from "utils/date.util";

Highcharts.setOptions({
  time: {
    useUTC: false
  },
  lang: {
    shortMonths: getLocalizedShortMonths()
  }
});

function SleepHistoryStagesChartMemo({
  onChartMouseOver,
  onChartClick,
  plotData,
  threshold,
  sessionIDsAsString,
  chartType
}: {
  onChartMouseOver: (src: any, e: Event) => void;
  onChartClick: (src: any, e: Event) => void;
  plotData: IHistoryStagesPlotData;
  threshold: number;
  sessionIDsAsString?: string;
  chartType: ChartTypes;
}) {
  const chartRef = React.createRef<any>();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ResizeDetector
      skipOnMount
      handleWidth
      onResize={() => {
        return chartRef.current ? chartRef.current.chart.reflow() : undefined;
      }}
    >
      {({ width, height }) => (
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          constructorType="stockChart"
          options={getOptions({
            onChartMouseOver,
            onChartClick,
            plotData,
            threshold,
            t,
            theme,
            chartType
          })}
        />
      )}
    </ResizeDetector>
  );
}

// are equal
const areEqual = (prevProps: any, nextProps: any) => {
  return prevProps.sessionIDsAsString === nextProps.sessionIDsAsString;
};

const SleepHistoryStagesChart = React.memo(
  SleepHistoryStagesChartMemo,
  areEqual
);
export default SleepHistoryStagesChart;

const getOptions = ({
  plotData,
  onChartClick,
  onChartMouseOver,
  threshold,
  t,
  theme,
  chartType
}: {
  onChartMouseOver: (src: any, e: Event) => void;
  onChartClick: (src: any, e: Event) => void;
  plotData: IHistoryStagesPlotData;
  threshold: number;
  t: any;
  theme: Theme;
  chartType: ChartTypes;
}) => {
  const colorType = "light";
  const series = plotData;

  // Column chart options

  const columnChartOptions: {
    chart: Highcharts.Options["chart"];
    plotOptions: Highcharts.Options["plotOptions"];
  } = {
    chart: {
      type: "column",
      // backgroundColor: "transparent",
      ignoreHiddenSeries: false,
      // height:800,
      marginRight: 35
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false
        },
        pointPadding: 0.05,
        groupPadding: 0,
        borderWidth: 0,
        borderRadius: 1,
        crisp: false
      }
    }
  };

  // Area chart options
  const areaChartOptions: {
    chart: Highcharts.Options["chart"];
    plotOptions: Highcharts.Options["plotOptions"];
  } = {
    chart: {
      type: "areaspline",
      backgroundColor: "transparent",
      ignoreHiddenSeries: false,
      marginRight: 50,
      zooming: {
        mouseWheel: {
          enabled: false
        }
      }
    },
    plotOptions: {
      series: {
        stacking: "normal",
        threshold
      },
      areaspline: {
        lineWidth: 0,
        trackByArea: true,
        gapSize: 36 * 60 * 60 * 1000, // Create a break in the areaspline if there is more than 36h between data.
        gapUnit: "value",
        marker: {
          lineWidth: 1,
          lineColor: "#666666",
          enabled: true,
          symbol: "circle"
        }
      }
    }
  };

  // relative session start
  if (chartType === ChartTypes.column_chart) {
    series?.relativeSessionStart.forEach((session) => {
      if (session.y) {
        // eslint-disable-next-line no-param-reassign
        session.y += 86400;
      }
    });
  }

  const collection = series?.relativeSessionStart;
  const result = collection
    ? // @ts-ignore
      _.minBy(collection, (item) => item?.y ?? Number.MAX_VALUE)?.y
    : null;
  const highchartOptions = {
    chart:
      chartType === ChartTypes.area_chart
        ? {
            ...areaChartOptions.chart
          }
        : {
            ...columnChartOptions.chart
          },
    title: {
      text: null
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: "datetime",
      minTickInterval:
        series?.relativeSessionStart?.length > 1 ? 24 * 3600 * 1000 : undefined, // One day in milliseconds
      labels: {
        formatter(a: any) {
          const xVal = new Date(a.value);
          const options: Intl.DateTimeFormatOptions = {
            month: "short",
            day: "numeric",
            timeZone: "Europe/Oslo",
            hourCycle: "h23"
          };
          const currentDate = xVal.toLocaleString(getLangKey(), options);

          return currentDate;
        }
      },
      // labels: {
      //   formatter(a: any) {
      //     const xVal = new Date(a.value);
      //     const options: Intl.DateTimeFormatOptions = {
      //       month: "short",
      //       day: "numeric"
      //     };
      //     const currentDate = xVal.toLocaleString(getLangKey(), options);

      //     return currentDate;
      //   }
      // },
      // dateTimeLabelFormats: {
      //   month: "%e. %b",
      //   year: "%b"
      // },
      title: {
        text: t("date")
      },
      ordinal: false
    },
    yAxis: {
      min: chartType === ChartTypes.column_chart ? result ?? 0 - 3600 : result,
      tickInterval: 3 * 3600,

      title: {
        text: null
      },
      labels: {
        enabled: true,
        x: 35,
        formatter() {
          let dt = new Date(2000, 1, 1, 0, 0, 0, 0);
          // @ts-ignore
          dt = new Date(dt.getTime() + this.value * 1000);
          // 'en-US'
          return dt.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h23",
            timeZone: "Europe/Oslo"
          }); // { timeZoneName: 'short', hour:'2-digit' });
        }
      }
    },
    scrollbar: {
      enabled: false
    },
    legend: {
      enabled: true,
      itemHiddenStyle: {
        color: theme.palette.chart.legend.unselected[colorType],
        textDecoration: "none",
      }
    },
    plotOptions:
      ChartTypes.area_chart === chartType
        ? {
            ...areaChartOptions.plotOptions
          }
        : {
            ...columnChartOptions.plotOptions
          },
    navigator: {
      enabled: false,
      series: {
        data: series.timeAsleep
      },
      yAxis: {
        min: 0
      }
    },
    rangeSelector: {
      enabled: false
    },
    series: [
      {
        name: t("Sleep Onset"),
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("onset", e),
            mouseOver: (e: Event) => {
              onChartMouseOver("onset", e);
            }
          }
        },
        color: "rgba(255, 168, 168, 1)",
        data: series.sleepOnset
      },
      {
        name: t("Wake after sleep onset"),
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("waso", e),
            mouseOver: (e: Event) => {
              onChartMouseOver("waso", e);
            }
          }
        },
        color: "rgba(139, 0, 0, 1)",
        data: series.timeWakeAfterSleepOnset
      },
      {
        name: t("wake_after_sleep"),
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("wbaas", e),
            mouseOver: (e: Event) => onChartMouseOver("wbaas", e)
          }
        },
        color: "rgba(255, 255, 77, 1)",
        data: series.wake_after_sleep,
        selected: false
      },
      {
        name: t("REM Sleep"),
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("rem", e),
            mouseOver: (e: Event) => onChartMouseOver("rem", e)
          }
        },
        color: "rgba(255, 140, 0, 1)",
        showInNavigator: true,
        data: series.timeInREMSleep
      },
      {
        name: t("Light Sleep"),
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("light", e),
            mouseOver: (e: Event) => onChartMouseOver("light", e)
          }
        },
        color: "rgba(173, 216, 230, 1)",
        showInNavigator: true,
        data: series.timeInLightSleep,
        area: false
      },
      {
        name: t("Deep Sleep"),
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("deep", e),
            mouseOver: (e: Event) => onChartMouseOver("deep", e)
          }
        },
        color: "rgba(0, 0, 139, 1)",
        data: series.timeInDeepSleep
      },
      {
        name: t("Went to bed"),
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("onset", e),
            mouseOver: (e: Event) => onChartMouseOver("onset", e)
          }
        },
        color: "transparent",
        data: series.relativeSessionStart,
        showInLegend: false
      }
    ],
    tooltip: {
      useHTML: true,
      shared: true,
      headerFormat: "",
      // eslint-disable-next-line func-names
      formatter() {
        // @ts-ignore
        const { points } = this;
        const pointsLength = points.length;
        const timestamp = new Date(points[0].key);
        // const currentDate = moment(timestamp)
        //   .utc()
        //   .locale(getLangKey())
        //   .format("LLLL");

        const options: Intl.DateTimeFormatOptions = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          timeZone: "Europe/Oslo",
          hourCycle: "h23"
        };
        const currentDate = new Date(timestamp).toLocaleString(
          getLangKey(),
          options
        );
        const currentDateUppercasefirstChar =
          currentDate.charAt(0).toUpperCase() + currentDate.slice(1);

        let tooltipMarkup: string = pointsLength
          ? `<span style="font-size:10px;font-weight:bold;">${currentDateUppercasefirstChar}</span><br/>`
          : "";

        let index;
        for (index = 0; index < pointsLength; index += 1) {
          if (points[index].series.name === t("Went to bed")) {
            // tool tip marckup for Went to bed
          } else {
            tooltipMarkup += `<span style="color:${points[index].series.color}">\u25CF</span> ${points[index].series.name}: <b>`;
            const sec = points[index].y;
            // const h = Math.round(sec / 60 / 60);
            // const m = Math.round((sec - (sec / 60 / 60) * 60 * 60) / 60);
            const secondsToTimeVal = secondsToTime(sec);
            tooltipMarkup += `${
              secondsToTimeVal.h > 0
                ? `${secondsToTimeVal.h}${t("hours_abbreviation_lc")} `
                : ""
            }${getMinuteString(secondsToTimeVal.h, secondsToTimeVal.m)}`;
            tooltipMarkup += "</b><br/>";
          }
        }
        return tooltipMarkup;
      }
    }
  };

  // highchart options
  return highchartOptions;
};
