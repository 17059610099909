import {
  Controller,
  FieldErrors,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";

import { ApplyMargin } from "styled/StylesStyled";
import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled
} from "styled/ContainerStylesStyled";
import { IZone } from "interfaces/zone.interface";
import { InputLabelStyled } from "styled/CommonStyled";
import {
  createZone,
  updateZone
} from "store/actions/administration/zoneActions";
import { getZoneDropDownOptions } from "utils/zone.util";
import { goBack, gotTo } from "utils/history.util";
import { setZone } from "utils/localStorage";
import { toastSuccessPositionCenter } from "utils/toast.util";
import SMFormDropDown from "components/DropDownSelect/SMFormDropDown";
import SMFormTextField from "components/SMTextField/SMFormTextField";
import SMTextField from "components/SMTextField/SMTextField";
import SMUpdateCancel from "components/SMUpdateCancel/SMUpdateCancel";
import SubTitle from "components/SMTitles/SubTitle";
import UseAdminContext from "hooks/UseAdminContext";
import getErrorInputElement from "utils/common/getErrorInputElement";

function ZoneInformation({
  create = false,
  zone,
  disabled = false
}: {
  create?: boolean;
  zone: IZone;
  disabled?: boolean;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { zones, setSelectedZone, selectedZone } = UseAdminContext();

  const authUserZones: IZone[] = useSelector((state: any) => {
    return state.authReducerV1?.authorizedZones || [];
  });

  const { handleSubmit, control, resetField, reset } = useForm<IZone>({
    defaultValues: {
      ...zone,
      // preset values
      parent_id: create ? selectedZone || "" : zone.parent_id
    },
    mode: "onTouched"
  });

  const resetFormFields = () => {
    reset({
      ...zone,
      // preset values
      parent_id: create ? selectedZone || "" : zone.parent_id
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // updated device change
  useEffect(() => {
    resetFormFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zone]);

  // create success callback
  const createZoneCallback = (item: IZone) => {
    // shpw toast message
    toastSuccessPositionCenter(t("Zone created successfully"));

    // set zone and redirect to the subject view page
    if (item && item.id) {
      // highlight active item in the sidebar
      setZone(item.id);
      if (setSelectedZone) {
        setSelectedZone(item.id);
      }
      gotTo("adminstration/settings");
    }
  };

  const updateZoneErrorCallBack = () => {
    resetFormFields();
  };
  const onSubmit: SubmitHandler<IZone> = (data) => {
    if (data.id) {
      dispatch(updateZone(data, updateZoneErrorCallBack));
    } else {
      dispatch(createZone(data, createZoneCallback));
    }
  };

  const errorFocusOrder: { [key: string]: string } = {
    name: "a",
    parent_id: "b"
  };

  // on Error
  function onErrors(err: FieldErrors<IZone>) {
    const { elem } = getErrorInputElement<IZone>(err, errorFocusOrder);
    // set the focus
    if (elem) {
      elem.focus();
    }
  }

  return (
    <div>
      <BoxSubSectionStyled>
        <SubTitle showEdit={!create} text={t("Zone Information")} />
        <BoxSubSectionContentStyled padding="16px 20px 34px 16px">
          <form onSubmit={handleSubmit(onSubmit, onErrors)}>
            <Grid container rowSpacing={0} columnSpacing={2.5}>
              <Grid item xs={12} md={4}>
                <ApplyMargin top={16}>
                  <InputLabelStyled maginbottom={6}>
                    {t("Zone name")}
                  </InputLabelStyled>
                  <Controller
                    rules={{ required: `${t("error_message_zone_name")}` }}
                    name="name"
                    control={control}
                    render={({ field, fieldState, formState }) => (
                      <SMFormTextField
                        field={field}
                        formState={formState}
                        fieldState={fieldState}
                        inlineEdit={!create}
                        disabled={!!disabled}
                        placeholder={t("zone_name_placeholder")}
                        onCancel={() => {
                          resetField("name");
                        }}
                        onUpdate={() => {
                          handleSubmit(onSubmit)();
                        }}
                      />
                    )}
                  />
                </ApplyMargin>
              </Grid>

              <Grid item xs={12} md={4}>
                <ApplyMargin top={16}>
                  <InputLabelStyled maginbottom={6}>
                    {t("Description (optional)")}
                  </InputLabelStyled>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState, formState }) => (
                      <SMFormTextField
                        field={field}
                        formState={formState}
                        fieldState={fieldState}
                        inlineEdit={!create}
                        disabled={!!disabled}
                        placeholder={
                          disabled ? "" : t("zone_description_placeholder")
                        }
                        onCancel={() => {
                          resetField("description");
                        }}
                        onUpdate={() => {
                          handleSubmit(onSubmit)();
                        }}
                      />
                    )}
                  />
                </ApplyMargin>
              </Grid>

              <Grid item xs={12} md={4}>
                <ApplyMargin top={16}>
                  <InputLabelStyled maginbottom={6}>
                    {t("Parent zone")}
                  </InputLabelStyled>

                  {!zone.parent_id && !create ? (
                    <SMTextField disabled defaultValue={t("none")} />
                  ) : (
                    <Controller
                      name="parent_id"
                      // rules={{
                      //   required: create
                      //     ? `${t("error_message_zone_parent_zone")}`
                      //     : false
                      // }}
                      rules={{
                        required: create
                          ? `${t("error_message_zone_parent_zone")}`
                          : false,
                        validate: {
                          isParentIDSelected: (value) => {
                            if (create && value === "0") {
                              return `${t("error_message_zone_parent_zone")}`;
                            }
                            return undefined;
                          }
                        }
                      }}
                      control={control}
                      render={({ field, fieldState, formState }) => (
                        <SMFormDropDown<IZone>
                          disabled={!create || !!disabled}
                          field={field}
                          fieldState={fieldState}
                          formState={formState}
                          placeholder={t("Select a zone")}
                          options={getZoneDropDownOptions(
                            zones,
                            authUserZones.map((item) => item.id)
                          )}
                          width="100%"
                          onUpdate={() =>
                            !create ? handleSubmit(onSubmit)() : null
                          }
                        />
                      )}
                    />
                  )}
                </ApplyMargin>
              </Grid>
            </Grid>
          </form>
        </BoxSubSectionContentStyled>
      </BoxSubSectionStyled>
      {create ? (
        <SMUpdateCancel
          onCancel={() => goBack()}
          onUpdate={handleSubmit(onSubmit, onErrors)}
          create
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default ZoneInformation;
