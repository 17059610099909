import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";

import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import {
  INightReport,
  NightReportItemV1
} from "interfaces/nightReport.interface";
import { IZone } from "interfaces/zone.interface";
import { SMTableWrapperBox, TableTitle } from "styled/CommonStyled";
import { fetchNightReportV1 } from "store/actions/dashboard/nightReportV1Actions";
import BuildZonePath from "@modules/administration/zones/components/BuildZonePath";
import SMNoDataBox from "components/SMDynamicTable/SMNoDataBox";
import UseDashboardContext from "hooks/UseDashboardContext";

import NightReportTable from "./NightReportTable";

function ZoneNightReport({
  zone,
  onNightReportRowClick
}: {
  zone: IZone;
  onNightReportRowClick: (row: NightReportItemV1) => void;
}): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { zoneEntity } = UseDashboardContext();
  const [nightReport, setNightReport] = React.useState<INightReport>();

  const nightReportV1Reducer = useSelector((state: any) => {
    return state?.nightReportV1Reducer || {};
  });

  useEffect(() => {
    dispatch(fetchNightReportV1(zone.id));
  }, [zone, dispatch]);

  useEffect(() => {
    if (nightReportV1Reducer.nightReport) {
      const report: INightReport = nightReportV1Reducer.nightReport.find(
        (item: INightReport) => item.zone.id === zone.id
      );

      if (report) {
        setNightReport(report);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nightReportV1Reducer]);

  if (nightReport && nightReport.items?.length) {
    return (
      <BoxPadding pad="0px" className="print_table_wrapper">
        <SMTableWrapperBox key={zone.id}>
          <TableTitle
            bg="light"
            sx={{
              padding: "16.5px 20px",
              borderRadius: "8px 8px 0px 0px"
            }}
          >
            {zoneEntity && (
              <BuildZonePath path={zone.path} zoneEntity={zoneEntity} />
            )}
          </TableTitle>
          <div style={{ padding: 1 }}>
            <NightReportTable
              report={nightReport}
              nightReportRowClick={onNightReportRowClick}
            />
          </div>
        </SMTableWrapperBox>{" "}
      </BoxPadding>
    );
  }
  return (
    <BoxPadding pad="0px">
      {" "}
      <SMNoDataBox
        message={t("no_subjects_with_device_in_this_zone")}
        title={
          zoneEntity ? (
            <BuildZonePath path={zone.path} zoneEntity={zoneEntity} />
          ) : (
            zone.name
          )
        }
        bodyBg="white"
      />
    </BoxPadding>
  );
}
export default ZoneNightReport;
