/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { styled } from "@mui/system";
import { useHistory } from "react-router-dom";
import Link from "@mui/material/Link";

import * as LOCAL_STORAGE from "utils/localStorage";
import { ReactComponent as BreadCrumpSeparator } from "assets/icons/new/breadcrump-separator.svg";
import { IZone } from "interfaces/zone.interface";
import { gotTo } from "utils/history.util";
import UseAdminContext from "hooks/UseAdminContext";
import { useTheme } from "@mui/material/styles";

export const LinkStyled = styled(Link)(
  ({
    children,
    last
  }: {
    children: React.ReactNode;
    last: number;
  }) => {
    const theme = useTheme();
    return {
      color: theme.palette.main[60],
      fontSize: 12,
      textTransform: "uppercase",
      letterSpacing: "0.4px",
      float: "left",
      cursor: !last ? "pointer" : "default",
      "&:hover": {
        "& >div": {
          textDecoration: !last ? "underline" : "none"
        }
      }
    };

  }
);

export const BreadCrumbWrapper = styled("div")(
  ({children }: { children: React.ReactNode }) => {
    return {
      float: "left",
      width: "100%",
      margin: "16px 24px 25px 24px"
    };
  }
);

export const BreadCrumbSeparatorStyled = styled(BreadCrumpSeparator)(
  () => {
    return {
      float: "left",
      margin: "3px 8px 0px 8px"
    };
  }
);

export const BreadCrumbLabel = styled("div")(
  ({ last }: { last: number }) => {
    const theme = useTheme();
    return {
      float: "left",
      color: last ? theme.palette.neutral[80] : theme.palette.main[60]
    };
  }
);

interface IProps {
  name: string;
  id: string;
}

export default function SMBreadCrump() {
  const { zones, selectedZone, currentPath, setSelectedZone, setCurrentPath } =
    UseAdminContext();

  const history = useHistory();

  // create zone entity
  const zoneEntity: { [key: string]: IZone } = {};
  zones?.forEach((item: IZone) => {
    zoneEntity[item.id] = item;
  });

  // get current zone path
  const paths = zones
    ?.find((zone: IZone) => zone.id === selectedZone)
    ?.path?.split(".");

  // set breadcrumb path
  const breadcrumpSelectedZonePaths: IProps[] = [];
  paths?.forEach((path: string) =>
    breadcrumpSelectedZonePaths.push({
      name: zoneEntity[path]?.name,
      id: zoneEntity[path].id
    })
  );

  const handleClick = (event: any, item: IProps) => {
    if (setSelectedZone) {
      setSelectedZone(item.id);
      LOCAL_STORAGE.setZone(item.id);
    }

    if (setCurrentPath) {
      setCurrentPath(undefined);
    }

    // breacrumb click from the subject/device detailed view page issue fix
    const pathblocks = history.location.pathname.split("/").reverse();
    if (
      pathblocks.length &&
      pathblocks[0] !== "subjects" &&
      pathblocks[0] !== "devices" &&
      pathblocks[0] !== "users"
    ) {
      // find the screen to redirect and redirect
      if (pathblocks.indexOf("subjects") !== -1) {
        gotTo("adminstration/zones/subjects");
      } else if (pathblocks.indexOf("users") !== -1) {
        gotTo("adminstration/zones/users");
      } else if (pathblocks.indexOf("devices") !== -1) {
        gotTo("adminstration/zones/devices");
      }
    }
  };

  const breadcrumps = [...breadcrumpSelectedZonePaths];
  if (currentPath) {
    breadcrumps.push(currentPath);
  }
  const SMBreadCrumbNode = (zone: IZone[]): JSX.Element => {
    return (
      <>
        {breadcrumps.map((item: IProps, index) => {
          return (
            <LinkStyled last={index === breadcrumps.length - 1 ? 1 : 0}>
              <BreadCrumbLabel
                last={index === breadcrumps.length - 1 ? 1 : 0}
                onClick={(e: any) =>
                  index !== breadcrumps?.length - 1
                    ? handleClick(e, item)
                    : () => {}
                }
              >
                {item.name}
              </BreadCrumbLabel>
              {index !== breadcrumps?.length - 1 ? (
                <BreadCrumbSeparatorStyled />
              ) : (
                ""
              )}
            </LinkStyled>
          );
        })}
      </>
    );
  };

  return <BreadCrumbWrapper>{SMBreadCrumbNode(zones || [])}</BreadCrumbWrapper>;
}
