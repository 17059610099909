import React from "react";

import { ReactComponent as ArrowStraight } from "assets/icons/new/arrow-straight.svg";
import { IZone } from "interfaces/zone.interface";

export default function BuildZonePath({
  path,
  zoneEntity
}: {
  path: string;
  zoneEntity: { [key: string]: IZone };
}) {
  const paths = path.split(".");

  const showArrow = (currentPath: string) => {
    if (paths.indexOf(currentPath) !== paths.length - 1) {
      return true;
    }
    return false;
  };
  return (
    <div>
      {paths.map((zoneID) => {
        return zoneEntity[zoneID] ? (
          <span key={zoneID}>
            {zoneEntity[zoneID].name}{" "}
            {showArrow(zoneID) ? (
              <ArrowStraight style={{ marginLeft: 8, marginRight: 8 }} />
            ) : (
              ""
            )}{" "}
          </span>
        ) : (
          ""
        );
      })}
    </div>
  );
}
