import axios from "axios";

import {
  handleError,
  handleResponse,
  showErrorFeedback
} from "../responseHandler";

export const axiosGET = (url: string) => {
  return axios({
    method: "get",
    url,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*"
    }
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
};

export const axiosGETWithParams = (url: string, params: any) => {
  return axios
    .get(url, {
      params
    })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
};
