import { ISession } from "interfaces/sleephistory.interface";

export const FETCH_SLEEP_EPOCH_DATA = "FETCH_SLEEP_EPOCH_DATA";
export const CLEAR_STATE = "CLEAR_STATE";
export const SLEEP_EPOCH_DATA_SUCCESS = "SLEEP_EPOCH_DATA_SUCCESS";

export interface clearSleepEpochActionType {
  type: typeof CLEAR_STATE;
}
export interface fetchSleepEpochActionType {
  type: typeof FETCH_SLEEP_EPOCH_DATA;
}
export interface fetchSleepEpochSuccessActionType {
  type: typeof SLEEP_EPOCH_DATA_SUCCESS;
  payload: ISession;
}

export type sleepEpochActionTypes =
  | fetchSleepEpochSuccessActionType
  | fetchSleepEpochActionType
  | clearSleepEpochActionType;
