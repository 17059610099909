import React from "react";

import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";

export const BoxWithPadding = ({
  children,
  pad
}: {
  children: React.ReactChild | React.ReactChild[];
  pad?: number | string;
}) => {
  return <BoxPadding pad={pad || 48}>{children}</BoxPadding>;
};

export default BoxWithPadding;
