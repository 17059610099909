import { MutableRefObject, createContext } from "react";

import { IZone } from "interfaces/zone.interface";

export type DashboardContextType = {
  selectedZones: IZone[] | undefined;
  zoneEntity: { [key: string]: IZone };
  setSelectedZones: React.Dispatch<React.SetStateAction<IZone[] | undefined>>;
  handlePrint: (() => void) | undefined;
  componentRef: MutableRefObject<any>;
};

export const DashboardContext = createContext<DashboardContextType | null>(
  {} as DashboardContextType
);
