import { ErrorOutline } from "@mui/icons-material";
import { Grid  } from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { IZone } from "interfaces/zone.interface";
import { NightReportItemV1 } from "interfaces/nightReport.interface";
import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { clearNightReportV1 } from "store/actions/dashboard/nightReportV1Actions";
import { setLocalSettings } from "utils/localStorage";
import SMGoogleAnalytic from "components/GoogleAnalytic/GoogleAnalytic";
import UseDashboardContext from "hooks/UseDashboardContext";

import SleepHistoryModal from "../SleepHistoryModel";
import ZoneNightReport from "./components/ZoneNightReport";

const LegendStyled = styled("div")(
  ({ children }: { children: React.ReactNode }) => {
    return {
      fontSize: 14,
      color: "rgba(0, 0, 0, 0.6)",
      float: "left",
      marginTop: 20,
      "& .legendsTitle": {
        fontWeight: 500,
        clear: "both",
        marginBottom: 8,
        color: "rgba(0, 0, 0, 0.87)"
      },
      "& .legendsIcon": {
        width: 16,
        height: 16,
        marginRight: 8,
        color: "#B00020",
        float: "left",
        "& > span": {}
      },
      "& .legendItems": {
        clear: "both",
        margin: 0,
        "& > li": {
          margin: "4px 0"
        }
      },
      "& .baseLineHeights": {
        fontSize: 14,
        color: "rgba(0, 0, 0, 0.6)",
        lineHeight: "20px",
        width: 480,
        marginTop: 16
      },
      "& .desc": {
        clear: "both",
        lineHeight: "17px"
      },
      "@media print": {
        breakInside: "avoid"
      }
    };
  }
);

const NightReportTableWraper = styled("div")(
  ({ children }: { children: React.ReactNode }) => {
    return {
      width: "100%"
    };
  }
);

function NightReportV1() {
  const { selectedZones, componentRef } = UseDashboardContext();
  const [selectedSession, setSelectedSession] = useState<string>();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [dashboardModalSubject, setDashboardModalSubject] = useState<{
    id: string;
    identifier: string;
    birth_year: number;
    sex: string;
  }>({ id: "", identifier: "", sex: "", birth_year: 0 });

  useEffect(() => {
    return () => {
      dispatch(clearNightReportV1());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!selectedZones) {
    return <div />;
  }

  const onNightReportRowClick = (item: NightReportItemV1) => {
    if (item?.subject?.id) {
      // set session
      setSelectedSession(item.session_id);

      // set default view to night report
      setLocalSettings({
        [USER_SETTINGS_CONSTANTS.set_history_view_mode]: "last_night"
      });

      setDashboardModalSubject({
        id: item.subject.id,
        identifier: item.subject.identifier,
        sex: item.subject.sex,
        birth_year: item.subject.birth_year
      });
    } else {
      setDashboardModalSubject({
        id: "",
        identifier: "",
        sex: "",
        birth_year: 0
      });
    }
  };

  return (
    <>
      {/* Add google analytic */}
      <SMGoogleAnalytic title="Night Report Screen" />

      <Grid container ref={componentRef} sx={{ width: "100%" }}>
        {/* <style>{getPageMargins()}</style> */}
        <NightReportTableWraper className="printContainer">
          {selectedZones.map((zone: IZone) => {
            return (
              <ZoneNightReport
                zone={zone}
                key={zone.id}
                onNightReportRowClick={onNightReportRowClick}
              />
            );
          })}
        </NightReportTableWraper>
        <LegendStyled>
          <div className="legendsTitle">{t("Legends")}</div>
          <div className="desc">
            <ErrorOutline className="legendsIcon" />
            <span>
              {t(
                "Flagged for attention if there is a difference compared to the baseline value, as follows"
              )}
              :
            </span>
          </div>
          <ul className="legendItems">
            <li>{t("Respiration Rate difference > 15 % (below or above)")}</li>
            <li>{t("Movement difference > 30% (below or above)")}</li>
            <li>{t("Total Sleep Time difference > 30% (below or above)")}</li>
          </ul>
          {/* <div className="baseLineHeights">
              {t(
                "(*) Baseline Nights the number of nights used to calculate the baseline value, which is the average measurement for the past 21 days"
              )}
            </div> */}
        </LegendStyled>

        <SleepHistoryModal
          setDashboardModalSubject={setDashboardModalSubject}
          dashboardModalSubject={dashboardModalSubject}
          sessionID={selectedSession}
        />
      </Grid>
    </>
  );
}
export default NightReportV1;
