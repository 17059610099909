import { Button, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

import * as LOCAL_STORAGE from "utils/localStorage";
import { ApplyFloat, ApplyMargin } from "styled/StylesStyled";
import {
  AuthorizationContext,
  ZonePermissionEnum
} from "authorization/AuthorizationContext";
import { IZone } from "interfaces/zone.interface";
import { deleteZone } from "store/actions/administration/zoneActions";
import { gotTo } from "utils/history.util";
import { toastSuccessPositionCenter } from "utils/toast.util";
import { useCustomArrayEffect } from "hooks/UseCustomArrayEffect";
import BoxWithPadding from "components/BoxWithPadding/BoxWithPadding";
import Maintitle from "components/SMTitles/MainTitle";
import SMAlertDialog from "components/SMDialogs/SMAlertDialog";
import SMConfirmDialog from "components/SMDialogs/SMConfirmDialog";
import SMGoogleAnalytic from "components/GoogleAnalytic/GoogleAnalytic";
import UseAdminContext from "hooks/UseAdminContext";

import ZoneInformation from "./components/ZoneInformation";

const ButtonStyled = styled(Button)(
  ({ children }: {children?: React.ReactNode }) => {
    const theme = useTheme();
    return {
      marginRight: 4,
      color: theme.palette.main[60],
      fontWeight: 500,
      cursor: "pointer",
      textTransform: "capitalize",
      fontSize: 16,
      paddingRight: 12,
      paddingLeft: 12,
      marginBottom: 0,
      "&:hover": {
        background: "#FFF"
      },
      "> span": {
        marginLeft: 0
      },
      "& svg": {
        width: 24,
        height: 24
      }
    };
  }
);

// lazy load component
const NotificationScreen = React.lazy(
  () => import("../notification/NotificationScreen")
);
function ZoneSettingsPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [zone, setZone] = useState<IZone>();
  const [openzoneDeleteErrorAlert, setOpenzoneDeleteErrorAlert] =
    useState<boolean>(false);
  const { permissions } = useContext(AuthorizationContext);

  // context state
  const { zones, selectedZone, setSelectedZone } = UseAdminContext();

  const isDisableZonUpdate = () => {
    return permissions.indexOf(ZonePermissionEnum["zone.update"]) === -1;
  };

  const setItem = () => {
    if (!selectedZone || !zones) {
      return;
    }
    const currentZone: IZone | undefined = zones.find(
      (item: IZone) => item.id === selectedZone
    );
    if (currentZone) {
      setZone(currentZone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  
  // array change effect
  useCustomArrayEffect(() => {
    if (selectedZone) {
      setItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zones]);


  useEffect(() => {
    setItem();

    return () => {
      // reset logs
      // dispatch(resetLogsState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZone]);

  const onBack = () => {
    // Back button from the zone settings page to the devices list. as a default page.
    gotTo(`adminstration/zones/devices`);
  };

  // delete succes calback to show success message
  const deleteSuccessCallBack = (item: IZone) => {
    // shpw toast message
    toastSuccessPositionCenter(t("Zone deleted successfully"));

    // load the parent zone
    if (item.parent_id) {
      LOCAL_STORAGE.setZone(item.parent_id);
      if (setSelectedZone) {
        setSelectedZone(item.parent_id);
      }
      gotTo(`adminstration/zones/devices`);
    } else {
      // handle no zone scenario Todo fr@vitalthings.com
    }
  };

  const errorCallback = (err: any) => {
    setOpenzoneDeleteErrorAlert(true);
  };
  const onDelete = () => {
    if (zone && zone.id) {
      dispatch(deleteZone(zone, deleteSuccessCallBack, errorCallback));
    }
  };
  return (
    <>
      {/* Add google analytic */}
      <SMGoogleAnalytic title="Zone Settings Screen" />

      <BoxWithPadding pad="24px 24px 24px 4px">
        <ApplyMargin bottom={21}>
          <ApplyFloat float="left">
            <Maintitle
              text={`${t("Zone Settings")} - ${zone?.name}`}
              showBack={false}
            />
          </ApplyFloat>

          <ApplyFloat float="right">
            {/* <TextWithIconButtonStyled
              startIcon={<CloseIconStyled />}
              onClick={onBack}
            >
              {t("close")}
            </TextWithIconButtonStyled> */}
            <ButtonStyled startIcon={<CloseIcon />} onClick={onBack}>
              {t("close")}
            </ButtonStyled>
          </ApplyFloat>
        </ApplyMargin>
        <Grid container rowSpacing={0}>
          <Grid container rowSpacing={2.5}>
            <Grid item xs={12} md={12}>
              {zone && (
                <ZoneInformation zone={zone} disabled={isDisableZonUpdate()} />
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Suspense fallback={<span>Loading...</span>}>
                <NotificationScreen />
              </Suspense>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            {zone && (
              <SMConfirmDialog
                title={t("Delete zone")}
                dialogDesc={t("Are you sure you want to delete this zone?")}
                onDelete={onDelete}
                buttonOk={t("Delete")}
                buttonCancel={t("Cancel")}
                okButtonBg="red"
                disableDelete={
                  permissions.indexOf(ZonePermissionEnum["zone.delete"]) === -1
                }
              />
            )}
          </Grid>
        </Grid>
        <SMAlertDialog
          title={t("information")}
          dialogDesc={t("cannot_delete_the_selected_zone_as_it_is_not_empty")}
          buttonOk={t("ok")}
          open={openzoneDeleteErrorAlert}
          setOpen={setOpenzoneDeleteErrorAlert}
        />
      </BoxWithPadding>
    </>
  );
}
export default ZoneSettingsPage;
