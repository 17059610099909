import _ from "lodash";

import { IUser } from "interfaces/user.interface";
import { ZoneUserRolesEnum } from "constants/userContstants";

import {
  AuthorizationPermissionsType,
  DevicePermissionEnum,
  NotificationHandlerPermissionEnum,
  RulesPermissionEnum,
  SubjectPermissionEnum,
  UserPermissionEnum,
  ZonePermissionEnum
} from "./AuthorizationContext";

export const can = (
  permissions: AuthorizationPermissionsType[],
  policy: AuthorizationPermissionsType
): boolean => {
  if (permissions.indexOf(policy) !== -1) {
    return true;
  }
  return false;
};

export const getUserPermission = (
  user: IUser
): AuthorizationPermissionsType[] => {
  let permissions: AuthorizationPermissionsType[] = [];
  if (!user) {
    return permissions;
  }
  const { roles } = user;
  if (!roles) {
    return permissions;
  }

  // OWNER
  if (roles.includes(ZoneUserRolesEnum.OWNER)) {
    // zone roles
    const zoneRolesForOwner: ZonePermissionEnum[] = [
      ZonePermissionEnum["zone.list"],
      ZonePermissionEnum["zone.create"],
      ZonePermissionEnum["zone.read"],
      ZonePermissionEnum["zone.update"],
      ZonePermissionEnum["zone.delete"],
      ZonePermissionEnum["zone.live_monitor"],
      ZonePermissionEnum["zone.generate_report"]
    ];
    // subject roles
    const subjectRolesForOwner: SubjectPermissionEnum[] = [
      SubjectPermissionEnum["subject.read"],
      SubjectPermissionEnum["subject.update"],
      SubjectPermissionEnum["subject.delete"],
      SubjectPermissionEnum["subject.list"],
      SubjectPermissionEnum["subject.create"]
    ];
    // user roles for admin
    const userRolesForOwner: UserPermissionEnum[] = [
      UserPermissionEnum["user.read"],
      UserPermissionEnum["user.update"],
      UserPermissionEnum["user.delete"],
      UserPermissionEnum["user.create"],
      UserPermissionEnum["user.list"],
      UserPermissionEnum["user.manage_role_assignment:OWNER"],
      UserPermissionEnum["user.manage_role_assignment:ADMIN"],
      UserPermissionEnum["user.manage_role_assignment:MANAGER"],
      UserPermissionEnum["user.manage_role_assignment:MANAGER_LIMITED"],
      UserPermissionEnum["user.manage_role_assignment:DATA_READER"],
      UserPermissionEnum["user.manage_role_assignment:DATA_READER_LIMITED"]
    ];
    const deviceRolesForOwner: DevicePermissionEnum[] = [
      DevicePermissionEnum["device.read"],
      DevicePermissionEnum["device.update"],
      DevicePermissionEnum["device.delete"],
      DevicePermissionEnum["device.list"],
      DevicePermissionEnum["device.create"],
      DevicePermissionEnum["device.assign_subject"],
      DevicePermissionEnum["device.update_user_settings"]
    ];

    const notificationHandlersForOwner: NotificationHandlerPermissionEnum[] = [
      NotificationHandlerPermissionEnum["notification_handler.list"],
      NotificationHandlerPermissionEnum["notification_handler.create"],
      NotificationHandlerPermissionEnum["notification_handler.read"],
      NotificationHandlerPermissionEnum["notification_handler.update"],
      NotificationHandlerPermissionEnum["notification_handler.delete"]
    ];
    const rulesForOwner: RulesPermissionEnum[] = [
      RulesPermissionEnum["rule.list"],
      RulesPermissionEnum["rule.create"],
      RulesPermissionEnum["rule.read"],
      RulesPermissionEnum["rule.update"],
      RulesPermissionEnum["rule.delete"]
    ];
    permissions = permissions.concat(
      zoneRolesForOwner,
      subjectRolesForOwner,
      userRolesForOwner,
      deviceRolesForOwner,
      notificationHandlersForOwner,
      rulesForOwner
    );
  }

  // ADMIN
  if (roles.includes(ZoneUserRolesEnum.ADMIN)) {
    const zoneRolesForAdmin: ZonePermissionEnum[] = [
      ZonePermissionEnum["zone.list"],
      ZonePermissionEnum["zone.read"],
      ZonePermissionEnum["zone.live_monitor"],
      ZonePermissionEnum["zone.generate_report"]
    ];
    const subjectRolesForAdmin: SubjectPermissionEnum[] = [
      SubjectPermissionEnum["subject.read"],
      SubjectPermissionEnum["subject.update"],
      SubjectPermissionEnum["subject.delete"],
      SubjectPermissionEnum["subject.list"],
      SubjectPermissionEnum["subject.create"]
    ];
    const userRolesForAdmin: UserPermissionEnum[] = [
      UserPermissionEnum["user.read"],
      UserPermissionEnum["user.update"],
      UserPermissionEnum["user.delete"],
      UserPermissionEnum["user.create"],
      UserPermissionEnum["user.list"],
      UserPermissionEnum["user.manage_role_assignment:ADMIN"],
      UserPermissionEnum["user.manage_role_assignment:MANAGER"],
      UserPermissionEnum["user.manage_role_assignment:MANAGER_LIMITED"],
      UserPermissionEnum["user.manage_role_assignment:DATA_READER"],
      UserPermissionEnum["user.manage_role_assignment:DATA_READER_LIMITED"]
    ];
    const deviceRolesForAdmin: DevicePermissionEnum[] = [
      DevicePermissionEnum["device.read"],
      DevicePermissionEnum["device.update"],
      DevicePermissionEnum["device.delete"],
      DevicePermissionEnum["device.list"],
      DevicePermissionEnum["device.create"],
      DevicePermissionEnum["device.assign_subject"],
      DevicePermissionEnum["device.update_user_settings"]
    ];

    const notificationHandlersForAdmin: NotificationHandlerPermissionEnum[] = [
      NotificationHandlerPermissionEnum["notification_handler.list"],
      NotificationHandlerPermissionEnum["notification_handler.create"],
      NotificationHandlerPermissionEnum["notification_handler.read"],
      NotificationHandlerPermissionEnum["notification_handler.update"],
      NotificationHandlerPermissionEnum["notification_handler.delete"]
    ];
    const rulesForAdmin: RulesPermissionEnum[] = [
      RulesPermissionEnum["rule.list"],
      RulesPermissionEnum["rule.create"],
      RulesPermissionEnum["rule.read"],
      RulesPermissionEnum["rule.update"],
      RulesPermissionEnum["rule.delete"]
    ];
    permissions = permissions.concat(
      zoneRolesForAdmin,
      subjectRolesForAdmin,
      userRolesForAdmin,
      deviceRolesForAdmin,
      rulesForAdmin,
      notificationHandlersForAdmin
    );
  }

  // MANAGER
  if (roles.includes(ZoneUserRolesEnum.MANAGER)) {
    const zoneRolesForManager: ZonePermissionEnum[] = [
      ZonePermissionEnum["zone.list"],
      ZonePermissionEnum["zone.read"]
    ];
    const subjectRolesForManager: SubjectPermissionEnum[] = [
      SubjectPermissionEnum["subject.read"],
      SubjectPermissionEnum["subject.update"],
      SubjectPermissionEnum["subject.delete"],
      SubjectPermissionEnum["subject.list"],
      SubjectPermissionEnum["subject.create"]
    ];
    const userRolesForManager: UserPermissionEnum[] = [
      // UserPermissionEnum["user.read"],
      // UserPermissionEnum["user.list"]
    ];
    const deviceRolesForManager: DevicePermissionEnum[] = [
      DevicePermissionEnum["device.list"],
      DevicePermissionEnum["device.read"],
      DevicePermissionEnum["device.assign_subject"],
      DevicePermissionEnum["device.update_user_settings"]
    ];

    const notificationHandlersForManager: NotificationHandlerPermissionEnum[] =
      [
        NotificationHandlerPermissionEnum["notification_handler.list"],
        NotificationHandlerPermissionEnum["notification_handler.create"],
        NotificationHandlerPermissionEnum["notification_handler.read"],
        NotificationHandlerPermissionEnum["notification_handler.update"],
        NotificationHandlerPermissionEnum["notification_handler.delete"]
      ];
    const rulesForManager: RulesPermissionEnum[] = [
      RulesPermissionEnum["rule.list"],
      RulesPermissionEnum["rule.create"],
      RulesPermissionEnum["rule.read"],
      RulesPermissionEnum["rule.update"],
      RulesPermissionEnum["rule.delete"]
    ];

    permissions = permissions.concat(
      zoneRolesForManager,
      subjectRolesForManager,
      userRolesForManager,
      deviceRolesForManager,
      rulesForManager,
      notificationHandlersForManager
    );
  }

  // MANAGER_LIMITED
  if (roles.includes(ZoneUserRolesEnum.MANAGER_LIMITED)) {
    const zoneRolesForManagerLimited: ZonePermissionEnum[] = [
      ZonePermissionEnum["zone.list"],
      ZonePermissionEnum["zone.read"]
    ];
    const subjectRolesForManagerLimited: SubjectPermissionEnum[] = [
      SubjectPermissionEnum["subject.read"],
      SubjectPermissionEnum["subject.list"]
    ];
    const userRolesForManagerLimited: UserPermissionEnum[] = [
      // UserPermissionEnum["user.read"],
      // UserPermissionEnum["user.list"]
    ];
    const deviceRolesForManagerLimited: DevicePermissionEnum[] = [
      DevicePermissionEnum["device.list"],
      DevicePermissionEnum["device.read"],
      DevicePermissionEnum["device.update_user_settings"]
    ];

    const notificationHandlersForManager: NotificationHandlerPermissionEnum[] =
      [
        NotificationHandlerPermissionEnum["notification_handler.list"],
        NotificationHandlerPermissionEnum["notification_handler.read"]
      ];
    const rulesForManager: RulesPermissionEnum[] = [
      RulesPermissionEnum["rule.list"],
      RulesPermissionEnum["rule.read"]
    ];

    permissions = permissions
      .concat(zoneRolesForManagerLimited)
      .concat(subjectRolesForManagerLimited)
      .concat(userRolesForManagerLimited)
      .concat(deviceRolesForManagerLimited)
      .concat(notificationHandlersForManager)
      .concat(rulesForManager);
  }

  // DATA_READER
  if (roles.includes(ZoneUserRolesEnum.DATA_READER)) {
    const zoneRolesForDataReader: ZonePermissionEnum[] = [
      ZonePermissionEnum["zone.live_monitor"],
      ZonePermissionEnum["zone.generate_report"],
      ZonePermissionEnum["zone.sleep_history"]
    ];
    permissions = permissions.concat(zoneRolesForDataReader);
  }

  // DATA_READER_LIMITED
  if (roles.includes(ZoneUserRolesEnum.DATA_READER_LIMITED)) {
    const zoneRolesForDataReaderLimited: ZonePermissionEnum[] = [
      ZonePermissionEnum["zone.live_monitor"],
      ZonePermissionEnum["zone.generate_report"]
    ];

    permissions = permissions.concat(zoneRolesForDataReaderLimited);
  }
  return _.uniq(permissions);
};

export const isAllowed = (
  loginedUser: IUser,
  routeRoles?: ZoneUserRolesEnum[]
) => {
  if (!routeRoles || !loginedUser) {
    return false;
  }
  return loginedUser?.roles.some((e: ZoneUserRolesEnum) =>
    routeRoles.includes(e)
  );
};
