import { ISession } from "interfaces/sleephistory.interface";

export const FETCH_SLEEP_HISTORY = "FETCH_SLEEP_HISTORY";
export const CLEAR_STATE = "CLEAR_STATE";
export const FETCH_SLEEP_HISTORY_SUCCESS = "FETCH_SLEEP_HISTORY_SUCCESS";
export const FETCH_SLEEP_HISTOR_BY_ID_ACTION =
  "FETCH_SLEEP_HISTOR_BY_ID_ACTION";
export const FETCH_SLEEP_HISTOR_BY_ID_SUCCESS =
  "FETCH_SLEEP_HISTOR_BY_ID_SUCCESS";

export interface clearSleepHistoryActionType {
  type: typeof CLEAR_STATE;
}
export interface fetchSleepHistoryActionType {
  type: typeof FETCH_SLEEP_HISTORY;
}
export interface fetchSleepHistorySuccessActionType {
  type: typeof FETCH_SLEEP_HISTORY_SUCCESS;
  payload: ISession[];
}

export interface fetchSleepHistoryByIDActionType {
  type: typeof FETCH_SLEEP_HISTOR_BY_ID_ACTION;
}
export interface fetchSleepHistoryByIDSuccessActionType {
  type: typeof FETCH_SLEEP_HISTOR_BY_ID_SUCCESS;
  payload: ISession;
}

export type sleepHistoryActionTypes =
  | fetchSleepHistorySuccessActionType
  | fetchSleepHistoryActionType
  | clearSleepHistoryActionType
  | fetchSleepHistoryByIDSuccessActionType
  | fetchSleepHistoryByIDActionType;
