import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { toast } from "react-toastify";
import React from "react";

export const toastSucess = (message: string) => {
  toast(<Message message={message} type="success" />, {
    style: {
      color: "rgba(0, 0, 0, 0.87)",
      border: "1px solid #457E89",
      background: "#E3F6F3"
    }
  });
};

export const toastError = (message: string) => {
  toast(<Message message={message} type="error" />, {
    style: {
      color: "rgba(0, 0, 0, 0.87)",
      border: "1px solid #FFA39E",
      background: "#FFF1F0",
      fontSize: 14
    }
  });
};

export const toastErrorPositionCenter = (message: string) => {
  toast(<MessageNotitle message={message} type="error" />, {
    position: "bottom-center",
    style: {
      color: "rgba(0, 0, 0, 0.87)",
      border: "1px solid #FFA39E",
      background: "#FFF1F0",
      fontSize: 14,
      marginBottom: 0
    }
  });
};

export const toastSuccessPositionCenter = (message: string) => {
  toast(<MessageNotitle message={message} type="success" />, {
    position: "bottom-center",
    style: {
      color: "rgba(0, 0, 0, 0.87)",
      border: "1px solid #457E89",
      background: "#E3F6F3",
      fontSize: 14,
      marginBottom: 0
    }
  });
};

const Message = ({ message, type }: { message: string; type: string }) => (
  <div>
    <div
      style={{ width: "100%", float: "left", paddingLeft: 4, paddingTop: 4 }}
    >
      {type === "error" && (
        <ErrorOutline
          style={{ marginRight: 18, float: "left", color: "#F5222D" }}
        />
      )}
      {type === "success" && (
        <CheckCircleOutline
          style={{ marginRight: 18, float: "left", color: "#457E89" }}
        />
      )}
      <div style={{ float: "left" }}>
        <div style={{ marginBottom: 8 }}>
          {type === "error" ? "Error" : "Success"}
        </div>
        <div>{message}</div>
      </div>
    </div>
  </div>
);

const MessageNotitle = ({
  message,
  type
}: {
  message: string;
  type: string;
}) => (
  <div>
    <div
      style={{ width: "100%", float: "left", paddingLeft: 4, paddingTop: 4 }}
    >
      {type === "error" && (
        <ErrorOutline
          style={{ marginRight: 18, float: "left", color: "#F5222D" }}
        />
      )}
      {type === "success" && (
        <CheckCircleOutline
          style={{ marginRight: 18, float: "left", color: "#457E89" }}
        />
      )}
      <div style={{ float: "left" }}>
        <div>{message}</div>
      </div>
    </div>
  </div>
);
