import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { ApplyMargin } from "styled/StylesStyled";
import {
  AuthorizationContext,
  SubjectPermissionEnum,
  ZonePermissionEnum
} from "authorization/AuthorizationContext";
import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled
} from "styled/ContainerStylesStyled";
import { ButtonAsTextStlyled, TableTitle } from "styled/CommonStyled";
import { ISubject } from "interfaces/subject.interface";
import {
  deleteSubject,
  getAllSubjects
} from "store/actions/administration/subjectActions";
import { gotTo } from "utils/history.util";
import { toastSuccessPositionCenter } from "utils/toast.util";
import Maintitle from "components/SMTitles/MainTitle";
import SMConfirmDialog from "components/SMDialogs/SMConfirmDialog";
import SMGoogleAnalytic from "components/GoogleAnalytic/GoogleAnalytic";
import SleepHistoryModal from "@modules/monitor/SleepHistoryModel";
import SubTitle from "components/SMTitles/SubTitle";
import UseAdminContext from "hooks/UseAdminContext";

import { SubjectDevices } from "./components/SubjectDevices";
import NotificationRulesAndLogs from "../components/NotificationRulesAndLogs";
import SubjectInformation from "./components/SubjectInformation";

import { getTenantByID } from "store/actions/administration/tenantActions";
import AdditionalSubjectInformation from "./components/AdditionalSubjectInformation";


export const SubjectViewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [subject, setSubject] = useState<ISubject>();
  const [nosubject, setNoSubject] = useState<boolean>(false);
  const { permissions } = React.useContext(AuthorizationContext);

  const [dashboardModalSubject, setDashboardModalSubject] = useState<{
    id: string;
    identifier: string;
    birth_year: number;
    sex: string;
  }>({
    id: "",
    identifier: "",
    birth_year: 0,
    sex: ""
  });

  // context state
  const { setCurrentPath, selectedZone, setSelectedZone, zoneEntity } =
    UseAdminContext();

  const subjectState = useSelector((state: any) => {
    return state.subjectReducer || {};
  });

  const tenantState = useSelector((state: any) => {
    return state.tenantReducer || {};
  });


  // load subjects if not loaded
  useEffect(() => {
    setNoSubject(false);
    if (zoneEntity && selectedZone && zoneEntity[selectedZone]) {
      dispatch(getAllSubjects(selectedZone));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoneEntity, selectedZone]);

  // filter current subject
  useEffect(() => {
    if (subjectState && subjectState.subjects) {
      const sub: ISubject = subjectState.subjects.find(
        (item: ISubject) => item.id === id
      );
      if (sub) {
        if (setCurrentPath) {
          setCurrentPath({ id: sub.id, name: sub.identifier });
        }
        setSubject(sub);
      } else {
        // no device found
        setNoSubject(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectState]);


  useEffect(() => {
  if (subject && subject.tenant_id) {
    dispatch(getTenantByID(subject.tenant_id))
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  // delete subject callback
  const subjectDeleteCB = () => {
    // show toast
    toastSuccessPositionCenter(t("Subject deleted successfully"));
    gotTo("adminstration/zones/subjects");
  };

  // delete subject
  const onDelete = () => {
    if (subject) {
      dispatch(deleteSubject(subject, subjectDeleteCB));
    }
  };

  const openSleepHistory = () => {
    if (subject?.id) {
      setDashboardModalSubject({
        id: subject.id,
        identifier: subject.identifier,
        sex: subject.sex,
        birth_year: Number(subject.birth_year)
      });
    } else {
      setDashboardModalSubject({
        id: "",
        identifier: "",
        birth_year: 0,
        sex: ""
      });
    }
  };
  if (!subjectState?.subjects) {
    return <div />;
  }

  if (nosubject) {
    return <BoxPadding pad="0px 25px">{t("no_subject_found")}</BoxPadding>;
  }

  // has permission
  const hasSleepHistoryPermission = () => {
    if (!permissions) {
      return false;
    }
    if (
      permissions.includes(ZonePermissionEnum["zone.sleep_history"]) ||
      permissions.includes(ZonePermissionEnum["zone.live_monitor"])
    ) {
      return true;
    }
    return false;
  };

  const hasRulesPermission = () => {
    return true;
  };

  const disableUpdate = () => {
    return permissions.indexOf(SubjectPermissionEnum["subject.update"]) === -1;
  };

  return (
    <>
      {/* Add google analytic */}
      <SMGoogleAnalytic title="Subject View Page" />

      <BoxPadding pad="0px 24px 24px 4px">
        <ApplyMargin bottom={21}>
          <Maintitle text={subject?.identifier || ""} showBack />
        </ApplyMargin>
        <Grid container rowSpacing={0} columnSpacing={2.5}>
          <Grid item xs={12} md={8}>
            {subject && (
              <SubjectInformation subject={subject} disable={disableUpdate()} />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            {subject && (
              <SubjectDevices
                devices={subject?.devices?.data || []}
                subject={subject}
                setSelectedZone={setSelectedZone}
              />
            )}
          </Grid>

          { subject && tenantState?.tenant && tenantState?.tenant?.custom_fields && tenantState?.tenant?.custom_fields?.length !== 0 && (

              <Grid item xs={12} md={12} sx={{ marginTop: 2.5 }}>
                <AdditionalSubjectInformation
                  subject={subject}
                  tenant={tenantState.tenant}
                  disable={disableUpdate()}
                />
            </Grid>
            )}

          {hasSleepHistoryPermission() && (
            <Grid item xs={12} md={12} sx={{ marginTop: 2.5 }}>
              <BoxSubSectionStyled>
                <SubTitle showEdit={false} text={t("Sleep history")} />
                <BoxSubSectionContentStyled>
                  <ButtonAsTextStlyled onClick={openSleepHistory}>
                    {t("view_this_users_sleep_history")}
                  </ButtonAsTextStlyled>
                </BoxSubSectionContentStyled>
              </BoxSubSectionStyled>
            </Grid>
          )}

          {hasRulesPermission() && subject?.id && (
            <Grid item xs={12} md={12} sx={{ marginTop: 2.5 }}>
              <BoxSubSectionStyled>
                <Box sx={{ width: "100%", clear: "both" }}>
                  <TableTitle
                    bg="light"
                    border="light"
                    sx={{
                      borderRadius: "8px 8px 0px 0px",
                      padding: "16.5px 20px",
                      textTransform: "uppercase",
                      fontSize: 16
                    }}
                  >
                    {t("notification_rules_and_logs")}
                  </TableTitle>
                </Box>
                <NotificationRulesAndLogs type="subject" entityId={id} />
              </BoxSubSectionStyled>
            </Grid>
          )}

          <Grid item xs={12} md={12} sx={{ marginTop: "10px" }}>
            {subject && !disableUpdate() && (
              <SMConfirmDialog
                title={t("Delete subject")}
                dialogDesc={t(
                  "deleting_this_subject_will_also_delete_the_sleep_data_associated_with_the_subject"
                )}
                confirmPlaceHolder={t("Type confirmation text here")}
                confirmTextDesc={t("TYPE_IAM_SURE_PLACEHOLDER")}
                onDelete={onDelete}
                buttonOk={t("Delete")}
                buttonCancel={t("Cancel")}
                confirmText={t("I AM SURE")}
                okButtonBg="red"
              />
            )}
          </Grid>
        </Grid>
        <SleepHistoryModal
          setDashboardModalSubject={setDashboardModalSubject}
          dashboardModalSubject={dashboardModalSubject}
        />
      </BoxPadding>
    </>
  );
};

export default SubjectViewPage;