import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";

import { IconButtonStyled } from "styled/CommonStyled";
import { ReactComponent as SortIcon } from "assets/icons/new/table-sort.svg";
import {
  TableHeadDataStyled,
  TableHeadRowStyled
} from "styled/TableElementStyled";
/**
 * Sort order
 */
type Order = "asc" | "desc";

export type HeadCell = {
  id: string;
  label: string;
  hideSort?: boolean;
  textCenter?: boolean;
  hideColumn?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type EnhancedTableProps<T> = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
  stylesPropHead?: { [key: string]: { [key: string]: string } };
  dynamic_padding?: number;
};

const SMTableHeader = React.memo(function SMTableHeader<T>(
  props: EnhancedTableProps<T>
) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    stylesPropHead,
    dynamic_padding
  } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableHeadRowStyled
        disable_row_hover={0}
        dynamic_padding={dynamic_padding}
      >
        {headCells
          .filter((headCell: HeadCell) => !headCell.hideColumn)
          .map((headCell: HeadCell) => (
            <TableCell
              key={headCell.label}
              sortDirection={orderBy === headCell.id ? order : false}
              style={
                stylesPropHead
                  ? stylesPropHead[`${headCell.id.toString()}`]
                  : undefined
              }
            >
              <TableHeadDataStyled
                active={orderBy === headCell.id ? 1 : 0}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={
                  !headCell.hideSort ? createSortHandler(headCell.id) : () => {}
                }
                cursordefault={headCell.hideSort ? 1 : 0}
                txtcenter={headCell.textCenter ? 1 : 0}
              >
                <div>
                  <div> {headCell.label} </div>
                  {!headCell.hideSort && (
                    <IconButtonStyled padding={0} width="24px">
                      <SortIcon />
                    </IconButtonStyled>
                  )}
                </div>
              </TableHeadDataStyled>
            </TableCell>
          ))}
      </TableHeadRowStyled>
    </TableHead>
  );
});

export default SMTableHeader;
