import { INightReport } from "interfaces/nightReport.interface";

export const FETCH_NIGHT_REPORT_V1 = "FETCH_NIGHT_REPORT_V1";
export const FETCH_NIGHT_REPORT_V1_SUCCESS = "FETCH_NIGHT_REPORT_V1_SUCCESS";
export const CLEAR_NIGHT_REPORT = "CLEAR_NIGHT_REPORT";

export interface clearNightReportV1ActionType {
  type: typeof CLEAR_NIGHT_REPORT;
}
export interface fetchNightReportV1ActionType {
  type: typeof FETCH_NIGHT_REPORT_V1;
}
export interface fetchNightReportV1SuccessActionType {
  type: typeof FETCH_NIGHT_REPORT_V1_SUCCESS;
  payload: INightReport;
}

export type nightReportV1ActionTypes =
  | fetchNightReportV1ActionType
  | fetchNightReportV1SuccessActionType
  | clearNightReportV1ActionType;
