import React, { useContext } from "react";

import { DividerRow } from "./SplitPanelStyledComponents";
import SplitPaneContext from "./SplitPanelContext";

export const SplitPanelDivider = ({
  cursor
}: {
  cursor?: "row-resize" | "col-resize" | "ew-resize";
}) => {
  const PaneContext = useContext(SplitPaneContext);
  return (
    <DividerRow
      onMouseDown={(e: any) => PaneContext?.onMouseHoldDown(e)}
      onTouchStart={(e: any) => PaneContext?.onMouseHoldDown(e)}
      style={{ cursor }}
    >
      <div />
    </DividerRow>
  );
};
