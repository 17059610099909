import {
  Controller,
  SubmitHandler,
  useFormContext
} from "react-hook-form";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";

import { ApplyMargin } from "styled/StylesStyled";
import { IUser } from "interfaces/user.interface";
import { InputLabelStyled } from "styled/CommonStyled";
import SMFormTextField from "components/SMTextField/SMFormTextField";

function UserForm({
  user,
  onSubmit,
  disabled
}: {
  user: IUser;
  onSubmit: SubmitHandler<IUser>;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const { resetField, handleSubmit, control } = useFormContext<IUser>();

  const submitForm = () => {
    // reset tree, since zone update is performed by a different action, not here.
    resetField("zones.data");
    handleSubmit(onSubmit)();
  };
  return (
    <Grid container rowSpacing={0} columnSpacing={2.4}>
      <Grid item xs={12} md={6}>
        <ApplyMargin top={4}>
          <InputLabelStyled maginbottom={6}>{t("First Name")}</InputLabelStyled>
        </ApplyMargin>
        <Controller
          rules={{ required: `${t("error_message_employee_first_name")}` }}
          name="first_name"
          control={control}
          render={({ field, fieldState, formState }) => {
            return (
              <SMFormTextField<IUser>
                field={field}
                disabled={disabled}
                formState={formState}
                fieldState={fieldState}
                inlineEdit={!!user.id}
                placeholder={t("user_first_name_placeholder")}
                onCancel={() => {
                  resetField("first_name");
                }}
                onUpdate={submitForm}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ApplyMargin top={4}>
          <InputLabelStyled maginbottom={6}>{t("Last Name")}</InputLabelStyled>
          <Controller
            name="last_name"
            control={control}
            rules={{ required: `${t("error_message_employee_last_name")}` }}
            render={({ field, fieldState, formState }) => (
              <SMFormTextField<IUser>
                field={field}
                disabled={disabled}
                formState={formState}
                fieldState={fieldState}
                inlineEdit={!!user.id}
                placeholder={t("user_last_name_placeholder")}
                onCancel={() => {
                  resetField("last_name");
                }}
                onUpdate={submitForm}
              />
            )}
          />
        </ApplyMargin>
      </Grid>

      <Grid item xs={12} md={6}>
        <ApplyMargin top={24}>
          <InputLabelStyled maginbottom={6}> {t("Email")}</InputLabelStyled>
          <Controller
            rules={{
              required: `${t("error_message_employee_valid_email")}`,
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: `${t("error_message_employee_valid_email")}`
              }
            }}
            name="email"
            control={control}
            render={({ field, fieldState, formState }) => (
              <SMFormTextField<IUser>
                field={field}
                disabled={disabled}
                formState={formState}
                fieldState={fieldState}
                inlineEdit={!!user.id}
                placeholder={t("user_email_placeholder")}
                onCancel={() => {
                  resetField("email");
                }}
                onUpdate={submitForm}
              />
            )}
          />
        </ApplyMargin>
      </Grid>
      <Grid item xs={12} md={6}>
        <ApplyMargin top={24}>
          <InputLabelStyled maginbottom={6}>{t("Job Title")}</InputLabelStyled>
          <Controller
            name="profession"
            control={control}
            render={({ field, fieldState, formState }) => {
              return (
                <SMFormTextField<IUser>
                  field={field}
                  disabled={disabled}
                  formState={formState}
                  fieldState={fieldState}
                  inlineEdit={!!user.id}
                  placeholder={disabled ? "" : t("user_title_placeholder")}
                  onCancel={() => {
                    resetField("profession");
                  }}
                  onUpdate={submitForm}
                />
              );
            }}
          />
        </ApplyMargin>
      </Grid>
    </Grid>
  );
}

export default UserForm;
