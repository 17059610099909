import {
  SubmitHandler,
  useForm
} from "react-hook-form";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled
} from "styled/ContainerStylesStyled";
import { ISubject } from "interfaces/subject.interface";
import { updateSubject } from "store/actions/administration/subjectActions";
import { resetRulesState } from "store/actions/rules/rules";
import SubTitle from "components/SMTitles/SubTitle";
import UseAdminContext from "hooks/UseAdminContext";
import { ITenantPublic } from "interfaces/tenant.interface";

import CustomFieldGridItem from "../../../../../components/CustomField/CustomField";


export default function AdditionalSubjectInformation({
  create = false,
  subject,
  tenant,
  disable
}: {
  create?: boolean;
  subject: ISubject;
  tenant: ITenantPublic;
  disable?: boolean;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  // use context
  const { setSelectedZone } = UseAdminContext();

  const default_custom_fields: { [key: string]: string } = {};

  for (const keys in Object.keys(tenant.custom_fields)) {
    var cf_id = tenant.custom_fields[keys].id;
    default_custom_fields[cf_id] = (subject?.custom_fields?.[cf_id] ? subject.custom_fields[cf_id] : "0");
  };


  const {
    handleSubmit,
    control,
    reset,
    getValues,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setFocus,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors }
  } = useForm<ISubject>({
    defaultValues: {
      ...subject,
      // preset-values
      custom_fields: default_custom_fields
    },
    mode: "onTouched"
  });

  const resetFormFields = () => {
    reset({
      ...subject,
      // preset-values
      custom_fields: subject?.custom_fields || {}
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    resetFormFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  const updateSubjectErrorCB = () => {
    resetFormFields();
  };

  const successCallback = () => {
    const data: ISubject = getValues();
    if (setSelectedZone) {
      setSelectedZone(data.zone_id);
    }
  };

  const onSubmit: SubmitHandler<ISubject> = (data: ISubject) => {
    // The custom field values are set to "0" if the user does not select a value.
    // We don't want to send this to the backend, so we remove these fields.
    if (!data) {
      data = getValues();
    };

    const refreshRules = data.zone_id !== subject?.zone_id;
    for (const key in data.custom_fields) {
      if (key && data.custom_fields[key] && data.custom_fields[key] === "0") {
        delete data.custom_fields[key];
      }
    }
    dispatchUpdateSubject(data, false, refreshRules);
  };

  // dispatch subject
  const dispatchUpdateSubject = (
    sub: ISubject,
    removeDevice: boolean,
    refreshRules?: boolean
  ) => {
    if (refreshRules) {
      // reset rules, becuase zone changed
      dispatch(resetRulesState());
    }
    dispatch(
      updateSubject({
        subject: sub,
        removeDevice,
        updateSubjectErrorCB,
        successCallback,
        refreshRules: !!refreshRules
      })
    );
  };

  return (
      <div>
      <BoxSubSectionStyled>
        <SubTitle showEdit={false} text={t("additional_subject_information_title")} defaultVal />
          <BoxSubSectionContentStyled>
            <Grid container rowSpacing={0} columnSpacing={2.5}>
            
            { tenant && tenant?.custom_fields ? (
              Object.keys(tenant.custom_fields).map((key: string) =>
                <CustomFieldGridItem
                  create={create}
                  // @ts-ignore to ignore index type not found
                  custom_field={tenant?.custom_fields[key]}
                  control={control}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                />
              )
            ) :
            (
              <></>
            )}
            </Grid>
          </BoxSubSectionContentStyled>

      </BoxSubSectionStyled>
    </div>
  );
}