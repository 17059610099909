import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import axios from "axios";

import * as LOCAL_STORAGE from "utils/localStorage";
import { ADMINSTRATION_DEVICES, PROD_API_BASE_URL } from "constants/constants";
import { IUser } from "interfaces/user.interface";
import { ZoneUserRolesEnum } from "constants/userContstants";
import { clearCookies } from "utils/auth/auth.util";
import { getAuthUser } from "store/actions/auth/authActions";
import { gotTo } from "utils/history.util";
import { isAllowed } from "authorization/authorization.util";
import { removeLanguagePreference, setLanguage } from "utils/localStorage";

const UseAuthenticatedUser = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const location = useLocation();
  const [authUser, setAuthUser] = useState<IUser | false>();
  const authState: any = useSelector((state: any) => {
    return state.authReducerV1 || {};
  });

  useEffect(() => {
    if (process.env.REACT_APP_PRODUCTION === "false") {
      const baseURL = LOCAL_STORAGE.getCustomAPIEndPoint() || PROD_API_BASE_URL;
      axios.defaults.baseURL = baseURL;
    } else {
      axios.defaults.baseURL = PROD_API_BASE_URL;
    }
    const token = Cookies.get("access_token");
    if (token) {
      dispatch(getAuthUser());
    } else {
      setAuthUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // refresh on auth user on
  useEffect(() => {
    
    const onFocus = () => {
      const token = Cookies.get("access_token");
      if (!token) {
        clearCookies();
        setAuthUser(false);
      }
      if (document.visibilityState === "visible") {
        if (token) {
          dispatch(getAuthUser());
        }
      }
    };

    document.addEventListener("visibilitychange", onFocus);
    return () => {
      document.removeEventListener("visibilitychange", onFocus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authState.user) {
      setAuthUser(authState.user);
      // set email
      Cookies.set("logged_user_email", authState.user.email);
      // set the language preference
      if (authState.user.preferences?.language) {
        setLanguage(authState.user.preferences?.language);
        //
        i18n.changeLanguage(authState.user.preferences?.language);
      }

      if (
        !isAllowed(authState.user, [
          ZoneUserRolesEnum.DATA_READER,
          ZoneUserRolesEnum.DATA_READER_LIMITED
        ])
      ) {
        if (location.pathname.includes("monitor/overview")) {
          gotTo(ADMINSTRATION_DEVICES);
        }
      }
    } else if (Object.keys(authState).length !== 0) {
      setAuthUser(false);
      clearCookies();

      // remove language preference
      removeLanguagePreference();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.user]);

  return { authUser };
};

export default UseAuthenticatedUser;
