import PropTypes from "prop-types";
import React from "react";
import { Typography } from "@mui/material";

function SingleTextField({ title, text }) {
  return (
    <div>
      <Typography align="center">{title}</Typography>
      <Typography
        variant="h4"
        align="center"
        sx={{ overflowWrap: "break-word" }}
        dangerouslySetInnerHTML={{ __html: text || "-" }}
      />
      {/* >{text || " - "}
      </Typography> */}
    </div>
  );
}

SingleTextField.propTypes = {
  title: PropTypes.any,
  text: PropTypes.any
};

export default SingleTextField;
