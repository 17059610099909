import React from "react";
import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import DehazeIcon from "@mui/icons-material/Dehaze";
import Divider from "@mui/material/Divider";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";

import { ButtonStyled } from "styled/CommonStyled";
import { IColorValue } from "interfaces/color.interface";
import { SMMenuProps } from "interfaces/menu.interface";

export default function CustomizedMenus({
  onClick,
  menus,
  menuLabel,
  manuLabelComponent,
  hoverColor,
  title
}: {
  onClick: (route: SMMenuProps) => void;
  menus: SMMenuProps[];
  menuLabel?: string;
  manuLabelComponent?: JSX.Element;
  hoverColor?: IColorValue;
  title?: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<
    null | HTMLElement | SVGSVGElement
  >(null);
  const open = Boolean(anchorEl);
  const ref = React.useRef<any>();
  const handleClick = (
    event: React.MouseEvent<HTMLElement> | React.MouseEvent<SVGSVGElement>
  ) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  };

  const handleClose = (
    e: React.MouseEvent<HTMLElement>,
    routeValue: string
  ) => {
    if (routeValue !== "backdropClick") {
      const route = menus.find(
        (item: SMMenuProps) => item.value === routeValue
      );
      if (route) {
        onClick(route);
      }
    }
    setAnchorEl(null);
  };

  const getMenuButtonComponent = () => {
    if (menuLabel) {
      return (
        <ButtonStyled
          aria-haspopup="true"
          variant="contained"
          disableElevation
          onClick={handleClick}
        >
          {menuLabel || ""}
        </ButtonStyled>
      );
    }
    return (
      <IconButtonStyled onClick={handleClick}>
        <DehazeIcon />
      </IconButtonStyled>
    );
  };
  return (
    <div ref={ref}>
      {!manuLabelComponent ? (
        getMenuButtonComponent()
      ) : (
        <DivStyled onClick={handleClick} aria-hidden>
          {manuLabelComponent}
        </DivStyled>
      )}
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        container={ref?.current}
        hover={hoverColor}
      >
        {title && (
            <MenuItem
              disableRipple
              sx={{
                "&:hover": {
                  background: "none"
                }
              }}
            >
              <Box sx={{ width: "32px", height: "26px" }} />
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px"
                }}
              >
                {title}
              </Box>
            </MenuItem>
        )}

        <DividerStyled sx={{ my: 0.5 }} />
        {menus.map((item: SMMenuProps, index: number) => {
          if (item.hide) {
            return <div key={item.value} />;
          }
          return (
            <div key={item.value}>
              <MenuItem
                onClick={(e: any) => handleClose(e, item.value)}
                disableRipple
              >
                {item?.icon ? <span>{item.icon}</span> : ""} {item.label}
              </MenuItem>
              {index !== menus.length - 1 && !item.hideDivider && (
                <DividerStyled sx={{ my: 0.5 }} />
              )}
            </div>
          );
        })}
      </StyledMenu>
    </div>
  );
}

const IconButtonStyled = styled(IconButton)(
  ({ children }: { children?: React.ReactNode }) => {
    const theme = useTheme();
    return {
      color: theme.palette.main[60],
      cursor: "pointer",
      padding: 5,
      borderRadius: 4,
      height: "auto",
  
      "&:hover": {
        background: "#FFF"
      }
    };
  }
);

const DivStyled = styled("div")(
  ({ children }: { children?: React.ReactNode }) => {
    return {
      position: "relative",
      minHeight: 34
    };
  }
);

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ hover }: { hover?: IColorValue }) => {
  const theme = useTheme();
  return {
    "& .MuiPaper-root": {
      borderRadius: 6,
      minWidth: 180,
      color: "rgb(55, 65, 81)",
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0"
      },
      "& .MuiMenuItem-root": {
        paddingLeft: 16,
        minHeight: 36.5,
        fontSize: 14,
        fontFamily: "Roboto",
        "&:active": {},
        "&:hover": {
          background: hover ?? theme.palette.neutral[3]
        }
      },
      "& svg": {
        marginRight: 8,
        marginTop: 2,
        float: "left"
      },
      "& .MuiList-root": {
        padding: 0
      }
    }
  };
});

export const MenuItemMainStyled = styled(MenuItem)(
  ({  children }: { children: any }) => {
    return {
      position: "relative",
      padding: "10px 16px 10.5px 12px !important" as any,
      fontWeight: 500,
      "&:hover": {
        background: "none"
      }
    };
  }
);

export const DividerStyled = styled(Divider)(() => {
  return {
    margin: "0 !important" as any,
    "& .MuiDivider-root": {
      margin: 0
    }
  };
});
