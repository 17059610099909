import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { Suspense } from "react";

import SMTabPanel from "components/SMTabPanel/SMTabPanel";
import SMTabs from "components/SMTabPanel/SMTabs";
import UseAdminContext from "hooks/UseAdminContext";

// lazy load component
const RulesScreen = React.lazy(() => import("../rules/RulesScreen"));
const LogsScreen = React.lazy(
  () => import("../notificationLogs/NotificationLogsScreen")
);

function NotificationRulesAndLogs({
  type,
  entityId
}: {
  type?: "zone" | "subject";
  entityId?: string;
}) {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const { selectedZone } = UseAdminContext();

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newTabIndex: number
  ) => {
    setTabIndex(newTabIndex);
  };

  const tabs = [
    {
      id: "2",
      title: t("zone_rules")
    },
    {
      id: "1",
      title: t("log")
    }
  ];
  return (
    <Box sx={{ clear: "both" }}>
      <SMTabs
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
      />
      <Box>
        {/* ***************************************************** */
        /*            Notification Logs                           */
        /* ***************************************************** */}
        <SMTabPanel value={tabIndex} index={0}>
          <Suspense fallback={<span>Loading...</span>}>
            <RulesScreen
              entityId={entityId || selectedZone}
              ruleEntityType={type || "zone"}
            />
          </Suspense>
        </SMTabPanel>

        <SMTabPanel value={tabIndex} index={1}>
          {/* ***************************************************** */
          /*            Notification Rules                          */
          /* *****************************************************  */}
          {selectedZone && (
            <Suspense fallback={<span>Loading...</span>}>
              <LogsScreen
                entityId={entityId || selectedZone}
                logEntityType={type || "zone"}
              />
            </Suspense>
          )}
        </SMTabPanel>
      </Box>
    </Box>
  );
}

export default NotificationRulesAndLogs;
