import {
  NightReportItem,
  NightReportItemMetric,
  NightReportItemStatus
} from "interfaces/nightReport.interface";
import { NightReportSortTypes } from "constants/app.enum";

import i18n from "../i18n/config";

export const getNightReportValueRounded = ({
  value,
  round,
  percnt = false,
  plus = true
}: {
  value: number;
  round: number;
  percnt?: boolean;
  plus?: boolean;
}): string => {
  if (!value) {
    return "";
  }
  if (round > 0) {
    return ` (${value > 0 && plus ? "+" : ""}${value.toFixed(round)}${
      percnt ? " %" : ""
    })`;
  }
  return ` (${value > 0 && plus ? "+" : ""}${Math.round(value)}${
    percnt ? " %" : ""
  })`;
};

export const getNightReportValueDifference = ({
  item,
  baseline,
  pct,
  round
}: {
  item: NightReportItemMetric;
  baseline: boolean;
  pct: boolean;
  round: number;
}): string => {
  if (baseline) {
    return getNightReportValueRounded({
      value: item?.baseline,
      round,
      plus: false
    });
  }
  if (pct && item?.deviation_pct) {
    return getNightReportValueRounded({
      value: +item?.deviation_pct,
      round,
      percnt: true
    });
  }
  if (!pct && item?.deviation) {
    return getNightReportValueRounded({ value: item?.deviation, round });
  }
  return "";
};

export const getRoundedNightReportValue = (
  value: number | undefined,
  valuRound: boolean
) => {
  if (value) {
    if (valuRound) {
      return Math.round(value);
    }
    return value;
  }
  return value === 0 ? "0" : i18n.t("N/A");
};

export const NightReportSortOrder = {
  [NightReportItemStatus.PRESENCE_AND_SLEEP]: 1,
  [NightReportItemStatus.NO_SLEEP]: 2,
  [NightReportItemStatus.NO_PRESENCE]: 3
};

export const sortNightReportByType = (
  items: NightReportItem[],
  order: "ASC" | "DESC",
  sortType: NightReportSortTypes
) => {
  const sotedItems = items.slice();

  const absoluteValueComparison = (a: number, b: number) => {
    // sort by absolute value
    if (Math.abs(a) < Math.abs(b)) {
      return -1;
    }
    if (Math.abs(a) > Math.abs(b)) {
      return 1;
      // sort identical absolute values in numerical order
    }
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  switch (sortType) {
    case NightReportSortTypes.DeviceSort:
      sotedItems.sort((a: NightReportItem, b: NightReportItem) =>
        `${a?.device?.name}`.localeCompare(`${b.device?.name}`)
      );
      break;
    case NightReportSortTypes.RespirationRateSort:
      sotedItems.sort((a: NightReportItem, b: NightReportItem) =>
        absoluteValueComparison(
          a.respiration_rate?.value || 0,
          b.respiration_rate?.value || 0
        )
      );
      break;
    case NightReportSortTypes.MovementSort:
      sotedItems.sort((a: NightReportItem, b: NightReportItem) =>
        absoluteValueComparison(a.movement?.value || 0, b.movement?.value || 0)
      );
      break;
    case NightReportSortTypes.TotalSleepTimeSort:
      sotedItems.sort((a: NightReportItem, b: NightReportItem) =>
        absoluteValueComparison(
          a.time_asleep?.value || 0,
          b.time_asleep?.value || 0
        )
      );
      break;
    case NightReportSortTypes.SleepScoreSort:
      sotedItems.sort((a: NightReportItem, b: NightReportItem) =>
        absoluteValueComparison(
          a.sleep_score?.value || 0,
          b.sleep_score?.value || 0
        )
      );
      break;
    case NightReportSortTypes.OutOfBedSort:
      sotedItems.sort((a: NightReportItem, b: NightReportItem) =>
        absoluteValueComparison(
          a.out_of_bed?.value || 0,
          b.out_of_bed?.value || 0
        )
      );
      break;
    case NightReportSortTypes.BaselineNightsSort:
      sotedItems.sort((a: NightReportItem, b: NightReportItem) =>
        absoluteValueComparison(
          a.baseline_night_count || 0,
          b.baseline_night_count || 0
        )
      );
      break;
    case NightReportSortTypes.UserSort:
      sotedItems.sort((a: NightReportItem, b: NightReportItem) =>
        `${a?.user.display_name}`.localeCompare(`${b.user?.display_name}`)
      );
      break;
    default:
      break;
  }

  if (order === "DESC") {
    sotedItems.reverse();
  }
  sotedItems.sort(
    (a: NightReportItem, b: NightReportItem) =>
      NightReportSortOrder[a.status] - NightReportSortOrder[b.status]
  );

  return sotedItems;
};

// build comparison function
