import {
  FieldErrors,
  FormProvider,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";

import { ApplyMargin } from "styled/StylesStyled";
import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled
} from "styled/ContainerStylesStyled";
import { IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";
import {
  createUser,
  updateUser,
  updateUserZone
} from "store/actions/administration/userActionsV2";
import { getAuthUser } from "store/actions/auth/authActions";
import { goBack, gotTo } from "utils/history.util";
import { toastSuccessPositionCenter } from "utils/toast.util";
import SMUpdateCancel from "components/SMUpdateCancel/SMUpdateCancel";
import SubTitle from "components/SMTitles/SubTitle";
import getErrorInputElement from "utils/common/getErrorInputElement";

import UserForm from "./UserForm";
import UserZonesAndRoles from "./UserZonesAndRoles";

export default function UserInformation({
  create = false,
  user,
  disabled = false,
  authUser
}: {
  create?: boolean;
  user: IUser;
  disabled?: boolean;
  authUser: IUser;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // refresh authencticated user
  useEffect(() => {
    dispatch(getAuthUser());
  }, [dispatch]);

  const methods = useForm<IUser>({
    defaultValues: {
      ...user,
      profession: user.profession || ""
    },
    mode: "onTouched"
  });

  // reset
  const resetFormFields = () => {
    methods.reset({
      ...user,
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      profession: user.profession || "",
      roles: user.roles || [],
      zones: user.zones || {}
    });
  };
  
  useEffect(() => {
    resetFormFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onRolesUpdate = () => {
    if (user.id) {
      methods.resetField("zones.data");
      methods.handleSubmit(onSubmit)();
    }
  };

  const onZoneUpdate = (slctdZones: IZone[]) => {
    const formValues = methods.getValues();
    if (user.id) {
      const selectedZoneIds =
        formValues.zones?.data.map((item: IZone) => item.id) || [];
      const currentZoneIds =
        user.zones?.data?.map((item: IZone) => item.id) || []

      const toDeleteIds =
        currentZoneIds?.filter((x) => !selectedZoneIds.includes(x)) || [];
      const toAddIds =
        selectedZoneIds.filter((x) => !currentZoneIds.includes(x)) || [];

      const toDelete: IZone[] =
        user.zones?.data?.filter(
          (item) => toDeleteIds.indexOf(item.id) !== -1
        ) || [];
      const toAdd: IZone[] =
        formValues.zones?.data?.filter(
          (item) => toAddIds.indexOf(item.id) !== -1
        ) || [];
      dispatch(updateUserZone({ data: formValues, toAdd, toDelete }));
    }
    return true;
  };

  // Create subject success callback
  const createUserSuccessCB = (item: IUser) => {
    // show toast message
    // const topZone = item.zones?.data[0];
    // if (topZone) {
    //   if (setSelectedZone) {
    //     setSelectedZone(topZone.id);
    //   }
    // }
    toastSuccessPositionCenter(t("employee_created_successfully"));

    // redirect to the user view page
    gotTo(`adminstration/zones/users/${item.id}`);
  };

  const updateUserErrorCB = () => {
    resetFormFields();
  };
  const errorFocusOrder: { [key: string]: string } = {
    first_name: "a",
    last_name: "b",
    email: "c"
  };

  function onErrors(err: FieldErrors<IUser>) {
    const { elem, key } = getErrorInputElement<IUser>(err, errorFocusOrder);
    // set the focus
    if (elem && key !== "roles") {
      elem.focus();
    }
  }

  const onSubmit: SubmitHandler<IUser> = (data) => {
    if (data.id) {
      dispatch(updateUser(data, updateUserErrorCB));
    } else {
      dispatch(createUser(data, createUserSuccessCB));
    }
  };

  return (
    <div>
      <FormProvider {...methods}>
        <BoxSubSectionStyled>
          <SubTitle
            showEdit={false}
            text={t("employee_information")}
            defaultVal
          />
          <BoxSubSectionContentStyled>
            <UserForm user={user} onSubmit={onSubmit} disabled={disabled} />
          </BoxSubSectionContentStyled>
        </BoxSubSectionStyled>
        <ApplyMargin top={20}>
          <BoxSubSectionStyled>
            <SubTitle text={t("Zones and Roles")} />
            <BoxSubSectionContentStyled>
              <UserZonesAndRoles
                user={user}
                onZoneUpdate={onZoneUpdate}
                onRolesUpdate={onRolesUpdate}
                disabled={disabled}
                authUser={authUser}
              />
            </BoxSubSectionContentStyled>
          </BoxSubSectionStyled>
        </ApplyMargin>
        {!user.id ? (
          <SMUpdateCancel
            onCancel={() => goBack()}
            onUpdate={methods.handleSubmit(onSubmit, onErrors)}
            create
          />
        ) : (
          ""
        )}
      </FormProvider>
    </div>
  );
}
