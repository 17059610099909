import {
  Box,
  IconButton,
  TextField,
  TextFieldProps
} from "@mui/material";
import { ControllerRenderProps } from "react-hook-form";
import { SystemCssProperties, styled } from "@mui/system";
import React, { ChangeEvent, useRef } from "react";
import { useTheme } from "@mui/material/styles";


import { ReactComponent as CheckMark } from "assets/icons/new/check-mark.svg";
import { ReactComponent as Cross } from "assets/icons/new/cross.svg";
import { ErrorMessage } from "styled/StylesStyled";
import { InlineButtonsStyled } from "styled/CommonStyled";

export const TextFieldWrapper = styled("div")(() => {
  return {
    position: "relative"
  };
});

export const TextFieldStyled = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "focusedStyle" && prop !== "borderRed" && prop !== "disabledInput"
})(
  ({
    focusedStyle = 0,
    borderRed,
    width,
    masked_input,
    disabledInput
  }: {
    focusedStyle?: number;
    borderRed: number;
    width?: number | string;
    masked_input: number;
    disabledInput?: number;
  }) => {
    const theme = useTheme(); 
    const border = () => {
      if (borderRed) {
        return `1px solid ${theme.palette.red[50]}`;
      }
      if (focusedStyle) {
        return `1px solid ${theme.palette.main[70]}`;
      }
      return `1px solid ${theme.palette.neutral[5]}`;
    };
    return {
      WebkitTextSecurity: masked_input ? "disc" : "none",
      "& .Mui-disabled": {
        // background: theme.palette.neutral[5],
        color: theme.palette.neutral[80],
        WebkitTextFillColor: theme.palette.neutral[80],
        cursor: "not-allowed"
      },
      width: width || "100%",
      minWidth: 150,
      height: 48,
      border: border(),
      background: disabledInput ? theme.palette.neutral[5] : "none",
      boxShadow: focusedStyle
        ? `0px 0px 4px ${
            borderRed ? theme.palette.red[50] : theme.palette.main[70]
          }`
        : "",
      borderRadius: focusedStyle ? "2px" : 4,
      "& input": {
        padding: "12px 8px",
        fontSize: 16,
        color: theme.palette.neutral[70],
        "&.Mui-disabled": {
          cursor: "not-allowed"
          // background: theme.palette.neutral[5]
        }
      },
      "& input:disabled": {}
    };
  }
);

type ISMTextFieldProps = Omit<TextFieldProps, "variant" | "autoComplete"> & {
  field: ControllerRenderProps<any, any>;
  formState: any;
  fieldState: any;
  inlineEdit?: boolean;
  onCancel?: () => void;
  onUpdate?: () => void;
  withFocusStyle?: boolean;
  width?: number | string;
  maskedInput?: boolean;
  endAdornment?: JSX.Element;
  leadingComponent?: JSX.Element;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SMFormTextField<T>({
  field,
  formState,
  fieldState,
  inlineEdit = false,
  onCancel,
  onUpdate,
  withFocusStyle,
  width,
  endAdornment,
  maskedInput,
  leadingComponent,
  ...textFieldProps
}: ISMTextFieldProps): JSX.Element {
  const textRef = useRef<HTMLElement>();
  const [showInlineButtons, setShowInlineButtons] =
    React.useState<boolean>(false);
  const onFocus = () => {
    if (inlineEdit) {
      setShowInlineButtons(true);
    }
  };

  const cancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.preventDefault();
    resetField(event, true);
  };

  const onOk = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { errors } = formState;
    event?.preventDefault();
    if (errors[field.name]) {
      textRef.current?.focus();
    } else if (onUpdate) {
      onUpdate();
      resetField(event);
    }
  };

  const resetField = (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FocusEvent<HTMLElement>,
    callCancel?: boolean
  ) => {
    if (inlineEdit) {
      setShowInlineButtons(false);
      if (onCancel && callCancel) {
        onCancel();
      }
    }
  };

  const onBlurEvt = (event: React.FocusEvent<HTMLElement>) => {
    if (!event?.currentTarget?.parentNode?.contains(event?.relatedTarget)) {
      resetField(event, true);
      if (field && field.onBlur) {
        field.onBlur();
      }
    }
  };

  const { error } = fieldState;
  return (
    <>
      <TextFieldWrapper onBlur={onBlurEvt}>
        <TextFieldStyled
          {...textFieldProps}
          variant="standard"
          autoComplete="off"
          width={width}
          type={textFieldProps.type || "text"}
          InputProps={{
            disableUnderline: true,
            endAdornment: endAdornment || null
          }}
          {...field}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            field.onChange(event);
          }}
          inputRef={textRef}
          onKeyDown={onFocus}
          onFocus={onFocus}
          focusedStyle={showInlineButtons || withFocusStyle ? 1 : 0}
          borderRed={error?.message ? 1 : 0}
          disabledInput={textFieldProps.disabled ? 1 : 0}
          key={field.name}
          sx={
            leadingComponent
              ? { ...textFieldProps.sx as SystemCssProperties, float: "left" }
              : { ...textFieldProps.sx as SystemCssProperties }
          }
          masked_input={maskedInput ? 1 : 0}
        />
        {showInlineButtons ? (
          <InlineButtonsStyled>
            <IconButton aria-label="ok" onMouseDown={onOk}>
              <CheckMark />
            </IconButton>
            <IconButton aria-label="reset" onMouseDown={cancel}>
              <Cross />
            </IconButton>
          </InlineButtonsStyled>
        ) : (
          ""
        )}
        {leadingComponent || <></>}
        {leadingComponent ? <Box sx={{ clear: "both" }} /> : <></>}
        {error?.message ? <ErrorMessage>{error?.message}</ErrorMessage> : ""}
      </TextFieldWrapper>
    </>
  );
}
export default SMFormTextField;
