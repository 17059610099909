import React from "react";
import { Route, Switch } from "react-router-dom";
import Cookies from "js-cookie";

import { ApplyBackround } from "styled/StylesStyled";
import { OVERVIEW_PAGE } from "constants/constants";
import { gotTo } from "utils/history.util";
import UseAuthenticatedUser from "hooks/UseAuthenticatedUser";
import rawRoutes from "services/utils/routes";


function AuthNew() {
  const { authUser } = UseAuthenticatedUser();

  if (authUser === undefined) {
    return <div />;
  }

  if (Cookies.get("access_token")) {
    gotTo(OVERVIEW_PAGE);
  }

  return (
    <ApplyBackround bg="bgLight">
      <Switch>
        {rawRoutes.map((item) => {
          return (
            <Route
              path={item.layout + item.path}
              component={item.component}
              key={item.path}
            />
          );
        })}
      </Switch>
    </ApplyBackround>
  );
}
export default AuthNew;
