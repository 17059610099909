import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";

import { ApplyMargin } from "styled/StylesStyled";
import { IUser } from "interfaces/user.interface";
import { InputLabelStyled } from "styled/CommonStyled";
import SMFormDropDown from "components/DropDownSelect/SMFormDropDown";

function UserPreference({ onSubmit }: { onSubmit: SubmitHandler<IUser> }) {
  const { t } = useTranslation();

  const { handleSubmit, control } = useFormContext<IUser>();
  const LANG_OPTIONS = [
    {
      id: "1",
      name: t("Language for Health and Welfare"),
      value: "nb_healthwelfare"
    },
    {
      id: "3",
      name: t("Language English"),
      value: "en"
    }
  ];

  return (
    <Grid container rowSpacing={0} columnSpacing={2.4}>
      <Grid item xs={12} md={6}>
        <ApplyMargin top={4}>
          <InputLabelStyled maginbottom={6}>{t("Language")}</InputLabelStyled>
        </ApplyMargin>
        <Controller
          name="preferences.language"
          control={control}
          render={({ field, fieldState, formState }) => (
            <SMFormDropDown<IUser>
              field={field}
              fieldState={fieldState}
              options={LANG_OPTIONS}
              width="100%"
              onUpdate={() => {
                handleSubmit(onSubmit)();
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
export default UserPreference;
