import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";

import * as LOCAL_STORAGE from "utils/localStorage";
import { IAuthResponse } from "interfaces/auth.interface";
import { OVERVIEW_PAGE } from "constants/constants";
import { REDIRECT_URL } from "constants/auth.constants";
import { clearCookies, getTokenParams } from "utils/auth/auth.util";
import { gotTo } from "utils/history.util";

const UseAuth = (tokenUrl: string) => {
  const { search } = useLocation();
  const [authResponse, setAuthResponse] = useState<IAuthResponse | undefined>();
  const [loginError, setLoginError] = useState<boolean>(false);
  
  useEffect(() => {
    const getToken = (dataStringified: string) => {
      axios({
        method: "post",
        url: tokenUrl,
        timeout: 30000,
        data: dataStringified,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then((response: AxiosResponse<IAuthResponse>) => {
          // set login error as false
          setLoginError(false);
  
          if (response.data) {
            setAuthResponse(response.data);
            if (response.data.access_token) {
              const expires = response.data.expires_in || 0;
              const date = new Date();
              date.setTime(date.getTime() + expires * 1000);
              Cookies.set("access_token", response.data.access_token, {
                expires: date
              });
              Cookies.set("userId", response.data.userId, { expires: date });
  
              // landing page
              gotTo(OVERVIEW_PAGE);
            }
          }
        })
        .catch((error) => {
          clearCookies();
          setLoginError(true);
        });
    };

    const code = new URLSearchParams(search).get("code");
    const responseState = new URLSearchParams(search).get("state");
    const userState = new URLSearchParams(search).get("userState");

    const state = sessionStorage.getItem("state");
    const code_verifier = sessionStorage.getItem("code_verifier");
    const client_id = LOCAL_STORAGE.getClientId();
    if (
      userState &&
      responseState &&
      state &&
      code &&
      client_id &&
      code_verifier &&
      !Cookies.get("access_token") &&
      userState === "Authenticated" &&
      state === responseState
    ) {
      const params: any = getTokenParams({
        code,
        client_id,
        code_verifier,
        redirectUri: REDIRECT_URL
      });

      const dataStringified: string = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join("&");
      getToken(dataStringified);
    } else {
      gotTo("auth/login", true);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { authResponse, loginError };
};

export default UseAuth;
