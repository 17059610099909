import * as types from "../../actionTypes/sleephistory/sleepEpochActionTypes";

export default function sleepEpochReducer(
  state: any = {},
  action: types.sleepEpochActionTypes
) {
  switch (action.type) {
    case types.SLEEP_EPOCH_DATA_SUCCESS:
      return {
        ...state,
        sleepEpoch: state.sleepEpoch
          ? {
              ...state.sleepEpoch,
              [action.payload.id]: action.payload.epoch_data
            }
          : { [action.payload.id]: action.payload.epoch_data }
      };
    case types.CLEAR_STATE:
      return {
        sleepEpoch: undefined
      };
    default:
      return state;
  }
}
