import { styled } from "@mui/system";
import React from "react";
import { useTheme } from "@mui/material/styles";

export const FlexOne = styled("div")(
  ({
    bg
  }: {
    bg?: string;
  }) => {
    const theme = useTheme();
    return {
      flex: 1,
      background: bg === "light" ? theme.palette.neutral[3] : ""
    };
  }
);

export const DividerRow = styled("div")(
  ({ children }: { children?: React.ReactNode }) => {
    const theme = useTheme();
    return {
      width: 20,
      cursor: "ew-resize",
      background: theme.palette.neutral[3],
      borderLeft: `1px solid ${theme.palette.neutral[5]}`,
      zIndex: 0,
      "&:hover": {
        borderLeft: `1px solid ${theme.palette.main[70]}`
      }
    };
  }
);

export const SplitPanelContainer = styled("div")(
  ({ children }: { children: React.ReactNode }) => {
    return {
      height: "calc(100vh - 60px)",
      display: "flex"
    };
  }
);

export const BoxPadding = styled("div")(
  ({
    pad,
    children,
    sx
  }: {
    pad?: number | string;
    children?: React.ReactNode;
    sx?: React.CSSProperties;
  }) => {
    return {
      padding: pad || 0,
      float: "left",
      width: "100%",
      ...sx
    };
  }
);
