import { API_ACTIONS } from "store/actionTypes/apiActionTypes";

import * as types from "../../actionTypes/administration/tenantActionTypes";


export default function tenantReducer(
  state: any = {},
  action: types.tenantsActionTypes
) {
  switch (action.type) {
    case types.FETCH_TENANT_BY_ID_SUCCESS:
      return {
        ...state,
        tenant: action.payload,
        apitStatus: API_ACTIONS.success
      };
    default:
      return state;
  }
}

