import * as types from "../../actionTypes/dashboard/nightReportActionTypesV1";

export default function nightReportV1Reducer(
  state: any = {},
  action: types.nightReportV1ActionTypes
) {
  switch (action.type) {
    case types.FETCH_NIGHT_REPORT_V1_SUCCESS:
      return {
        ...state,
        nightReport: state.nightReport
          ? [...state.nightReport, action.payload]
          : [action.payload]
      };
    case types.CLEAR_NIGHT_REPORT:
      return {
        ...state,
        nightReport: undefined
      };
    default:
      return state;
  }
}
