import { AppBar, Box, Grid, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";

import * as LOCAL_STORAGE from "utils/localStorage";
import { ApplyMargin, ErrorMessage } from "styled/StylesStyled";
import { LOGIN_TENENTS, PROD_API_BASE_URL } from "constants/constants";
import { ReactComponent as Logo } from "assets/icons/new/logo.svg";
import { fetchTenents, resetTenentState } from "store/actions/auth/authActions";
import SMButtonNew from "components/SMButton/SMButtonNew";
import SMFormTextField from "components/SMTextField/SMFormTextField";
import WIPMessage from "components/WIPMessage/WIPMessage";

import APISelector from "./APISelector";

const SMLogo = styled(Logo)(() => {
  return {
    marginRight: 35,
    cursor: "pointer"
  };
});

const BodyBg = styled("div")(() => {
  const theme = useTheme();
  return {
    background: theme.palette.neutral[3]
  };
});

const StyledP = styled("p")(() => {
  const theme = useTheme();
  return {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 32,
    lineHeight: "40px",
    color: theme.palette.neutral[80],
    margin: 0,
    marginBottom: 37
  };
});

function LoginLanding() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authState: any = useSelector((appstate: any) => {
    return appstate.authReducerV1 || {};
  });
  const history = useHistory();
  const { search } = useLocation();
  const redirectreason = new URLSearchParams(search).get("redirectreason");
  const [loginEmail, setLoginEmail] = useState<string>();
  const defaultApiEndpoint = LOCAL_STORAGE.getCustomAPIEndPoint() || "";

  // last email
  const lastEmail: string | null = localStorage.getItem("LAST_USED_EMAIL");

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm<{ email: string }>({
    defaultValues: {
      email: lastEmail ?? ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    return () => {
      dispatch(resetTenentState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authState.tenants?.length && loginEmail) {
      history.push({
        pathname: `${LOGIN_TENENTS}`,
        // search:`email=${encodeURIComponent(loginEmail)}`,
        state: { tenants: authState.tenants, email: loginEmail }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  const onSubmit = ({ email }: { email: string }) => {
    if (email) {
      // set login email
      setLoginEmail(email);
      dispatch(fetchTenents(email || ""));
    }
  };

  const onUpdateAPIEndPoint = (endpoint: string) => {
    if (process.env.REACT_APP_PRODUCTION === "false") {
      LOCAL_STORAGE.setCustomAPIEndPoint(endpoint);
      axios.defaults.baseURL = endpoint || PROD_API_BASE_URL;
    }
  };

  const emailWatch = watch("email");
  return (
    <BodyBg>
      <AppBar
        position="fixed"
        sx={{ background: "#FFFFFF", boxShadow: "none" }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <SMLogo />
          {process.env.REACT_APP_PRODUCTION === "false" && (
            <Stack direction="row" spacing={1}>
              <APISelector
                defaultApi={defaultApiEndpoint}
                urls={process.env?.REACT_APP_API_END_POINTS?.split(",") || []}
                label="API Endpoint"
                onUpdate={onUpdateAPIEndPoint}
              />
            </Stack>
          )}
        </Toolbar>
      </AppBar>

      {/* Warning message */}
      <WIPMessage />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={12} md={6} textAlign="center">
            {authState.tenants !== undefined && !authState.tenants?.lengh && (
              <ErrorMessage sx={{ marginBottom: 15 }}>
                {t("no_tenets_for_this_email")}
              </ErrorMessage>
            )}
            <ErrorMessage sx={{ marginBottom: 15 }}>
              {redirectreason === "unauthorize"
                ? t("your_session_has_expired_please_login_again_to_continue")
                : ""}
            </ErrorMessage>

            <StyledP>{t("your_email_address")}</StyledP>
            <Box sx={{ width: "80%", margin: "0 auto" }}>
              <Controller
                rules={{
                  required: `${t("error_message_your_email")}`
                }}
                name="email"
                control={control}
                render={({ field, fieldState, formState }) => (
                  <SMFormTextField<{ email: string }>
                    field={field}
                    placeholder={t("email_id_placeholder")}
                    formState={formState}
                    fieldState={fieldState}
                    inlineEdit={false}
                    withFocusStyle={!!field.value}
                    onCancel={() => {}}
                    onUpdate={() => {
                      handleSubmit(onSubmit)();
                    }}
                  />
                )}
              />
            </Box>
            <ApplyMargin top={37}>
              <SMButtonNew
                text={t("Continue")}
                onClick={() => {}}
                disabled={!!errors.email || !emailWatch}
                submit
              />
            </ApplyMargin>
          </Grid>
        </Grid>
      </form>
    </BodyBg>
  );
}
export default LoginLanding;
