import { styled } from "@mui/system";
import React, { useEffect } from "react";

import { ApplyFloat } from "styled/StylesStyled";
import { getCurrentDay, getLangKey } from "utils/common/locale.util";
import { useTheme } from "@mui/material/styles";

const DateLabelStyled = styled("div")(
  ({children }: { children?: React.ReactNode }) => {
    const theme = useTheme();
    return {
      fontSize: 16,
      color: theme.palette.neutral[80]
    };
  }
);

function DateTimeText() {
  const langKey = getLangKey() === "nb" ? "nb" : "en-gb";
  const [currentDateTime, setCurrentDateTime] = React.useState<string>(
    getCurrentDay(langKey)
  );

  useEffect(() => {
    setCurrentDateTime(getCurrentDay(langKey));
    const timer = setInterval(() => {
      setCurrentDateTime(getCurrentDay(langKey));
    }, 10 * 1000);

    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ApplyFloat float="left">
      <DateLabelStyled>{currentDateTime || ""}</DateLabelStyled>
    </ApplyFloat>
  );
}
export default DateTimeText;
