import DataUsageIcon from "@mui/icons-material/DataUsage";
import FaceIcon from "@mui/icons-material/Face";
import PersonIcon from "@mui/icons-material/Person";

import { AppRouteProps } from "interfaces/router.interface";
import { basePath } from "constants/constants";

import DeviceViewPage from "./deviceView/DeviceViewPage";
import DevicesLandingPage from "./devices/DevicesLandingPage";
import SubjectLandingPage from "./subjects/SubjectLandingPage";
import SubjectViewPage from "./subjectView/SubjectViewPage";
import UserLandingPage from "./users/UserLandingPage";
import UserViewPage from "./userView/UserViewPage";

export const zonesChildRouteNames = {
  home: "/home",
  devices: "Devices",
  subjects: "Subjects",
  users: "employees",
  zoneSettings: "zoneSettings",
  subjectsView: "subjectsView",
  devicesView: "devicesView",
  usersView: "usersView"
};

export const zonesChildRoutes: AppRouteProps[] = [
  {
    name: zonesChildRouteNames.devices,
    path: `${basePath}/adminstration/zones/devices`,
    exact: true,
    component: DevicesLandingPage,
    icon: DataUsageIcon,
    primary: true
  },
  {
    name: zonesChildRouteNames.subjects,
    path: `${basePath}/adminstration/zones/subjects`,
    exact: true,
    component: SubjectLandingPage,
    icon: FaceIcon,
    primary: true
  },
  {
    name: zonesChildRouteNames.users,
    path: `${basePath}/adminstration/zones/users`,
    exact: true,
    component: UserLandingPage,
    icon: PersonIcon,
    primary: true
  },
  {
    name: zonesChildRouteNames.devicesView,
    path: `${basePath}/adminstration/zones/devices/:id`,
    exact: true,
    component: DeviceViewPage,
    primary: false
  },
  {
    name: zonesChildRouteNames.subjectsView,
    path: `${basePath}/adminstration/zones/subjects/:id`,
    exact: true,
    component: SubjectViewPage,
    primary: false
  },

  {
    name: zonesChildRouteNames.usersView,
    path: `${basePath}/adminstration/zones/users/:id`,
    exact: true,
    component: UserViewPage,
    primary: false
  }
];
export default zonesChildRouteNames;
