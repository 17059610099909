import { ISubject } from "interfaces/subject.interface";

export const RESET_SUBJECT_STATE = "RESET_SUBJECT_STATE";

export const FETCH_SUBJECT = "FETCH_SUBJECT";
export const FETCH_SUBJECT_SUCCESS = "FETCH_SUBJECT_SUCCESS";

export const CREATE_SUBJECT = "CREATE_SUBJECT";
export const CREATE_SUBJECT_SUCCESS = "CREATE_SUBJECT_SUCCESS";

export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const UPDATE_SUBJECT_SUCCESS = "UPDATE_SUBJECT_SUCCESS";

export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const DELETE_SUBJECT_SUCCESS = "DELETE_SUBJECT_SUCCESS";

export const RESET_SUBJECT_ACTIONS = "RESET_SUBJECT_ACTIONS";

export interface resetSubjectStateActionType {
  type: typeof RESET_SUBJECT_STATE;
}
export interface resetSubjectActionType {
  type: typeof RESET_SUBJECT_ACTIONS;
}
export interface fetchSubjectActionType {
  type: typeof FETCH_SUBJECT;
}
export interface fetchSubjectSuccessActionType {
  type: typeof FETCH_SUBJECT_SUCCESS;
  payload: ISubject[];
}

export interface createSubjectActionType {
  type: typeof CREATE_SUBJECT;
}
export interface createSubjectSuccessActionType {
  type: typeof CREATE_SUBJECT_SUCCESS;
  payload: ISubject;
}

export interface updateSubjectActionType {
  type: typeof UPDATE_SUBJECT;
}
export interface updateSubjectSuccessActionType {
  type: typeof UPDATE_SUBJECT_SUCCESS;
  payload: ISubject;
}

export interface deleteSubjectActionType {
  type: typeof DELETE_SUBJECT;
}
export interface deleteSubjectSuccessActionType {
  type: typeof DELETE_SUBJECT_SUCCESS;
  payload: string;
}

export type subjectsActionTypes =
  | resetSubjectActionType
  | fetchSubjectActionType
  | fetchSubjectSuccessActionType
  | createSubjectActionType
  | createSubjectSuccessActionType
  | updateSubjectActionType
  | updateSubjectSuccessActionType
  | deleteSubjectActionType
  | deleteSubjectSuccessActionType
  | resetSubjectStateActionType;
