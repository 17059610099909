import { AxiosResponse } from "axios";

import { clearCookies } from "utils/auth/auth.util";

import {
  toastError,
  toastErrorPositionCenter,
  toastSuccessPositionCenter
} from "utils/toast.util";

// Success handler
export async function handleResponse(response: any, showError?: boolean) {
  if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 204
  ) {
    return response.data;
  }
  if (response.status === 400) {
    // const error = await response.text();
    throw new Error("server error");
  }

  throw new Error("Network response was not ok.");
}

// Error Handler. In a prod app, would likely call an error logging service.
export function handleError(error: any) {
  if (error?.response?.status === 401) {
    clearCookies();
    window.location.reload();
    // gotTo(`${window.location.origin}/${LOGIN_PAGE}?redirectreason=unauthorize`, false);
  }
  console.error(`API call failed. ${error}`);
}

export const showErrorToastForUser = (error: any) => {
  // eslint-disable-next-line no-console
  const errorMessage = error?.response?.data?.errors;
  if (errorMessage && Array.isArray(errorMessage) && errorMessage.length) {
    for (let i = 0; i < errorMessage.length; i += 1) {
      const arr = errorMessage[i];
      if (arr?.param !== "display_name") {
        toastError(`${arr?.param ? arr.param : ""} : ${arr?.msg}`);
      }
    }
  }
  const onlyMessage = error?.response?.data?.message;
  if (onlyMessage) {
    toastError(`${onlyMessage}`);
  }
  const onlyMsg = error?.response?.data?.msg;
  if (onlyMsg) {
    toastError(`${onlyMsg}`);
  }

  const onlyError = error?.response?.data?.error;
  if (onlyError) {
    toastError(`${onlyError}`);
  }
};

export const showErrorFeedback = (error: any) => {
  if (error?.response?.status === 400) {
    // show error toast here
  }
  if (error?.response?.status === 403) {
    // show error toast here
  }
  if (error?.response?.status === 500) {
    // show error toast here
    toastErrorPositionCenter(
      `API call failed. Error: Request failed with status code 500`
    );
  }
  if (error?.response?.status === 404) {
    // show error toast here
  }
  const { detail } = error?.response?.data || { detail: null };
  if (detail && Array.isArray(detail)) {
    detail.forEach((item: any, index) => {
      if (item?.msg) {
        toastErrorPositionCenter(`${item.msg}`);
      }
    });
  }

  if (typeof detail === "string") {
    toastErrorPositionCenter(detail);
  }
};

export const showSuccessFeedback = (message: string) => {
  if (message) {
    toastSuccessPositionCenter(message);
  }
};

export const showMultipleErrorFeedback = (
  response: PromiseSettledResult<AxiosResponse<any>>[] | PromiseRejectedResult[]
) => {
  const errors: PromiseRejectedResult[] = response.filter(
    (item) => item.status === "rejected"
  ) as PromiseRejectedResult[];
  if (errors && errors?.length) {
    errors.forEach((error, index) => {
      const resp = error.reason || "";
      if (resp) {
        showErrorFeedback(resp);
      }
    });
  }
};
