import { Box, Grid } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
 
import { ApplyFloat } from "styled/StylesStyled";
import {
  AuthorizationContext,
  UserPermissionEnum
} from "authorization/AuthorizationContext";
import { ButtonAsTextStlyled, InputLabelStyled } from "styled/CommonStyled";
import { IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";
import {
  ZoneUserRolesEnum,
  administrativeRoles
} from "constants/userContstants";
import { can } from "authorization/authorization.util";
import { makeZoneTree } from "utils/zone.util";
import TreeViewWithCheckbox from "components/TreeView/TreeViewWithCheckbox/TreeViewWithCheckbox";
import UseAdminContext from "hooks/UseAdminContext";

import UserRoles from "./UserRoles";

const ArrowForwardIosIconStyled = styled(ArrowForwardIosIcon)(
  ({  children }: { children?: any }) => {
    return {};
  }
);

const ZonePathStyled = styled("div")(
  ({ children }: { children?: any }) => {
    const theme = useTheme();
    return {
      fontSize: 16,
      color: theme.palette.neutral[70],
      lineHeight: "20px",
      letterSpacing: 0.25,
      "& > svg": {
        width: 14,
        height: 18,
        margin: "0 8px",
        paddingTop: 5
      }
    };
  }
);

const ZonePathWrapperStyled = styled("div")(
  ({ children }: {  children?: any }) => {
    return {
      background: "#FFFFFF",
      boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 4,
      width: "95%",
      minHeight: 32,
      padding: "4px 8px",
      margin: 8,
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      rowGap: 5,
      marginLeft: 1
    };
  }
);

function UserZonesAndRoles({
  user,
  onZoneUpdate,
  onRolesUpdate,
  disabled = false,
  authUser
}: {
  user: IUser;
  onZoneUpdate: (selected: IZone[]) => void;
  onRolesUpdate?: () => void;
  disabled?: boolean;
  authUser: IUser;
}) {
  const { t } = useTranslation();
  const {
    resetField,
    control,
    formState: { dirtyFields }
  } = useFormContext<IUser>();

  const { zones } = UseAdminContext();
  const [toggleZoneTree, setToggleZoneTree] = useState<boolean>(!user.id); //! user.id
  const [zoneTree, setZoneTree] = useState<IZone>();
  const [zoneEntity, setZoneEntity] = useState<{ [key: string]: IZone }>({});
  const { permissions } = React.useContext(AuthorizationContext);
  const [authUserRoleHigherOrder, setAuthUserRoleHigherOrder] =
    useState<number>(0);

  const [currentUserHigherRole, setCurrentUserHigherRole] = useState<number>(0);

  useEffect(() => {
    const ztree = makeZoneTree<IZone>(zones);
    const entity: { [key: string]: IZone } = {};
    zones.forEach((item) => {
      entity[item.id] = item;
    });
    setZoneEntity(entity);

    if (ztree && ztree.length) {
      setZoneTree(ztree[0]);
    }
  }, [zones, user]);

  useEffect(() => {
    const authRoles = authUser.roles;
    let adminHigherOrder: number = 0;
    authRoles.forEach((item: ZoneUserRolesEnum) => {
      if (
        item === "MANAGER_LIMITED" ||
        item === "MANAGER" ||
        item === "ADMIN" ||
        item === "OWNER"
      ) {
        if (administrativeRoles[item] > adminHigherOrder) {
          adminHigherOrder = administrativeRoles[item];
        }
      }
    });
    setAuthUserRoleHigherOrder(adminHigherOrder);

    let userHigherOrder: number = 0;
    user.roles.forEach((item: ZoneUserRolesEnum) => {
      if (
        item === "MANAGER_LIMITED" ||
        item === "MANAGER" ||
        item === "ADMIN" ||
        item === "OWNER"
      ) {
        if (administrativeRoles[item] > userHigherOrder) {
          userHigherOrder = administrativeRoles[item];
        }
      }
    });

    setCurrentUserHigherRole(userHigherOrder);
  }, [authUser, user]);

  const isZonesEditDisabled = (): boolean => {
    if (disabled) {
      return true;
    }
    if (authUserRoleHigherOrder < currentUserHigherRole) {
      return true;
    }
    return false;
  };
  return (
    <Grid container rowSpacing={0} columnSpacing={2.4}>
      <Grid item xs={12} md={6}>
        <InputLabelStyled maginbottom={0}>{t("Zones")}</InputLabelStyled>
        {/* <StyledP>{t("select_zones_to_assign_this_user_to")}</StyledP> */}
        {/* <ZoneViewWrapper> */}
        {toggleZoneTree && !disabled && (
          <Controller
            name="zones.data"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TreeViewWithCheckbox
                  treeHeight="450px"
                  defaultValues={field.value || []}
                  inheritSelected
                  items={zones}
                  showSearchbox={false}
                  showActionButtons={false}
                  showInlineButtons={!!user.id && fieldState.isDirty}
                  errorMessage={fieldState?.error?.message || ""}
                  currentUserZones={
                    can(permissions, UserPermissionEnum["user.create"])
                      ? authUser.zones?.data.map((item: IZone) => item.id)
                      : undefined
                  }
                  onChange={(seletedZones: IZone[]) => {
                    field.onChange(seletedZones);
                  }}
                  onResetTree={() => {
                    resetField("zones.data");
                    // setToggleZoneTree(false);
                  }}
                  onSave={(seletedZones: IZone[]) => {
                    onZoneUpdate(seletedZones);
                    // setToggleZoneTree(false);
                  }}
                />
              );
            }}
          />
        )}
        {!toggleZoneTree && zoneTree && (
          <UserZonesMap
            zones={user.zones?.data || []}
            zoneTree={zoneTree}
            zoneEntity={zoneEntity}
          />
        )}

        {/* The purpose of this line of code is to address the issue of layout flickering caused by 
        the height of the scrollbar when the close zone button toggled. This does not provide any functional value. */}
        {toggleZoneTree && (
          <Box sx={{ visibility: "hidden", height: "40px", float: "left" }} />
        )}
        {/* flickering issue fix end */}

        {!toggleZoneTree && (
          <ButtonAsTextStlyled
            onClick={() =>
              !isZonesEditDisabled() ? setToggleZoneTree(!toggleZoneTree) : {}
            }
            disabled={isZonesEditDisabled()}
          >
            {t("change_zones")}
          </ButtonAsTextStlyled>
        )}
        {user.id && toggleZoneTree && !dirtyFields?.zones?.data && (
          <ApplyFloat float="right">
            <ButtonAsTextStlyled
              onClick={() => {
                resetField("zones.data");
                setToggleZoneTree(!toggleZoneTree);
              }}
            >
              {t("close_zone_tree")}
            </ButtonAsTextStlyled>
          </ApplyFloat>
        )}
        {/* </ZoneViewWrapper> */}
      </Grid>
      <Grid item xs={12} md={6}>
        <InputLabelStyled maginbottom={0}> {t("Role(s)")}</InputLabelStyled>
        <Controller
          name="roles"
          control={control}
          rules={{ required: `${t("error_message_employee_roles")}` }}
          render={({ field, fieldState }: any) => (
            <UserRoles
              roles={field.value}
              disabled={disabled}
              authUserRoleHigherOrder={authUserRoleHigherOrder}
              onRolesUpdate={(roles: ZoneUserRolesEnum[]) => {
                field.onChange(roles);
                if (onRolesUpdate) {
                  onRolesUpdate();
                }
              }}
              fieldState={fieldState}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
export default UserZonesAndRoles;

function UserZonesMap({
  zones,
  zoneEntity,
  zoneTree
}: {
  zones: IZone[];
  zoneEntity: { [key: string]: IZone };
  zoneTree: IZone;
}): JSX.Element {
  const buildZonePath = (paths: string[]) => {
    return paths.map((path) => {
      if (paths.indexOf(path) !== paths.length - 1) {
        return (
          <ZonePathStyled key={path}>
            {zoneEntity[path]?.name} <ArrowForwardIosIconStyled />{" "}
          </ZonePathStyled>
        );
      }
      return <ZonePathStyled>{zoneEntity[path]?.name}</ZonePathStyled>;
    });
  };

  return (
    <div>
      {zones.map((item: IZone) => {
        const paths = item.path.split(".") || [];
        return (
          <ZonePathWrapperStyled key={item.id}>
            {buildZonePath(paths)}
          </ZonePathWrapperStyled>
        );
      })}
    </div>
  );
}
