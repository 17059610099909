import { styled } from "@mui/system";
import { useLocation } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { useTheme } from "@mui/material/styles";

import { AppGlobalContextType, GlobalContext } from "global/GlobalContext";
import { IWIPMessage, isWIPMessageType } from "interfaces/wipMessage.interface";
import i18n from "i18n/config";

// is utc date in range
function isUtcDateInRange(fromDate: Date, toDate: Date) {
  const currentDate = new Date();
  const now = Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds()
  );

  const from = Date.UTC(
    fromDate.getFullYear(),
    fromDate.getMonth(),
    fromDate.getDate(),
    fromDate.getHours(),
    fromDate.getMinutes(),
    fromDate.getSeconds()
  );
  const to = Date.UTC(
    toDate.getFullYear(),
    toDate.getMonth(),
    toDate.getDate(),
    toDate.getHours(),
    toDate.getMinutes(),
    toDate.getSeconds()
  );
  return now >= from && now <= to;
}

const WarningMessageStyled = styled("div")(
  ({
    children,
    type
  }: {
    children: any;
    type: IWIPMessage["type"];
  }) => {
    const theme = useTheme();
    return {
      top: "100px",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      position: "absolute",
      marging: "10px",
      "& >div": {
        color: theme.palette.red[50],
        border: `1px solid ${theme.palette.neutral[5]}`,
        padding: 10,
        textAlign: "center",
        "& h2": {
          margin: "10px 0",
          padding: 0,
          fontWeight: "normal",
          fontSize: 18
        },
        "& p": {
          margin: 0,
          padding: 0,
          fontWeight: "normal"
        }
      }
    };
  }
);

function getObjectValue(obj: any, path: string[]) {
  let value = obj;
  for (let i = 0; i < path.length; i += 1) {
    if (value[path[i]]) {
      value = value[path[i]];
    }
  }
  return value;
}

// warning message styled
function WIPMessage() {
  const location = useLocation();
  const [wipmessages, setWipMessages] = React.useState<IWIPMessage[]>();
  const messages = useContext<AppGlobalContextType | null>(GlobalContext);
  useEffect(() => {
    if (messages?.appWarningMessage) {
      const paths = location.pathname.split("/").filter((val) => val) || [];
      let message;
      if (paths.length) {
        message = getObjectValue(messages?.appWarningMessage, paths);
        if (message && isWIPMessageType(message)) {
          setWipMessages(message as IWIPMessage[]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  if (!wipmessages) {
    return <div />;
  }
  // is date in range
  return (
    <>
      {wipmessages.map((wipmessage: IWIPMessage) => {
        return isUtcDateInRange(
          new Date(wipmessage.enable_from),
          new Date(wipmessage.enable_to)
        ) && wipmessage.enabled ? (
          <WarningMessageStyled
            type={wipmessage.type}
            key={`${wipmessage?.enable_from}`}
          >
            {i18n.language === "en" ? (
              <MessageComponent
                title={
                  wipmessage.message.en?.title || wipmessage.message.no.title
                }
                body={wipmessage.message.en?.body || wipmessage.message.no.body}
              />
            ) : (
              <MessageComponent
                title={wipmessage.message.no.title}
                body={wipmessage.message.no.body}
              />
            )}
          </WarningMessageStyled>
        ) : (
          <></>
        );
      })}
    </>
  );
}
export default WIPMessage;

// message component
const MessageComponent = ({ title, body }: { title: string; body: string }) => {
  return (
    <div>
      <h2>{title || ""}</h2>
      <p dangerouslySetInnerHTML={{ __html: `${body}` }} />
    </div>
  );
};
