import { INotificationHandler } from "interfaces/notification.interface";

import * as types from "../../actionTypes/notifications/notificationHandlerActionTypes";

export default function notificationReducer(
  state: any = {},
  action: types.notificationsActionTypes
) {
  switch (action.type) {
    case types.FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload
      };
    case types.CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: state?.notifications
          ? [...state.notifications, action.payload]
          : [action.payload]
      };
    case types.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.filter(
          (handler: INotificationHandler) => handler.id !== action.payload
        )
      };

    case types.UPDATE_NOTIFICATION_HANDLER_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map(
          (notification: INotificationHandler) =>
            notification.id === action.payload.id
              ? { ...action.payload }
              : notification
        )
      };
    case types.RESET_NOTIFICATION_STATE:
      return {
        notifications: undefined
      };
    default:
      return state;
  }
}
