import { useTranslation } from "react-i18next";
import React from "react";

import * as LOCAL_STORAGE from "utils/localStorage";
import { ApplyMargin, StyledP } from "styled/StylesStyled";
import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled
} from "styled/ContainerStylesStyled";
import { ButtonAsTextStlyled } from "styled/CommonStyled";
import { IDevice } from "interfaces/device.interface";
import { ISubject } from "interfaces/subject.interface";
import { gotTo } from "utils/history.util";
import SubTitle from "components/SMTitles/SubTitle";

import DeviceRow from "./DeviceRow";

export function SubjectDevices({
  devices,
  subject,
  setSelectedZone
}: {
  devices: IDevice[];
  subject: ISubject;
  setSelectedZone: React.Dispatch<React.SetStateAction<string>> | undefined;
}) {
  const { t } = useTranslation();

  const onClick = (item: IDevice) => {
    if (item.id) {
      gotTo(`adminstration/zones/devices/${item.id}`);
    }
  };

  const gotoDevices = () => {
    if (setSelectedZone && subject.zone_id) {
      setSelectedZone(subject.zone_id);
    }
    const settings = LOCAL_STORAGE.getAdminSettings();
    LOCAL_STORAGE.setAdminSettings({
      ...settings,
      includeDeviceChildren: false
    });
    gotTo(`adminstration/zones/devices/`);
  };

  const NoDevicesElem = () => {
    return (
      <>
        <StyledP>{t("no_device_assigned_to_this_subject_yet")}</StyledP>
        <ApplyMargin top={22}>
          <ButtonAsTextStlyled onClick={gotoDevices}>
            {t("see_devices_in_this_zone")}
          </ButtonAsTextStlyled>
        </ApplyMargin>
      </>
    );
  };

  return (
    <BoxSubSectionStyled>
      <SubTitle showEdit={false} text={t("Devices")} defaultVal={false} />
      <BoxSubSectionContentStyled padding={20}>
        {!devices?.length && <NoDevicesElem />}
        {devices.map((device: IDevice) => {
          return (
            <DeviceRow
              device={device}
              onClick={onClick}
              key={device.serial_number}
            />
          );
        })}
      </BoxSubSectionContentStyled>
    </BoxSubSectionStyled>
  );
}
