import { IGroup } from "interfaces/group.interface";
import { IUser } from "interfaces/user.interface";

/**
 * This function will return the avatar letter
 *
 * @param name:string user name
 * @return first letter or string -
 */
export const getAvatar = (name: string) => {
  if (!name) {
    return "-";
  }
  return name.charAt(0);
};

/**
 * This function will convert user groups as option type
 * @param name:
 * @return
 */
export const convertUserGroupAsOptions = (groups: IGroup[]) => {
  if (!groups || !groups.length) {
    return [];
  }
  return groups
    .sort((a: IGroup, b: IGroup) =>
      a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())
    )
    .map((item: IGroup) => {
      return {
        id: item.id,
        primaryText: item.name,
        secondaryTexts: []
      };
    });
};

/**
 * This function will convert group users as option type
 * @param name:
 * @return
 */
export const convertGroupUsersAsOptions = (users: IUser[]) => {
  if (!users || !users.length) {
    return [];
  }
  return users.map((item: IUser) => {
    return {
      id: item.id,
      primaryText:
        item.first_name || item.last_name
          ? `${item.first_name || ""} ${item.last_name || ""}`
          : item.email,
      secondaryTexts: []
    };
  });
};
