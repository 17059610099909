import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid/index.js";
import React from "react";
import moment from "moment";

import Clock from "components/Clock/Clock"
import SingleTextField from "components/SingleTextField/SingleTextField.js";

const CLOCK_SIZE = 120;
const PREFIX = "SleepStatistics";
const classes = {
  root: `${PREFIX}-root`
};

const StyledGrid = styled(Grid)(() => {
  return {
    [`& .${classes.root}`]: {}
  };
});

function SleepStatisticsComponent ({
  timeAtStart,
  timeAtSleep,
  timeAtWakeup,
  timeAsleepString,
  sleepOnsetString,
  sleepEfficiency,
  timesAway,
  nonREMMeanRespirationRate,
  epochsWithMovement
}: {
  timeAtStart: any;
  timeAtSleep: any;
  timeAtWakeup: any;
  timeAsleepString: string;
  sleepOnsetString: string;
  sleepEfficiency: number | string;
  timesAway: number;
  nonREMMeanRespirationRate: string | number;
  epochsWithMovement: string | number;
}) {
  const { t } = useTranslation();
  return (
    <StyledGrid container spacing={3} direction="row">
      <Grid key="TimeAtStart" item xs={4}>
        <Clock
          title={t("Time at Start")}
          time={moment(timeAtSleep)?.isValid() ? timeAtStart : timeAtSleep}
          size={CLOCK_SIZE}
          datetime={true}
        />
      </Grid>
      <Grid key="TimeAtSleep" item xs={4}>
        <Clock
          title={t("First Asleep")}
          time={timeAtSleep}
          size={CLOCK_SIZE}
          datetime={false}
        />
      </Grid>
      <Grid key="TimeAtWakeup" item xs={4}>
        <Clock
          title={t("Woke up")}
          time={timeAtWakeup}
          size={CLOCK_SIZE}
          datetime={true}
        />
      </Grid>
      <Grid key="TotalTimeAsleep" item xs={4}>
        <SingleTextField
          title={t("Total Time Asleep")}
          text={timeAsleepString}
        />
      </Grid>
      <Grid key="SleepOnset" item xs={4}>
        <SingleTextField
          title={!moment(timeAtSleep)?.isValid()
            ? t("time_in_bed")
            : t("Sleep Onset")}
          text={sleepOnsetString}
        />
      </Grid>
      <Grid key="SleepEfficiency" item xs={4}>
        <SingleTextField
          title={t("Sleep Efficiency")}
          text={`${sleepEfficiency}<span style="word-spacing: -0.125em;">&nbsp;</span>%`}
        />
      </Grid>
      <Grid key="OutOfBed" item xs={4}>
        <SingleTextField
          title={t("Out of Bed")}
          text={timesAway || "0"}
        />
      </Grid>
      <Grid key="Movement" item xs={4}>
        <SingleTextField
          title={t("Movement")}
          text={`${epochsWithMovement}<span style="word-spacing: -0.125em;">&nbsp;</span>%`}
        />
      </Grid>
      <Grid key="RestingRespirationRate" item xs={4}>
        <SingleTextField
          title={t("Resting Respiration Rate")}
          text={nonREMMeanRespirationRate}
        />
      </Grid>
    </StyledGrid>
  );
};

export default React.memo(SleepStatisticsComponent);
