import { INotificationHandler } from "interfaces/notification.interface";

export const RESET_NOTIFICATION_STATE = "RESET_NOTIFICATION_STATE";
export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";

export const FETCH_NOTIFICATION_BY_ID = "FETCH_NOTIFICATION_BY_ID";
export const FETCH_NOTIFICATION_BY_ID_SUCCESS =
  "FETCH_NOTIFICATION_BY_ID_SUCCESS";

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_HANDLER_SUCCESS =
  "UPDATE_NOTIFICATION_SUCCESS";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";

export const TEST_NOTIFICATION = "TEST_NOTIFICATION";
export const TEST_NOTIFICATION_SUCCESS = "TEST_NOTIFICATION_SUCCESS";

export interface resetNotificationStateActionType {
  type: typeof RESET_NOTIFICATION_STATE;
}

export interface fetchNotificationActionType {
  type: typeof FETCH_NOTIFICATION;
}
export interface fetchNotificationSuccessActionType {
  type: typeof FETCH_NOTIFICATION_SUCCESS;
  payload: INotificationHandler[];
}

export interface createNotificationActionType {
  type: typeof CREATE_NOTIFICATION;
}
export interface createNotificationSuccessActionType {
  type: typeof CREATE_NOTIFICATION_SUCCESS;
  payload: INotificationHandler;
}

export interface updateNotificationActionType {
  type: typeof UPDATE_NOTIFICATION;
}
export interface updateNotificationSuccessActionType {
  type: typeof UPDATE_NOTIFICATION_HANDLER_SUCCESS;
  payload: INotificationHandler;
}

export interface fetchNotificationByIDActionType {
  type: typeof FETCH_NOTIFICATION_BY_ID;
}

export interface fetchNotificationByIDSuccessActionType {
  type: typeof FETCH_NOTIFICATION_BY_ID_SUCCESS;
  payload: INotificationHandler;
}

export interface deleteNotificationHandlerActionType {
  type: typeof DELETE_NOTIFICATION;
}

export interface deleteNotificationHandlerSuccessActionType {
  type: typeof DELETE_NOTIFICATION_SUCCESS;
  payload: string;
}

export interface testNotificationHandlerActionType {
  type: typeof TEST_NOTIFICATION;
}

export interface testNotificationHandlerSuccessActionType {
  type: typeof TEST_NOTIFICATION_SUCCESS;
  payload: string;
}

export type notificationsActionTypes =
  | fetchNotificationActionType
  | fetchNotificationSuccessActionType
  | createNotificationActionType
  | createNotificationSuccessActionType
  | updateNotificationActionType
  | updateNotificationSuccessActionType
  | resetNotificationStateActionType
  | fetchNotificationByIDActionType
  | fetchNotificationByIDSuccessActionType
  | deleteNotificationHandlerActionType
  | deleteNotificationHandlerSuccessActionType
  | testNotificationHandlerActionType
  | testNotificationHandlerSuccessActionType;
