import { IColor } from "interfaces/color.interface";

const Primary: Omit<IColor, 110 | 0> = {
  1: "#F9FDFD",
  2: "#F4FBFC",
  3: "#ECF8F9",
  4: "#E5F4F5",
  5: "#DEF1F2",
  10: "#C3E4E5",
  20: "#94C6C7",
  30: "#67A8AA",
  40: "#3F8C8F",
  50: "#347073",
  60: "#185D61",
  70: "#004E52",
  80: "#003A3D",
  90: "#002629",
  100: "#001314"
};
export default Primary;
