import { API_ACTIONS } from "store/actionTypes/apiActionTypes";
import { ISubject } from "interfaces/subject.interface";

import * as types from "../../actionTypes/administration/subjectActionTypes";

export default function subjectReducer(
  state: any = {},
  action: types.subjectsActionTypes
) {
  switch (action.type) {
    case types.FETCH_SUBJECT_SUCCESS:
      return {
        ...state,
        subjects: action.payload,
        apitStatus: API_ACTIONS.reset
      };

    case types.RESET_SUBJECT_STATE:
      return {
        subjects: undefined,
        apitStatus: API_ACTIONS.reset
      };
    case types.CREATE_SUBJECT_SUCCESS:
      return {
        ...state,
        subjects: state?.subjects
          ? [...state.subjects, action.payload]
          : [action.payload],
        apitStatus: API_ACTIONS.created
      };
    case types.UPDATE_SUBJECT_SUCCESS:
      return {
        ...state,
        apitStatus: API_ACTIONS.updated,
        subjects: state.subjects.map((subject: ISubject) =>
          subject.id === action.payload.id ? { ...action.payload } : subject
        )
      };
    case types.RESET_SUBJECT_ACTIONS:
      return {
        ...state,
        apitStatus: API_ACTIONS.reset
      };
    case types.DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        subjects: state.subjects.filter(
          (subject: ISubject) => action.payload.indexOf(subject.id) === -1
        )
      };
    default:
      return state;
  }
}
