import { IColor } from "interfaces/color.interface";

const Yellow: Omit<
  IColor,
  0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
> = {
  1: "#FFFFF5",
  5: "#FFFFDE",
  10: "#FFFFC4",
  20: "#FFF696",
  30: "#FFE769",
  40: "#FFD840",
  50: "#F7CA18"
};

export default Yellow;
