import { Box, Dialog, Portal, paperClasses } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import React from "react";

import SleepHistoryScreen from "@modules/sleepHistory/SleepHistoryScreen";

const DialogStyled = styled(Dialog)(
  ({ children }: { children: React.ReactNode }) => {
    return {
      [`& .MuiDialog-container > .${paperClasses.root}`]: {
        padding: 0,
        p: 0,
        m: 0,
        boxShadow: "none",
        background: "none"
      }
    };
  }
);

function SleepHistoryModal({
  dashboardModalSubject,
  setDashboardModalSubject,
  sessionID
}: {
  sessionID?: string;
  dashboardModalSubject: {
    id: string;
    identifier: string;
    sex: string;
    birth_year: number;
  };
  setDashboardModalSubject: React.Dispatch<
    React.SetStateAction<{
      id: string;
      identifier: string;
      sex: string;
      birth_year: number;
    }>
  >;
}) {
  const { t } = useTranslation();
  // const apiInfo = { token: getToken(), authType: "Basic" };
  const closeDashboardPopup = () => {
    setDashboardModalSubject({
      id: "",
      identifier: "",
      sex: "",
      birth_year: 0
    });
  };

  const getTranslatedGender = (key: string): string => {
    if (!key) {
      return "";
    }
    if (key === "male") {
      return t("Male");
    }
    if (key === "female") {
      return t("Female");
    }
    return "";
  };
  const getDashboardTitle = () => {
    return `${dashboardModalSubject.identifier} (${getTranslatedGender(
      dashboardModalSubject.sex
    )}, ${dashboardModalSubject.birth_year})`;
  };

  return (
    <Portal>
      <DialogStyled
        disablePortal
        open={dashboardModalSubject.id !== ""}
        onClose={(event: {}, reason: "backdropClick" | "escapeKeyDown") =>
          closeDashboardPopup()
        }
        fullWidth
        maxWidth={false}
      >
        {/* <Paper 
        sx={{
          margin: 4,
          p: 2,
          height: "calc(100vh - 76px)",
          zIndex: 999,
          boxShadow:'none'
        }} 
      > */}
        <Box
          sx={{
            height: "calc(100vh - 76px)",
            boxShadow:
              "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            overflow: "auto",
            margin: 4,
            p: 2,
            background: "#FFF"
          }}
        >
          <CloseOutlined
            onClick={() => closeDashboardPopup()}
            style={{
              position: "absolute",
              right: 18,
              top: 18,
              backgroundColor: "#FFF",
              border: "1px solid rgba(0, 0, 0, 0.4)",
              borderRadius: 23,
              fontSize: 38,
              cursor: "pointer"
            }}
          />
          <SleepHistoryScreen
            selectedSubjectId={dashboardModalSubject.id}
            userDisplayName={getDashboardTitle()}
            sessionID={sessionID}
          />
        </Box>
        {/* </Paper> */}
      </DialogStyled>
    </Portal>
  );
}
export default SleepHistoryModal;
