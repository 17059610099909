import { Button, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { styled } from "@mui/system";
import CircleIcon from "@mui/icons-material/Circle";
import IconButton from "@mui/material/IconButton";
import React from "react";

import { IZone } from "interfaces/zone.interface";
import { ReactComponent as ZoneArrow } from "assets/icons/new/zone_arrow.svg";

const NodeWrapper = styled("div")(
  ({
    children,
    level,
    hasChildren,
    levelPadding,
    cursordisabled
  }: {
    children: React.ReactNode;
    level: number;
    hasChildren?: boolean;
    levelPadding: number;
    cursordisabled?: number;
  }) => {
    const theme = useTheme();
    return {
      paddingLeft: `${level * levelPadding}px`,
      position: "relative",
      height: 44,
      cursor: cursordisabled ? "not-allowed" : "pointer",
      "&:before": {
        display: "block",
        borderBottom:
          level !== 0 ? `.5px dotted ${theme.palette.neutral[80]}` : "",
  
        content: '""',
        position: "absolute",
        width: 12,
        top: 18, // half of the height 36 /2
        left: `${level * levelPadding - (8 + 4)}px` // (level * levelPadding ) - (horizontalLineWidth +spaceBWLineAndIcon)
      }
    };
  }
);

const CircleIconStyled = styled(CircleIcon)(() => {
  return {
    width: `8px !important`,
    color: "rgb(51, 94, 102)"
  };
});

const ToggleButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "collapsed"
})(
  ({
    children,
    collapsed
  }: {
    children: any;
    collapsed: boolean;
  }) => {
    const theme = useTheme();
    return {
      color: theme.palette.main[60],
      padding: 0,
      borderRadius: 0,
      minHeight: 36,
      width: 36,
      transform: collapsed ? "" : "rotate(180deg)",
      "&:hover": {
        backgroundColor: "rgba(25, 118, 210, 0.04)"
      }
    };
  }
);

const NodeNameStyled = styled(Button)(
  ({
    children,
    active,
    nodedisabled
  }: {
    children: React.ReactNode;
    active: number;
    nodedisabled: number;
  }) => {
    const theme = useTheme();
    return {
      color: active ? "#FFF" : theme.palette.neutral[80],
      opacity: nodedisabled ? 0.26 : 1,
      cursor: nodedisabled ? "not-allowed" : "pointer",
      fontSize: 16,
      padding: "8px 10px",
      lineHeight: "20px",
      margin: 0,
      fontWeight: active ? "500" : "400",
      textTransform: "none",
      background: active ? theme.palette.main[40] : "none",
      "&:hover": {
        background: active ? theme.palette.main[40] : theme.palette.main[2]
      }
    };
  }
);

function Node({
  item,
  hasChildren,
  level,
  onToggle,
  collapsed,
  levelPadding,
  handleChange,
  activeZone,
  isDisableNode,
  disabled_tooltip
}: {
  item: IZone;
  hasChildren: boolean;
  level: number;
  onToggle: () => void;
  collapsed: boolean;
  levelPadding: number;
  handleChange: (e: React.MouseEvent<HTMLElement>, item: IZone) => void;
  activeZone: string;
  isDisableNode: (item: IZone) => boolean;
  disabled_tooltip: string;
}) {
  const isDisabled = isDisableNode(item);
  return (
    <NodeWrapper
      level={level}
      hasChildren={hasChildren}
      levelPadding={levelPadding}
      cursordisabled={isDisabled ? 1 : 0}
    >
      <ToggleButton
        onClick={onToggle}
        collapsed={collapsed}
        disabled={isDisabled}
      >
        {item?.children?.length ? (
          <ZoneArrow opacity={isDisabled ? 0.3 : 1} />
        ) : (
          <CircleIconStyled opacity={isDisabled ? 0.3 : 1} />
        )}
      </ToggleButton>

      {isDisabled && (
        <Tooltip placement="right" title={disabled_tooltip}>
          <NodeNameStyled
            active={activeZone === item.id ? 1 : 0}
            onClick={(e: React.MouseEvent<HTMLElement>) => {}}
            nodedisabled={1}
          >
            <span>{item.name}</span>
          </NodeNameStyled>
        </Tooltip>
      )}
      {!isDisabled && (
        <NodeNameStyled
          active={activeZone === item.id ? 1 : 0}
          onClick={(e: React.MouseEvent<HTMLElement>) => handleChange(e, item)}
          nodedisabled={0}
        >
          <span>{item.name}</span>
        </NodeNameStyled>
      )}
    </NodeWrapper>
  );
}
export default Node;
