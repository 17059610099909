import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Theme,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import React, { useState } from "react";
import ResizeDetector from "react-resize-detector";

import { IHistoryVitalsPlotData } from "interfaces/sleephistory.interface";
import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { getLangKey } from "utils/common/locale.util";
import { getLocalSettings, setLocalSettings } from "utils/localStorage";

function SleepHistoryVitalsChartMemo({
  onChartMouseOver,
  onChartClick,
  plotData,
  enableHeartRateMean,
  selectedDates,
  sessionIDsAsString
}: {
  onChartMouseOver: (src: any, e: Event) => void;
  onChartClick: (src: any, e: Event) => void;
  plotData: IHistoryVitalsPlotData;
  enableHeartRateMean: boolean;
  selectedDates: { from: string; to: string };
  sessionIDsAsString: string;
}) {
  const chartRef = React.createRef<any>();
  const { t } = useTranslation();
  const theme = useTheme();
  const [showVitals, setShowVitals] = useState<boolean>(true);
  return (
    <>
      <CardActions sx={{ justifyContent: "space-between" }}>
        <CardHeader title={`${t("Sleep History Vitals")}`} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>{`${showVitals ? t("Hide") : t("Show")} ${t(
            "Vital signs"
          )}`}</Typography>
          <IconButton
            onClick={() => setShowVitals(!showVitals)}
            aria-expanded={showVitals}
            aria-label="Show vitals"
          >
            {showVitals ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Box>
      </CardActions>
      <Collapse in={showVitals} mountOnEnter unmountOnExit>
        <CardContent>
          <ResizeDetector
            handleWidth
            onResize={() =>
              chartRef.current ? chartRef.current.chart.reflow() : undefined
            }
          >
            <HighchartsReact
              ref={chartRef}
              highcharts={Highcharts}
              constructorType="stockChart"
              options={getOptions({
                plotData,
                onChartClick,
                onChartMouseOver,
                enableHeartRateMean,
                t,
                theme
              })}
            />
          </ResizeDetector>
        </CardContent>
      </Collapse>
    </>
  );
}

const areEqual = (prevProps: any, nextProps: any) => {
  return prevProps.sessionIDsAsString === nextProps.sessionIDsAsString;
};
const SleepHistoryVitalsChart = React.memo(
  SleepHistoryVitalsChartMemo,
  areEqual
);
export default SleepHistoryVitalsChart;

const getOptions = ({
  plotData,
  onChartClick,
  onChartMouseOver,
  enableHeartRateMean,
  t,
  theme
}: {
  onChartMouseOver: (src: any, e: Event) => void;
  onChartClick: (src: any, e: Event) => void;
  plotData: IHistoryVitalsPlotData;
  enableHeartRateMean: boolean;
  t: any;
  theme: Theme;
}) => {
  const colorType = "light";
  const showHeartRate =
    plotData?.nonREMMeanHeartRate.length > 0 && !!enableHeartRateMean;
  const showExternalHeartRate =
    plotData?.nonREMMeanExternalHeartRate?.length > 0;
  const showExternalSpO2 = plotData?.externalSpO2Mean?.length > 0;
  const vitalSignsSettings =
    getLocalSettings(USER_SETTINGS_CONSTANTS.vital_signs_settings) || {};

  // const localSettings = getLocalSettings(USER_SETTINGS_CONSTANTS.vital_signs_settings) || {};
  if (vitalSignsSettings.resting_respiration_rate === undefined) {
    vitalSignsSettings.resting_respiration_rate = true;
  }
  return {
    chart: {
      type: "spline",
      backgroundColor: "transparent",
      zooming: {
        mouseWheel: {
          enabled: false
        }
      }
    },
    title: {
      text: null // 'Somnofy Sleep History'
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: "datetime",
      minTickInterval:
        plotData?.epochsWithMovementPct?.length <= 1
          ? undefined
          : 24 * 3600 * 1000, // One day in milliseconds
      labels: {
        formatter(a: any) {
          const xVal = new Date(a.value);
          const options: Intl.DateTimeFormatOptions = {
            month: "short",
            day: "numeric",
            timeZone: "Europe/Oslo",
            hourCycle: "h23"
          };
          const currentDate = xVal.toLocaleString(getLangKey(), options);

          return currentDate;
        }
      },
      title: {
        text: t("date")
      },
      ordinal: false
    },
    yAxis: [
      {
        // 0

        min: 0,
        labels: {
          enabled: false
        }
      },
      {
        // 1

        min: 0,
        labels: {
          enabled: false
        }
      },
      {
        // 2

        min: 0,
        labels: {
          enabled: false
        }
      },
      {
        // 3

        min: 0,
        labels: {
          enabled: false
        }
      },
      {
        // 4

        min: 0,
        labels: {
          enabled: false
        }
      },
      {
        // 5

        min: 0,
        labels: {
          enabled: false
        }
      },
      {
        // 6

        min: 0,
        labels: {
          enabled: false
        }
      },
      {
        // 7: Sound

        min: 20,
        labels: {
          enabled: false
        }
      },
      {
        // 8: SpO2

        min: 60,
        labels: {
          enabled: false
        }
      }
    ],
    legend: {
      enabled: true,
      itemHiddenStyle: {
        color: theme.palette.chart.legend.unselected[colorType],
        textDecoration: "none",
      }
    },
    scrollbar: {
      enabled: false
    },
    navigator: {
      enabled: false
    },
    rangeSelector: {
      enabled: false
    },
    plotOptions: {
      series: {
        gapSize: 36 * 60 * 60 * 1000,
        gapUnit: "value",
        dataGrouping: {
          enabled: false
        },
        marker: {
          enabled: true
        },
        events: {
          legendItemClick(event: any) {
            if (event?.target?.userOptions?.slug) {
              vitalSignsSettings[event.target.userOptions.slug] =
                !event.target.userOptions.visible;
              setLocalSettings({
                [USER_SETTINGS_CONSTANTS.vital_signs_settings]:
                  vitalSignsSettings
              });
            }
          }
        }
      }
    },
    series: [
      {
        name: t("Resting respiration rate (rpm)"),
        slug: "resting_respiration_rate",
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("rpm", e),
            mouseOver: (e: Event) => {
              onChartMouseOver("rpm", e);
            }
          }
        },
        yAxis: 0,
        color: "blue",
        visible: !!vitalSignsSettings.resting_respiration_rate,
        data: plotData.nonREMMeanRespirationRate
      },
      {
        name: t("Mean heart rate"),
        slug: "mean_heart_rate",
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("rhr", e),
            mouseOver: (e: Event) => onChartMouseOver("rhr", e)
          }
        },
        yAxis: 1,
        color: "red",
        data: plotData.nonREMMeanHeartRate,
        visible: showHeartRate && !!vitalSignsSettings.mean_heart_rate,
        showInLegend: showHeartRate
      },
      {
        name: t("Mean heart rate external"),
        slug: "mean_heart_rate_external",
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("rhrext", e),
            mouseOver: (e: Event) => onChartMouseOver("rhrext", e)
          }
        },
        yAxis: 1,
        color: "purple",
        data: plotData.nonREMMeanExternalHeartRate,
        visible:
          showExternalHeartRate &&
          !!vitalSignsSettings.mean_heart_rate_external,
        showInLegend: showExternalHeartRate
      },
      {
        name: t("Mean SpO2 external"),
        slug: "mean_sp02_external",
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("mspo2", e),
            mouseOver: (e: Event) => onChartMouseOver("mspo2", e)
          }
        },
        yAxis: 8,
        color: "blue",
        data: plotData.externalSpO2Mean,
        visible: showExternalSpO2 && !!vitalSignsSettings.mean_sp02_external,
        showInLegend: showExternalSpO2
      },
      {
        name: t("Time asleep"),
        slug: "time_asleep",
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("asleep", e),
            mouseOver: (e: Event) => onChartMouseOver("asleep", e)
          }
        },
        yAxis: 2,
        color: "black",
        visible: !!vitalSignsSettings.time_asleep,
        data: plotData.timeAsleep
      },
      {
        name: t("Sleep Onset"),
        slug: "sleep_onset",
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("onset", e),
            mouseOver: (e: Event) => onChartMouseOver("onset", e)
          }
        },
        yAxis: 2,
        color: "yellow",
        visible: !!vitalSignsSettings.sleep_onset,
        data: plotData.sleepOnset
      },
      {
        name: t("Sleep Efficiency"),
        slug: "sleep_efficieny",
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("efficiency", e),
            mouseOver: (e: Event) => onChartMouseOver("efficiency", e)
          }
        },
        yAxis: 3,
        color: "green",
        visible: !!vitalSignsSettings.sleep_efficieny,
        data: plotData.sleepEfficiency
      },
      {
        name: t("Sleep Score"),
        slug: "sleep_score",
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("score", e),
            mouseOver: (e: Event) => onChartMouseOver("score", e)
          }
        },
        yAxis: 3,
        color: "orange",
        visible: !!vitalSignsSettings.sleep_score,
        data: plotData.sleepScore
      },
      {
        name: t("Times awake"),
        slug: "times_awake",
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("timesawake", e),
            mouseOver: (e: Event) => onChartMouseOver("timesawake", e)
          }
        },
        yAxis: 4,
        color: "brown",
        visible: !!vitalSignsSettings.times_awake,
        data: plotData.numberOfTimesAwakeLong
      },
      {
        name: t("Times not present"),
        slug: "times_not_present",
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("timesnotpresent", e),
            mouseOver: (e: Event) => onChartMouseOver("timesnotpresent", e)
          }
        },
        yAxis: 4,
        color: "orange",
        visible: !!vitalSignsSettings.times_not_present,
        data: plotData.numberOfTimesNoPresence
      },

      {
        name: t("Time with movement"),
        slug: "time_with_movement",
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("epochswithmovementpct", e),
            mouseOver: (e: Event) =>
              onChartMouseOver("epochswithmovementpct", e)
          }
        },
        yAxis: 5,
        color: "black",
        visible: !!vitalSignsSettings.time_with_movement,
        data: plotData.epochsWithMovementPct
      },
      {
        name: t("illuminance"),
        slug: "light",
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("lightambientmean", e),
            mouseOver: (e: Event) => onChartMouseOver("lightambientmean", e)
          }
        },
        yAxis: 6,
        color: "orange",
        visible: !!vitalSignsSettings.light,
        data: plotData.lightAmbientMean
      },
      {
        name: t("Sound amplitude"),
        slug: "sound_amplitude",
        cursor: "pointer",
        point: {
          events: {
            click: (e: Event) => onChartClick("soundamplitudemean", e),
            mouseOver: (e: Event) => onChartMouseOver("soundamplitudemean", e)
          }
        },
        yAxis: 7,
        color: "green",
        visible: !!vitalSignsSettings.sound_amplitude,
        data: plotData.soundAmplitudeMean
      }
    ],
    tooltip: {
      shared: true,
      split: false,
      crosshairs: true,
      // eslint-disable-next-line func-names
      formatter() {
        // @ts-ignore
        const { points } = this;
        // points
        if (!points) {
          return <div>ss</div>;
        }

        const pointsLength = points.length;
        const timestamp = new Date(points[0].key);
        // const currentDate = moment(timestamp)
        //   .utc()
        //   .locale(getLangKey())
        //   .format("LLLL");

        const options: Intl.DateTimeFormatOptions = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hourCycle: "h23",
          timeZone: "Europe/Oslo"
        };
        const currentDate = new Date(timestamp).toLocaleString(
          getLangKey(),
          options
        );

        const currentDateUppercasefirstChar =
          currentDate.charAt(0).toUpperCase() + currentDate.slice(1);
        let tooltipMarkup: string = pointsLength
          ? `<span style="font-size: 10px;font-weight:bold">${currentDateUppercasefirstChar}</span><br/>`
          : "";
        let index;

        for (index = 0; index < pointsLength; index += 1) {
          if (
            points[index].series.userOptions.slug === "resting_respiration_rate"
          )
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} RPM</b><br/>`;
          else if (
            points[index].series.userOptions.slug === "mean_heart_rate" ||
            points[index].series.userOptions.slug === "mean_heart_rate_external"
          )
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} ${t("bpm")}</b><br/>`;
          else if (
            points[index].series.userOptions.slug === "time_asleep" ||
            points[index].series.userOptions.slug === "sleep_onset"
          ) {
            tooltipMarkup += `<span style="color:${points[index].series.color}">\u25CF</span> ${points[index].series.name}: <b>`;
            const sec = points[index].y;
            const h = Math.trunc(sec / 60 / 60);
            const m = Math.trunc((sec - h * 60 * 60) / 60);
            tooltipMarkup += `${
              (h > 0 ? `${h}${t("hours_abbreviation_lc")} ` : "") + m
            }${t("minutes_abbreviation_lc")}`;
            tooltipMarkup += "</b><br/>";
          } else if (points[index].series.userOptions.slug === "light") {
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} ${t("lux")}</b><br/>`;
          } else if (
            points[index].series.userOptions.slug === "sound_amplitude"
          ) {
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} ${t("dba")}</b><br/>`;
          } else if (
            points[index].series.userOptions.slug === "sleep_efficieny" ||
            points[index].series.userOptions.slug === "time_with_movement"
          ) {
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} %</b><br/>`;
          } else
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)}</b><br/>`;
        }
        return tooltipMarkup;
      }
    }
  };
};
