import React from "react";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";

import {
  INightReport,
  NightReportItemMetric,
  NightReportItemStatus,
  NightReportItemV1
} from "interfaces/nightReport.interface";
import { IconButtonStyled, ShortSpace } from "styled/CommonStyled";
import { ReactComponent as SortIcon } from "assets/icons/new/table-sort.svg";
import { getMinuteString, secondsToTime } from "utils/date.util";
import i18n from "i18n/config";

import {
  NightReportColumnNoAlert,
  NightReportColumnNoData
} from "../NightReportColumn";

interface HeadCell {
  id: string;
  label: string;
  hideSort?: boolean;
  tooltip?: string;
  width?: string | number;
  minWidth?: string | number;
}

/**
 * Sort order
 */
type Order = "asc" | "desc";
export const getHeadCells = (t: any) => {
  const headCells: HeadCell[] = [
    {
      id: "device_name",
      label: t("Device Name / Location"),
      hideSort: false,
      tooltip: t("night_report_device_name_info"),
      width: "15%"
    },
    {
      id: "subject_id",
      label: t("subject"),
      tooltip: t("night_report_subject_info"),
      width: "15%"
    },
    {
      id: "respiration_rate",
      label: t("Respiration Rate (rpm)"),
      tooltip: t("night_report_respiration_rate_info"),
      width: "10%"
    },
    {
      id: "movement",
      label: t("Movement"),
      tooltip: t("night_report_movement_info"),
      width: "10%"
    },
    {
      id: "time_asleep",
      label: t("Total Sleep Time"),
      tooltip: t("night_report_time_asleep_info"),
      width: "20%"
    },
    {
      id: "sleep_score",
      label: t("Sleep Score"),
      tooltip: t("night_report_sleep_score_info"),
      width: "10%"
    },
    {
      id: "out_of_bed",
      label: t("Out of Bed"),
      tooltip: t("night_report_out_of_bed_info"),
      width: "10%"
    },
    {
      id: "selected_session_is_in_progress",
      label: t("Session in Progress"),
      tooltip: t("night_report_selected_session_is_in_progress_info"),
      width: "10%"
    },
    {
      id: "baseline_night_count",
      label: i18n.t("Baseline Nights"),
      tooltip: i18n.t("night_report_baseline_night_count_info")
    }
  ];
  return headCells;
};

/* *****************************************************
 *                STYLED COMPONETS
 * ***************************************************** */
export const TableRowStyled = styled(TableRow)(
  ({ children }: {children: React.ReactNode }) => {
    const theme = useTheme();
    return {
      padding: "16px 20px",
      fontSize: 14,
      cursor: "pointer",
      textAlign: "left",
      background: theme.palette.white,
      borderBottom: `1px solid ${theme.palette.neutral[5]}`,
      color: theme.palette.neutral[40],
      [`& .${tableCellClasses.root}`]: {
        border: "none"
      },
      "&:last-child": {
        borderBottom: "none"
      },
      "& td": {
        color: theme.palette.neutral[40],
        fontSize: 16
      },
      "& td:first-of-type": {
        color: theme.palette.neutral[70],
        fontSize: 16
      }
    };
  }
);

export const TableHeadRowStyled = styled(TableRowStyled)(
  ({ children }: { children: React.ReactNode }) => {
    const theme = useTheme();
    return {
      textTransform: "uppercase",
      textAlign: "left",
      verticalAlign: "top",
      background: theme.palette.neutral[2],
      fontWeight: "normal",
      borderBottom: `1px solid ${theme.palette.neutral[5]}`,
      cursor: "default",
      // boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
      "& th": {
        fontWeight: "normal",
        fontSize: 14,
        color: theme.palette.neutral[50]
      },

      [`& .${tableCellClasses.root}`]: {
        border: "none"
      },
      "&:last-child": {
        borderBottom: `1px solid ${theme.palette.neutral[5]}`
      }
    };
  }
);

export const TableHeadDataStyled = styled("div")(
  ({
    children,
    active,
    direction,
    cursordefault,
    alignLeft
  }: {
    children: React.ReactNode;
    active?: number;
    direction?: string;
    cursordefault?: number;
    alignLeft?: number;
  }) => {
    return {
      cursor: cursordefault ? "default" : "pointer",

      // direction
      "& > div": {
        display: "flex",
        flexDirection: "row",
        justifyContent: alignLeft ? "left" : "center",
        textAlign: alignLeft ? "left" : "center",
        // alignItems: "center",

        columnGap: 5,
        "& > div > svg": {
          width: 24,
          padding: 0,
          height: 24
        }
      },

      "& > div:nth-of-type(2)": {
        marginLeft: 10
      }
    };
  }
);

const ValueWrapper = styled("div")(() => {
  return {
    display: "inline-block",
    // width: 135,
    textAlign: "left",
    "&>span": {
      wordSpacing: "-0.125em"
    }
  };
});

const ErrorOutlineWrapperStyled = styled("div")(
  () => {
    return {
      marginRight: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&>svg": {
        color: "rgb(165, 5, 32)",
        width: 28,
        height: 28,
        "@media print": {
          width: 12,
          height: 12
        }
      }
    };
  }
);

const ErrorPlaceHolderStyled = styled("div")(() => {
  return {
    width: 28,
    height: 28,
    marginRight: 10,
    "@media print": {
      width: 16,
      height: 16
    }
  };
});

const DivWrapper = styled("div")(
  ({
    children,
    sx
  }: {
    children: React.ReactNode;
    sx?: React.CSSProperties;
  }) => {
    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ...sx
    };
  }
);

const ValueWrapperNoWidth = styled("div")(() => {
  return {
    display: "inline-block",
    textAlign: "left",
    "&>span": {
      wordSpacing: "-0.125em"
    }
  };
});
/**
 * Enhanced Interface for table head
 *
 * @props `onRequestSort` function to request sort for the cicked column
 * @props `onRequestSort` sort type - ASC || DESC
 * @props `orderBy`  sort property
 * @props `renderComponentForValue` object as [rowKey]:JSX.Element, Render component passed instead of row value,
 */
interface EnhancedTableProps<T> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: Order;
  orderBy: keyof T | undefined | string;
}

/* *****************************************************
 *               Dynamic Sorting
 * ***************************************************** */

/**
 * Sort function
 * this function do item sort
 *
 * @returns sort return
 */
function descendingComparator(
  a: NightReportItemV1,
  b: NightReportItemV1,
  orderBy: keyof NightReportItemV1 | "" | string
) {
  if (!orderBy) {
    return 0;
  }
  let key = orderBy;
  if (orderBy === "device_name") {
    key = "device" as keyof NightReportItemV1;
  }
  if (orderBy === "subject_id") {
    key = "subject" as keyof NightReportItemV1;
  }

  let leftVal = a[key as keyof NightReportItemV1];
  let rightVal = b[key as keyof NightReportItemV1];

  if (key === "device") {
    leftVal = leftVal as {
      id: string;
      type: string;
      serial_number: string;
      name: string;
    };

    rightVal = rightVal as {
      id: string;
      type: string;
      serial_number: string;
      name: string;
    };
    leftVal = leftVal.name;
    rightVal = rightVal.name;
  }

  if (key === "subject") {
    leftVal = leftVal as {
      id: string;
      identifier: string;
      sex: string;
      birth_year: number;
    };

    rightVal = rightVal as {
      id: string;
      identifier: string;
      sex: string;
      birth_year: number;
    };
    leftVal = leftVal.identifier;
    rightVal = rightVal.identifier;
  }

  if (
    orderBy === "respiration_rate" ||
    orderBy === "movement" ||
    orderBy === "time_asleep" ||
    orderBy === "out_of_bed" ||
    orderBy === "sleep_score"
  ) {
    leftVal = leftVal as NightReportItemMetric;
    rightVal = rightVal as NightReportItemMetric;
    leftVal = leftVal?.value;
    rightVal = rightVal?.value;
  }

  // undefined value
  if (leftVal === undefined) {
    return 1;
  }
  if (rightVal === undefined) {
    return -1;
  }

  // if the left and right is string
  if (typeof leftVal === "string" && typeof rightVal === "string") {
    return Intl.Collator("nb", { sensitivity: "base" }).compare(
      leftVal,
      rightVal
    );
  }

  if (leftVal < rightVal) {
    return -1;
  }

  if (leftVal > rightVal) {
    return 1;
  }
  return 0;
}

/**
 * Function to check the type of operation
 *
 * @returns `descendingComparator` function
 *
 */
function getComparator(
  order: Order,
  orderBy?: keyof NightReportItemV1 | string
): (a: NightReportItemV1, b: NightReportItemV1) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy || "")
    : (a, b) => -descendingComparator(a, b, orderBy || "");
}

/**
 * Stable sort function
 *
 * This method is created for cross-browser compatibility, if you don't
 * need to support IE11, you can use Array.prototype.sort() directly
 *
 */
function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/**
 * SMTableHead
 *
 * This function will render the table head row based on the [HeadCell]s.
 * `onRequestSort` will be called on every column click
 *
 * @props `EnhancedTableProps` Props of type EnhancedTableProps
 */
function SMTableHead<T>(props: EnhancedTableProps<T>) {
  const { order, orderBy, onRequestSort } = props;
  const { t } = useTranslation();
  const createSortHandler =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const headCells: HeadCell[] = getHeadCells(t);
  return (
    <TableHead>
      <TableHeadRowStyled>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.label}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: headCell?.width || "auto" }}
          >
            <TableHeadDataStyled
              active={orderBy === headCell.id ? 1 : 0}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                !headCell.hideSort
                  ? createSortHandler(headCell.id as keyof T)
                  : () => {}
              }
              cursordefault={headCell.hideSort ? 1 : 0}
              alignLeft={headCells.indexOf(headCell) <= 1 ? 1 : 0}
            >
              <Tooltip placement="top" title={headCell.tooltip || "dd"}>
                <div>
                  <div
                    className={
                      headCell.id === "baseline_night_count"
                        ? "print_css_baseline_night"
                        : ""
                    }
                  >
                    {" "}
                    {headCell.label}
                  </div>{" "}
                  <div className="sortIconParentDiv">
                    {" "}
                    {!headCell.hideSort && (
                      <IconButtonStyled padding={0} width="24px">
                        <SortIcon />
                      </IconButtonStyled>
                    )}{" "}
                  </div>
                </div>
              </Tooltip>
            </TableHeadDataStyled>
          </TableCell>
        ))}
      </TableHeadRowStyled>
    </TableHead>
  );
}

/**
 * SMDynamicTable
 *
 * This function will render the table rows based on the RowData array of type `IRowData`
 * @param `IRowData` Interface of the rowdata
 * @props `IProps` Props of type IProps
 */
export default function NightReportTable({
  report,
  nightReportRowClick
}: {
  report: INightReport;
  nightReportRowClick: (row: NightReportItemV1) => void;
}) {
  const { t } = useTranslation();
  const [order, setOrder] = React.useState<Order>("asc");
  // added string type, to fix the Typescript Error
  const [orderBy, setOrderBy] = React.useState<
    keyof NightReportItemV1 | undefined | string
  >("subject_id");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof NightReportItemV1
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isBaselinNightLessthanThreshold = (row: NightReportItemV1) => {
    return row.baseline_night_count <= 2;
  };

  const renderAlert = (row?: NightReportItemMetric) => {
    return row?.deviation_alert ? (
      <ErrorOutlineWrapperStyled>
        {" "}
        <ErrorOutlineIcon />{" "}
      </ErrorOutlineWrapperStyled>
    ) : (
      <ErrorPlaceHolderStyled />
    );
  };

  const getRoundedValue = (row?: NightReportItemMetric) => {
    if (row?.value) {
      return Math.round(row.value);
    }
    return row?.value === 0 ? "0" : i18n.t("N/A");
  };

  const getDeviationPCT = ({
    value,
    round,
    hideOperator = false
  }: {
    value: number;
    round: number;
    hideOperator?: boolean;
  }): string => {
    if (!value) {
      return "";
    }
    if (round > 0) {
      return `${value > 0 && !hideOperator ? "+" : ""}${value.toFixed(round)}`;
    }
    const val = `${value > 0 && !hideOperator ? "+" : ""}${Math.round(value)}`;
    if (i18n.language !== "en") {
      return val.replace(".", ",");
    }
    return val;
  };

  const getTimeAsleep = (seconds: number): string => {
    if (!seconds) {
      return "";
    }

    const timeAsleep = secondsToTime(seconds);
    return `${
      timeAsleep.h > 0
        ? `${timeAsleep.h}${i18n.t("hours_abbreviation_lc")}`
        : ""
    } ${getMinuteString(timeAsleep.h, timeAsleep.m)}`;
  };

  const customSortOrder = {
    PRESENCE_AND_SLEEP: 1,
    NO_SLEEP: 2,
    NO_PRESENCE: 3
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }}>
          <SMTableHead<NightReportItemV1>
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort<NightReportItemV1>(
              report.items,
              getComparator(order, orderBy)
            )
              .sort(
                (a: NightReportItemV1, b: NightReportItemV1) =>
                  (customSortOrder[a.state] || 0) - customSortOrder[b.state] ||
                  0
              )
              .map((row: NightReportItemV1, index: number) => {
                if (
                  row.state !== NightReportItemStatus.NO_PRESENCE &&
                  row.state !== NightReportItemStatus.NO_SLEEP
                ) {
                  return (
                    <TableRowStyled
                      onClick={(event) => nightReportRowClick(row)}
                      tabIndex={-1}
                      key={row.device.id}
                    >
                      <TableCell>{row.device.name}</TableCell>
                      <TableCell>{row.subject.identifier}</TableCell>
                      <TableCell align="center">
                        {isBaselinNightLessthanThreshold(row) && (
                          <NightReportColumnNoAlert
                            value={
                              row.respiration_rate?.value
                                ? Math.round(row.respiration_rate?.value)
                                : ""
                            }
                          />
                        )}
                        {!isBaselinNightLessthanThreshold(row) && (
                          <>
                            <DivWrapper>
                              <span className="iconAlertWrapper">
                                {renderAlert(row.respiration_rate)}
                              </span>
                              <ValueWrapper>
                                {getRoundedValue(row.respiration_rate)}
                                {row.respiration_rate?.deviation_pct ? (
                                  <>
                                    {" "}
                                    (
                                    {getDeviationPCT({
                                      value:
                                        +row.respiration_rate?.deviation_pct,
                                      round: 0
                                    })}
                                    <ShortSpace>&nbsp;</ShortSpace>%)
                                  </>
                                ) : (
                                  ""
                                )}
                              </ValueWrapper>
                            </DivWrapper>
                          </>
                        )}
                      </TableCell>

                      <TableCell align="center">
                        {isBaselinNightLessthanThreshold(row) && (
                          <NightReportColumnNoAlert
                            value={
                              row.movement?.value
                                ? `${Math.round(row.movement?.value)}`
                                : ""
                            }
                          />
                        )}
                        {!isBaselinNightLessthanThreshold(row) && (
                          <>
                            <DivWrapper>
                              <span className="iconAlertWrapper">
                                {renderAlert(row.movement)}
                              </span>
                              <ValueWrapper>
                                {getRoundedValue(row.movement)}
                                {/* <ShortSpace>&nbsp;</ShortSpace>% */}
                                {row.movement?.deviation_pct ? (
                                  <>
                                    {" "}
                                    (
                                    {getDeviationPCT({
                                      value: +row.movement?.deviation_pct,
                                      round: 0
                                    })}
                                    <ShortSpace>&nbsp;</ShortSpace>%)
                                  </>
                                ) : (
                                  ""
                                )}
                              </ValueWrapper>
                            </DivWrapper>

                            {/* <NightReportColumn
                            item={row.movement}
                            pct
                            round={0}
                            valueRound
                            valueAsPercentage
                          /> */}
                          </>
                        )}
                      </TableCell>

                      <TableCell align="center">
                        {isBaselinNightLessthanThreshold(row) && (
                          <NightReportColumnNoAlert
                            value={
                              row?.time_asleep?.value
                                ? getTimeAsleep(row?.time_asleep?.value)
                                : t("N/A")
                            }
                          />
                        )}
                        {!isBaselinNightLessthanThreshold(row) && (
                          <>
                            <DivWrapper>
                              <span className="iconAlertWrapper">
                                {renderAlert(row.time_asleep)}
                              </span>
                              <ValueWrapper>
                                {row?.time_asleep?.value ? (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `${getTimeAsleep(
                                        row?.time_asleep?.value
                                      )}`
                                    }}
                                  />
                                ) : (
                                  t("N/A")
                                )}
                                {row.time_asleep?.deviation_pct ? (
                                  <>
                                    {" "}
                                    (
                                    {getDeviationPCT({
                                      value: +row.time_asleep?.deviation_pct,
                                      round: 0
                                    })}
                                    <ShortSpace>&nbsp;</ShortSpace>%)
                                  </>
                                ) : (
                                  ""
                                )}
                              </ValueWrapper>
                            </DivWrapper>
                          </>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {isBaselinNightLessthanThreshold(row) && (
                          <div>
                            {row.sleep_score?.value >= 0
                              ? Math.round(row.sleep_score?.value)
                              : ""}
                          </div>
                        )}
                        {!isBaselinNightLessthanThreshold(row) && (
                          <>
                            <DivWrapper>
                              <ValueWrapperNoWidth>
                                {getRoundedValue(row.sleep_score)}
                                {row.sleep_score?.baseline ? (
                                  <>
                                    {" "}
                                    (
                                    {getDeviationPCT({
                                      value: row.sleep_score?.baseline,
                                      round: 0,
                                      hideOperator: true
                                    })}
                                    )
                                  </>
                                ) : (
                                  ""
                                )}
                              </ValueWrapperNoWidth>
                            </DivWrapper>

                            {/* <NightReportColumnCenter
                            item={row.sleep_score}
                            baseline
                            pct={false}
                            round={0}
                            valueRound
                          /> */}
                          </>
                        )}
                      </TableCell>

                      <TableCell align="center">
                        {isBaselinNightLessthanThreshold(row) && (
                          <div>{getRoundedValue(row.out_of_bed)}</div>
                        )}
                        {!isBaselinNightLessthanThreshold(row) && (
                          <>
                            <DivWrapper>
                              <ValueWrapperNoWidth>
                                {getRoundedValue(row.out_of_bed)}
                                {row.out_of_bed?.baseline ? (
                                  <>
                                    {" "}
                                    (
                                    {getDeviationPCT({
                                      value: row.out_of_bed?.baseline,
                                      round: 1,
                                      hideOperator: true
                                    })}
                                    )
                                  </>
                                ) : (
                                  ""
                                )}
                              </ValueWrapperNoWidth>
                            </DivWrapper>

                            {/* <NightReportColumnCenter
                              item={row.out_of_bed}
                              pct={false}
                              baseline
                              round={1}
                              usecomma={i18n.language !== "en"}
                            /> */}
                          </>
                        )}
                      </TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            columnGap: "5px",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <div>
                            {row.selected_session_is_in_progress
                              ? t("Yes")
                              : t("No")}
                          </div>
                          <Box sx={{ visibility: "hidden" }} width="24px" />
                        </Box>
                      </TableCell>
                      <TableCell>
                        {row.baseline_night_count
                          ? `${row.baseline_night_count}`
                          : t("N/A")}
                      </TableCell>
                    </TableRowStyled>
                  );
                }
                return (
                  <TableRowStyled
                    onClick={(event) => {}}
                    tabIndex={-1}
                    key={row.device?.id}
                  >
                    <TableCell>{row.device?.name}</TableCell>
                    <TableCell>{row.subject?.identifier}</TableCell>

                    <TableCell align="left" colSpan={7}>
                      <NightReportColumnNoData
                        t={t}
                        message={
                          row.state === NightReportItemStatus.NO_PRESENCE
                            ? t("subjet_not_present_or_data_not_available")
                            : t("No sleep data available")
                        }
                      />
                    </TableCell>
                  </TableRowStyled>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
