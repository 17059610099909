import { INightReport } from "interfaces/nightReport.interface";
import { nightReportServices } from "services/dashboard/nightReportService";

import * as types from "../../actionTypes/dashboard/nightReportActionTypesV1";

/* ************************************************** */
/*                      Actions                       */
/* ************************************************** */

export const clearNightReportV1Action =
  (): types.clearNightReportV1ActionType => {
    return {
      type: types.CLEAR_NIGHT_REPORT
    };
  };

/**
 * Redux action to fetch all zones
 *
 * @return type - redux action type |
 */
export const fetchNightReportV1Action =
  (): types.fetchNightReportV1ActionType => {
    return {
      type: types.FETCH_NIGHT_REPORT_V1
    };
  };

/**
 * Redux monitor success action
 *
 * @return type - redux action type |
 *         payload - subjects
 */
export const fetchNightReportV1SuccessAction = (
  data: INightReport
): types.fetchNightReportV1SuccessActionType => {
  return {
    type: types.FETCH_NIGHT_REPORT_V1_SUCCESS,
    payload: data
  };
};

/* ************************************************** */
/*                      Services                      */
/* ************************************************** */

/**
 *  Function to fetch all the subjects
 *
 * @param none
 * @return all the subjects in the system
 */
export function fetchNightReportV1(zones: string) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    nightReportServices
      .fetchNightReport(zones)
      .then((response: { data: any }) => {
        if (response?.data && response?.data?.length) {
          dispatch(fetchNightReportV1SuccessAction(response.data[0]));
        }
      });
  };
}

export function clearNightReportV1() {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(clearNightReportV1Action());
  };
}
