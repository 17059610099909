import { Alert, Container } from "@mui/material";
import { Box } from "@mui/system";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { AppMenuProps } from "interfaces/router.interface";
import {
  AuthorizationContext,
  AuthorizationPermissionsType
} from "authorization/AuthorizationContext";
import { ZoneUserRolesEnum } from "constants/userContstants";
import { getUserPermission, isAllowed } from "authorization/authorization.util";
import ConfirmDialogProvider from "providers/ConfirmDialogProvider";
import DialogProvider from "providers/DialogProvider";
import SMAdminAppBar from "components/SMAdminAppBar/SMAdminAppBar";
import SleepHistoryFilterDropdown from "components/StyledMenu/SleepHistoryFilterDropdown";
import useNetworkStatus from "hooks/UseNetworkStatus";

import routes from "../routes/admin.routes";

function AdminLayout() {
  const { t } = useTranslation();
  const isOnline = useNetworkStatus();
  const authState: any = useSelector((state: any) => {
    return state.authReducerV1 || {};
  });
  const [permissions, setPermissions] = useState<
    AuthorizationPermissionsType[]
  >([]);

  useEffect(() => {
    const userPermissions = getUserPermission(authState?.user);
    setPermissions(userPermissions);
  }, [authState.user]);

  const filteredRoutes = (rawRoutes: AppMenuProps[]) => {
    return rawRoutes.filter((route: AppMenuProps) => {
      return isAllowed(authState.user, route.allowedRoles);
    });
  };

  // if the path is / so redirect to monitor or admin  based on the access
  const getAdminRedirectPath = () => {
    if (
      isAllowed(authState.user, [
        ZoneUserRolesEnum.DATA_READER,
        ZoneUserRolesEnum.DATA_READER_LIMITED
      ])
    ) {
      return "/monitor";
    }
    return "/adminstration";
  };
  return (
    <AuthorizationContext.Provider
      value={{
        permissions
      }}
    >
      <DialogProvider>
        <ConfirmDialogProvider>
          <Container maxWidth={false} disableGutters>
            <SMAdminAppBar
              screens={filteredRoutes(routes) || []}
              loginedUser={authState.user}
            />
            <Box sx={{ m: "10px" }}>
              <SleepHistoryFilterDropdown />
            </Box>
            {/* <Offline>
              <Alert variant="filled" severity="error" sx={{ borderRadius: 0 }}>
                {t("No internet connection")}
              </Alert>
            </Offline> */}

            {/* if no network */}
            {!isOnline && (
              <Alert variant="filled" severity="error" sx={{ borderRadius: 0 }}>
                {t("No internet connection")}
              </Alert>
            )}

            <Switch>
              {filteredRoutes(routes).map((route: AppMenuProps) => {
                return (
                  <Route
                    exact={false}
                    key={route.name}
                    path={route.path}
                    component={route.component}
                  />
                );
              })}
              <Redirect to={getAdminRedirectPath()} />
            </Switch>
          </Container>
        </ConfirmDialogProvider>
      </DialogProvider>
    </AuthorizationContext.Provider>
  );
}

export default AdminLayout;
