import { SvgIcon } from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Dialog, { dialogClasses } from "@mui/material/Dialog";
import DialogActions, { dialogActionsClasses } from "@mui/material/DialogActions";
import DialogContent, { dialogContentClasses } from "@mui/material/DialogContent";
import DialogContentText, { dialogContentTextClasses } from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import WarningIcon from "@mui/icons-material/Warning";

import SMButtonNew from "components/SMButton/SMButtonNew";
import SMTextField from "components/SMTextField/SMTextField";

const DialogStyled = styled(Dialog)(
  () => {
    const theme = useTheme();
    return {
      padding: "8px",
      [`& .${dialogClasses.root}`]: {
        padding: 8
      },
      [`& .${dialogClasses.paper}`]: {
        "& h2": {
          fontSize: 16,
          color: theme.palette.neutral[80],
          fontWeight: 500,
          textDecoration: "none",
          textTransform: "none",
          padding: "24px 24px 16px 24px"
        },
        "& span": {
          "& svg": {
            float: "left",
            marginTop: 3,
            marginRight: 10
          }
        },
        [`& .${dialogContentClasses.root}`]: {
          paddingTop: 0,
          marginBottom: 0,
          color: theme.palette.neutral[80],
          [`& .${dialogContentTextClasses.root}`]: {
            fontSize: 14,
            width: "100%",
            "& > p": {
              marginBottom: 8
            }
          }
        },
        [`& .${dialogActionsClasses.root}`]: {
          padding: "0 24px 24px 24px"
        }
      }
    };
  }
);

const SvgIconStyled = styled(SvgIcon)(
  ({
    bg
  }: {
    bg?: "green" | "red" | "none" | "yellow";
  }) => {
    const theme = useTheme();
    return {
      marginRight: 10,
      float: "left",
      color:
        bg === "yellow" || bg === "green"
          ? theme.palette.orange[40]
          : theme.palette.red[40]
    };
  }
);

const DialogContentTextStyled = styled(DialogContentText)(
  () => {
    const theme = useTheme();
    return {
      textTransform: "none",
      color: theme.palette.neutral[80],
      "& p": {
        color: theme.palette.neutral[80]
      }
    };
  }
);

const EMPTY_FUNC = () => {};
type ConfirmDialogParams = {
  children?: React.ReactNode;
  open: boolean;
  onClose: () => void;
  title?: string;
  confirmButtonBg?: "green" | "red" | "none" | "yellow";
  dialogDesc: string;
  confirmPlaceHolder?: string;
  confirmTextDesc?: string;
  confirmText?: string;
  buttonConfirmText: string;
  callback: () => void;
};
type ConfirmDialogOption = Omit<ConfirmDialogParams, "open">;

type ConfirmProviderContext = readonly [
  (option: ConfirmDialogOption) => void,
  () => void
];

const ConfirmDialogContext = React.createContext<ConfirmProviderContext>([
  EMPTY_FUNC,
  EMPTY_FUNC
]);
export const useCofirmDialog = () => React.useContext(ConfirmDialogContext);

function DialogContainer(props: ConfirmDialogParams) {
  const { t } = useTranslation();
  const {
    children,
    open,
    onClose,
    title,
    dialogDesc,
    confirmButtonBg,
    confirmPlaceHolder,
    confirmTextDesc,
    confirmText,
    buttonConfirmText,
    callback
  } = props;
  const [confirm, setConfirm] = useState<string>("");

  const getOpacity = () => {
    if (confirmText) {
      return confirm === confirmText ? 1 : 0.5;
    }
    return 1;
  };
  const getCursor = () => {
    if (confirmText) {
      return confirm === confirmText ? "pointer" : "not-allowed";
    }
    return "pointer";
  };
  return (
    <DialogStyled open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <SvgIconStyled bg={confirmButtonBg}>
          <WarningIcon />
        </SvgIconStyled>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentTextStyled
          dangerouslySetInnerHTML={{
            __html: dialogDesc
          }}
          sx={{
            marginBottom: "20px"
          }}
        />
        {confirmTextDesc && (
          <>
            <DialogContentTextStyled
              dangerouslySetInnerHTML={{
                __html: confirmTextDesc
              }}
              sx={{ marginBottom: "4px" }}
            />
            <SMTextField
              placeholder={confirmPlaceHolder}
              onUpdate={(val: string) => setConfirm(val)}
              defaultValue={confirm}
              width={304}
            />
          </>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        <SMButtonNew onClick={onClose} text={t("Cancel")} background="none" />
        <SMButtonNew
          onClick={() => {
            if (confirmText && confirmTextDesc) {
              if (confirm === confirmText) {
                return callback();
              }
              return null;
            }
            return callback();
          }}
          text={buttonConfirmText}
          opacity={getOpacity()}
          cursor={getCursor()}
          background={confirmButtonBg}
        />
      </DialogActions>
    </DialogStyled>
  );
}

export default function ConfirmDialogProvider({
  children
}: {
  children: JSX.Element;
}) {
  const [confirmDialogs, setConfirmDialogs] = React.useState<
    ConfirmDialogParams[]
  >([]);
  const createConfirmDialog = (option: ConfirmDialogOption) => {
    const dialog = { ...option, open: true };
    setConfirmDialogs((prevState) => [...prevState, dialog]);
  };

  // close dialog
  const closeConfirmDialog = () => {
    setConfirmDialogs((prevState) => {
      const latest = prevState[prevState.length - 1];
      if (latest && latest.onClose) {
        latest.onClose();
      }
      return prevState.slice(0, prevState.length - 1);
    });
  };
  const contextValue = React.useRef([
    createConfirmDialog,
    closeConfirmDialog
  ] as const);
  return (
    <ConfirmDialogContext.Provider value={contextValue.current}>
      {children}
      {confirmDialogs.map((confirmDialog, i) => {
        const { onClose, ...dialogParams } = confirmDialog;
        return (
          <DialogContainer
            // eslint-disable-next-line react/no-array-index-key
            key={`${i}_key`}
            onClose={closeConfirmDialog}
            {...dialogParams}
          />
        );
      })}
    </ConfirmDialogContext.Provider>
  );
}
