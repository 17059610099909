import { styled } from "@mui/system";
import React, { useState } from "react";

import { IZone } from "interfaces/zone.interface";

import NodeWithCheckbox from "./NodeWithCheckbox";

const NodeParentWraper = styled("div")(
  ({
    children,
    hasChildren,
    level,
    levelPadding
  }: {
    children: React.ReactNode;
    hasChildren?: boolean;
    level: number;
    levelPadding: number;
  }) => {
    return {
      position: "relative"
    };
  }
);

function TreeBranchWithCheckbox({
  item,
  level,
  onChecked,
  levelPadding,
  selected,
  search,
  isDisableNode,
  disabled_tooltip_message,
  no_permission_tooltip_message
}: {
  item: IZone;
  level: number;
  onChecked: (
    e: React.SyntheticEvent<Element, Event>,
    checked: boolean,
    node: IZone
  ) => void;
  levelPadding: number;
  selected: string[];
  search?: string;
  isDisableNode: (node: IZone) => boolean;
  disabled_tooltip_message: string;
  no_permission_tooltip_message: string;
}) {
  const [collapsed, setCollapsed] = useState<any>(item.collapsed ?? false);
  const hasChildren = (item.children && item.children.length !== 0) || false;

  function renderBranches() {
    if (hasChildren) {
      const newLevel = level + 1;
      return item?.children?.map((child: any) => {
        return (
          <TreeBranchWithCheckbox
            key={child.id}
            item={child}
            level={newLevel}
            onChecked={onChecked}
            levelPadding={levelPadding}
            selected={selected}
            search={search}
            isDisableNode={isDisableNode}
            disabled_tooltip_message={disabled_tooltip_message}
            no_permission_tooltip_message={no_permission_tooltip_message}
          />
        );
      });
    }
    return null;
  }

  const toggleSelected = React.useCallback(
    () => {
      setCollapsed((prev: any) => !prev);
    },
    [setCollapsed]
  );

  return (
    <NodeParentWraper
      hasChildren={hasChildren}
      level={level}
      levelPadding={levelPadding}
    >
      <NodeWithCheckbox
        item={item}
        collapsed={collapsed}
        hasChildren={hasChildren}
        level={level}
        onToggle={toggleSelected}
        levelPadding={levelPadding}
        onChecked={onChecked}
        selected={selected}
        search={search}
        isDisableNode={isDisableNode}
        disabled_tooltip_message={disabled_tooltip_message}
        no_permission_tooltip_message={no_permission_tooltip_message}
      />
      {!collapsed && renderBranches()}
    </NodeParentWraper>
  );
}
export default TreeBranchWithCheckbox;
