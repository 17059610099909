import * as React from "react";
import GlobalStyles from "@mui/material/GlobalStyles";

import { appTheme } from "theme/default/somnofy";

export default function GlobalCssOverride() {
  return (
    <>
      <GlobalStyles
        styles={{
          ".chip_online": {
            background: appTheme.palette.green[10],
            borderRadius: 16,
            color: appTheme.palette.neutral[80],
            fontSize: 14,
            width: 70,
            textAlign: "center",
            border: `1px solid ${appTheme.palette.green[20]}`,
            padding: "2px 12px"
          },
          ".chip_male": {
            background: appTheme.palette.blue[5],
            borderRadius: 16,
            color: appTheme.palette.neutral[80],
            fontSize: 14,
            width: 70,
            textAlign: "center",
            border: `1px solid ${appTheme.palette.blue[10]}`,
            padding: "2px 12px"
          },
          ".chip_female": {
            background: appTheme.palette.teal[5],
            borderRadius: 16,
            color: appTheme.palette.neutral[80],
            fontSize: 14,
            width: 70,
            textAlign: "center",
            border: `1px solid ${appTheme.palette.teal[10]}`,
            padding: "2px 12px"
          },
          ".chip_offline": {
            background: appTheme.palette.red[10],
            borderRadius: 16,
            color: appTheme.palette.neutral[80],
            fontSize: 14,
            border: `1px solid ${appTheme.palette.red[20]}`,
            padding: "2px 12px",
            width: 70,
            textAlign: "center"
          },
          ".flex_center": {
            display: "flex",
            justifyContent: "center"
          }
        }}
      />
    </>
  );
}
