import { ILog } from "interfaces/logs.interface";

export const FETCH_NOTIFICATION_LOGS = "FETCH_NOTIFICATION_LOGS";
export const FETCH_NOTIFICATION_LOGS_SUCCESS =
  "FETCH_NOTIFICATION_LOGS_SUCCESS";
export const RESET_NOTIFICATION_LOGS = "RESET_NOTIFICATION_LOGS";

export interface resetLogsStateActionType {
  type: typeof RESET_NOTIFICATION_LOGS;
}

export interface fetchLogsStateActionType {
  type: typeof FETCH_NOTIFICATION_LOGS;
}

export interface fetchLogsSuccessStateActionType {
  type: typeof FETCH_NOTIFICATION_LOGS_SUCCESS;
  payload: ILog[];
}

export type logsActionTypes =
  | fetchLogsSuccessStateActionType
  | fetchLogsStateActionType
  | resetLogsStateActionType;
