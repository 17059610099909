import { CardContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";

import { ISleepEpochPlotData } from "interfaces/sleephistory.interface";
import { fetchSleepEpoch } from "store/actions/sleephistory/sleepEpochActions";
import { getSleepEpochDataPlotData } from "utils/sleepHistory/sleepEpochData";

import {
  SleepHistoryContext,
  SleepHistoryContextType
} from "../SleepHistoryContext";
import SleepEpochDataChart from "../charts/SleepEpochDataChart";

function SleepEpochData({ sessionId }: { sessionId: string }) {
  const dispatch = useDispatch();
  const [epochPlotData, setEpochPlotData] = useState<ISleepEpochPlotData>();

  const { enableHeartRateMean } =
    useContext<SleepHistoryContextType>(SleepHistoryContext);

  const sleepEpochData = useSelector((state: any) => {
    return state?.sleepEpochReducer?.sleepEpoch || {};
  });

  useEffect(() => {
    if (sleepEpochData && sleepEpochData[sessionId]) {
      //
    } else {
      dispatch(fetchSleepEpoch(sessionId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    const epoch = sleepEpochData[sessionId];
    if (epoch) {
      const plotData: ISleepEpochPlotData = getSleepEpochDataPlotData(epoch);
      setEpochPlotData(plotData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sleepEpochData]);

  return (
    <div>
      {epochPlotData && (
        <CardContent>
          <SleepEpochDataChart
            plotData={epochPlotData}
            enableHeartRateMean={enableHeartRateMean}
          />
        </CardContent>
      )}
    </div>
  );
}
export default SleepEpochData;
