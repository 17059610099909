import { API_ACTIONS } from "store/actionTypes/apiActionTypes";
import { IZone } from "interfaces/zone.interface";

import * as types from "../../actionTypes/administration/zoneActionTypes";

export default function zoneReducer(
  state: any = {},
  action: types.zonesActionTypes
) {
  switch (action.type) {
    case types.FETCH_ZONES_SUCCESS:
      return {
        ...state,
        zones: action.payload || [],
        apitStatus: API_ACTIONS.reset
      };
    case types.CREATE_ZONE_SUCCESS:
      return {
        ...state,
        zones: [action.payload, ...state.zones],
        apitStatus: API_ACTIONS.created
      };

    case types.UPDATE_ZONE_SUCCESS:
      return {
        ...state,
        apitStatus: API_ACTIONS.updated,
        zones: state.zones.map((zone: IZone) =>
          zone.id === action.payload.id ? { ...action.payload } : zone
        )
      };
    case types.RESET_ZONE_ACTIONS:
      return {
        ...state,
        apitStatus: API_ACTIONS.reset
      };

    case types.DELETE_ZONE_SUCCESS:
      return {
        ...state,
        zones: state.zones.filter(
          (zone: IZone) => action.payload.indexOf(zone.id) === -1
        )
      };

    default:
      return state;
  }
}
