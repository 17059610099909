import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";

import * as LOCAL_STORAGE from "utils/localStorage";
import { IZone } from "interfaces/zone.interface";
import { SplitPaneLeft } from "components/ResizablePanel/SplitPanelLeft";
import { SplitPanel } from "components/ResizablePanel/SplitPanel";
import { SplitPanelDivider } from "components/ResizablePanel/SplitPanelDivider";
import { canAccessZone } from "utils/zone.util";
import { getAllZones } from "store/actions/administration/zoneActions";
import AdminMainContainer from "@modules/administration/components/AdminMainContainer";
import SplitPanelRight from "components/ResizablePanel/SplitPanelRight";

import { AdministrationContext } from "./AdministrationContext";
import ZoneSidebar from "./components/ZoneSidebar";

type THistory = ReturnType<typeof useHistory>;

function AdministrationScreen() {
  const dispatch = useDispatch();
  const history: THistory = useHistory<THistory>();

  const [currentPath, setCurrentPath] =
    useState<{ id: string; name: string }>();

  const [selectedZone, setSelectedZone] = useState<string>(
    LOCAL_STORAGE.getZone() || ""
  );
  const [zoneEntity, setZoneEntity] = useState<{ [key: string]: IZone }>({});

  const zoneReducer = useSelector((state: any) => {
    return state?.zoneReducer || {};
  });

  const authUser: any = useSelector((state: any) => {
    return state.authReducerV1?.user || {};
  });

  useEffect(() => {
    // update selected zone in localstore
    LOCAL_STORAGE.setZone(selectedZone);
  }, [selectedZone]);

  // Fetch all zones
  useEffect(() => {
    dispatch(getAllZones());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const authZones =
      authUser?.zones?.data?.map((item: IZone) => item.id) || [];
    if (zoneEntity && zoneEntity[selectedZone]) {
      const selectedZonePath = zoneEntity[selectedZone].path || "";
      if (!canAccessZone(authZones, selectedZonePath.split("."))) {
        // current selected is not authorized, so select the authorized zone
        setSelectedZone(authZones[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, zoneEntity]);

  useEffect(() => {
    const entity: { [key: string]: IZone } = {};
    if (zoneReducer.zones && Array.isArray(zoneReducer.zones)) {
      zoneReducer.zones.forEach((item: IZone) => {
        entity[item.id] = item;
      });
      setZoneEntity(entity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoneReducer.zones]);

  useEffect(() => {
    return history.listen((location) => {
      const path = history.location?.pathname?.split("/")?.reverse();
      if (
        path &&
        path.length &&
        (path[0] === "subjects" || path[0] === "devices" || path[0] === "users")
      ) {
        if (setCurrentPath) {
          setCurrentPath({ id: "", name: "" });
        }
      }
    });
  }, [history]);

  return (
    <AdministrationContext.Provider
      value={{
        zones: zoneReducer.zones || [],
        currentPath,
        setCurrentPath,
        selectedZone,
        setSelectedZone,
        zoneEntity
      }}
    >
      <SplitPanel flexDirection="row">
        <SplitPaneLeft>
          <SplitPanel flexDirection="column">
            {authUser && authUser.id && (
              <ZoneSidebar
                zoneList={zoneReducer.zones || []}
                authUser={authUser}
              />
            )}
          </SplitPanel>
        </SplitPaneLeft>
        <SplitPanelDivider cursor="ew-resize" />
        <SplitPanelRight>
          <AdminMainContainer />
        </SplitPanelRight>
      </SplitPanel>
    </AdministrationContext.Provider>
  );
}

export default AdministrationScreen;
