import React, { createRef, useContext, useEffect } from "react";

import { FlexOne } from "./SplitPanelStyledComponents";
import SplitPanelContext, { ContextInterface } from "./SplitPanelContext";

export const SplitPaneLeft = ({
  children
}: {
  children: React.ReactChild | React.ReactChild[];
}) => {
  const topRef = createRef<any>();
  const PanelContext = useContext<ContextInterface | null>(SplitPanelContext);

  useEffect(() => {
    if (!PanelContext) {
      return;
    }
    if (!PanelContext.clientWidth) {
      PanelContext.setClientWidth(topRef.current.clientWidth / 2);
      return;
    }
    topRef.current.style.minWidth = `${PanelContext.clientWidth}px`;
    topRef.current.style.maxWidth = `${PanelContext.clientWidth}px`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PanelContext?.clientWidth]);

  return (
    <FlexOne ref={topRef} style={{ minWidth: 256, overflow: "hidden auto" }}>
      {children}
    </FlexOne>
  );
};
