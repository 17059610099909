import {
  Checkbox,
  FormControlLabel,
  Tooltip,
  checkboxClasses
} from "@mui/material";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import React from "react";

import { IZone } from "interfaces/zone.interface";
import { flatten } from "utils/zone.util";
import Highlighted from "components/Highlighted/HighLighted";

const IndeterminateStyled = styled(IndeterminateCheckBoxOutlinedIcon)(
  ({ disabled }: { disabled: number }) => {
    return {
    // background:theme.palette.main[60]
    // color:'#FFF'
    };
  }
);

const NodeWrapper = styled("div")(
  ({
    children,
    level,
    hasChildren,
    levelPadding
  }: {
    children: React.ReactNode;
    level: number;
    hasChildren?: number;
    levelPadding: number;
  }) => {
    return {
      paddingLeft: hasChildren
      ? `${level * levelPadding}px`
      : `${level * levelPadding + 24}px`,
    position: "relative"
    };
  }
);

const ToggleButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "collapsed"
})(
  ({
    children,
    collapsed
  }: {
    children: React.ReactNode;
    collapsed: boolean;
  }) => {
    const theme = useTheme();
    return {
      color: theme.palette.main[70],
      width: 24,
      height: 24,
      borderRadius: 0,
      transform: collapsed ? "rotate(-90deg)" : "",
      "&:hover": {
        backgroundColor: "rgba(25, 118, 210, 0.04)"
      }
    };
  }
);

// cursor:cursordisabled ? "not-allowed" : "pointer"
const FormControlLabelStyled = styled(FormControlLabel)(
  ({ cursordisabled }: { cursordisabled?: number }) => {
    const theme = useTheme();
    return {
      color: theme.palette.neutral[80],
      fontSize: 16,
      padding: 6,
      margin: 0,
      alignItems: "flex-start",
      textTransform: "none",
      "& .MuiFormControlLabel-label": {
        fontSize: 16
      },
      "&.Mui-disabled": {
        cursor: "not-allowed"
      },
      [`& .${checkboxClasses.root}`]: {
        color: theme.palette.main[70],
        width: 12,
        height: 12,
        marginLeft: 1,
        marginRight: 10,
        p: 0,
        borderRadius: 0,
        marginTop: 4
      }
    };
  }
);

const CheckboxStyled = styled(Checkbox)(
  ({
    isindeterminate,
    itemselected
  }: {
    isindeterminate?: number;
    itemselected?: number;
  }) => {
    const theme = useTheme();
    return {
      "&.Mui-disabled": {
        cursor: "not-allowed",
        color: theme.palette.neutral[10],
        background:
          itemselected || isindeterminate ? "none" : theme.palette.neutral[10]
      }
    };
  }
);

function NodeWithCheckbox({
  item,
  hasChildren,
  level,
  onToggle,
  onChecked,
  levelPadding,
  collapsed,
  selected,
  search,
  isDisableNode,
  disabled_tooltip_message,
  no_permission_tooltip_message
}: {
  item: IZone;
  hasChildren: boolean;
  level: number;
  onToggle: () => void;
  onChecked: (
    e: React.SyntheticEvent<Element, Event>,
    checked: boolean,
    node: IZone
  ) => void;
  levelPadding: number;
  collapsed: boolean;
  selected: string[];
  search?: string;
  isDisableNode: (node: IZone) => boolean;
  disabled_tooltip_message: string;
  no_permission_tooltip_message: string;
}) {
  // const checkIndeterminate = () => {
  //   if (!selected.length || !item.children?.length) {
  //     return false;
  //   }
  //   if (selected.indexOf(item.id) !== -1) {
  //     // parent is already selected
  //     return false;
  //   }
  //   // so the parent node is not selected
  //   const flattenChildren = flatten(item).filter((node) => node.id !== item.id);
  //   if (flattenChildren) {
  //     for (let i = 0; i < flattenChildren.length; i += 1) {
  //       if (selected.indexOf(flattenChildren[i].id) !== -1) {
  //         // atleast one child node is selected
  //         return true;
  //       }
  //     }
  //   }
  //   return false;
  // };

  const indeterminate = React.useMemo(() => {
    if (!selected.length || !item.children?.length) {
      return false;
    }
    if (selected.indexOf(item.id) !== -1) {
      // parent is already selected
      return false;
    }
    // so the parent node is not selected
    const flattenChildren = flatten(item).filter((node) => node.id !== item.id);
    if (flattenChildren) {
      for (let i = 0; i < flattenChildren.length; i += 1) {
        if (selected.indexOf(flattenChildren[i].id) !== -1) {
          // atleast one child node is selected
          return true;
        }
      }
    }
    return false;
  }, [selected, item]);

  // const indeterminate = checkIndeterminate();
  const isDisabled = isDisableNode(item);
  const isSelected = selected.indexOf(item.id) !== -1 ? 1 : 0;
  const ifParentSelected = !!isSelected || !!indeterminate;

  // get tooltip message
  const getTooltipMessage = React.useMemo(() => {
    if (!item.parent_id) {
      return "";
    }
    if (ifParentSelected) {
      return disabled_tooltip_message;
    }
    return no_permission_tooltip_message;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ifParentSelected]);

  return (
    <NodeWrapper
      level={level}
      hasChildren={hasChildren ? 1 : 0}
      levelPadding={levelPadding}
    >
      {hasChildren && (
        <ToggleButton onClick={onToggle} collapsed={collapsed}>
          <KeyboardArrowDownOutlinedIcon />
        </ToggleButton>
      )}
      {isDisabled ? (
        <Tooltip placement="right" title={getTooltipMessage}>
          <FormControlLabelStyled
            disabled={isDisabled || false}
            checked={selected.indexOf(item.id) !== -1}
            cursordisabled={isDisabled ? 1 : 0}
            onChange={(
              e: React.SyntheticEvent<Element, Event>,
              checked: boolean
            ) => {
              return onChecked(e, checked, item);
            }}
            control={
              <CheckboxStyled
                isindeterminate={indeterminate ? 1 : 0}
                size="medium"
                indeterminate={indeterminate}
                itemselected={isSelected}
              />
            }
            label={item.name}
          />
        </Tooltip>
      ) : (
        ""
      )}

      {!isDisabled && (
        <FormControlLabelStyled
          disabled={isDisabled || false}
          checked={selected.indexOf(item.id) !== -1}
          onChange={(
            e: React.SyntheticEvent<Element, Event>,
            checked: boolean
          ) => {
            return onChecked(e, checked, item);
          }}
          control={
            <CheckboxStyled
              size="medium"
              indeterminate={indeterminate}
              indeterminateIcon={<IndeterminateStyled disabled={0} />}
            />
          }
          label={<Highlighted text={item.name} highlight={search} />}
        />
      )}
    </NodeWrapper>
  );
}
export default NodeWithCheckbox;
