import { useContext } from "react";

import {
  DashboardContext,
  DashboardContextType
} from "@modules/monitor/DashboardContext";

export default function UseDashboardContext() {
  const {
    selectedZones,
    zoneEntity,
    setSelectedZones,
    handlePrint,
    componentRef
  } = useContext<DashboardContextType | null>(DashboardContext || null) || {
    tileScale: 50
  };

  return {
    selectedZones,
    zoneEntity,
    setSelectedZones,
    handlePrint,
    componentRef
  };
}
