import { createContext } from "react";

import { IZone } from "interfaces/zone.interface";

export type AdministrationContextType = {
  currentPath: { id: string; name: string } | undefined;
  setCurrentPath: React.Dispatch<
    React.SetStateAction<{ id: string; name: string } | undefined>
  >;
  zones: IZone[];
  selectedZone: string;
  setSelectedZone: React.Dispatch<React.SetStateAction<string>>;
  zoneEntity: { [key: string]: IZone };
};

export const AdministrationContext =
  createContext<AdministrationContextType | null>(
    {} as AdministrationContextType
  );
