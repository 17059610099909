import { Box, Theme } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import React, { memo, useContext } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";

import * as LOCAL_STORAGE from "utils/localStorage";
import {
  AuthorizationContext,
  SubjectPermissionEnum
} from "authorization/AuthorizationContext";
import {
  HeadCell,
  Order
} from "interfaces/componentsTypes/tableComponentsTypes";
import { ISubject } from "interfaces/subject.interface";
import { IZone } from "interfaces/zone.interface";
import { TableBodyStyled } from "styled/TableElementStyled";
import { can } from "authorization/authorization.util";
import { useSortableData } from "hooks/UseSortableData";
import SMTableHeader from "components/SMTableComponents/SMTableHeader";
import UseAdminContext from "hooks/UseAdminContext";

import { MemoizedDeviceStatus, MemoizedSex } from "./SubjectTableRowComponets";

type IProps = {
  headCells: HeadCell[];
  subjects: ISubject[];
  zoneEntity: Record<string, IZone>;
};

const SubjectList = memo(function SubjectList({
  headCells,
  subjects,
  zoneEntity
}: IProps) {
  var settings = LOCAL_STORAGE.getAdminSettings() || {};
  const appTheme: Theme = useTheme();
  const { permissions } = useContext(AuthorizationContext);
  const history = useHistory();
  const { setSelectedZone } = UseAdminContext();
  
  // Set default sort order in localStorage Admin Settings 
  if (!settings.subject_sort_config) {
    LOCAL_STORAGE.setAdminSettings({
      ...settings,
      "subject_sort_config": {
        key: "idenfier",
        direction: "asc"
      }
    });
    settings = LOCAL_STORAGE.getAdminSettings();
  }
  
  const { items, requestSort, sortConfig } = useSortableData(
    subjects,
    settings.subject_sort_config,
    zoneEntity
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    // set local storage
    const currentSettings = LOCAL_STORAGE.getAdminSettings();
    LOCAL_STORAGE.setAdminSettings({
      ...currentSettings,
      "subject_sort_config": {
        key: property,
        direction: sortConfig?.direction === "asc" ? "desc" : "asc"
      }
    });

    requestSort(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: ISubject) => {
    if (can(permissions, SubjectPermissionEnum["subject.read"])) {
      if (setSelectedZone) {
        setSelectedZone(row.zone_id);
      }
      history.push(`/adminstration/zones/subjects/${row.id}`);
    }
  };

  return (
    <div style={{ padding: 1 }}>
      <Box sx={{ width: "100%" }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }}>
            <SMTableHeader
              order={sortConfig?.direction || ("asc" as Order)}
              orderBy={(sortConfig?.key as string) || ""}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBodyStyled>
              {items.map((subject: ISubject) => {
                return (
                  <tr
                    key={subject.id}
                    onClick={(event) => handleClick(event, subject)}
                  >
                    <td>{subject.identifier}</td>
                    <td>{zoneEntity[subject.zone_id]?.name || ""}</td>
                    <td align="center">
                      <MemoizedSex gender={subject.sex} />
                    </td>
                    <td align="center">
                      <div className="flex_center">
                        {subject.birth_year}
                        <Box sx={{ visibility: "hidden" }} width="24px" />
                      </div>
                    </td>
                    <td>{subject?.devices?.data[0]?.name || "-"}</td>
                    <td>
                      <Box
                        sx={{
                          color: appTheme.palette.neutral[80],
                          fontSize: 16,
                          fontFamily: "Roboto Mono, monospace"
                        }}
                      >
                        {subject?.devices?.data[0]?.serial_number || "-"}
                      </Box>
                    </td>
                    <td align="center">
                      <MemoizedDeviceStatus
                        deviceStatus={
                          subject?.devices?.data &&
                          subject?.devices?.data?.length > 0
                            ? subject?.devices?.data[0]?.online
                            : undefined
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </TableBodyStyled>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
});
export default SubjectList;
