import { IColor } from "interfaces/color.interface";

const Purple: Omit<
  IColor,
  0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
> = {
  1: "#F6F5F9",
  5: "#E2E0EF",
  10: "#CCC7E5",
  20: "#A49BD2",
  30: "#8073BF",
  40: "#6051AC",
  50: "#453499"
};

export default Purple;
