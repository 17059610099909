import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { IMonitor } from "interfaces/monitor.interface";
import { IZone } from "interfaces/zone.interface";
import { fetchLiveMonitoring } from "store/actions/dashboard/monitorActions";
import NoDeviceInZone from "components/NoDeviceInZone/NoDeviceInZone";
import SMGoogleAnalytic from "components/GoogleAnalytic/GoogleAnalytic";
import UseDashboardContext from "hooks/UseDashboardContext";
import UseInterval from "hooks/UseInterval";

import MonitorZoneTiles from "./components/MonitorZoneTiles";
import SleepHistoryModal from "../SleepHistoryModel";
import SupportSettingsPopover from "./components/SupportSettingsPopover";

export interface LiveDataTableProps {
  zone: string;
  data: IMonitor[];
  path: string[];
}

function SubjectOverview() {
  const { selectedZones, zoneEntity } = UseDashboardContext();
  const dispatch = useDispatch();
  const [liveMonitorData, setLiveMonitorData] = React.useState<
    LiveDataTableProps[]
  >([]);
  const [showHiddenSettings, setShowHiddenSettings] =
    React.useState<boolean>(false);
  const [dashboardModalSubject, setDashboardModalSubject] = useState<{
    id: string;
    identifier: string;
    birth_year: number;
    sex: string;
  }>({ id: "", identifier: "", sex: "", birth_year: 0 });

  const liveMonitorReducer = useSelector((state: any) => {
    return state?.liveMonitorReducer || {};
  });

  const fetchLiveData = () => {
    if (selectedZones && selectedZones.length) {
      dispatch(
        fetchLiveMonitoring(selectedZones.map((item: IZone) => item.id))
      );
    }
  };

  let numberofTimeSClicked = 0;
  // onDocumentKeydown
  const onDocumentKeydown = (event: KeyboardEvent) => {
    if (event.code === "KeyS") {
      numberofTimeSClicked += 1;
      if (numberofTimeSClicked === 4) {
        setShowHiddenSettings(true);
        numberofTimeSClicked = 0;
      }
    } else if (numberofTimeSClicked) {
      numberofTimeSClicked = 0;
    }
  };

  // call live data
  UseInterval(fetchLiveData, 30000);

  // keypress
  useEffect(() => {
    document.addEventListener("keypress", onDocumentKeydown);

    return () => {
      document.removeEventListener("keypress", onDocumentKeydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedZones && selectedZones.length) {
      fetchLiveData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZones]);

  useEffect(() => {
    if (liveMonitorReducer.liveMonitor) {
      // Group by "zone['id']"
      const result = _.groupBy(liveMonitorReducer.liveMonitor, "zone['id']");
      const liveData: LiveDataTableProps[] = [];
      selectedZones?.forEach((zone: IZone) => {
        if (result[zone.id] && zone.path) {
          liveData.push({
            zone: zone.id,
            path: zone?.path?.split("."),
            data: result[zone.id]
          });
        } else {
          liveData.push({
            zone: zone.id,
            path: zone?.path?.split(".") ?? [],
            data: []
          });
        }
      });
      setLiveMonitorData(liveData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveMonitorReducer.liveMonitor]);

  if (!selectedZones) {
    return <div />;
  }

  const openDashBoardPopup = (item: IMonitor) => {
    if (item?.subject?.id) {
      setDashboardModalSubject({
        id: item.subject.id,
        identifier: item.subject.identifier,
        sex: item.subject.sex,
        birth_year: item.subject.birth_year
      });
    } else {
      setDashboardModalSubject({
        id: "",
        identifier: "",
        sex: "",
        birth_year: 0
      });
    }
  };

  return (
    <div>
      {/* Add google analytic */}
      <SMGoogleAnalytic title="Subject Overview Screen" />

      <Grid container>
        {zoneEntity &&
          liveMonitorData?.map((liveData: LiveDataTableProps) => {
            if (liveData.data.length) {
              return (
                <MonitorZoneTiles
                  liveData={liveData}
                  zoneEntity={zoneEntity}
                  onTileClick={openDashBoardPopup}
                  key={liveData.zone}
                />
              );
            }
            return (
              <NoDeviceInZone
                zoneEntity={zoneEntity}
                paths={liveData.path}
                bg="white"
                key={liveData.zone}
              />
            );
          })}
      </Grid>
      <SupportSettingsPopover
        showHiddenSettings={showHiddenSettings}
        setShowHiddenSettings={setShowHiddenSettings}
      />
      <SleepHistoryModal
        setDashboardModalSubject={setDashboardModalSubject}
        dashboardModalSubject={dashboardModalSubject}
      />
    </div>
  );
}
export default SubjectOverview;