import { TextField } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { useTheme} from "@mui/material/styles";

import { ErrorMessage } from "styled/StylesStyled";
import debounce from "utils/common/debounce";

export const TextFieldStyled = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "borderRed"
})(
  ({
    children,
    borderRed
  }: {
    children?: React.ReactNode;
    borderRed: number;
  }) => {
    const theme = useTheme(); 
    return {
      border: "none",
      marginLeft: 0,
      maxWidth: 160,
      "& .Mui-disabled": {
        background: theme.palette.neutral[5],
        color: theme.palette.neutral[80],
        cursor: "not-allowed",
        WebkitTextFillColor: theme.palette.neutral[80]
      },
      '& input[type="time"]::-webkit-calendar-picker-indicator': {
        color: theme.palette.main[70]
      },
      "& input": {
        padding: "10px 8px",
        border: borderRed
          ? `1px solid ${theme.palette.red[50]}`
          : `1px solid ${theme.palette.neutral[5]}`,
        minWidth: 32,
        color: theme.palette.neutral[70],
        borderRadius: 2
      },
      "& button": {
        padding: 0,
        "& svg": {
          color: theme.palette.main[70]
        }
      },
      "& fieldset": {
        border: "none"
      }
    };
  }
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IProps<T> {
  field: any; // Todo
  formState?: any; // Todo
  onUpdate?: () => void;
  disabled?: boolean;
}

function SMFormTimePicker<T>({
  field,
  formState,
  onUpdate,
  disabled
}: IProps<T>) {
  const [value, setValue] = useState<string>(field.value);

  const debouncedValueUpdate = debounce(() => {
    if (onUpdate) {
      onUpdate();
    }
  }, 1000);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    setValue(event.target.value);
    if (onUpdate) {
      debouncedValueUpdate();
    }
  };

  return (
    <>
      <TextFieldStyled
        {...field}
        value={value}
        disabled={!!disabled}
        inputProps={{ type: "time" }}
        onChange={onChange}
        // onKeyDown={(e: any) => e.preventDefault()}
        borderRed={formState?.error?.message ? 1 : 0}
      />
      {formState?.error?.message ? (
        <ErrorMessage>{formState?.error?.message}</ErrorMessage>
      ) : (
        ""
      )}
    </>
  );
}

export default SMFormTimePicker;
