import React, { createContext } from "react";

export interface ContextInterface {
  clientHeight: number | null;
  clientWidth: number | null;
  setClientHeight: React.Dispatch<React.SetStateAction<number | null>>;
  setClientWidth: React.Dispatch<React.SetStateAction<number | null>>;
  onMouseHoldDown: (e: MouseEvent) => void;
}
export const SplitPaneContext = createContext<ContextInterface | null>(null);
export default SplitPaneContext;
