import { IDevice, ISettings } from "interfaces/device.interface";
import { deviceServices } from "services/administration/deviceService";

import * as types from "../../actionTypes/administration/deviceActionTypes";

export const restDeviceCurrentAction = (): types.resetDeviceActionType => {
  return {
    type: types.RESET_DEVICE_ACTIONS
  };
};

export const resetDeviceStateAction = (): types.resetDeviceStateActionType => {
  return {
    type: types.RESET_DEVICE_STATE
  };
};

/* ************************************************** */
/*                      Actions                       */
/* ************************************************** */

/**
 * Redux action to fetch all zones
 *
 * @return type - redux action type |
 */
export const getAllDeviceAction = (): types.fetchDeviceActionType => {
  return {
    type: types.FETCH_DEVICE
  };
};

/**
 * Redux action to fetch all devices
 *
 * @return type - redux action type |
 *         payload - devices
 */
export const getAllDeviceActionSuccess = (
  data: IDevice[]
): types.fetchDeviceSuccessActionType => {
  return {
    type: types.FETCH_DEVICE_SUCCESS,
    payload: data
  };
};

export const getDeviceByIDAction = (): types.fetchDeviceByIDActionType => {
  return {
    type: types.FETCH_DEVICE_BY_ID
  };
};

export const getDeviceByIDActionSuccess = (
  data: IDevice
): types.fetchDeviceByIDSuccessActionType => {
  return {
    type: types.FETCH_DEVICE_BY_ID_SUCCESS,
    payload: data
  };
};

/**
 * Create  device ation
 *
 * @return type - redux action type |
 *
 */
export const createDeviceAction = (): types.createDeviceActionType => {
  return {
    type: types.CREATE_DEVICE
  };
};

/**
 * Create  device success action
 *
 * @return type - redux action type |
 *         payload - param
 */
export const createDeviceSuccesAction = (
  data: IDevice
): types.createDeviceSuccessActionType => {
  return {
    type: types.CREATE_DEVICE_SUCCESS,
    payload: data
  };
};

/**
 * update device actionType
 * @return type - redux action type
 *
 */
export const updateDeviceAction = (): types.updateDeviceActionType => {
  return {
    type: types.UPDATE_DEVICE
  };
};

/**
 * update device successAction
 * @return type - redux action type
 *         response - Created device
 */
export const updateDeviceSuccessAction = (
  data: IDevice
): types.updateDeviceSuccessActionType => {
  return {
    type: types.UPDATE_DEVICE_SUCCESS,
    payload: data
  };
};

/* ************************************************** */
/*                      Services                      */
/* ************************************************** */

export function resetDeviceState() {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(resetDeviceStateAction());
  };
}
/**
 *  Function to fetch all the devices
 *
 * @param none
 * @return all the devices in the system
 */
export function getAllDevicesNew(path?: string) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    // dispatch(getAllDeviceAction);
    deviceServices
      .fetchDevices(path)
      .then((data: { object: "list"; data: IDevice[] }) => {
        if (data?.data) {
          data?.data?.sort((a: IDevice, b: IDevice) => {
            return Intl.Collator("nb", { sensitivity: "base" }).compare(
              a.name || "",
              b.name || ""
            );
          });

          dispatch(getAllDeviceActionSuccess(data.data));
        }
      });
  };
}

export function getDeviceByID(id?: string) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(getDeviceByIDAction());
    deviceServices
      .fetchDevice(id)
      .then((data: { object: "list"; data: IDevice }) => {
        if (data?.data) {
          dispatch(getDeviceByIDActionSuccess(data.data));
        }
      });
  };
}

/**
 *  Function to create device
 *
 * @param name - data of type IDevice
 * @return created device
 */
export function createDevice(data: IDevice) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(createDeviceAction());
    const param: any = {
      ...data,
      birth_year: null,
      height: null,
      weight: null
    };
    deviceServices.createDevice(param).then((response: { data: IDevice }) => {
      dispatch(createDeviceSuccesAction(response.data));
    });
  };
}

/**
 *  Function to patch device
 *
 * @param name - data of type IDevice
 * @return updated device
 */
export function patchDevice(
  id: string,
  data: IDevice | ISettings | { [key: string]: any },
  errorCallback?: () => void,
  successCallback?: () => void
) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(updateDeviceAction());
    deviceServices
      .updateDevice(id, data)
      .then((response: { data: IDevice }) => {
        if (response?.data) {
          dispatch(updateDeviceSuccessAction(response.data));
          if (successCallback) {
            successCallback();
          }
        } else if (errorCallback) {
          errorCallback();
        }
      });
  };
}
