import { IRule, IRulePreset } from "interfaces/rules.interface";

export const RESET_RULES_STATE = "RESET_RULES_STATE";
export const FETCH_RULES = "FETCH_RULES";
export const FETCH_RULES_SUCCESS = "FETCH_RULES_SUCCESS";

export const FETCH_RULES_BY_ID = "FETCH_RULES_BY_ID";
export const FETCH_RULES_BY_ID_SUCCESS = "FETCH_RULES_BY_ID_SUCCESS";

export const CREATE_RULES = "CREATE_RULES";
export const CREATE_RULES_SUCCESS = "CREATE_RULES_SUCCESS";

export const UPDATE_RULES = "UPDATE_RULES";
export const UPDATE_RULES_HANDLER_SUCCESS = "UPDATE_RULES_SUCCESS";

export const DELETE_RULES = "DELETE_RULES";
export const DELETE_RULES_SUCCESS = "DELETE_RULES_SUCCESS";
export const FETCH_RULES_PRESET_SUCCESS = "FETCH_RULES_PRESET_SUCCESS";

export interface resetRulesStateActionType {
  type: typeof RESET_RULES_STATE;
}

export interface fetchRulesActionType {
  type: typeof FETCH_RULES;
}
export interface fetchRulesSuccessActionType {
  type: typeof FETCH_RULES_SUCCESS;
  payload: IRule[];
}

export interface createRulesActionType {
  type: typeof CREATE_RULES;
}
export interface createRulesSuccessActionType {
  type: typeof CREATE_RULES_SUCCESS;
  payload: IRule;
}

export interface updateRulesActionType {
  type: typeof UPDATE_RULES;
}
export interface updateRulesSuccessActionType {
  type: typeof UPDATE_RULES_HANDLER_SUCCESS;
  payload: IRule;
}

export interface fetchRulesByIDActionType {
  type: typeof FETCH_RULES_BY_ID;
}

export interface fetchRulesByIDSuccessActionType {
  type: typeof FETCH_RULES_BY_ID_SUCCESS;
  payload: IRule;
}

export interface deleteRulesHandlerActionType {
  type: typeof DELETE_RULES;
}

export interface deleteRulesHandlerSuccessActionType {
  type: typeof DELETE_RULES_SUCCESS;
  payload: string;
}

export interface fetchRulesPresetSuccessActionType {
  type: typeof FETCH_RULES_PRESET_SUCCESS;
  payload: { type: string; data: IRulePreset[] };
}

export type rulesActionTypes =
  | fetchRulesActionType
  | fetchRulesSuccessActionType
  | createRulesActionType
  | createRulesSuccessActionType
  | updateRulesActionType
  | updateRulesSuccessActionType
  | resetRulesStateActionType
  | fetchRulesByIDActionType
  | fetchRulesByIDSuccessActionType
  | deleteRulesHandlerActionType
  | deleteRulesHandlerSuccessActionType
  | fetchRulesPresetSuccessActionType;
