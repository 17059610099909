import * as React from "react";
import { TableBody } from "@mui/material";
import { styled } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import { useTheme } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";

export const TableBodyStyled = styled(TableBody)(
  ({
    disable_hover
  }: {
    disable_hover?: number;
  }) => {
    const theme = useTheme();
    return {
      "& tr": {
        padding: "16px 20px",
        fontSize: 14,
        cursor: disable_hover ? "default" : "pointer",
        textAlign: "left",
        background: theme.palette.white,
        borderBottom: `1px solid ${theme.palette.neutral[5]}`,
        color: theme.palette.neutral[40]
      },
      "& tr:hover": {
        background: disable_hover ? "none" : theme.palette.main[2]
      },
      "& td": {
        color: theme.palette.neutral[40],
        fontSize: 16,
        padding: "16px"
      },
      [`& .${tableCellClasses.root}`]: {
        border: "none",
        verticalAlign: "top"
      },
      "& tr:last-child": {
        borderBottom: "none"
      },

      "& td:first-of-type": {
        color: theme.palette.neutral[70],
        fontSize: 16
      }
    };
  }
);

export const TableRowStyled = styled(TableRow)(
  ({
    children,
    disable_row_hover,
    dynamic_padding
  }: {
    children: React.ReactNode;
    disable_row_hover: number;
    dynamic_padding?: number;
  }) => {
    const theme = useTheme();
    return {
      padding: "16px 20px",
      fontSize: 14,
      cursor: disable_row_hover ? "default" : "pointer",
      textAlign: "left",
      background: theme.palette.white,
      borderBottom: `1px solid ${theme.palette.neutral[5]}`,
      color: theme.palette.neutral[40],
      [`& .${tableCellClasses.root}`]: {
        border: "none",
        verticalAlign: "top"
      },
      "&:last-child": {
        borderBottom: "none"
      },
      "& td": {
        color: theme.palette.neutral[0],
        fontSize: 16,
        [theme.breakpoints.down("lg")]: {
          padding: dynamic_padding ? "16px 5px" : "16px"
        }
      },
      "& td:first-of-type": {
        color: theme.palette.neutral[70],
        fontSize: 16
      },
      "&:hover": {
        background: disable_row_hover ? "none" : theme.palette.main[2]
      }
    };
  }
);

export const TableHeadRowStyled = styled(TableRowStyled)(
  ({
    children,
    disable_row_hover,
    dynamic_padding
  }: {
    children: React.ReactNode;
    disable_row_hover: number;
    dynamic_padding?: number;
  }) => {
    const theme = useTheme();
    return {
      textTransform: "uppercase",
      textAlign: "left",
      verticalAlign: "top",
      background: theme.palette.neutral[2],
      fontWeight: "normal",
      borderBottom: `1px solid ${theme.palette.neutral[5]}`,
      cursor: "default",
      // boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
      "& th": {
        fontWeight: "normal",
        fontSize: 14,
        color: theme.palette.neutral[50],
        [theme.breakpoints.down("lg")]: {
          padding: dynamic_padding ? "16px 5px" : "16px"
        }
      },

      [`& .${tableCellClasses.root}`]: {
        border: "none"
      },
      "&:last-child": {
        borderBottom: `1px solid ${theme.palette.neutral[5]}`
      },
      "&:hover": {
        background: theme.palette.neutral[2]
      }
    };
  }
);

export const TableHeadDataStyled = styled("div")(
  ({
    active,
    direction,
    cursordefault,
    txtcenter
  }: {
    active?: number;
    direction?: string;
    cursordefault?: number;
    txtcenter?: number;
  }) => {
    return {
      cursor: cursordefault ? "default" : "pointer",
      // direction
      "& > div": {
        display: "flex",
        verticalAlign: "middle",
        // alignItems: "center",
        columnGap: 5,
        justifyContent: txtcenter ? "center" : "none",
        textAlign: txtcenter ? "center" : "left"
      },
      "& > div:nth-of-type(2)": {
        marginLeft: 10
      }
    };
  }
);
