import "./i18n/config";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { Route, Switch } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import axios from "axios";

import { IColor } from "interfaces/color.interface";
import { IWIPMessage } from "interfaces/wipMessage.interface";
import { WIPMessageUrl } from "constants/constants";
import { appTheme } from "theme/default/somnofy";
import { jwtInterceptor } from "interceptors/axios.interceptor";
import GlobalCssOverride from "GlobalStyles";
import SMGoogleAnalytic from "components/GoogleAnalytic/GoogleAnalytic";

import { GlobalContext } from "./global/GlobalContext";
import AdminLayoutNew from "./layouts/AdminLayout";
import AuthLayoutNew from "./layouts/AuthNew";
import HomeLayout from "./layouts/Home";
import PrivateRouteV1 from "./services/utils/PrivateRouteV1";

import "@fontsource/roboto";
import "@fontsource/roboto-mono";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css"; // main style file

// for to stop typescript complaining about document's full screen methods
declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    webkitCancelFullScreen?: Element;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullscreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
    webkitRequestFullScreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitEnterFullscreen?: () => Promise<void>;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    white: string;
    neutral: IColor;
    main: Omit<IColor, 110 | 0>;
    blue: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    green: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    orange: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    purple: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    red: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    teal: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    yellow: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    lightblue: string;
    chart: {
      legend: {
        selected: {
          light: string;
          dark: string;
        };
        unselected: {
          light: string;
          dark: string;
        };
        hover: {
          light: string;
          dark: string;
        };
      };
    };
  }
  interface Theme {}
  interface PaletteOptions {
    white: string;
    neutral: IColor;
    main: Omit<IColor, 110 | 0>;
    blue: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    green: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    orange: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    purple: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    red: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    teal: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    yellow: Omit<
      IColor,
      0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
    >;
    lightBlue: string;
    chart: {
      legend: {
        selected: {
          light: string;
          dark: string;
        };
        unselected: {
          light: string;
          dark: string;
        };
        hover: {
          light: string;
          dark: string;
        };
      };
    };
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {}
}

// Sentry integration
if (process.env.REACT_APP_PRODUCTION === "true") {
  Sentry.init({
    dsn: "https://1e6cf856b7504037a3ace15b2d2e811d@o197106.ingest.sentry.io/4504558544945152",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

// APP
function App() {
  jwtInterceptor();

  const [appWarningMessage, setAppWarningMessage] =
    React.useState<IWIPMessage | null>(null);
  const filePath =
    process.env.REACT_APP_PRODUCTION === "true" ? "prod.json" : "dev.json";
  useEffect(() => {
    axios({
      method: "get",
      url: `${WIPMessageUrl}/${filePath}`,
      timeout: 30000,
      transformRequest: (data, headers) => {
        // eslint-disable-next-line no-param-reassign
        delete headers.common.Authorization;
        return data;
      }
    })
      .then((response) => {
        if (response?.data) {
          setAppWarningMessage(response?.data);
        }
      })
      .catch((error) => {
        console.log("Eror while loading warning message", error);
      });

    if (process.env.REACT_APP_PRODUCTION !== "true") {
      // initialize google analytics
      ReactGA.initialize("G-ZYCK2SGDL7");
    }

    // add analytic
    <SMGoogleAnalytic title="App" />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <GlobalCssOverride />
      <ThemeProvider theme={appTheme}>
        <CssBaseline>
          {/* autoClose={4000} */}
          <ToastContainer
            hideProgressBar
            position="bottom-right"
            autoClose={7000}
            toastClassName="toast-custom-styles"
            style={{
              width: "auto",
              right: 64,
              bottom: 24
            }}
          />
          <GlobalContext.Provider
            value={{
              appWarningMessage
            }}
          >
            <Switch>
              <Route path="/auth" component={AuthLayoutNew} />
              <Route path="/home" component={HomeLayout} />
              <PrivateRouteV1 path="/" component={AdminLayoutNew} />
            </Switch>
          </GlobalContext.Provider>
        </CssBaseline>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
