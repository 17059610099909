import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { styled } from "@mui/system";

import Box from "@mui/system/Box";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import React, { RefObject, forwardRef } from "react";

const StyledMenuItem = styled(MenuItem)(
  ({ children }: { children?: any }) => {
    return {
      padding: "6px 8px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      fontSize: "16px",
      "&:hover": {
        background: "none"
      }
    };
  }
);

const FlexBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: "100%"
});

type IconMenuItemProps = {
  MenuItemPropsRest?: MenuItemProps;
  className?: string;
  disabled?: boolean;
  label?: string;
  leftIcon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  ref?: RefObject<HTMLLIElement>;
  sx?: SxProps;
};

export const IconMenuItem = forwardRef<HTMLLIElement, IconMenuItemProps>(
  function IconMenuItem(
    {
      MenuItemPropsRest,
      className,
      label,
      leftIcon,
      ...props
    }: IconMenuItemProps,
    ref
  ) {
    const { sx } = props;
    return (
      <StyledMenuItem
        {...MenuItemPropsRest}
        sx={sx}
        ref={ref}
        className={className}
        {...props}
        disableRipple
      >
        <FlexBox>
          {leftIcon}
          <Box
            sx={{
              lineHeight: "20px",
              letterSpacing: "0.25px",
              minWidth: "186px",
              fontSize: "14px"
            }}
          >
            {" "}
            {label}
          </Box>
        </FlexBox>
      </StyledMenuItem>
    );
  }
);
