import { Box, Theme } from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";

import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import React, { useState } from "react";

import { IFilterOption } from "interfaces/filters.interface";

import { IconMenuItem } from "../IconMenuItem";

const OptionsTitle = styled(Box)(
  ({ children }: { children?: React.ReactNode }) => {
    const theme = useTheme();
    return {
      color: theme.palette.neutral[80],
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "20px",
      letterspacing: "0.1px",
      padding: "8px 36px",
      width: "100%",
      alignItems: "center",
      display: "flex",
      borderBottom: `1px solid ${theme.palette.neutral[5]}`
    };
  }
);

function MenuOptionsComponent({
  handleClose,
  options,
  title,
  customComponent,
  onSelect,
  selected
}: {
  handleClose: (e: React.MouseEventHandler<HTMLLIElement> | undefined) => void;
  options: IFilterOption[];
  title?: string;
  customComponent: React.ReactNode;
  onSelect: (option: string) => void;
  selected: string;
}) {
  const [open, setOpen] = useState(isCustomChecked("custom"));
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  function isCustomChecked(key: string): boolean {
    if (key === "custom" && selected !== "") {
      const selectedOption = options.find(
        (item: IFilterOption) => item.value === selected
      );
      if (!selectedOption) {
        return true;
      }
      return false;
    }
    return false;
  }

  function getCheckedIcon(key: string) {
    if (isCustomChecked(key)) {
      return <CheckIcon htmlColor={theme.palette.main[60]} />;
    }
    return selected === key ? (
      <CheckIcon htmlColor={theme.palette.main[60]} />
    ) : (
      <Box sx={{ width: "24px", height: "24px" }} />
    );
  }

  return (
    <>
      {title && (
        <OptionsTitle aria-hidden onClick={(e) => e.stopPropagation()}>
          {title}
        </OptionsTitle>
      )}
      {options.map((item: IFilterOption) => (
        <IconMenuItem
          label={item.key}
          key={item.value}
          leftIcon={getCheckedIcon(item.value)}
          onClick={(e) => {
            onSelect(item.value);
          }}
        />
      ))}

      <IconMenuItem
        label={t("custom")}
        key="custom"
        leftIcon={getCheckedIcon("custom")}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          setOpen(!open);
        }}
      />

      {open && customComponent && (
        <Box
          sx={{
            borderTop: `1px solid ${theme.palette.neutral[5]}`,
            padding: "16px"
          }}
        >
          <Box>{customComponent || null}</Box>
        </Box>
      )}
    </>
  );
}
export default MenuOptionsComponent;
