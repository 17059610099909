import {
  Box,
  Card,
  CardActions,
  CardHeader,
  Collapse,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useContext, useEffect, useState } from "react";

import { ISession } from "interfaces/sleephistory.interface";
import { getLocale } from "utils/date.util";

import {
  SleepHistoryContext,
  SleepHistoryContextType
} from "../SleepHistoryContext";
import EnvironmentDetails from "./EnvironmentDetails";
import SessionDetails from "./SessionDetails";
import SleepEpochData from "./SleepEpochData";

function SessionDetailedViewMemo({
  session,
  onNextPrev
}: {
  session: ISession;
  onNextPrev: (type: "next" | "prev") => void;
}) {
  const { t } = useTranslation();
  const [showHypnogram, setShowHypnogram] = useState<boolean>(false);
  const { viewMode } = useContext<SleepHistoryContextType>(SleepHistoryContext);

  const onNext = () => {
    setShowHypnogram(false);
    onNextPrev("next");
  };
  const onPrev = () => {
    setShowHypnogram(false);
    onNextPrev("prev");
  };

  useEffect(() => {
    if (showHypnogram) {
      setShowHypnogram(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const getCurrentDay = () => {
    if (!session) {
      return "";
    }
    return new Intl.DateTimeFormat(getLocale(), {
      dateStyle: "full"
    }).format(new Date(session.session_start));
  };

  return (
    <>
      {/* subheader={new Intl.DateTimeFormat(getLocale(), {
              dateStyle: "full"
            }).format(new Date(session.session_start))} */}
      <Stack direction="column" spacing={0} sx={{ marginBottom: 2 }}>
        <CardHeader
          title={t("Session Details")}
          sx={{ paddingBottom: 0, paddingTop: 0 }}
        />
        <Stack direction="row">
          {viewMode !== "last_night" && (
            <IconButton onClick={onPrev}>
              <ArrowBackIosNewIcon />
            </IconButton>
          )}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ paddingLeft: viewMode === "last_night" ? 3 : 0 }}
          >
            {getCurrentDay()}
          </Box>
          {viewMode !== "last_night" && (
            <IconButton onClick={onNext}>
              <ArrowForwardIosIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>

      {/** *********************************************************************
       *  SLEEP SESSION DETAILS
            - Sleep stages
            - Sleep statitics
            - Sleep score
       *   *********************************************************************
      */}
      <SessionDetails session={session} />
      {/*
          SLEEP SESSION DETAILS END
      */}

      <Card sx={{ height: "auto", marginBottom: 3, marginTop: 2 }}>
        <EnvironmentDetails session={session} />
      </Card>
      <Card sx={{ height: "auto", marginBottom: 3 }}>
        <CardActions sx={{ justifyContent: "space-between" }}>
          <CardHeader title={`${t("Hypnogram")}`} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>{`${showHypnogram ? t("Hide") : t("Show")} ${t(
              "Hypnogram"
            )}`}</Typography>
            <IconButton
              onClick={() => setShowHypnogram(!showHypnogram)}
              aria-expanded={showHypnogram}
              aria-label="showHypnogram"
            >
              {showHypnogram ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        </CardActions>
        <Collapse in={showHypnogram} mountOnEnter unmountOnExit>
          <SleepEpochData sessionId={session.id} />
        </Collapse>
      </Card>
    </>
  );
}
// are equal
const SessionDetailedView = React.memo(SessionDetailedViewMemo);
export default SessionDetailedView;
