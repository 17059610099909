import { styled } from "@mui/system";
import React from "react";
import Switch, { switchClasses } from "@mui/material/Switch";
import { useTheme } from "@mui/material/styles";

export const SwitchStyled = styled(Switch)(
  ({ children }: { children?: React.ReactNode }) => {
    const theme = useTheme(); 
    return {
      [`&.${switchClasses.root}`]: {
        width: 48,
        height: 24,
        padding: 0,
        borderRadius: "24px"
      },
      [`& .${switchClasses.switchBase}`]: {
        padding: 0,
        paddingLeft: 2
      },
      [`& .${switchClasses.checked}`]: {
        transform: "translateX(32px)",
        color: theme.palette.common.white,
        [`&+.${switchClasses.track}`]: {
          background: theme.palette.main[70],
          opacity: 1
        }
      },

      [`& .${switchClasses.input}`]: {
        left: -33,
        width: 81,
        height: 24
      },
      [`& .${switchClasses.thumb}`]: {
        width: 12,
        height: 12,
        marginTop: 6,
        background: theme.palette.common.white
      },
      [`& .${switchClasses.track}`]: {
        opacity: 1,
        background: theme.palette.neutral[10],
        transition: "none"
      }
    };
  }
);

interface IProps {
  field: any;
  formState: any;
  fieldState: any;
  onUpdate: (checked: boolean) => void;
}
function OnOffFormSwitch({
  field,
  formState,
  fieldState,
  onUpdate
}: IProps): JSX.Element {
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    field.onChange(event, checked);
    if (onUpdate) {
      onUpdate(checked);
    }
  };
  return <SwitchStyled {...field} checked={field.value} onChange={onChange} />;
}

export default OnOffFormSwitch;
