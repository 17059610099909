import { CardHeader, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useContext } from "react";

import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import { clearSleepHistory } from "store/actions/sleephistory/sleephistoryActions";
import { setLocalSettings } from "utils/localStorage";

import {
  SleepHistoryContext,
  SleepHistoryContextType
} from "../SleepHistoryContext";

function SleepHistoryHeader({
  title,
  showSleepHistory
}: {
  title: string;
  showSleepHistory: boolean;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { viewMode, setViewMode, setCurrentSession } =
    useContext<SleepHistoryContextType>(SleepHistoryContext);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    newMode: "history" | "last_night"
  ) => {
    if (newMode !== null) {
      // reducer cache fix
      setCurrentSession(undefined);
      dispatch(clearSleepHistory());
      // end
      setViewMode(newMode);
      setLocalSettings({
        [USER_SETTINGS_CONSTANTS.set_history_view_mode]: newMode
      });
    }
  };

  return (
    <CardHeader
      title={title}
      action={
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={handleAlignment}
        >
          {showSleepHistory && (
            <ToggleButton value="history">{t("History")}</ToggleButton>
          )}
          <ToggleButton value="last_night">{t("Last night")}</ToggleButton>
        </ToggleButtonGroup>
      }
    />
  );
}

export default SleepHistoryHeader;
