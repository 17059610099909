import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  UseFormStateReturn
} from "react-hook-form";
import { MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import React, { useMemo } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as DownArrowOutline } from "assets/icons/DownArrowOutline.svg";
import { ErrorMessage } from "styled/StylesStyled";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Props<T> {
  field: ControllerRenderProps<any, any>;
  options: {
    id: string;
    name: string;
    value: string;
    padding?: number;
    disabled?: boolean;
  }[];
  fieldState: ControllerFieldState;
  width?: number | string;
  placeholder?: string;
  onUpdate?: () => void;
  disabled?: boolean;
  capitalize?: boolean;
  formState?: UseFormStateReturn<FieldValues>;
}

export const SelectStyled = styled(Select, {
  shouldForwardProp: (prop) => prop !== "borderRed"
})(
  ({
    width,
    borderRed,
    capitalize
  }: {
    width?: number | string;
    borderRed: number;
    capitalize: number;
  }) => {
    const theme = useTheme(); 
    return {
      textTransform: capitalize ? "capitalize" : "none",
      border: `1px solid  ${
        borderRed ? theme.palette.red[50] : theme.palette.neutral[5]
      }`,
      borderRadius: 4,
      height: 48,
      "&.Mui-disabled": {
        background: theme.palette.neutral[5],
        cursor: "not-allowed"
      },
      "& > .MuiSelect-select": {
        width: width || "100%",
        padding: "12px 8px",
        fontSize: 16,
        height: 20
      },
      "& .Mui-disabled": {
        cursor: "not-allowed",
        WebkitTextFillColor: theme.palette.neutral[80]
      },
      "& svg": {
        position: "absolute",
        right: 20,
        top: "16px"
      },
      "&::focus": {
        //
      }
    };
  }
);

export const MenuItemStyled = styled(MenuItem)(
  ({ padding }: { padding?: number }) => {
    const theme = useTheme();
    return {
      paddingLeft: padding && padding > 1 ? padding * 24 : 16,
      fontSize: 16,
      paddingTop: 6,
      paddingBottom: 6,
      "&:hover": {
        background: theme.palette.main[2]
      },
      "&.Mui-selected": {
        background: `${theme.palette.main[4]} !important`
      }
    };
  }
);

function SMFormDropDown<T>({
  field,
  fieldState,
  options,
  placeholder,
  width,
  formState,
  onUpdate,
  disabled = false,
  capitalize
}: Props<T>): JSX.Element {
  const onChange = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    field.onChange(event, child);
    if (onUpdate) {
      onUpdate();
    }
  };
  const { error } = fieldState;

  const memoizeOptions = useMemo(() => {
    return options;
  }, [options]);

  return (
    <>
      <SelectStyled
        disableUnderline
        variant="standard"
        value={field?.value || "0"}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
        disabled={disabled}
        width={width || 212}
        borderRed={error?.message ? 1 : 0}
        capitalize={capitalize ? 1 : 0}
        IconComponent={(props) =>
          !disabled ? <DownArrowOutline {...props} /> : null
        }
      >
        {placeholder && (
          <MenuItemStyled
            value="0"
            key="placeholder"
            padding={0}
            sx={{
              textTransform: "none",
              cursor: "not-allowed"
            }}
          >
            {placeholder}
          </MenuItemStyled>
        )}
        {memoizeOptions.map(
          (option: {
            name: string;
            value: string;
            id: string;
            padding?: number;
            disabled?: boolean;
          }) => {
            return (
              <MenuItemStyled
                value={option.value}
                key={`${option.value}`}
                padding={option?.padding || 0}
                disabled={option?.disabled}
              >
                {option.name}
              </MenuItemStyled>
            );
          }
        )}
      </SelectStyled>
      {error?.message ? <ErrorMessage>{error?.message}</ErrorMessage> : ""}
    </>
  );
}

export default SMFormDropDown;
// export default React.memo(SMFormDropDown) as IMemoDropDown;
