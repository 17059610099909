import { Breakpoint, DialogTitle } from "@mui/material";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import { useTheme  } from "@mui/material/styles";

const DialogTitleStyled = styled(DialogTitle)(
  ({ children }: { children: React.ReactNode }) => {
    const theme = useTheme();
    return {
      fontSize: 22,
      color: theme?.palette.neutral[70],
      textTransform: "none",
      fontWeight: 500,
      margin: 0,
      padding: "32px 32px 25px 32px"
    };
  }
);

const IconButtonStyled = styled(Button)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.main[70],
    position: "absolute",
    right: 23,
    top: 39,
    minWidth: 24,
    padding: 5,
    "& svg": {
      borderRadius: 0
    }
  };
});


type DialogParams = {
  children: React.ReactNode;
  open: boolean;
  onClose?: Function;
  maxWidth?: false | Breakpoint | undefined;
  title?: string;
  minHeight?: string | number;
};
type DialogOption = Omit<DialogParams, "open">;

type ProviderContext = readonly [(option: DialogOption) => void, () => void];
type DialogContainerProps = DialogParams & {
  onClose: () => void;
};

const EMPTY_FUNC = () => {};

const DialogContext = React.createContext<ProviderContext>([
  EMPTY_FUNC,
  EMPTY_FUNC
]);
export const useDialog = () => React.useContext(DialogContext);

function DialogContainer(props: DialogContainerProps) {
  const { children, open, onClose, maxWidth, title, minHeight } = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={maxWidth || "lg"}>
      <DialogTitleStyled>
        {title}
        <IconButtonStyled aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButtonStyled>
      </DialogTitleStyled>
      <DialogContent style={{ minHeight }}>{children}</DialogContent>
    </Dialog>
  );
}

export default function DialogProvider({
  children
}: {
  children: JSX.Element;
}) {
  const [dialogs, setDialogs] = React.useState<DialogParams[]>([]);
  const createDialog = (option: DialogOption) => {
    const dialog = { ...option, open: true };
    setDialogs((prevState) => [...prevState, dialog]);
  };

  // close dialog
  const closeDialog = () => {
    setDialogs((prevState) => {
      const latest = prevState[prevState.length - 1];
      if (latest && latest.onClose) {
        latest.onClose();
      }
      return prevState.slice(0, prevState.length - 1);
    });
  };
  const contextValue = React.useRef([createDialog, closeDialog] as const);
  return (
      <DialogContext.Provider value={contextValue.current}>
        {children}
        {dialogs.map((dialog, i) => {
          const { onClose, ...dialogParams } = dialog;
          return (
            <DialogContainer
              // eslint-disable-next-line react/no-array-index-key
              key={`${i}_key`}
              onClose={closeDialog}
              {...dialogParams}
            />
          );
        })}
      </DialogContext.Provider>
  );
}
