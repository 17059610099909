import axios  from "axios";

import { API_SERVICES } from "../../constants/constants";
import { handleError, handleResponse } from "../responseHandler";

export const monitorServices = {
  fetchLiveMonitor
};

/**
 * This function fetch live monitor data
 * @param none
 * @return all the monitor data
 */
async function fetchLiveMonitor(zones: string[]) {
  const url = `${
    API_SERVICES.liveMonitoring
  }?device_type=vitalthings-somnofy-sm100&&include_unassigned_devices=true&zones=${zones.join(
    "&zones="
  )}`;
  return axios({
    method: "get",
    url,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*"
    }
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}
