import { basePath } from "constants/constants";
import { history } from "index";

/**
 * History Push
 * This function will open page based on the path using react router-dom history
 * @param path:path to push
 * @return none
 */
export const gotTo = (path: string, absolute?: boolean) => {
  if (absolute) {
    history.push(`/${path}`);
  } else {
    history.push(`${basePath}/${path}`);
  }
};

/**
 * History Pop
 * This function will go back to the previous page using react router-dom history
 * @param none
 * @return none
 */
export const goBack = () => {
  history.goBack();
};
