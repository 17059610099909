import { IMonitor } from "interfaces/monitor.interface";

export const FETCH_LIVE_MONITORING = "FETCH_LIVE_MONITORING";
export const CLEAR_STATE = "CLEAR_STATE";
export const FETCH_LIVE_MONITORING_SUCCESS = "FETCH_LIVE_MONITORING_SUCCESS";

export interface clearLiveMonitoringActionType {
  type: typeof CLEAR_STATE;
}
export interface fetchLiveMonitoringActionType {
  type: typeof FETCH_LIVE_MONITORING;
}
export interface fetchLiveMonitoringSuccessActionType {
  type: typeof FETCH_LIVE_MONITORING_SUCCESS;
  payload: IMonitor[];
}

export type monitorActionTypes =
  | fetchLiveMonitoringSuccessActionType
  | fetchLiveMonitoringActionType
  | clearLiveMonitoringActionType;
