import axios from "axios";

import { API_SERVICES } from "../../constants/constants";
import { handleError, handleResponse } from "../responseHandler";

export const nightReportServices = {
  fetchNightReport
};

/**
 * This function fetch live monitor data
 * @param none
 * @return all the monitor data
 */
async function fetchNightReport(zones: string) {
  const url = `${API_SERVICES.reports}?type=night-report-v1&device_type=vitalthings-somnofy-sm100&zones=${zones}`;

  return axios({
    method: "get",
    url,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*"
    }
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}
