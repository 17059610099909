import * as React from "react";
// import { createContext } from 'react';
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";

import * as LOCAL_STORAGE from "utils/localStorage";
import { IconButtonStyled } from "styled/CommonStyled";
import { ReactComponent as SortIcon } from "assets/icons/new/table-sort.svg";

/* *****************************************************
 *                STYLED COMPONETS
 * ***************************************************** */
export const TableRowStyled = styled(TableRow)(
  ({
    children,
    disable_row_hover,
    dynamic_padding
  }: {
    children: React.ReactNode;
    disable_row_hover: number;
    dynamic_padding?: number;
  }) => {
    const theme = useTheme(); 
    return {
      padding: "16px 20px",
      fontSize: 14,
      cursor: disable_row_hover ? "default" : "pointer",
      textAlign: "left",
      background: theme.palette.white,
      borderBottom: `1px solid ${theme.palette.neutral[5]}`,
      color: theme.palette.neutral[40],
      [`& .${tableCellClasses.root}`]: {
        border: "none",
        verticalAlign: "top"
      },
      "&:last-child": {
        borderBottom: "none"
      },
      "& td": {
        color: theme.palette.neutral[40],
        fontSize: 16,
        [theme.breakpoints.down("lg")]: {
          padding: dynamic_padding ? "16px 5px" : "16px"
        }
      },
      "& td:first-of-type": {
        color: theme.palette.neutral[70],
        fontSize: 16
      },
      "&:hover": {
        background: disable_row_hover ? "none" : theme.palette.main[2]
      }
    };
  }
);

export const TableHeadRowStyled = styled(TableRowStyled)(
  ({
    children,
    disable_row_hover,
    dynamic_padding
  }: {
    children: React.ReactNode;
    disable_row_hover: number;
    dynamic_padding?: number;
  }) => {
    const theme = useTheme(); 
    return {
      textTransform: "uppercase",
      textAlign: "left",
      verticalAlign: "top",
      background: theme.palette.neutral[2],
      fontWeight: "normal",
      borderBottom: `1px solid ${theme.palette.neutral[5]}`,
      cursor: "default",
      // boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
      "& th": {
        fontWeight: "normal",
        fontSize: 14,
        color: theme.palette.neutral[50],
        [theme.breakpoints.down("lg")]: {
          padding: dynamic_padding ? "16px 5px" : "16px"
        }
      },

      [`& .${tableCellClasses.root}`]: {
        border: "none"
      },
      "&:last-child": {
        borderBottom: `1px solid ${theme.palette.neutral[5]}`
      },
      "&:hover": {
        background: theme.palette.neutral[2]
      }
    };
  }
);

export const TableHeadDataStyled = styled("div")(
  ({
    children,
    active,
    direction,
    cursordefault,
    txtcenter
  }: {
    children: React.ReactNode;
    active?: number;
    direction?: string;
    cursordefault?: number;
    txtcenter?: number;
  }) => {
    return {
      cursor: cursordefault ? "default" : "pointer",
      // direction
      "& > div": {
        display: "flex",
        verticalAlign: "middle",
        // alignItems: "center",
        columnGap: 5,
        justifyContent: txtcenter ? "center" : "none",
        textAlign: txtcenter ? "center" : "left"
      },
      "& > div:nth-of-type(2)": {
        marginLeft: 10
      }
    };
  }
);

export const KeyContext = React.createContext(false);

/**
 * Sort order
 */
type Order = "asc" | "desc";

/**
 * Interface HeadCell
 *
 * Interface for the table HeadCells
 * @props `id` should be property name of the table row, use to sort dynamically.
 * @props  `label` String to render as table head cell
 *
 */
interface HeadCell<IRowData> {
  id: keyof IRowData;
  label: string;
  hideSort?: boolean;
  textCenter?: boolean;
  hideColumn?: boolean;
}

/**
 * Interface IProps
 *
 * Interface for the table rows
 * @props `rows` Table row data of type IRowData [generic]
 * @props `headCells` Table row data of type
 * @props `stylesProp` styles object as [rowKey]:styles, Inorder to apply special styling for certan rows
 * @props `renderComponentForValue` object as [rowKey]:JSX.Element, Render component passed instead of row value,
 */
interface IProps<IRowData> {
  rows: IRowData[];
  headCells: HeadCell<IRowData>[];
  onRowClick: (event: React.MouseEvent<unknown>, row: IRowData) => void;
  stylesPropData?: { [key: string]: { [key: string]: string } };
  stylesPropHead?: { [key: string]: { [key: string]: string } };
  renderComponentForValue?: { [key: string]: (label: any) => JSX.Element };
  disable_row_hover?: boolean;
  dynamic_padding?: boolean;
  minWidth?: number;
  defaultOrderBy?: keyof IRowData;
  localSettingKey?: string;
}

/**
 * Enhanced Interface for table head
 *
 * @props `onRequestSort` function to request sort for the cicked column
 * @props `onRequestSort` sort type - ASC || DESC
 * @props `orderBy`  sort property
 * @props `renderComponentForValue` object as [rowKey]:JSX.Element, Render component passed instead of row value,
 */
interface EnhancedTableProps<IRowData> {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IRowData
  ) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell<IRowData>[];
  stylesPropHead?: { [key: string]: { [key: string]: string } };
  dynamic_padding?: number;
}

/* *****************************************************
 *               Dynamic Sorting
 * ***************************************************** */

/**
 * Sort function
 * this function do item sort
 *
 * @returns sort return
 */
function descendingComparator<T>(a: T, b: T, orderBy: keyof T | "") {
  if (!orderBy) {
    return 0;
  }
  const leftVal = b[orderBy];
  const rightVal = a[orderBy];

  // if the left and right is array
  if (Array.isArray(rightVal) && Array.isArray(leftVal)) {
    if (
      typeof leftVal[leftVal.length - 1] === "string" &&
      typeof rightVal[rightVal.length - 1] === "string"
    ) {
      return Intl.Collator("nb", { sensitivity: "base" }).compare(
        leftVal[leftVal.length - 1],
        rightVal[rightVal.length - 1]
      );
    }
    if (leftVal[leftVal.length - 1] < rightVal[rightVal.length - 1]) {
      return -1;
    }
    if (leftVal[leftVal.length - 1] > rightVal[rightVal.length - 1]) {
      return 1;
    }
  }

  // if the left and right is string
  if (typeof leftVal === "string" && typeof rightVal === "string") {
    return Intl.Collator("nb", { sensitivity: "base" }).compare(
      leftVal,
      rightVal
    );
  }

  if (leftVal < rightVal) {
    return -1;
  }
  if (leftVal > rightVal) {
    return 1;
  }
  return 0;
}

/**
 * Function to check the type of operation
 *
 * @returns `descendingComparator` function
 *
 */
function getComparator<IRowData>(
  order: Order,
  orderBy: keyof IRowData | undefined
): (a: IRowData, b: IRowData) => number {
  return order === "desc"
    ? (a, b) => descendingComparator<IRowData>(a, b, orderBy || "")
    : (a, b) => -descendingComparator<IRowData>(a, b, orderBy || "");
}

/**
 * Stable sort function
 *
 * This method is created for cross-browser compatibility, if you don't
 * need to support IE11, you can use Array.prototype.sort() directly
 *
 */
function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/**
 * SMTableHead
 *
 * This function will render the table head row based on the [HeadCell]s.
 * `onRequestSort` will be called on every column click
 *
 * @props `EnhancedTableProps` Props of type EnhancedTableProps
 */
function SMTableHead<IRowData>(props: EnhancedTableProps<IRowData>) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    stylesPropHead,
    dynamic_padding
  } = props;
  const createSortHandler =
    (property: keyof IRowData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableHeadRowStyled
        disable_row_hover={0}
        dynamic_padding={dynamic_padding}
      >
        {headCells
          .filter((headCell: HeadCell<IRowData>) => !headCell.hideColumn)
          .map((headCell: HeadCell<IRowData>) => (
            <TableCell
              key={headCell.label}
              sortDirection={orderBy === headCell.id ? order : false}
              style={
                stylesPropHead
                  ? stylesPropHead[`${headCell.id.toString()}`]
                  : undefined
              }
            >
              <TableHeadDataStyled
                active={orderBy === headCell.id ? 1 : 0}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={
                  !headCell.hideSort ? createSortHandler(headCell.id) : () => {}
                }
                cursordefault={headCell.hideSort ? 1 : 0}
                txtcenter={headCell.textCenter ? 1 : 0}
              >
                <div>
                  <div> {headCell.label} </div>
                  {!headCell.hideSort && (
                    <IconButtonStyled padding={0} width="24px">
                      <SortIcon />
                    </IconButtonStyled>
                  )}
                </div>
                {/* <div>
                {" "}
               {" "}
              </div> */}
              </TableHeadDataStyled>
            </TableCell>
          ))}
      </TableHeadRowStyled>
    </TableHead>
  );
}

/**
 * SMDynamicTable
 *
 * This function will render the table rows based on the RowData array of type `IRowData`
 * @param `IRowData` Interface of the rowdata
 * @props `IProps` Props of type IProps
 */
export default function SMDynamicTable<IRowData>({
  headCells,
  rows,
  onRowClick,
  stylesPropData,
  stylesPropHead,
  renderComponentForValue,
  disable_row_hover,
  dynamic_padding,
  minWidth,
  defaultOrderBy,
  localSettingKey
}: IProps<IRowData>) {
  const settings = LOCAL_STORAGE.getAdminSettings() || {};
  const localSetting = settings[localSettingKey || ""] || {};
  const [order, setOrder] = React.useState<Order>(
    localSetting.direction || "asc"
  );
  const [orderBy, setOrderBy] = React.useState<keyof IRowData | undefined>(
    localSetting.key || defaultOrderBy
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IRowData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    if (localSettingKey) {
      // set local storage
      const currentSettings = LOCAL_STORAGE.getAdminSettings();
      LOCAL_STORAGE.setAdminSettings({
        ...currentSettings,
        [localSettingKey]: {
          key: property,
          direction: isAsc ? "desc" : "asc"
        }
      });
    }
  };

  const generateKey = (ind: number) => {
    return ind + 1000;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table sx={{ minWidth: minWidth || 750 }}>
          <SMTableHead<IRowData>
            key="TableHead"
            order={order}
            orderBy={orderBy as string}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            stylesPropHead={stylesPropHead}
            dynamic_padding={dynamic_padding ? 1 : 0}
          />
          <TableBody key="TableBody">
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort<IRowData>(
              rows,
              getComparator<IRowData>(order, orderBy)
            ).map((row: IRowData, index: number) => {
              return (
                <TableRowStyled
                  onClick={(event) =>
                    onRowClick ? onRowClick(event, row) : null
                  }
                  disable_row_hover={disable_row_hover ? 1 : 0}
                  tabIndex={-1}
                  dynamic_padding={dynamic_padding ? 1 : 0}
                >
                  {
                    // @ts-ignore
                    (Object.keys(row) as Array<keyof IRowData>)
                      .filter(
                        (key: keyof IRowData) =>
                          `${key.toString()}`.charAt(0) !== "_"
                      )
                      .map((key: keyof IRowData, ind: number) => {
                        if (
                          renderComponentForValue &&
                          renderComponentForValue[`${key.toString()}`]
                        ) {
                          return (
                            <TableCell
                              key={generateKey(ind)}
                              style={
                                stylesPropData
                                  ? stylesPropData[`${key.toString()}`]
                                  : undefined
                              }
                            >
                              {/* {renderComponentForValue[`${key.toString()}`](
                                React.cloneElement(
                                  row[key] as React.ReactElement,
                                  {key: generateKey(ind).toString()}
                                )
                              )}, */}
                              <KeyContext.Provider key={generateKey(ind)} value={true}>
                                {renderComponentForValue[`${key.toString()}`](row[key])}
                              </KeyContext.Provider>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={generateKey(ind)}
                            style={
                              stylesPropData
                                ? stylesPropData[`${key.toString()}`]
                                : undefined
                            }
                          >
                            <KeyContext.Provider key={generateKey(ind)} value={true}>
                              {row[key] as React.ReactNode}
                            </KeyContext.Provider>
                            {/* {React.cloneElement(
                              row[key] as React.ReactElement,
                              {key: generateKey(ind)}
                            )} */}
                            {/* {row[key] as React.ReactNode} */}
                          </TableCell>
                        );
                      })
                  }
                </TableRowStyled>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
