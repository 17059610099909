import { Checkbox, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";

import * as LOCAL_STORAGE from "utils/localStorage";
import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import {
  FormControlLabelStyled,
  SMTableWrapperBox,
  TableTitle
} from "styled/CommonStyled";
import { HeadCell } from "interfaces/componentsTypes/tableComponentsTypes";
import { ISubject } from "interfaces/subject.interface";
import { getAllSubjects } from "store/actions/administration/subjectActions";
import SMGoogleAnalytic from "components/GoogleAnalytic/GoogleAnalytic";
import SMNoDataBox from "components/SMDynamicTable/SMNoDataBox";
import UseAdminContext from "hooks/UseAdminContext";

import { SubjectContext } from "./SubjectContext";
import SubjectList from "./SubjectList";

export const SubjectLandingPage = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { selectedZone, setCurrentPath, zoneEntity } = UseAdminContext();
  const [zoneSubjects, setZoneSubjects] = React.useState<ISubject[]>();
  const adminSettings = LOCAL_STORAGE.getAdminSettings() || {};

  const [showChildren, setShowChildren] = React.useState<boolean>(
    adminSettings.includeSubjectChildren || false
  );

  const subjectState: any = useSelector((state: any) => {
    return state.subjectReducer || {};
  });

  useEffect(() => {
    if (setCurrentPath) {
      setCurrentPath(undefined);
    }
    if (zoneEntity && selectedZone && zoneEntity[selectedZone]) {
      dispatch(getAllSubjects(`${zoneEntity[selectedZone].path}.*`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoneEntity, selectedZone]);

  useEffect(() => {
    if (subjectState?.subjects && selectedZone && zoneEntity) {
      const filtered = subjectState.subjects.filter((item: ISubject) => {
        if (showChildren) {
          const paths = item.path?.split(".") || [];
          return paths.indexOf(selectedZone) !== -1;
        }
        return item.zone_id === selectedZone;
      });
      filtered.sort((a: ISubject, b: ISubject) => {
        return Intl.Collator("nb", { sensitivity: "base" }).compare(
          a.identifier,
          b.identifier
        );
      });
      setZoneSubjects(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectState?.subjects, selectedZone, showChildren]);

  const getHeadCells = (): HeadCell[] => {
    return [
      {
        id: "identifier",
        label: t("Subject Id")
      },
      {
        id: "zone_id",
        label: t("Zone")
      },
      {
        id: "sex",
        label: t("Sex"),
        textCenter: true
      },
      {
        id: "birth_year",
        label: t("Birth Year"),
        textCenter: true
      },
      {
        id: "name",
        label: t("Device Name / Location")
      },
      {
        id: "serial_number",
        label: t("Serial Number")
      },
      {
        id: "device_status",
        label: t("Device Status"),
        textCenter: true
      }
    ];
  };

  const getCheckBoxComponent = (): JSX.Element => {
    return (
      <Grid container justifyContent="flex-end" sx={{ marginBottom: "8px" }}>
        <FormControlLabelStyled
          checked
          onChange={(
            e: React.SyntheticEvent<Element, Event>,
            checked: boolean
          ) => {
            const settings = LOCAL_STORAGE.getAdminSettings();
            LOCAL_STORAGE.setAdminSettings({
              ...settings,
              includeSubjectChildren: checked
            });
            setShowChildren(checked);
          }}
          control={<Checkbox checked={showChildren} />}
          label={t("include_subjects_in_children_zones") as string}
        />
      </Grid>
    );
  };

  if (!zoneSubjects || !selectedZone) {
    return <div />;
  }

  if (!zoneSubjects?.length) {
    return (
      <BoxPadding pad="0px 24px 24px 4px">
        {getCheckBoxComponent()}
        <SMNoDataBox
          message={t("there_are_no_subjects_in_this_zone")}
          title={(zoneEntity && zoneEntity[selectedZone]?.name) || ""}
          bodyBg="white"
        />
      </BoxPadding>
    );
  }

  return (
    <>
      {/* Add google analytic */}
      <SMGoogleAnalytic title="Subjects Screen" />

      <SubjectContext.Provider value={{}}>
        <BoxPadding pad="0px 24px 24px 4px">
          {getCheckBoxComponent()}
          <SMTableWrapperBox>
            <TableTitle
              bg="light"
              border="light"
              sx={{
                padding: "16.5px 20px",
                borderRadius: "8px 8px 0px 0px"
              }}
            >
              {zoneEntity && zoneEntity[selectedZone]?.name}
            </TableTitle>
            <div style={{ padding: 1 }}>
              {zoneEntity && (
                <SubjectList
                  headCells={getHeadCells()}
                  subjects={zoneSubjects}
                  zoneEntity={zoneEntity}
                />
              )}
              {/* <SMDynamicTable<Data>
              headCells={getHeadCells()}
              rows={getRows(zoneSubjects)}
              onRowClick={handleClick}
              renderComponentForValue={renderComponentForValue}
            /> */}
            </div>
          </SMTableWrapperBox>
        </BoxPadding>
      </SubjectContext.Provider>
    </>
  );
};

export default SubjectLandingPage;
