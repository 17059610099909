import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";

import { ApplyMargin } from "styled/StylesStyled";
import SMButtonNew from "components/SMButton/SMButtonNew";

function SMUpdateCancel({
  onCancel,
  onUpdate,
  create = false,
  submit = false,
  btnText,
  disabled = false
}: {
  onCancel: () => void | null;
  onUpdate: () => void;
  create?: boolean;
  submit?: boolean;
  btnText?: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const getBtnText = () => {
    if (btnText) {
      return btnText;
    }
    return create ? t("Create") : t("Update");
  };
  return (
    <ApplyMargin top={20}>
      <Stack direction="row" spacing={2}>
        <SMButtonNew
          text={getBtnText()}
          onClick={onUpdate}
          submit={submit}
          disabled={disabled}
        />
        <SMButtonNew text={t("Cancel")} onClick={() => onCancel()} outlineBtn />
      </Stack>
    </ApplyMargin>
  );
}
export default SMUpdateCancel;
