import { Box } from "@mui/material";
import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function SMTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vt-tabpanel-${index}`}
      aria-labelledby={`vt-tab-${index}`}
      {...other}
      sx={{
        width: "100%",
        float: "left"
      }}
    >
      {value === index && <div> {children}</div>}
    </Box>
  );
}
export default SMTabPanel;
