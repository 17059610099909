import {
    Control,
    FieldErrors,
    SubmitHandler,
    UseFormHandleSubmit,
    UseFormResetField
  } from "react-hook-form";
  import { Grid } from "@mui/material";
  import { useTranslation } from "react-i18next";
  
  import {
    BoxSubSectionContentStyled,
    BoxSubSectionStyled
  } from "styled/ContainerStylesStyled";
  import { ISubject } from "interfaces/subject.interface";
  import SubTitle from "components/SMTitles/SubTitle";
  import { ITenantPublic } from "interfaces/tenant.interface";
  
  import CustomFieldGridItem from "components/CustomField/CustomField";
  
  export default function AdditionalSubjectInformation({
    create=true,
    subject,
    tenant,
    disable,
    onSubmit,
    onErrors,
    handleSubmit,
    control,
    resetField,
    setOpenDialog,
  }: {
    create?: boolean;
    subject: ISubject;
    tenant: ITenantPublic;
    disable?: boolean;
    onSubmit: SubmitHandler<ISubject>;
    onErrors: (err: FieldErrors<ISubject>) => void;
    handleSubmit: UseFormHandleSubmit<ISubject, ISubject>;
    control: Control<ISubject, any, ISubject>;
    resetField: UseFormResetField<ISubject>;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  }) {
    const { t } = useTranslation();
    return (
      <div>
        <BoxSubSectionStyled>
          <SubTitle showEdit={false} text={t("additional_subject_information_title")} defaultVal />
            <BoxSubSectionContentStyled>
              <Grid container rowSpacing={0} columnSpacing={2.5}>
                { tenant ? (
                  Object.keys(tenant.custom_fields).map((key: string) =>
                    <CustomFieldGridItem
                      create={create}
                      // @ts-ignore to ignore index type not found
                      custom_field={tenant?.custom_fields[key]}
                      control={control}
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                    />
                  )
                ) :
                (
                  <></>
                )}
              </Grid>
            </BoxSubSectionContentStyled>
        </BoxSubSectionStyled>
      </div>
    );
  }
  
  
  