import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import ResizeDetector from "react-resize-detector";
import SolidGauge from "highcharts/modules/solid-gauge";

import i18n from "../../../i18n/config.js";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

const t = i18n.t.bind(i18n);

function SleepScoreGaugeChart({ sleepScore }: { sleepScore: number | undefined }) {
  const chartRef = React.createRef<any>();
  return (
    <ResizeDetector
      handleWidth
      onResize={() =>
        chartRef.current ? chartRef.current.chart.reflow() : undefined
      }
    >
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        constructorType="chart"
        options={getPlotOptions(sleepScore)}
      />
    </ResizeDetector>
  );
}

export default React.memo(SleepScoreGaugeChart);

const getPlotOptions = (sleepScore: number | undefined) => {
  return {
    chart: {
      type: "solidgauge",
      margin: [0, 0, 0, 0],
      backgroundColor: "transparent"
    },
    title: null,
    pane: {
      center: ["50%", "50%"],
      size: "90%",
      startAngle: -120,
      endAngle: 120,
      background: {
        backgroundColor: "#EEE",
        innerRadius: "60%",
        outerRadius: "100%",
        shape: "arc"
      }
    },
    tooltip: {
      enabled: false
    },
    credits: {
      enabled: false
    },

    // the value axis

    yAxis: {
      stops: [
        [0.1, "#000000"], // black
        [0.7, "#DF5353"], // red
        [0.8, "#DDDF0D"], // yellow
        [0.9, "#55BF3B"] // green
      ],
      lineWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        text: null,
        y: -70
      },
      labels: {
        y: 15
      },
      min: 0,
      max: 100
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 20,
          borderWidth: 0,
          useHTML: true
        }
      }
    },
    series: [
      {
        name: "SleepScore",
        data: [sleepScore],
        dataLabels: {
          format: `<div style="text-align:center"><span style="font-size:25px;color:black
                    ">{y}</span><br/>
                    <span style="font-size:12px;color:black">${t(
                      "Sleep Score"
                    )}</span></div>`
        }
      }
    ]
  };
};
