import * as types from "../../actionTypes/authActionTypes";

export default function authReducerV1(
  state: any = {},
  action: types.authActionTypes
) {
  switch (action.type) {
    case types.FETCH_TENENTS:
      return {
        ...state
      };
    case types.FETCH_TENENTS_SUCCESS:
      return {
        ...state,
        tenants: action.payload
      };

    case types.GET_AUTH_USER_SUCCESS_ACTION:
      return {
        ...state,
        user: action.payload
      };
    case types.GET_AUTH_ZONES_SUCCESS_ACTION:
      return {
        ...state,
        authorizedZones: action.payload
      };
    case types.UPDATE_AUTH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload
      };

    case types.RESET_TENENTS:
      return {
        ...state,
        tenants: undefined
      };
    case types.RESET_AUTH_STATE:
      return {
        user: null,
        tenants: null,
        authorizedZones: []
      };

    default:
      return state;
  }
}
