import {
  Avatar,
  Box,
  CardActions,
  CardContent,
  Tooltip,
  Typography
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import React, { useCallback, useEffect } from "react";

import { IMonitor } from "interfaces/monitor.interface";
import { ITileSettings } from "@modules/monitor/DashboardScreen";
import { MonitorCardStyled } from "styled/ContainerStylesStyled";
import {
  getDuration,
  getMonitorMovementText,
  getMonitorTileStatusIcon,
  getMonitorTileStatusText
} from "utils/dashboard/dashboard.util";
import { secondsToTime } from "utils/date.util";

interface Iprops {
  data: IMonitor;
  t: any;
  onClick: (item: IMonitor) => void;
}

const LiveMonitorTile = ({ data, t, onClick }: Iprops) => {
  const { watch } = useFormContext<ITileSettings>();
  const [epochText, setEpochText] = React.useState<string>("\u00A0");
  const [durationText, setDurationText] = React.useState<string>("\u00A0");

  // form watch
  const tileScale = watch("tileScale", 50);
  const showIcon = watch("showIcon");
  const showSubjectId = watch("showSubjectId");
  const showDeviceName = watch("showDeviceName");
  const showDuration = watch("showDuration");
  const showVitals = watch("showVitals");
  const showEpochTimestamp = watch("show_epoch_timestamp");
  const deviceNameFontsize = (32 * tileScale) / 100; // 32
  const subjectFontSize = (32 * tileScale) / 100; // 32
  const statusFontSize = (40 * tileScale) / 100; // 36
  const avatarScale = (120 * tileScale) / 100;
  const footerFontSize = (23 * tileScale) / 100;
  const durationFontSize = (28 * tileScale) / 100;
  const cardHeaderPadding = (32 * tileScale) / 100;
  const cardActionPadding = (21 * tileScale) / 100;

  const isDisabled =
    data.state === "device_inactive" ||
    data.state === "device_offline" ||
    data.state === "device_sleep_tracking_disabled";


  // UseInterval(() => {
  //   if (showEpochTimestamp) {
  //     getMonitorEpochTimestamp();
  //   }
  //   getDurationText();
  // }, 30000);

  const getDurationText = useCallback(() => {
    const duration = getDuration(data.state_changed_at);
    setDurationText(duration);
  }, [data.state_changed_at]);

  const getMonitorEpochTimestamp = useCallback(() => {
    if (data.latest_epoch_timestamp) {
      const now = new Date();
      const latestEpochTimestamp = new Date(data.latest_epoch_timestamp);
      const milisecDiff = now.getTime() - latestEpochTimestamp.getTime();
      const duration = secondsToTime(milisecDiff / 1000);
      const epoch = `${t("updated")} ${duration.h} ${t("hours")} ${
        duration.m
      } ${t("minutes")} ${t("ago")}`;
      setEpochText(epoch);
    } else {
      setEpochText("\u00A0");
    }
  }, [data.latest_epoch_timestamp, t]);

  useEffect(() => {
    getDurationText();
    getMonitorEpochTimestamp();
  }, [getDurationText, getMonitorEpochTimestamp]);

  return (
    <Box onClick={() => onClick(data)}>
      <Tooltip
        placement="top"
        title={
          !data.subject?.identifier
            ? t("no_care_receiver_associated_with_this_device")
            : ""
        }
      >
        <MonitorCardStyled
          square
          pointer={data.subject?.identifier ? 1 : 0}
          state={data.state}
        >
          <CardContent
            sx={{
              m: 0,
              p: 0,
              height: "auto"
            }}
            style={{
              paddingLeft: cardHeaderPadding,
              paddingRight: cardHeaderPadding,
              paddingTop: !showDeviceName
                ? cardHeaderPadding / 2
                : cardHeaderPadding,
              paddingBottom: !showDeviceName ? cardHeaderPadding / 2 : 2
            }}
          >
            {showDeviceName && (
              <Typography
                style={{
                  fontSize: deviceNameFontsize,
                  paddingBottom: cardHeaderPadding / 2
                  // lineHeight: `${100 + (tileScale / 100) * 30}%`
                }}
                variant="h3"
                noWrap
              >
                {data.device?.name || <div>&nbsp;</div>}
              </Typography>
            )}
            {showSubjectId && (
              <Typography
                style={{
                  fontSize: subjectFontSize,
                  lineHeight: `${100 + (tileScale / 100) * 30}%`,
                  paddingTop: !showDeviceName ? cardHeaderPadding / 2 : 0
                }}
                variant="subtitle1"
                noWrap
              >
                {data.subject?.identifier || <div>&nbsp;</div>}
              </Typography>
            )}
          </CardContent>
          {/* <Divider /> */}
          <CardContent
            style={{ padding: cardHeaderPadding }}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              borderTop:
                showDeviceName || showSubjectId
                  ? "1px solid rgba(0, 0, 0, 0.12)"
                  : "none",
              borderBottom:
                showDuration || showVitals
                  ? "1px solid rgba(0, 0, 0, 0.12)"
                  : "none"
            }}
          >
            {showIcon && (
              <Avatar
                style={{ width: avatarScale, height: avatarScale }}
                src={getMonitorTileStatusIcon(data.state)}
              />
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: showIcon ? "5%" : 0,
                flexDirection: "column"
                // marginRight:cardHeaderPadding
              }}
            >
              <Typography
                style={{ fontSize: statusFontSize, fontWeight: "500" }}
                variant="h4"
              >
                {getMonitorTileStatusText(data.state)}
              </Typography>
              {showDuration && showVitals && (
                <Typography
                  style={{ fontSize: durationFontSize }}
                  variant="h6"
                  fontWeight="400"
                >
                  {durationText}
                </Typography>
              )}
            </div>
          </CardContent>
          {/* <Divider /> */}

          {showVitals && (
            <CardActions
              style={{ paddingLeft: cardHeaderPadding }}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                padding: `${cardActionPadding}px`
              }}
            >
              {/* to fix height */}
              {isDisabled && (
                <div style={{ fontSize: footerFontSize }}>&nbsp;</div>
              )}

              {!isDisabled && (
                <Typography
                  style={{ fontSize: footerFontSize }}
                  variant="h5"
                  sx={{ width: "50%" }}
                  fontWeight="normal"
                >
                  {data.state !== "not_in_bed" && data.metrics
                    ? getMonitorMovementText(data.metrics.latest_movement_mean)
                    : ""}
                </Typography>
              )}

              {!isDisabled && (
                <Typography
                  style={{ fontSize: footerFontSize }}
                  variant="h5"
                  sx={{ width: "25%" }}
                  fontWeight="normal"
                >
                  {data.metrics && data.metrics.latest_spo2_mean ? (
                    `${t("spo2")}: ${Math.round(data.metrics.latest_spo2_mean)}`
                  ) : (
                    <div style={{ fontSize: footerFontSize }}>&nbsp;</div>
                  )}
                </Typography>
              )}
              {!isDisabled && (
                <Typography
                  style={{ fontSize: footerFontSize }}
                  variant="h5"
                  sx={{ width: "25%" }}
                  fontWeight="normal"
                >
                  {/* {(data.state === "awake_in_bed" ||
                    data.state === "asleep") && ( */}
                  <span style={{ float: "right" }}>
                    {`${t("rr")}: ${
                      data.metrics?.respiration_rate_pooled_mean
                        ? Math.round(data.metrics.respiration_rate_pooled_mean)
                        : "-"
                    }`}
                  </span>
                  {/* )} */}
                </Typography>
              )}
            </CardActions>
          )}

          {!showVitals && showDuration && (
            <CardActions style={{ padding: cardHeaderPadding }}>
              <Typography
                style={{ fontSize: footerFontSize }}
                variant="caption"
                fontWeight="normal"
              >
                {t("Duration")}: {durationText}
              </Typography>
            </CardActions>
          )}

          {showEpochTimestamp && (
            <Box
              sx={{
                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                padding: "5px 16px"
              }}
            >
              {epochText}
            </Box>
          )}
        </MonitorCardStyled>
      </Tooltip>
    </Box>
  );
};

// const areEqual = (prevProps: Iprops, nextProps: Iprops) => {
//   if (prevProps.data.state_changed_at === nextProps.data.state_changed_at) {
//     return true;
//   }
//   return false;
// };
export default React.memo(LiveMonitorTile);
// export default LiveMonitorTile;
