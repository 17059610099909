import axios from "axios";

import { IUser } from "interfaces/user.interface";
import { toastErrorPositionCenter } from "utils/toast.util";

import { API_SERVICES } from "../../constants/constants";
import {
  handleError,
  handleResponse,
  showErrorFeedback
} from "../responseHandler";

export const authServices = {
  fetchTenents,
  getAuthUser,
  updateAuthUser,
  passwordReset,
  getAuthUserZones
};

/**
 * This function fetch all the tenents
 * @param
 * @return all the zones in the system
 */
async function fetchTenents(email: string) {
  return axios({
    method: "get",
    url: `${API_SERVICES.tenents}?email=${encodeURIComponent(email)}`,
    timeout: 10000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      if (error?.code === "ECONNABORTED") {
        toastErrorPositionCenter(`${error.message}, Please try again`);
      } else {
        showErrorFeedback(error);
      }
      return handleError(error);
    });
}

/**
 * This function to get the authenticated user
 * @param
 * @return authenticated user
 */
async function getAuthUser() {
  const url = API_SERVICES.authUser;
  return axios({
    method: "get",
    url,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*"
    }
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

async function getAuthUserZones() {
  const url = API_SERVICES.authUserZones;
  return axios({
    method: "get",
    url,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*"
    }
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

/**
 * This function to update the authenticated user
 * @param
 * @return authenticated user
 */
async function updateAuthUser(user: IUser) {
  const url = `${API_SERVICES.authUser}`;
  const data = {
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    profession: user.profession,
    preferences: user.preferences
  };
  return axios({
    method: "patch",
    url,
    data,
    timeout: 30000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
}

async function passwordReset() {
  const url = `${API_SERVICES.passwordReset}`;
  return axios({
    method: "post",
    url,
    timeout: 30000
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}
