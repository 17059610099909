import { createContext } from "react";

import { ChartTypes, ISession } from "interfaces/sleephistory.interface";
import { IFilter } from "interfaces/filters.interface";

export type SleepHistoryContextType = {
  selectedDates: { from: string; to: string; type?: string };
  setSelectedDates: React.Dispatch<
    React.SetStateAction<{
      from: string;
      to: string;
      type?: string;
    }>
  >;
  viewMode: "last_night" | "history";
  setViewMode: React.Dispatch<React.SetStateAction<"last_night" | "history">>;
  enableHeartRateMean: boolean;
  setCurrentSession: React.Dispatch<React.SetStateAction<ISession | undefined>>;
  disableNext: boolean;
  disablePrev: boolean;
  activeChart: ChartTypes;
  setActiveChart: React.Dispatch<React.SetStateAction<ChartTypes>>;
  sleepHistoryFilters: IFilter;
  setSleepHistoryFilters: React.Dispatch<React.SetStateAction<IFilter>>;
};

export const SleepHistoryContext = createContext<SleepHistoryContextType>(
  {} as SleepHistoryContextType
);
