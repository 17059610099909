import React, { memo } from "react";
import ReactGA from "react-ga4";

// can configure more hitType later
function SMGoogleAnalytic({
  title,
  hitType
}: {
  title?: string;
  hitType?: "pageview";
}) {
  if (process.env.REACT_APP_PRODUCTION !== "true") {
    return (
      <>
        {ReactGA.send({
          hitType: hitType || "pageview",
          page: window.location.pathname,
          title
        })}
      </>
    );
  }

  return <></>;
}
export default memo(SMGoogleAnalytic);
