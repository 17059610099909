/* eslint-disable prefer-destructuring */
import { Card } from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";

import { IColorValue } from "interfaces/color.interface";

interface BoxSubSectionStyledProps {
  width?: number | string;
  children: React.ReactNode;
  height?: string | number;
  bg?: "light" | "dark";
}
export const BoxSubSectionStyled = styled("div")(
  ({ children, width, height, bg }: BoxSubSectionStyledProps) => {
    const theme = useTheme();
    let background = "#FFF";
    if (bg === "light") {
      background = "#FFF";
    }
    return {
      border: `1px solid ${theme.palette.neutral[5]}`,
      boxSizing: "border-box",
      borderRadius: 8,
      //   boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
      //   filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))',
      float: "left",
      width: width || "100%",
      height: height || "100%",
      background
    };
  }
);

export const BoxSubSectionContentStyled = styled("div")(
  ({
    children,
    width,
    padding
  }: {
    width?: string | number;
    children: React.ReactNode;
    padding?: string | number;
  }) => {
    return {
      padding: padding || "16px 24px 34px 16px",
      float: "left",
      width: width || "100%",
      height: "100%"
    };
  }
);

export const WrapperStyled = styled("div")(
  ({
    children,
    sx
  }: {
    children: React.ReactNode;
    sx?: React.CSSProperties;
  }) => {
    return {
      width: "100%",
      float: "left",
      ...sx
    };
  }
);

export const ContentWrapper = styled("div")(
  ({ children }: { children: React.ReactNode }) => {
    return {
      height: "100%",
      width: "100%"
    };
  }
);

export const MonitorCardStyled = styled(Card)(
  ({
    children,
    pointer,
    state
  }: {
    children: React.ReactNode;
    pointer: number;
    state: string;
  }) => {
    const theme = useTheme();
    let background: IColorValue;
    switch (state) {
      case "device_offline":
        background = theme.palette.neutral[5];
        break;
      case "device_inactive":
        background = theme.palette.neutral[5];
        break;
      case "device_sleep_tracking_disabled":
        background = theme.palette.neutral[5];
        break;
      case "not_in_bed":
        background = theme.palette.orange[30];
        break;
      case "awake_in_bed":
        background = theme.palette.blue[20];
        break;
      case "asleep":
        background = theme.palette.neutral[20];
        break;

      default:
        background = theme.palette.neutral[5];
    }
    return {
      background,
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-between",
      cursor: pointer ? "pointer" : "not-allowed"
    };
  }
);
