import _ from "lodash";

import { ITenents } from "interfaces/auth.interface";
import { IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";
import { authServices } from "services/auth/authService";

import * as types from "../../actionTypes/authActionTypes";

/* ************************************************** */
/*                      Actions                       */
/* ************************************************** */

/**
 * Redux action to fetch all tenents
 *
 * @return type - redux action type |
 */
export const fetchTenentsAction = (): types.fetchTenentsActionType => {
  return {
    type: types.FETCH_TENENTS
  };
};

export const resetAuthStateAction = (): types.resetAuthStateActionType => {
  return {
    type: types.RESET_AUTH_STATE
  };
};

export const resetTenentsAction = (): types.resetTenentsStateActionType => {
  return {
    type: types.RESET_TENENTS
  };
};
/**
 * Redux  success action
 *
 * @return type - redux action type |
 *         payload - subjects
 */
export const fetchTenentsActionSuccess = (
  data: ITenents[]
): types.fetchTenentsSuccessActionType => {
  return {
    type: types.FETCH_TENENTS_SUCCESS,
    payload: data
  };
};

// fetch tenents
export const fetchTenents = (email: string) => {
  return function (dispatch: (arg0: any) => void) {
    authServices.fetchTenents(email).then((response: any) => {
      if (response?.data) {
        dispatch(fetchTenentsActionSuccess(response.data));
      }
    });
  };
};

/**
 * Redux action
 *
 * @return type - redux action type |
 *         payload - subjects
 */
export const getAuthUserAction = (): types.getAuthUserActionType => {
  return {
    type: types.GET_AUTH_USER
  };
};

/**
 * Redux  success action
 *
 * @return type - redux action type |
 *         payload - subjects
 */
export const getAuthUserSuccessAction = (
  user: IUser
): types.getAuthUserSuccessActionType => {
  return {
    type: types.GET_AUTH_USER_SUCCESS_ACTION,
    payload: user
  };
};

export const updateAuthUserAction = (): types.updateAuthUserActionType => {
  return {
    type: types.UPDATE_AUTH_USER
  };
};

/**
 * Redux success action
 *
 * @return type - redux action type |
 *         payload - subjects
 */
export const updateAuthUserSuccessAction = (
  user: IUser
): types.updateAuthUserSuccessActionType => {
  return {
    type: types.UPDATE_AUTH_USER_SUCCESS,
    payload: user
  };
};

export const getAuthUserZonesSuccessAction = (
  zones: IZone[]
): types.getAuthUserZonesSuccessActionType => {
  return {
    type: types.GET_AUTH_ZONES_SUCCESS_ACTION,
    payload: zones
  };
};

// getAuthUserZones
export const getAuthUserZones = () => {
  return function (dispatch: (arg0: any) => void) {
    authServices.getAuthUserZones().then((response: { data: IZone[] }) => {
      if (response?.data) {
        dispatch(getAuthUserZonesSuccessAction(response?.data));
      }
    });
  };
};

// fetch tenents
export const getAuthUser = () => {
  return function (dispatch: (arg0: any) => void) {
    dispatch(getAuthUserAction());
    authServices.getAuthUser().then((response: { data: IUser }) => {
      if (response?.data) {
        const userZones = _.orderBy(
          response?.data?.zones?.data || [],
          (item) => item.name.toLowerCase(),
          ["asc"]
        );
        dispatch(
          getAuthUserSuccessAction({
            ...response.data,
            zones: { data: userZones, object: response?.data?.zones?.object }
          })
        );

        // get the updated list of user zones
        dispatch(getAuthUserZones());
      } else {
        // clearCookies();
      }
    });
  };
};

//
export const updateAuthUser = (user: IUser) => {
  return function (dispatch: (arg0: any) => void) {
    dispatch(updateAuthUserAction());
    authServices.updateAuthUser(user).then((response: { data: IUser }) => {
      if (response?.data) {
        dispatch(updateAuthUserSuccessAction(response.data));
      } else {
        // clearCookies();
      }
    });
  };
};

export const resetAuthState = () => {
  return function (dispatch: (arg0: any) => void) {
    dispatch(resetAuthStateAction());
    // authServices.logoffAuthUser().then((response: any) => {
    //    console.log("response",response);
    // });
  };
};

export const resetTenentState = () => {
  return function (dispatch: (arg0: any) => void) {
    dispatch(resetTenentsAction());
  };
};

export const passWordReset = (callback?: () => void) => {
  return function (dispatch: (arg0: any) => void) {
    authServices.passwordReset().then(() => {
      if (callback) {
        callback();
      }
    });
  };
};
