import { IUser } from "interfaces/user.interface";

export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";

export const FETCH_USER_BY_ID = "FETCH_USER_BY_ID";
export const FETCH_USER_BY_ID_SUCCESS = "FETCH_USER_BY_ID_SUCCESS";

export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const UPDATE_USER_ZONES = "UPDATE_USER_ZONES";
export const UPDATE_USER_ZONES_SUCCESS = "UPDATE_USER_ZONES_SUCCESS";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export interface fetchUsersActionType {
  type: typeof FETCH_USER;
}
export interface fetchUsersuccessActionType {
  type: typeof FETCH_USER_SUCCESS;
  payload: IUser[];
}

export interface fetchUserByIDActionType {
  type: typeof FETCH_USER_BY_ID;
}
export interface fetchUserByIDSuccessActionType {
  type: typeof FETCH_USER_BY_ID_SUCCESS;
  payload: IUser;
}

export interface createUserActionType {
  type: typeof CREATE_USER;
}
export interface createUseruccessActionType {
  type: typeof CREATE_USER_SUCCESS;
  payload: IUser;
}

export interface updateUserActionType {
  type: typeof UPDATE_USER;
}
export interface updateUserSuccessActionType {
  type: typeof UPDATE_USER_SUCCESS;
  payload: IUser;
}

export interface updateUserZoneActionType {
  type: typeof UPDATE_USER_ZONES;
}
export interface updateUserZoneSuccessActionType {
  type: typeof UPDATE_USER_ZONES_SUCCESS;
  payload: IUser;
}

export interface deleteUserActionType {
  type: typeof DELETE_USER;
}
export interface deleteUserSuccessActionType {
  type: typeof DELETE_USER_SUCCESS;
  payload: string;
}

export type UsersActionTypes =
  | fetchUsersActionType
  | fetchUsersuccessActionType
  | createUserActionType
  | createUseruccessActionType
  | updateUserActionType
  | updateUserSuccessActionType
  | fetchUserByIDActionType
  | fetchUserByIDSuccessActionType
  | fetchUserByIDActionType
  | deleteUserActionType
  | deleteUserSuccessActionType
  | updateUserZoneActionType
  | updateUserZoneSuccessActionType;
