import { useContext } from "react";

import {
  AdministrationContext,
  AdministrationContextType
} from "@modules/administration/AdministrationContext";

export default function UseAdminContext() {
  const {
    zones,
    setCurrentPath,
    selectedZone,
    setSelectedZone,
    currentPath,
    zoneEntity
  } = useContext<AdministrationContextType | null>(
    AdministrationContext || null
  ) || { zones: [], selectedZone: "" };

  return {
    zones,
    setCurrentPath,
    selectedZone,
    setSelectedZone,
    currentPath,
    zoneEntity
  };
}
