import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";

import { IZone } from "interfaces/zone.interface";
import {
  IconButtonStyled,
  PositionAbsolute,
  PositionRelative,
  SettingsIconStyled
} from "styled/CommonStyled";
import { basePath } from "constants/constants";
import { getAuthUser } from "store/actions/auth/authActions";
import RouteTabsNew from "components/RouteTabs/RouteTabsNew";
import UseAdminContext from "hooks/UseAdminContext";

import {
  zonesChildRouteNames as routeLabel,
  zonesChildRoutes as routes
} from "./zone.routes";

export const ZoneLandingPage = () => {
  const { t } = useTranslation();
  const { selectedZone, setSelectedZone } = UseAdminContext();

  const dispatch = useDispatch();
  const history = useHistory();

  const authState: any = useSelector((state: any) => {
    return state.authReducerV1 || {};
  });

  const handleClickOpen = () => {
    history.push(`${basePath}/adminstration/settings`);
  };

  useEffect(() => {
    dispatch(getAuthUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedZone && setSelectedZone) {
      const authZones =
        authState.user?.zones?.data?.map((item: IZone) => item.id) || [];
      setSelectedZone(authZones[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  return (
    <PositionRelative>
      <RouteTabsNew
        routes={routes}
        routeLabel={routeLabel}
        t={t}
        hideBreadcrumb
      />
      <PositionAbsolute right={24} top={19}>
        <IconButtonStyled onClick={handleClickOpen}>
          <SettingsIconStyled style={{ cursor: "pointer" }} />
        </IconButtonStyled>
      </PositionAbsolute>
    </PositionRelative>
  );
};

export default ZoneLandingPage;
