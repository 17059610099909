import { Card, CardContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import React, { useEffect, useState } from "react";

import * as LOCAL_STORAGE from "utils/localStorage";
import {
  AuthorizationContext,
  ZonePermissionEnum
} from "authorization/AuthorizationContext";
import {
  ChartTypes,
  IHistoryStagesPlotData,
  IHistoryVitalsPlotData,
  ISession
} from "interfaces/sleephistory.interface";
import { IFilter } from "interfaces/filters.interface";
import { LabelStyled } from "styled/CommonStyled";
import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import {
  clearSleepHistory,
  fetchSleepHistory,
  fetchSleepHistoryByID
} from "store/actions/sleephistory/sleephistoryActions";
import {
  getFilteredSessionByTme,
  getSleepStagesPlotData,
  getSleepVitalsPlotData
} from "utils/sleepHistory/sleepHistory";
import { getLocalSettings, setLocalSettings } from "utils/localStorage";
import { toIsoString } from "utils/date.util";
import SMGoogleAnalytic from "components/GoogleAnalytic/GoogleAnalytic";

import { SleepHistoryContext } from "./SleepHistoryContext";
import SessionDetailedView from "./components/SessionDetailedView";
import SleepHistoryHeader from "./components/SleepHistoryHeader";
import SleepHistoryStagesChart from "./charts/SleepHistoryStagesChart";
import SleepHistoryToolBar from "./components/SleepHistoryToolBar";
import SleepHistoryVitalsChart from "./charts/SleepHistoryVitalsChart";

function SleepHistoryScreen({
  selectedSubjectId,
  userDisplayName,
  sessionID
}: {
  selectedSubjectId: string;
  userDisplayName: string;
  sessionID?: string;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { permissions } = React.useContext(AuthorizationContext);

  const enable_heart_rate_mean = new URLSearchParams(location.search).get(
    "enable_heart_rate_mean"
  );
  const [sessions, setSessions] = useState<ISession[]>();
  const [filteredSessions, setFilteredSessions] = useState<ISession[]>([]);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disablePrev, setDisablePrev] = useState<boolean>(false);

  const [viewMode, setViewMode] = useState<"last_night" | "history">(
    getLocalSettings(USER_SETTINGS_CONSTANTS.set_history_view_mode) ||
      "last_night"
  );

  // If no filter is stored or stored filter type is "custom", use latest 90 days instead
  const storedDateFilterType =
    LOCAL_STORAGE.getSleepHistoryDateFilterType() || "90";
  const defaultDateFilterType = parseInt(storedDateFilterType, 10)
    ? storedDateFilterType
    : "90";

  const endOfToday = new Date().setHours(23, 59, 59, 999);
  const fromDate = new Date().setDate(
    new Date().getDate() - parseInt(defaultDateFilterType, 10) || 90
  );

  const startOfFromDate = new Date(fromDate).setHours(0, 0, 0, 999);
  const defaultDateFilter = {
    to: toIsoString(new Date(endOfToday)),
    from: toIsoString(new Date(startOfFromDate)),
    type: defaultDateFilterType
  };
  const [selectedDates, setSelectedDates] = useState<{
    from: string;
    to: string;
    type?: string;
  }>(defaultDateFilter);

  // Time asleep filter
  const defaultFilters: IFilter = LOCAL_STORAGE.getSleepHistorySettings() || {
    timeInBed: "120",
    totalSleepTime: "",
    sessionStartTime: {
      start: "",
      end: ""
    }
  };
  const [sleepHistoryFilters, setSleepHistoryFilters] =
    useState<IFilter>(defaultFilters);

  const [sleepStagesOptions, setSleepStagesOptions] = useState<
    { series: IHistoryStagesPlotData; threshold: number } | undefined
  >(undefined);

  const [sleepVitalsOptions, setSleepVitalsOptions] =
    useState<IHistoryVitalsPlotData>();
  const [currentSession, setCurrentSession] = useState<ISession>();
  const [sessionIDsAsString, setSessionIDsAsString] = useState<string>("");

  // chart types
  const defaultChartType: ChartTypes =
    (LOCAL_STORAGE.getSleepHistoryChartType() as ChartTypes) ||
    ChartTypes.area_chart;
  const [activeChart, setActiveChart] =
    React.useState<ChartTypes>(defaultChartType);

  const sleepHistoryData = useSelector((state: any) => {
    return state?.sleepHistoryReducer?.sleepHistory;
  });

  // Show sleep history
  const showSleepHistory = () => {
    return (
      permissions &&
      permissions.indexOf(ZonePermissionEnum["zone.sleep_history"]) !== -1
    );
  };

  /* ****************************************************************************** */
  /*                     Heart rate settings                                        */
  /* ****************************************************************************** */
  let enableHeartRateMean;
  if (enable_heart_rate_mean) {
    if (enable_heart_rate_mean === "true") {
      // const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?myNewUrlQuery=1`;
      setLocalSettings({
        [USER_SETTINGS_CONSTANTS.enable_heart_rate_mean]: true
      });

      enableHeartRateMean = true;
    } else if (enable_heart_rate_mean === "false") {
      setLocalSettings({
        [USER_SETTINGS_CONSTANTS.enable_heart_rate_mean]: false
      });

      // set local state
      enableHeartRateMean = false;
    }
  } else {
    const lclEnableHeartRateValue = getLocalSettings(
      USER_SETTINGS_CONSTANTS.enable_heart_rate_mean
    );

    if (lclEnableHeartRateValue === true) {
      // const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?enable_heart_rate_mean=true`;
      // window.history.pushState({ path: newurl }, "", newurl);
      enableHeartRateMean = true;
    } else {
      enableHeartRateMean = false;
    }
  }

  /* ****************************************************************************** */
  /*                     Heart rate settings End                                    */
  /* ****************************************************************************** */
  useEffect(() => {
    if (!showSleepHistory() && viewMode === "history") {
      // change the the view mode
      setViewMode("last_night");
      setLocalSettings({
        [USER_SETTINGS_CONSTANTS.set_history_view_mode]: "last_night"
      });
    }

    return () => {
      // to fix the state caching issue
      dispatch(clearSleepHistory());
    };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (sleepHistoryData && sleepHistoryData.length) {
      setSessions(sleepHistoryData);

      const timeFilter = sleepHistoryFilters.sessionStartTime;
      if (viewMode === "history") {
        if (timeFilter.start && timeFilter.end) {
          const sessionsFilteredByTme = getFilteredSessionByTme({
            sessions: sleepHistoryData,
            from: timeFilter.start,
            to: timeFilter.end
          });
          setFilteredSessions(sessionsFilteredByTme);

          // for component caching
          setSessionIDsAsString(
            sessionsFilteredByTme.map((item: ISession) => item.id).join("-")
          );
          setSleepStagesOptions(getSleepStagesPlotData(sessionsFilteredByTme));
          setSleepVitalsOptions(getSleepVitalsPlotData(sessionsFilteredByTme));
        } else {
          setFilteredSessions([]);
          // for component caching
          setSessionIDsAsString(
            sleepHistoryData.map((item: ISession) => item.id).join("-")
          );
          setSleepStagesOptions(getSleepStagesPlotData(sleepHistoryData));
          setSleepVitalsOptions(getSleepVitalsPlotData(sleepHistoryData));
        }
      } else if (viewMode === "last_night") {
        if (!sessionID) {
          const lastSession = sleepHistoryData[0];
          setCurrentSession(lastSession);
        } else {
          const session = sleepHistoryData.find(
            (item: ISession) => item.id === sessionID
          );
          if (session) {
            setCurrentSession(session);
          }
        }
      }
    } else if (sleepHistoryData !== undefined) {
      if (!sleepHistoryData.lengh) {
        resetSleepData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sleepHistoryData, viewMode, sessionID]);

  const resetSleepData = () => {
    setSleepStagesOptions(undefined);
    setSessions([]);
    setFilteredSessions([]);
    setSessionIDsAsString("");
    setSleepVitalsOptions(undefined);
    setCurrentSession(undefined);
  };

  const onChartMouseOver = (src: any, e: any) => {
    if (e.target.sessionId) {
      const session = sessions?.find((item) => item.id === e.target.sessionId);
      if (session) {
        setCurrentSession(session);
        // if(!componentHash[session.id]){
        //   setComponentHash((prevState) => ({
        //     ...prevState,
        //      [session.id]:<SessionDetailedView session={session}/>
        //   }));
        // }
      }
    }
  };
  
  const dispatchFetchSleepAction = (
    from: string,
    to: string,
    subject_id: string
  ) => {
    dispatch(
      fetchSleepHistory({
        from,
        to,
        subject_id,
        latestSession: viewMode === "last_night",
        totalSleepTime: sleepHistoryFilters.totalSleepTime,
        timeInBed: sleepHistoryFilters.timeInBed,
        sessionStartTime: sleepHistoryFilters.sessionStartTime || null
      })
    );
  };

  const onChartClick = (src: any, e: Event) => {};
  useEffect(() => {
    if (viewMode === "last_night") {
      if (!sessionID) {
        const to = toIsoString(new Date(endOfToday));
        const from = toIsoString(new Date(fromDate));
        dispatchFetchSleepAction(from, to, selectedSubjectId);
      } else {
        // fetch session by id
        const session = sleepHistoryData?.find(
          (item: ISession) => item.id === sessionID
        );
        if (!session) {
          dispatch(fetchSleepHistoryByID({ id: sessionID }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);


  useEffect(() => {
    if (selectedDates.from && selectedDates.to && viewMode === "history") {
      dispatchFetchSleepAction(
        selectedDates.from,
        selectedDates.to,
        selectedSubjectId
      );
    }
    return () => {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates, viewMode, sleepHistoryFilters]);
  // Dependency array cant contain selectedSubjectId, otherwise error when closing sleep history screen

  const isTimeFilterEnabled = () => {
    const timeFilter = sleepHistoryFilters.sessionStartTime;
    if (timeFilter.start && timeFilter.end) {
      return true;
    }
    return false;
  };
  const isNoSleepData = (): boolean => {
    if (isTimeFilterEnabled()) {
      return filteredSessions !== undefined && !filteredSessions?.length;
    }
    return sessions !== undefined && !sessions?.length;
  };

  const onNextPrev = (type: "next" | "prev") => {
    if (!currentSession || !sessions) {
      return;
    }
    // rest disabled
    setDisablePrev(false);
    setDisableNext(false);

    const maxlength = isTimeFilterEnabled()
      ? filteredSessions.length
      : sessions?.length || 0;
    let index;

    if (isTimeFilterEnabled()) {
      index = filteredSessions.findIndex(
        (item: ISession) => item.id === currentSession.id
      );
    } else {
      index = sessions.findIndex(
        (item: ISession) => item.id === currentSession.id
      );
    }
    const adjacentIndex = type === "next" ? index + 1 : index - 1;
    if (index === -1 || adjacentIndex < 0 || adjacentIndex >= maxlength) {
      return;
    }
    const adjacentSession = isTimeFilterEnabled()
      ? filteredSessions[adjacentIndex]
      : sessions[adjacentIndex];
    if (adjacentSession) {
      setCurrentSession(adjacentSession);
    }

    if (adjacentIndex - 1 < 0) {
      setDisablePrev(true);
    }
    if (adjacentIndex + 1 >= maxlength) {
      setDisableNext(true);
    }
  };

  useEffect(() => {
    LOCAL_STORAGE.setSleepHistoryDateFilterType(selectedDates.type);
  }, [selectedDates]);

  // store filters
  useEffect(() => {
    LOCAL_STORAGE.setSleepHistorySettings(sleepHistoryFilters);
  }, [sleepHistoryFilters]);

  // store chart type
  useEffect(() => {
    LOCAL_STORAGE.setSleepHistoryChartType(activeChart);
  }, [activeChart]);

  return (
    <>
      {/* Add google analytic */}
      <SMGoogleAnalytic title="Sleep History" />
      <SleepHistoryContext.Provider
        value={{
          selectedDates,
          setSelectedDates,
          viewMode,
          setViewMode,
          enableHeartRateMean: enableHeartRateMean || false,
          setCurrentSession,
          disableNext,
          disablePrev,
          activeChart,
          setActiveChart,
          sleepHistoryFilters,
          setSleepHistoryFilters
        }}
      >
        <SleepHistoryHeader
          title={userDisplayName || ""}
          showSleepHistory={showSleepHistory()}
        />
        {viewMode === "history" && (
          <>
            <SleepHistoryToolBar />
            <Card sx={{ height: "auto", marginBottom: 3 }}>
              {isNoSleepData() && (
                <LabelStyled sx={{ padding: 20, fontSize: 18 }}>
                  {!sessions?.length
                    ? t("no_sleep_data_to_show")
                    : t("no_sleep_data_to_show_based_on_the_filter")}
                </LabelStyled>
              )}
              {!isNoSleepData() ? (
                <div>
                  <CardContent>
                    {sleepStagesOptions &&
                      activeChart === ChartTypes.area_chart && (
                        <SleepHistoryStagesChart
                          onChartMouseOver={onChartMouseOver}
                          onChartClick={onChartClick}
                          plotData={sleepStagesOptions.series}
                          threshold={sleepStagesOptions.threshold}
                          sessionIDsAsString={sessionIDsAsString}
                          chartType={ChartTypes.area_chart}
                        />
                      )}

                    {sleepStagesOptions &&
                      activeChart === ChartTypes.column_chart && (
                        <SleepHistoryStagesChart
                          onChartMouseOver={onChartMouseOver}
                          onChartClick={onChartClick}
                          plotData={sleepStagesOptions.series}
                          threshold={sleepStagesOptions.threshold}
                          sessionIDsAsString={sessionIDsAsString}
                          chartType={ChartTypes.column_chart}
                        />
                      )}
                  </CardContent>
                  <Divider />
                  <CardContent>
                    {sleepVitalsOptions && (
                      <SleepHistoryVitalsChart
                        onChartMouseOver={onChartMouseOver}
                        onChartClick={onChartClick}
                        plotData={sleepVitalsOptions}
                        enableHeartRateMean={enableHeartRateMean || false}
                        selectedDates={selectedDates}
                        sessionIDsAsString={sessionIDsAsString}
                      />
                    )}
                  </CardContent>
                </div>
              ) : (
                <></>
              )}
            </Card>
          </>
        )}

        {currentSession && (
          <SessionDetailedView
            session={currentSession}
            onNextPrev={onNextPrev}
          />
        )}

        {viewMode === "last_night" && isNoSleepData() && (
          <LabelStyled sx={{ padding: 20, fontSize: 18 }}>
            {t("no_sleep_data_to_show")}
          </LabelStyled>
        )}
      </SleepHistoryContext.Provider>
    </>
  );
}
export default SleepHistoryScreen;