import * as React from "react";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material/styles";

import { SubTitleStyled } from "styled/CommonStyled";
import { WrapperStyled } from "styled/ContainerStylesStyled";

export const StyledEditIcon = styled(EditIcon)(
  ({ children }: { children?: React.ReactNode }) => {
    const theme = useTheme();
    return {
      color: theme.palette.main[60],
      cursor: "pointer",
      fontSize: 21
    };
  }
);

function SubTitle({
  showEdit,
  text,
  cancelText,
  onChange,
  defaultVal = false
}: {
  showEdit?: boolean;
  text: string;
  cancelText?: string;
  onChange?: (val: boolean) => void;
  defaultVal?: boolean;
}) {
  return (
    <WrapperStyled>
      <SubTitleStyled>
        <div style={{ textTransform: "uppercase" }}> {text} </div>
        {/* {showEdit ? (
          <Button onClick={onClickHandler} style={{ padding: 0, minWidth: 32 }}>
            {defaultVal ? (
              <CancelButton>{cancelText || "cancel"}</CancelButton>
            ) : (
              <StyledEditIcon />
            )}
          </Button>
        ) : (
          ""
        )} */}
      </SubTitleStyled>
    </WrapperStyled>
  );
}

export default SubTitle;
