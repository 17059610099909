import { IRole } from "interfaces/user.interface";

export const UserAttributesTypes = {
  device: "device",
  viewer: "viewer",
  default: "default"
};

export const UserPrivileges = {
  view_dashbaord: "view_dashbaord",
  view_monitor: "view_monitor"
};

export enum ZoneUserRolesEnum {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  MANAGER_LIMITED = "MANAGER_LIMITED",
  DATA_READER = "DATA_READER",
  DATA_READER_LIMITED = "DATA_READER_LIMITED"
}

export type AdministrativeTypes =
  | ZoneUserRolesEnum.OWNER
  | ZoneUserRolesEnum.ADMIN
  | ZoneUserRolesEnum.MANAGER
  | ZoneUserRolesEnum.MANAGER_LIMITED;
export type OperativeTypes =
  | ZoneUserRolesEnum.DATA_READER
  | ZoneUserRolesEnum.DATA_READER_LIMITED;

export const operativeRoles: { [key in OperativeTypes]: number } = {
  DATA_READER_LIMITED: 1,
  DATA_READER: 2
};

export const administrativeRoles: { [key in AdministrativeTypes]: number } = {
  MANAGER_LIMITED: 3,
  MANAGER: 4,
  ADMIN: 5,
  OWNER: 6
};

// operative roles
export const getOperativeRoles = (t: any): IRole[] => {
  return [
    {
      name: t("role_date_reader_limited"),
      value: ZoneUserRolesEnum.DATA_READER_LIMITED,
      activeChipTheme: "teal",
      tooltip: t("role_date_reader_limited_info"),
      order: 1,
      type: "operative"
    },
    {
      name: t("role_date_reader"),
      value: ZoneUserRolesEnum.DATA_READER,
      activeChipTheme: "blue",
      tooltip: t("role_date_reader_info"),
      order: 2,
      type: "operative"
    }
  ];
};

// Administrative User Roles
export const getAdministrativeUserRoles = (t: any): IRole[] => {
  const ZoneUserRoles: IRole[] = [
    {
      name: t("role_manager_limited"),
      value: ZoneUserRolesEnum.MANAGER_LIMITED,
      activeChipTheme: "purple",
      tooltip: t("role_manager_limited_info"),
      order: 3,
      type: "administrative"
    },
    {
      name: t("role_manager"),
      value: ZoneUserRolesEnum.MANAGER,
      activeChipTheme: "yellow",
      tooltip: t("role_manager_info"),
      order: 4,
      type: "administrative"
    },
    {
      name: t("role_admin"),
      value: ZoneUserRolesEnum.ADMIN,
      activeChipTheme: "orange",
      tooltip: t("role_admin_info"),
      order: 5,
      type: "administrative"
    },
    {
      name: t("role_owner"),
      value: ZoneUserRolesEnum.OWNER,
      activeChipTheme: "pink",
      tooltip: t("role_owner_info"),
      order: 6,
      type: "administrative"
    }
  ];
  return ZoneUserRoles;
};

export const getZoneUserRolesAsentity = (t: any) => {
  const entity: {
    [key: string]: IRole;
  } = {};
  getOperativeRoles(t)
    .concat(getAdministrativeUserRoles(t))
    .forEach((role) => {
      entity[role.value] = role;
    });
  return entity;
};
