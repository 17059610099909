import { IColor } from "interfaces/color.interface";

const Green: Omit<
  IColor,
  0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
> = {
  1: "#F2F9F7",
  5: "#D2ECE3",
  10: "#ABDCCC",
  20: "#6ABFA3",
  30: "#36A17D",
  40: "#0F845D",
  50: "#006644"
};

export default Green;
