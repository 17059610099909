import React from "react";

import { FlexOne } from "./SplitPanelStyledComponents";

export const SplitPaneRight = ({
  children
}: {
  children: React.ReactChild | React.ReactChild[];
}) => {
  return (
    <FlexOne style={{ overflowX: "hidden", zIndex: 99 }} bg="light">
      {children}
    </FlexOne>
  );
};

export default SplitPaneRight;
