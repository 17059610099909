import { applyMiddleware, legacy_createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction.js";
import logger from "redux-logger";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";

import rootReducer from "./reducers/index.js";

export const initialState = {
  auth: {
    user: null,
    fetching: false,
    error: false
  },
  users: {
    users: null,
    fetching: false,
    error: false
  },
  customization: {
    styleType: "light"
  },
  devices: []
};

const store = legacy_createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, promise, logger.default))
);

// const persistor = persistStore(store);

export default store;
