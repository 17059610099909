import { Button, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { styled } from "@mui/system";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import * as LOCAL_STORAGE from "utils/localStorage";
import {
  AUTHORIZE_API,
  AUTH_BASE,
  REDIRECT_URL
} from "constants/auth.constants";
import { ITenents } from "interfaces/auth.interface";
import {
  enhanceAuthorizeUrl,
  generateCodeChallenge,
  generateState
} from "utils/auth/auth.util";
import WIPMessage from "components/WIPMessage/WIPMessage";

interface ILogin {
  client_id: string;
}

const LoginWrapper = styled("div")(
  ({ children }: {children: React.ReactNode }) => {
    return {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "100vh"
    };
  }
);

// grid-template-columns: repeat( 12, minmax(250px, 1fr) );
const TenentsGrid = styled("div")(
  ({ children }: {children: React.ReactNode }) => {
    return {
      display: "grid",
      gridTemplateColumns: `repeat(auto-fit,minmax(250px,1fr))`,
      gridColumnGap: "16px",
      rowGap: "16px",
      padding: "0 20px"
    };
  }
);

const TenentBox = styled(Button)(
  ({ children }: {children: React.ReactNode }) => {
    const theme = useTheme();
    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 128,
      fontSize: 14,
      textOverflow: "ellipsis",
      wordBreak: "break-word",
      textAlign: "center",
      textTransform: "none",
      border: `.5px solid ${theme.palette.neutral[10]}`,
      fontWeight: "500",
      color: theme.palette.neutral[80],
      letterSpacing: "0.1px",
      "&:hover": {
        border: `0.5px solid ${theme.palette.main[70]}`,
        background: theme.palette.main[4]
      }
    };
  }
);

const StyledP = styled("p")(() => {
  const theme = useTheme();
  return {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 32,
    lineHeight: "40px",
    color: theme.palette.neutral[80],
    margin: 0,
    marginBottom: 12,
    textAlign: "center"
  };
});

const SubTitle = styled("p")(() => {
  const theme = useTheme();
  return {
    fontWeight: 400,
    fontSize: 16,
    marginBottom: 32,
    margin: 0,
    letterSpacing: 0.5,
    lineHeight: "24px",
    color: theme.palette.neutral[50],
    textAlign: "center"
  };
});


function LoginScreen() {
  const { t } = useTranslation();
  const location = useLocation();

  const { tenants, email } = location.state as {
    tenants: ITenents[];
    email: string;
  };
  const [userTenents, setUserTenents] = useState<ITenents[]>();

  // set local storage
  localStorage.setItem("LAST_USED_EMAIL", email);

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleSubmit,
    control,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors }
  } = useForm<ILogin>({
    defaultValues: {
      client_id: ""
    },
    reValidateMode: "onChange",
    mode: "onChange"
  });

  const onClickTenent = useCallback((clientId: string) => {
    if (clientId !== "0") {
      const generatedState = generateState();
      const code_verifier = generateState();
      generateCodeChallenge(code_verifier).then((code_challenge: string) => {
        sessionStorage.setItem("state", generatedState);
        sessionStorage.setItem("code_verifier", code_verifier);
        sessionStorage.setItem("client_id", clientId);

        // store client id in local storage
        LOCAL_STORAGE.setClientId(clientId);
        // set client id in local storage
        const url = enhanceAuthorizeUrl({
          clientId,
          state: generatedState,
          code_challenge,
          authorizeUrl: AUTH_BASE + AUTHORIZE_API,
          redirectUri: REDIRECT_URL,
          loginHint: email || ""
        });
        window.open(url, "_self");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tenants && tenants?.length === 1) {
      onClickTenent(tenants[0].client_id);
    }
    if (tenants && tenants?.length > 1) {
      setUserTenents(tenants);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants]);


  if (!userTenents) {
    return <div />;
  }
  return (
    <LoginWrapper>
      <WIPMessage />
      <Grid container justifyContent="center" columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12} md={12}>
          <StyledP>{t("select_a_tenant")}</StyledP>
          <SubTitle>{t("choose_a_workspace")}</SubTitle>
          <Controller
            name="client_id"
            control={control}
            render={({ field, fieldState, formState }) => (
              <TenentsGrid>
                {userTenents?.map((tenent: ITenents) => {
                  return (
                    <TenentBox
                      onClick={() => onClickTenent(tenent.client_id)}
                      key={tenent.tenant_id}
                    >
                      {" "}
                      {tenent.tenant_name}
                    </TenentBox>
                  );
                })}
              </TenentsGrid>
            )}
          />
        </Grid>
      </Grid>
    </LoginWrapper>
  );
}
export default LoginScreen;
