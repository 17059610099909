import { createContext } from "react";

export enum ZonePermissionEnum {
  "zone.list" = "zone.list",
  "zone.create" = "zone.create",
  "zone.read" = "zone.read",
  "zone.update" = "zone.update",
  "zone.delete" = "zone.delete",
  "zone.live_monitor" = "zone.live_monitor",
  "zone.generate_report" = "zone.generate_report",
  "zone.sleep_history" = "zone.sleep_history"
}
export enum SubjectPermissionEnum {
  "subject.create" = "subject.create",
  "subject.read" = "subject.read",
  "subject.update" = "subject.update",
  "subject.delete" = "subject.delete",
  "subject.list" = "subject.list"
}
export enum DevicePermissionEnum {
  "device.list" = "device.list",
  "device.create" = "device.create",
  "device.read" = "device.read",
  "device.update" = "device.update",
  "device.delete" = "device.delete",
  "device.assign_subject" = "device.assign_subject",
  "device.update_user_settings" = "device.update_user_settings"
}
export enum UserPermissionEnum {
  "user.list" = "user.list",
  "user.create" = "user.create",
  "user.read" = "user.read",
  "user.update" = "user.update",
  "user.delete" = "user.delete",
  "user.manage_role_assignment:OWNER" = "user.manage_role_assignment:OWNER",
  "user.manage_role_assignment:ADMIN" = "user.manage_role_assignment:ADMIN",
  "user.manage_role_assignment:MANAGER" = "user.manage_role_assignment:MANAGER",
  "user.manage_role_assignment:MANAGER_LIMITED" = "user.manage_role_assignment:MANAGER_LIMITED",
  "user.manage_role_assignment:DATA_READER" = "user.manage_role_assignment:DATA_READER",
  "user.manage_role_assignment:DATA_READER_LIMITED" = "user.manage_role_assignment:DATA_READER_LIMITED"
}

export enum NotificationHandlerPermissionEnum {
  "notification_handler.list" = "notification_handler.list",
  "notification_handler.create" = "notification_handler.create",
  "notification_handler.read" = "notification_handler.read",
  "notification_handler.update" = "notification_handler.update",
  "notification_handler.delete" = "notification_handler.delete"
}
export enum RulesPermissionEnum {
  "rule.list" = "rule.list",
  "rule.create" = "rule.create",
  "rule.read" = "rule.read",
  "rule.update" = "rule.update",
  "rule.delete" = "rule.delete"
}

export type AuthorizationPermissionsType =
  | UserPermissionEnum
  | DevicePermissionEnum
  | SubjectPermissionEnum
  | ZonePermissionEnum
  | NotificationHandlerPermissionEnum
  | RulesPermissionEnum;
export type AuthorizationContextType = {
  permissions: AuthorizationPermissionsType[];
};

export const AuthorizationContext = createContext<AuthorizationContextType>({
  permissions: []
});
