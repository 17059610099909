import { Box, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useTheme } from "@mui/material/styles";

interface Props {
  handleTabChange: (
    event: React.SyntheticEvent<Element, Event>,
    newTabIndex: number
  ) => void;
  tabIndex: number;
  tabs: { id: string | number; title: string }[];
}

export const TabStyled = styled(Tab)(() => {
  const theme = useTheme(); 
  return {
    fontSize: 16,
    paddingTop: "25px",
    color: theme.palette.neutral[50],
    textTransform: "none",
    "&.Mui-selected": {
      color: theme.palette.main[70]
    },
    "&:hover": {
      background: theme.palette.main[2]
    }
  };
});

export const TabsStyled = styled(Tabs)(() => {
  const theme = useTheme(); 
  return {
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.main[70],
      heigth: "2px"
    }
  };
});

export const TabsWrapperStyled = styled(Box)(() => {
  const theme = useTheme(); 
  return {
    paddingLeft: "20px",
    float: "left",
    marginTop: "0",
    borderBottom: `1px solid ${theme.palette.neutral[4]}`,
    width: "100%"
  };
});

function SMTabs({ handleTabChange, tabIndex, tabs }: Props) {
  return (
    <TabsWrapperStyled>
      <TabsStyled value={tabIndex} onChange={handleTabChange}>
        {tabs.map((item: { id: string | number; title: string }) => {
          return <TabStyled label={item.title} key={item.id} />;
        })}
      </TabsStyled>
    </TabsWrapperStyled>
  );
}
export default SMTabs;
