import {
  Autocomplete,
  TextField
} from "@mui/material";
import { Box, styled } from "@mui/system";
import React from "react";

import { StyledP } from "styled/StylesStyled";

const TextFieldStyled = styled(TextField)(() => {
  return {
    fieldset: {
      borderWidth: "1px"
    },
    [`& > .MuiAutocomplete-inputRoot`]: {
      padding: 0
    }
  };
});

function APISelector({
  defaultApi,
  urls,
  label,
  onUpdate
}: {
  defaultApi: string;
  urls: string[];
  label: string;
  onUpdate: (val: string) => void;
}) {
  const onChange = (api: string) => {
    if (!api) {
      onUpdate("");
    } else {
      onUpdate(api);
    }
  };
  if (!urls.length) {
    return <></>;
  }

  return (
    <Box
      sx={{
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        gap: "10px"
      }}
    >
      <StyledP>{label}</StyledP>
      <Box sx={{ minWidth: 250 }}>
        <Autocomplete
          sx={{ padding: 0 }}
          autoSelect
          freeSolo
          options={urls}
          defaultValue={defaultApi}
          onChange={(event: any, value: any) => {
            onChange(value);
          }}
          renderInput={(params) => <TextFieldStyled {...params} />}
        />
      </Box>
    </Box>
  );
}
export default APISelector;
