import { ILog } from "interfaces/logs.interface";
import { LOGS_PER_PAGE } from "constants/logs.constants";

import * as types from "../../actionTypes/notification-logs/notificationlogsActionTypes";

export default function notificationLogReducer(
  state: { logs: ILog[] | undefined; allLogsLoaded: boolean } = {
    logs: [],
    allLogsLoaded: false
  },
  action: types.logsActionTypes
) {
  switch (action.type) {
    case types.FETCH_NOTIFICATION_LOGS_SUCCESS:
      return {
        logs: state.logs?.concat(action.payload),
        allLogsLoaded:
          action.payload?.length === 0 ||
          action.payload?.length < LOGS_PER_PAGE - 1
      };

    case types.RESET_NOTIFICATION_LOGS:
      return {
        logs: [],
        allLogsLoaded: false
      };
    default:
      return state;
  }
}
