import { TextField, TextFieldProps } from "@mui/material";
import { SystemCssProperties, styled } from "@mui/system";
import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";

export const TextFieldWrapper = styled("div")(() => {
  return {
    position: "relative"
  };
});

export const TextFieldStyled = styled(TextField)(
  ({ width }: { width?: string | number }) => {
    const theme = useTheme(); 
    return {
      width: "100%",
      minWidth: width || 150,
      height: 48,
      border: `1px solid ${theme.palette.neutral[5]}`,
      borderRadius: 4,
      "& .Mui-disabled": {
        cursor: "not-allowed",
        background: theme.palette.neutral[5],
        WebkitTextFillColor: theme.palette.neutral[80],
        borderRadius: "3px"
      },
      "& input": {
        padding: "12px 8px",
        fontSize: 16,
        color: theme.palette.neutral[70]
        // "&.Mui-disabled": {
        //   background: theme.palette.neutral[5],
        //   WebkitTextFillColor: theme.palette.neutral[80]
        // }
      }
    };
  }
);

type IProps = Omit<
  TextFieldProps,
  "variant" | "autoComplete" | "InputProps"
> & {
  onUpdate?: (val: string) => void;
  endAdornment?: JSX.Element;
  // onBlurHandler?: (val: string | undefined) => void;
  width?: number | string;
};

/**
 * Customized text field with no form
 * @params
 * @function onUpdate: called on every update.
 * @function  onBlur: called on evry blur.
 * @param  width: set width of this field
 */
function SMTextField({
  onUpdate,
  // onBlurHandler,
  width,
  endAdornment,
  ...textFieldProps
}: IProps): JSX.Element {
  const [value, setValue] = React.useState<string>(
    (textFieldProps.defaultValue as string) ?? ""
  );
  useEffect(() => {
    setValue((textFieldProps.defaultValue as string) || "");
  }, [textFieldProps.defaultValue]);

  useEffect(() => {
    if (onUpdate) {
      onUpdate(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const { placeholder, disabled = false, sx } = textFieldProps;
  return (
    <TextFieldWrapper>
      <TextFieldStyled
        sx={sx as SystemCssProperties}
        width={width}
        value={value}
        variant="standard"
        autoComplete="off"
        placeholder={placeholder || ""}
        onChange={
          textFieldProps.onChange
            ? textFieldProps.onChange
            : (e: React.ChangeEvent<HTMLInputElement>) =>
                setValue(e.target.value)
        }
        disabled={disabled}
        InputProps={{
          disableUnderline: true,
          endAdornment: endAdornment || null
        }}
      />
    </TextFieldWrapper>
  );
}
export default SMTextField;
