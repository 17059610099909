import Cookies from "js-cookie";

import { IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";
import { usersServicesV2 } from "services/administration/userServiceV2";

import * as types from "../../actionTypes/administration/userActionTypesNew";
import { getAuthUser } from "../auth/authActions";

/* ************************************************** */
/*                      Actions                       */
/* ************************************************** */

/**
 * Redux action to fetch all zones
 *
 * @return type - redux action type |
 */
export const getAllUserAction = (): types.fetchUsersActionType => {
  return {
    type: types.FETCH_USER
  };
};

/**
 * Redux action to fetch all subjects
 *
 * @return type - redux action type |
 *         payload - subjects
 */
export const getAllUserActionSuccess = (
  data: IUser[]
): types.fetchUsersuccessActionType => {
  return {
    type: types.FETCH_USER_SUCCESS,
    payload: data
  };
};

/**
 * Create  User ation
 *
 * @return type - redux action type |
 *
 */
export const createUserAction = (): types.createUserActionType => {
  return {
    type: types.CREATE_USER
  };
};

/**
 * Create  User success action
 *
 * @return type - redux action type |
 *         payload - param
 */
export const createUserSuccesAction = (
  data: IUser
): types.createUseruccessActionType => {
  return {
    type: types.CREATE_USER_SUCCESS,
    payload: data
  };
};

/**
 * update User actionType
 * @return type - redux action type
 *
 */
export const updateUserAction = (): types.updateUserActionType => {
  return {
    type: types.UPDATE_USER
  };
};

/**
 * update User successAction
 * @return type - redux action type
 *         response - Created User
 */
export const updateUserSuccessAction = (
  payload: IUser
): types.updateUserSuccessActionType => {
  return {
    type: types.UPDATE_USER_SUCCESS,
    payload
  };
};

/**
 * update User actionType
 * @return type - redux action type
 *
 */
export const updateUserZoneAction = (): types.updateUserZoneActionType => {
  return {
    type: types.UPDATE_USER_ZONES
  };
};

/**
 * update User successAction
 * @return type - redux action type
 *         response - Created User
 */
export const updateUserZoneSuccessAction = (
  payload: IUser
): types.updateUserZoneSuccessActionType => {
  return {
    type: types.UPDATE_USER_ZONES_SUCCESS,
    payload
  };
};

/**
 * fetch user by id action
 * @return type - redux action type
 *
 */
export const fetchUserByIdAction = (): types.fetchUserByIDActionType => {
  return {
    type: types.FETCH_USER_BY_ID
  };
};

/**
 * fetch user by id
 * @return type - redux action type
 *         response - Created User
 */
export const fetchUserByIDSuccessAction = (
  payload: IUser
): types.fetchUserByIDSuccessActionType => {
  return {
    type: types.FETCH_USER_BY_ID_SUCCESS,
    payload
  };
};

/**
 * delete User actionType
 * @return type - redux action type
 *
 */
export const deleteUserAction = (): types.deleteUserActionType => {
  return {
    type: types.DELETE_USER
  };
};

/**
 * update User successAction
 * @return type - redux action type
 *         response - Created User
 */
export const deleteUserSuccessAction = (
  id: string
): types.deleteUserSuccessActionType => {
  return {
    type: types.DELETE_USER_SUCCESS,
    payload: id
  };
};

/* ************************************************** */
/*                      Services                      */
/* ************************************************** */

/**
 *  Function to fetch all the subjects
 *
 * @param none
 * @return all the subjects in the system
 */
export function getAllUsersV2(path?: string) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    // dispatch(getAllUserAction);
    usersServicesV2
      .fetchUsers(path)
      .then((response: { object: "list"; data: IUser[] }) => {
        if (response?.data) {
          response.data.sort((a: IUser, b: IUser) => {
            return Intl.Collator("nb", { sensitivity: "base" }).compare(
              `${a.first_name} ${a.last_name}`,
              `${b.first_name} ${b.last_name}`
            );
          });
          dispatch(getAllUserActionSuccess(response.data));
        }
      });
  };
}

/**
 *  Function to create User
 *
 * @param name - data of type IUser
 * @return created User
 */
export function createUser(user: IUser, callback?: (item: IUser) => void) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(createUserAction());
    const params: any = {
      ...user
    };
    usersServicesV2.createUser(params).then((response: { data: IUser }) => {
      const selectedZones = user?.zones?.data || [];
      if (response?.data) {
        dispatch(
          attachZonesToUser({
            data: response?.data,
            toAdd: selectedZones,
            callback
          })
        );
      }
    });
  };
}

/**
 *  functioan to attach zones to user
 *
 * @param name - data of type IUser
 * @return created User
 */
export function attachZonesToUser({
  data,
  toAdd,
  toDelete,
  callback,
  update
}: {
  data: IUser;
  toAdd?: IZone[];
  toDelete?: IZone[];
  callback?: (item: IUser) => void;
  update?: boolean;
}) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    const toAddIds: string[] = toAdd?.map((item) => item.id) || [];
    const toDeleteIds: string[] = toDelete?.map((item) => item.id) || [];
    dispatch(updateUserZoneAction());
    usersServicesV2
      .attachMultipleZoneToUser({
        userId: data.id,
        toAdd: toAddIds,
        toDelete: toDeleteIds
      })
      .then((rejected: any) => {
        let updatedZones = !update ? toAdd : data?.zones?.data;
        if (rejected && rejected.length) {
          const itemError: string[] = [];

          rejected.forEach((item: any) => {
            if (item.value?.config?.zone_id) {
              itemError.push(item.value?.config?.zone_id);
            }
          });

          // handle 404 error
          rejected.forEach((item: any) => {
            if (item.reason?.config?.data) {
              try {
                const configData = JSON.parse(item.reason?.config?.data);
                if (configData?.zone_id) {
                  itemError.push(configData?.zone_id);
                }
              } catch (err: any) {
                console.log("error happened while updating zone");
              }
            }
          });

          const addError: string[] = (
            toAdd?.filter((item: IZone) => itemError.indexOf(item.id) !== -1) ||
            []
          ).map((elem) => elem.id);

          const deleteError: IZone[] =
            toDelete?.filter(
              (item: IZone) => itemError.indexOf(item.id) !== -1
            ) || [];

          if (addError && addError.length) {
            updatedZones = updatedZones?.filter((zone: IZone) => {
              return addError.indexOf(zone.id) === -1;
            });
          }
          if (deleteError && deleteError.length) {
            updatedZones = updatedZones?.concat(deleteError) || deleteError;
          }
        }
        const updatedUser = {
          ...data,
          zones: {
            object: "list",
            data: updatedZones || []
          }
        };

        if (!update) {
          dispatch(createUserSuccesAction(updatedUser));
        } else {
          dispatch(updateUserSuccessAction(updatedUser));
        }

        if (callback) {
          callback(updatedUser);
        }
      });
  };
}

/**
 *  Function to create User
 *
 * @param name - data of type IUser
 * @return created User
 */
export function updateUser(data: IUser, errorCallback?: () => void) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(updateUserAction());
    usersServicesV2.updateUser(data).then((response: { data: IUser }) => {
      if (response?.data) {
        const updatedUser = { ...response.data, zones: data.zones };
        dispatch(updateUserSuccessAction(updatedUser));
        const loggedUser = Cookies.get("logged_user_email");
        if (updatedUser.email === loggedUser) {
          dispatch(getAuthUser());
        }
      } else if (errorCallback) {
        errorCallback();
      }
    });
  };
}

/**
 *  Function to create User
 *
 * @param name - data of type IUser
 * @return created User
 */
export function updateUserZone({
  data,
  toAdd,
  toDelete,
  errorCallback
}: {
  data: IUser;
  toAdd: IZone[];
  toDelete: IZone[];
  errorCallback?: () => void;
}) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(
      attachZonesToUser({
        data,
        toAdd,
        toDelete,
        update: true,
        callback: errorCallback
      })
    );
  };
}

/**
 *  Function fetch a user by id
 *
 * @param name - data of type IUser
 * @return created User
 */
export function fetchUserByID(id: string) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(fetchUserByIdAction());
    usersServicesV2.fetchUserByID(id).then((response: { data: IUser }) => {
      if (response?.data) {
        dispatch(fetchUserByIDSuccessAction(response.data));
      }
    });
  };
}

/**
 *  Function to add multiple group to a user
 *
 * @param userId,groupIDs
 * @return updated user
 */
export function updateMultipleGroupsInUser({
  userId,
  toAdd,
  toDelete,
  callback
}: {
  userId: string;
  toAdd: string[];
  toDelete: string[];
  callback: () => void;
}) {
  // eslint-disable-next-line func-names
  // return function (dispatch: (arg0: any) => void) {
  //   dispatch(updateUserAction());
  //   usersServicesV2
  //     .updateMultipleGroupsInUser({ userId, toAdd, toDelete })
  //     .then((response: any) => {
  //       if (callback) {
  //         callback();
  //       }
  //       dispatch(fetchUserByID(userId));
  //     });
  // };
}

/**
 *  Function to delete User
 *
 * @param name - data of type IUser
 * @return none
 */
export function deleteUser(data: IUser, callback: () => void) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(deleteUserAction());
    usersServicesV2.deleteUser(data).then((response: any) => {
      if (response !== "error") {
        dispatch(deleteUserSuccessAction(data.id));
        // callback to show the feedback
        if (callback) {
          callback();
        }
      }
    });
  };
}
