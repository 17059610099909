import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import React from "react";

import { IZone } from "interfaces/zone.interface";
import { updatePropertyById } from "utils/zone.util";

import TreeBranch from "./TreeBranch";

const TreeViewWrapper = styled("div")(
  ({ children }: { children: React.ReactNode }) => {
    return {
      padding: "0px 12px 16px",
      overflowY: "auto",
      width: "100vw",
      // height: "calc(100vh - 60px)"
      height: "100%"
    };
  }
);
const TreeViewComponent = ({
  data,
  handleChange,
  setZones,
  activeZone,
  authorizedZones
}: {
  data: IZone[];
  setZones: React.Dispatch<React.SetStateAction<IZone[]>>;
  handleChange: (e: React.MouseEvent<HTMLElement>, clicked: IZone) => void;
  activeZone: string;
  authorizedZones: string[];
}) => {
  const { t } = useTranslation();
  const onCollapse = (item: IZone, collapse: boolean) => {
    const current = updatePropertyById(item.id, data[0], "collapsed", collapse);
    setZones([current]);
  };

  const checkIfUseHasAccess = (node: IZone) => {
    const nodePaths = node.path.split(".");
    for (let i = 0; i < authorizedZones.length; i += 1) {
      if (nodePaths.includes(authorizedZones[i])) {
        return true;
      }
    }
    return false;
  };

  const isDisableNode = (node: IZone) => {
    return !checkIfUseHasAccess(node);
  };
  return (
    <TreeViewWrapper>
      {data.map((item: IZone) => (
        <TreeBranch
          key={item.id}
          item={item}
          level={0}
          handleChange={handleChange}
          levelPadding={32}
          activeZone={activeZone}
          onCollapse={onCollapse}
          isDisableNode={isDisableNode}
          disabled_tooltip={t("no_permission_on_this_zone")}
        />
      ))}
    </TreeViewWrapper>
  );
};

export default TreeViewComponent;
