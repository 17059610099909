import Cookies from "js-cookie";
import axios from "axios";

export function jwtInterceptor() {
  axios.interceptors.request.use((request) => {
    const token = Cookies.get("access_token");
    const userId = Cookies.get("userId");
    if (token && userId) {
      request.headers.common.Authorization = `Bearer ${token}`;
    }
    return request;
  });
}
