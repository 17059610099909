import { Checkbox, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";

import * as LOCAL_STORAGE from "utils/localStorage";
import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import {
  FormControlLabelStyled,
  SMTableWrapperBox,
  TableTitle
} from "styled/CommonStyled";
import { IDevice } from "interfaces/device.interface";
import { ISubject } from "interfaces/subject.interface";
import { getAllDevicesNew } from "store/actions/administration/deviceActions";
import { getAllSubjects } from "store/actions/administration/subjectActions";
import SMGoogleAnalytic from "components/GoogleAnalytic/GoogleAnalytic";
import SMNoDataBox from "components/SMDynamicTable/SMNoDataBox";
import UseAdminContext from "hooks/UseAdminContext";

import { DeviceContext } from "./DeviceContext";
import DeviceList from "./DeviceList";

export const DevicesLandingPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [subjectEntity, setSubjectEntity] = React.useState<{
    [key: string]: ISubject;
  }>({});

  const [filteredDevices, setFilteredDevices] = React.useState<IDevice[]>();

  const { selectedZone, setCurrentPath, zoneEntity } = UseAdminContext();

  const devicesState: any = useSelector((state: any) => {
    return state.devicesState || {};
  });
  const subjectState: any = useSelector((state: any) => {
    return state.subjectReducer || {};
  });

  const adminSettings = LOCAL_STORAGE.getAdminSettings() || {};
  const [showChildren, setShowChildren] = React.useState<boolean>(
    adminSettings.includeDeviceChildren || false
  );

  // set subject entity
  useEffect(() => {
    // reset breadcrumb
    if (setCurrentPath) {
      setCurrentPath(undefined);
    }
    if (zoneEntity && selectedZone && zoneEntity[selectedZone]) {
      dispatch(getAllSubjects(`${zoneEntity[selectedZone].path}.*`));
      dispatch(getAllDevicesNew(`${zoneEntity[selectedZone].path}.*`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZone, zoneEntity]);

  useEffect(() => {
    if (devicesState.devices) {
      const filtered = devicesState.devices.filter((item: IDevice) => {
        if (showChildren) {
          const paths = item.path?.split(".") || [];
          return paths.indexOf(selectedZone) !== -1;
        }
        return item.zone_id === selectedZone;
      });
      setFilteredDevices(filtered);
    }
  }, [devicesState, selectedZone, showChildren]);

  useEffect(() => {
    const entity: { [key: string]: ISubject } = {};
    // set subject entity
    if (subjectState.subjects) {
      subjectState.subjects.forEach((item: ISubject, index: number) => {
        entity[item.id] = item;
      });
      setSubjectEntity(entity);
    }
  }, [subjectState]);

  if (!filteredDevices || !selectedZone) {
    return <div />;
  }

  const getCheckBoxComponent = (): JSX.Element => {
    return (
      <Grid container justifyContent="flex-end" sx={{ marginBottom: "8px" }}>
        <FormControlLabelStyled
          checked
          onChange={(
            e: React.SyntheticEvent<Element, Event>,
            checked: boolean
          ) => {
            const settings = LOCAL_STORAGE.getAdminSettings();
            LOCAL_STORAGE.setAdminSettings({
              ...settings,
              includeDeviceChildren: checked
            });
            setShowChildren(checked);
          }}
          control={<Checkbox checked={showChildren} />}
          label={t("include_devices_in_children_zones") as string}
        />
      </Grid>
    );
  };

  if (!filteredDevices?.length) {
    return (
      <BoxPadding pad="0px 24px 24px 4px">
        {getCheckBoxComponent()}
        <SMNoDataBox
          message={t("there_are_no_devices_in_this_zone")}
          title={(zoneEntity && zoneEntity[selectedZone]?.name) || ""}
          bodyBg="white"
        />
      </BoxPadding>
    );
  }

  // generate table data
  return (
    <>
      {/* Add google analytic */}
      <SMGoogleAnalytic title="Devices Screen" />

      <DeviceContext.Provider value={{}}>
        <BoxPadding pad="0px 24px 24px 4px">
          {getCheckBoxComponent()}
          <SMTableWrapperBox>
            <TableTitle
              bg="light"
              border="light"
              sx={{
                padding: "16.5px 20px",
                borderRadius: "8px 8px 0px 0px"
              }}
            >
              {(zoneEntity && zoneEntity[selectedZone]?.name) || ""}
            </TableTitle>
            <div style={{ padding: 1 }}>
              {zoneEntity && (
                <DeviceList
                  devices={filteredDevices}
                  zoneEntity={zoneEntity}
                  subjectEntity={subjectEntity}
                />
              )}

              {/* <SMDynamicTable<Data>
                headCells={getHeadCells()}
                rows={getRows(filteredDevices)}
                onRowClick={handleClick}
                renderComponentForValue={renderComponentForValue}
                stylesPropHead={stylesPropHead}
                defaultOrderBy="name"
                localSettingKey={DEVICE_SORT_CONFIG}
              /> */}
            </div>
          </SMTableWrapperBox>
        </BoxPadding>
      </DeviceContext.Provider>
    </>
  );
};

export default DevicesLandingPage;
