import React, { useEffect, useRef, useState, useMemo } from "react";

import * as LOCAL_STORAGE from "utils/localStorage";

import { SplitPanelContainer } from "./SplitPanelStyledComponents";
import SplitPaneContext from "./SplitPanelContext";

// sidebar width
const SIDE_BAR_WIDTH: string = "side_bar_width";

export const SplitPanel = ({
  children,
  flexDirection
}: {
  children: React.ReactChild | React.ReactChild[];
  flexDirection: "column" | "row";
}) => {
  const memoizedSettings = useMemo(() => LOCAL_STORAGE.getAdminSettings() || {}, []);

  const prevUsedClientWidth = memoizedSettings[SIDE_BAR_WIDTH] ?? 256;
  const [clientHeight, setClientHeight] = useState<number | null>(null);
  const [clientWidth, setClientWidth] = useState<number | null>(
    prevUsedClientWidth
  );
  const yDividerPos = useRef<number | null>(null);
  const xDividerPos = useRef<number | null>(null);

  const onMouseHoldDown = (e: any) => {
    yDividerPos.current = e.clientY || e?.touches[0]?.clientY;
    xDividerPos.current = e.clientX || e?.touches[0]?.clientX;
  };

  // A timer function setTimeout is called here, which waits for one second (1000 milliseconds) before performing some action.
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      LOCAL_STORAGE.setAdminSettings({
        ...memoizedSettings,
        [SIDE_BAR_WIDTH]: clientWidth
      });
    }, 300);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientWidth]);

  const onMouseHoldUp = () => {
    yDividerPos.current = null;
    xDividerPos.current = null;
  };

  const onMouseHoldMove = (e: any) => {
    if (!yDividerPos?.current && !xDividerPos.current) {
      return;
    }

    if (clientHeight) {
      setClientHeight(
        clientHeight +
          (e.clientY || e?.touches[0]?.clientY) -
          (yDividerPos?.current || 0)
      );
    }

    if (clientWidth) {
      setClientWidth(
        clientWidth +
          (e.clientX || e?.touches[0]?.clientX) -
          (xDividerPos?.current || 0)
      );
    }
    yDividerPos.current = e.clientY || e.touches[0].clientY;
    xDividerPos.current = e.clientX || e.touches[0].clientX;
  };

  useEffect(() => {
    document.addEventListener("mouseup", onMouseHoldUp, { capture: true });
    document.addEventListener("touchend", onMouseHoldUp, { capture: true });

    document.addEventListener("mousemove", onMouseHoldMove, { capture: true });
    document.addEventListener("touchmove", onMouseHoldMove, { capture: true });

    return () => {
      document.removeEventListener("mouseup", onMouseHoldUp, { capture: true });
      document.removeEventListener("mousemove", onMouseHoldMove, {
        capture: true
      });

      document.removeEventListener("touchend", onMouseHoldUp, {
        capture: true
      });
      document.removeEventListener("touchmove", onMouseHoldMove, {
        capture: true
      });
    };
  });

  return (
    <SplitPanelContainer style={{ flexDirection }}>
      <SplitPaneContext.Provider
        value={{
          clientHeight,
          setClientHeight,
          clientWidth,
          setClientWidth,
          onMouseHoldDown
        }}
      >
        {children}
      </SplitPaneContext.Provider>
    </SplitPanelContainer>
  );
};
