import moment from "moment";

import { ISession } from "interfaces/sleephistory.interface";
import { getMinuteString, secondsToTime } from "utils/date.util";
import i18n from "i18n/config";

export const getSleepStatisticsPlotData = (session: ISession) => {
  if (!session) {
    return null;
  }
  const sleepReport = session;

  // Intent to sleep, convert to nearest minutes
  let timeAtStart = new Date(
    Number(moment(sleepReport.time_at_intended_sleep))
  );

  if (moment(sleepReport.time_at_intended_sleep).seconds() > 30) {
    timeAtStart = new Date(
      Number(moment(sleepReport.time_at_intended_sleep).add(1, "minutes"))
    );
  }

  // First asleep, convert to nearest minutes
  let timeAtSleep = new Date(Number(moment(sleepReport.time_at_sleep_onset)));
  if (moment(sleepReport.time_at_sleep_onset).seconds() > 30) {
    timeAtSleep = new Date(
      Number(moment(sleepReport.time_at_sleep_onset).add(1, "minutes"))
    );
  }

  // timeAtWakeup, convert to nearest minutes
  let timeAtWakeup = new Date(Number(moment(sleepReport.time_at_wakeup)));
  if (moment(sleepReport.time_at_wakeup).seconds() > 30) {
    timeAtWakeup = new Date(
      Number(moment(sleepReport.time_at_wakeup).add(1, "minutes"))
    );
  }
  const timeAsleep = secondsToTime(sleepReport.time_asleep);
  const timeAsleepString = `${
    timeAsleep.h > 0
      ? `${
          timeAsleep.h
        }${"<span style='word-spacing: -0.125em'>&nbsp</span>"}${i18n.t(
          "hours_abbreviation_lc"
        )}`
      : ""
  } ${getMinuteString(timeAsleep.h, timeAsleep.m)}`;

  /* 
    let sec = sleepReport.time_asleep;
    let h = Math.trunc(sec / 60 / 60);
    let m = Math.trunc((sec - h * 60 * 60) / 60);
    const timeAsleepString = `${(h > 0 ? `${h}h ` : "") + m}m`;
  */
  const sec =
    sleepReport.time_asleep > 0
      ? sleepReport.sleep_onset_latency
      : sleepReport.time_in_bed;
  const secondsToTimeVal = secondsToTime(sec);
  const sleepOnsetString = `${
    secondsToTimeVal.h > 0
      ? `${
          secondsToTimeVal.h
        }${"<span style='word-spacing: -0.125em'>&nbsp</span>"}${i18n.t(
          "hours_abbreviation_lc"
        )}`
      : ""
  } ${getMinuteString(secondsToTimeVal.h, secondsToTimeVal.m)}`;
  return {
    series: {
      timeAtStart,
      timeAtSleep,
      timeAtWakeup,
      timeAsleepString,
      sleepOnsetString,
      sleepEfficiency: sleepReport.sleep_efficiency
        ? Math.floor(sleepReport.sleep_efficiency)
        : "",
      timesAwakeAway: [
        sleepReport.number_of_times_awake_long.toString(),
        sleepReport.number_of_times_no_presence
      ].join("/"),
      timesAway: sleepReport.number_of_times_no_presence,
      epochsWithMovement: sleepReport.epochs_with_movement_pct
        ? Math.round(sleepReport.epochs_with_movement_pct)
        : "",
      nonREMMeanHeartRate: sleepReport.heart_rate_non_rem_filtered_mean
        ? Math.round(sleepReport.heart_rate_non_rem_filtered_mean)
        : "",
      nonREMMeanExternalHeartRate:
        sleepReport.external_heart_rate_non_rem_filtered_mean,
      nonREMMeanRespirationRate: sleepReport.rpm_non_rem_filtered_mean
        ? Math.round(sleepReport.rpm_non_rem_filtered_mean)
        : "",
      externalSpO2Mean: sleepReport.external_spo2_mean
    }
  };
};

// if (!session) {
//   return null;
// }

// const sleepReport = session._embedded.sleep_analysis.report;
// const timeAtStart = new Date(
//   Number(moment.utc(sleepReport.time_at_intended_sleep))
// );
// const timeAtSleep = new Date(Number(moment.utc(sleepReport.time_at_sleep)));
// const timeAtWakeup = new Date(Number(moment.utc(sleepReport.time_at_wakeup)));

// let sec = sleepReport.time_asleep;
// let h = Math.trunc(sec / 60 / 60);
// let m = Math.trunc((sec - h * 60 * 60) / 60);
// const timeAsleepString = `${(h > 0 ? `${h}h ` : "") + m}m`;

// sec = sleepReport.sleep_onset;
// h = Math.trunc(sec / 60 / 60);
// m = Math.trunc((sec - h * 60 * 60) / 60);
// const sleepOnsetString = `${(h > 0 ? `${h}h ` : "") + m}m`;
