import { styled } from "@mui/system";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import React from "react";

import { ReactComponent as ArrowStraight } from "assets/icons/new/arrow-straight.svg";
import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import { IMonitor } from "interfaces/monitor.interface";
import { ITileSettings } from "@modules/monitor/DashboardScreen";
import { IZone } from "interfaces/zone.interface";
import {
  MonitorGrid,
  SMTableWrapperBox,
  TableTitle
} from "styled/CommonStyled";

import { LiveDataTableProps } from "../SubjectOverview";
import LiveMonitorTile from "./LiveMonitorTile";

const ArrowStraightStyled = styled(ArrowStraight)(
  ({ children }: { children?: any }) => {
    return {
      marginRight: 8,
      marginLeft: 8
    };
  }
);

function MonitorZoneTiles({
  liveData,
  zoneEntity,
  onTileClick
}: {
  liveData: LiveDataTableProps;
  zoneEntity: { [key: string]: IZone };
  onTileClick: (item: IMonitor) => void;
}) {
  const { t } = useTranslation();
  const { watch } = useFormContext<ITileSettings>();
  const tileScale = watch("tileScale", 50);
  const showArrow = (paths: string[], path: string) => {
    if (paths.indexOf(path) !== paths.length - 1) {
      return true;
    }
    return false;
  };

  return (
    <SMTableWrapperBox key={liveData.zone} sx={{ width: "100%" }}>
      <TableTitle
        sx={{
          padding: "16.5px 20px",
          borderRadius: "8px 8px 0px 0px",
          background: "#FFF"
        }}
      >
        {zoneEntity &&
          liveData.path.map((path: string) => {
            return zoneEntity[path] ? (
              <span key={zoneEntity[path].name}>
                {zoneEntity[path].name}{" "}
                {showArrow(liveData.path, path) ? <ArrowStraightStyled /> : ""}{" "}
              </span>
            ) : (
              ""
            );
          })}
      </TableTitle>
      <BoxPadding pad="0 16px 16px 16px" sx={{ background: "#FFF" }}>
        <MonitorGrid tilescale={tileScale} key={liveData.zone}>
          {liveData.data?.map((item: IMonitor) => {
            return (
              <LiveMonitorTile
                data={item}
                t={t}
                key={`${item.device.id} ${item.subject?.id}`}
                onClick={onTileClick}
              />
            );
          })}
          {/* </Box> */}
        </MonitorGrid>
      </BoxPadding>
    </SMTableWrapperBox>
  );
}
export default MonitorZoneTiles;
