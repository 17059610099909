import { ICustomField } from "interfaces/tenant.interface";
import { ISubject } from "interfaces/subject.interface";
import { Grid, Popover, Typography } from "@mui/material";
import { ApplyMargin } from "styled/StylesStyled";
import { useState } from "react";

import {
  Control,
  Controller,
  SubmitHandler,
  UseFormHandleSubmit
} from "react-hook-form";
import { InputLabelStyled } from "styled/CommonStyled";

import SMFormDropDown from "components/DropDownSelect/SMFormDropDown";
import SMFormTextField from "components/SMTextField/SMFormTextField";

import { useTranslation } from "react-i18next";
import { getLanguage } from "utils/localStorage";

import { HelpOutlineOutlined } from "@mui/icons-material";


function GetCustomFieldDictData({
  data
} : {
  data: {[key: string]: string};
}) {
  const lang = getLanguage();

  if (lang && Object.keys(data).includes(lang)) {
    return data[lang];
  } else if (Object.keys(data).includes("en")) {
    // Fallback to English
    return data["en"];
  } else {
    return "";
  }
}


function GetCustomFieldOptions({
  options
} : {
  options: ICustomField["options"];
}) {
  var options_updated = [];

  for (const key in options) {
    options_updated.push({
      id: key,
      // @ts-ignore implicit any type since index expression is not of type number
      name: GetCustomFieldDictData({data: options[key].name}),
      // @ts-ignore implicit any type since index expression is not of type number
      value: options[key].value,
      disabled: false,
      hidden: false
    });
  }

  return options_updated;
}

const ContextualHelp = ({
  info,
  title
}: {
  info: string;
  title: string;
}): JSX.Element => {

  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(
    null
  );

  // handle close
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = title + "custom_field_popover";

  const maxWidth = "360px";

  return (
    <>
      <HelpOutlineOutlined
        onClick={handleClick}
        fontSize={"small"}
        sx={{
          color: "rgba(0, 78, 82, 1)",
          cursor: "pointer",
          marginLeft: "4px",
          paddingBottom: "4px",
          height: "100%",
          verticalAlign: "middle",
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            marginTop: "-8px",
            borderRadius: "4px",
            padding: "12px, 16px, 8px, 16px",
            maxWidth: maxWidth
          },
        }}
        > 
          <Typography
            sx={{ p: 2, pb:0, fontStyle: "bold", fontSize: "14px" }}
            gutterBottom
            component="div"
            fontWeight="bold">
            {title}
          </Typography>
          <Typography sx={{ p: 2, pt:0, fontSize: "14px "}} component="div">
          {info}
        </Typography>
      </Popover>
    </>
  );
}

const CustomFieldDropDown = ({
  create=false,
  custom_field,
  control,
  onSubmit,
  handleSubmit
}: {
  create: boolean;
  custom_field: ICustomField;
  control: any;
  onSubmit: any;
  handleSubmit: any;
}): JSX.Element => {

  const { t } = useTranslation();

  const title = GetCustomFieldDictData({data: custom_field.title});
  const key_name = "custom_fields." + custom_field.id;
  const placeholder_pref_lang = GetCustomFieldDictData({data: custom_field.placeholder});
  const options_pref_lang = GetCustomFieldOptions({options: custom_field.options});
  const rules = custom_field.required ? { required: t("this_field_cannot_be_empty") }: {};

  const info = GetCustomFieldDictData({data: custom_field.info});

  return (
    <>
      <Grid item xs={12} md={4}>
      <ApplyMargin top={16}>
        <InputLabelStyled maginbottom={6}>
          {title}
          {info && (
            <ContextualHelp info={info} title={title}/>
          )}
        </InputLabelStyled>

        <Controller
         rules={rules}
        name={key_name}
        control={control}
        render={({ field, fieldState, formState }) => (

          <SMFormDropDown<ICustomField>
            field={field}
            disabled={false}
            fieldState={fieldState}
            formState={formState}
            options={options_pref_lang}
            placeholder={placeholder_pref_lang === "" ? placeholder_pref_lang : "Select"}
            width="100%"
            onUpdate={() => {
              if (!create) {
                (onSubmit)();
              }
            }}
          />
        )}
        />
      </ApplyMargin>
    </Grid>
    </>
  );
}

const CustomFieldTextInput = ({
  create=false,
  custom_field,
  control,
  onSubmit,
  handleSubmit
}: {
  create: boolean;
  custom_field: ICustomField;
  control: any;
  onSubmit: any;
  handleSubmit: any;
}): JSX.Element => {

  const { t } = useTranslation();

  const title = GetCustomFieldDictData({data: custom_field.title});
  const key_name = "custom_fields." + custom_field.id;
  const placeholder_pref_lang = GetCustomFieldDictData({data: custom_field.placeholder});
  const rules = custom_field.required ? { required: t("this_field_cannot_be_empty") }: {};

  const info = GetCustomFieldDictData({data: custom_field.info});
  
  return (
    <>
      <Grid item xs={12} md={4}>
        <ApplyMargin top={16}>
          <InputLabelStyled maginbottom={6}>
            {title}
            {info && (
            <ContextualHelp info={info} title={title}/>
          )}
          </InputLabelStyled>
          <Controller
            rules={rules}
            name={key_name}
            control={control}
            render={({ field, fieldState, formState }) => (
              <SMFormTextField<ISubject>
                field={field}
                formState={formState}
                fieldState={fieldState}
                placeholder={placeholder_pref_lang === "" ? placeholder_pref_lang : "Select"}
                inlineEdit={!create}
                onUpdate={() => {
                  if (!create) {
                    handleSubmit(onSubmit)();
                  }
                }}
              />
            )}
          />
        </ApplyMargin>
      </Grid>
    </>
  );
}

const CustomFieldGridItem = ({
  create=false,
  custom_field,
  control,
  onSubmit,
  handleSubmit
}: {
  create: boolean;
  custom_field: ICustomField;
  control: any;
  onSubmit: any;
  handleSubmit: any;
}): JSX.Element => {
  if (custom_field.entity === "subject" && custom_field.type === "dropdown") {
    return (
      <CustomFieldDropDown
        create={create}
        custom_field={custom_field}
        control={control}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}/>
    );
  } else if (custom_field.entity === "subject" && custom_field.type === "textinput") {
    return (
      <CustomFieldTextInput
        create={create}
        custom_field={custom_field}
        control={control}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}/>
    );
  }
  else {
    return <> </>
  }
};
export default CustomFieldGridItem;
