import { ITenents } from "interfaces/auth.interface";
import { IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";

export const FETCH_TENENTS = "FETCH_TENENTS";
export const FETCH_TENENTS_SUCCESS = "FETCH_TENENTS_SUCCESS";
export const GET_AUTH_USER_SUCCESS_ACTION = "GET_AUTH_USER_SUCCESS_ACTION";
export const GET_AUTH_USER = "GET_AUTH_USER";
export const UPDATE_AUTH_USER = "UPDATE_AUTH_USER";
export const UPDATE_AUTH_USER_SUCCESS = "UPDATE_AUTH_USER_SUCCESS";
export const GET_AUTH_ZONES_SUCCESS_ACTION = "GET_AUTH_ZONES_SUCCESS_ACTION";
export const RESET_AUTH_STATE = "RESET_AUTH_STATE";

export const RESET_TENENTS = "RESET_TENENTS";

export interface resetAuthStateActionType {
  type: typeof RESET_AUTH_STATE;
}
export interface resetTenentsStateActionType {
  type: typeof RESET_TENENTS;
}

export interface fetchTenentsActionType {
  type: typeof FETCH_TENENTS;
}
export interface fetchTenentsSuccessActionType {
  type: typeof FETCH_TENENTS_SUCCESS;
  payload: ITenents[];
}

export interface getAuthUserActionType {
  type: typeof GET_AUTH_USER;
}
export interface getAuthUserSuccessActionType {
  type: typeof GET_AUTH_USER_SUCCESS_ACTION;
  payload: IUser;
}

export interface getAuthUserZonesSuccessActionType {
  type: typeof GET_AUTH_ZONES_SUCCESS_ACTION;
  payload: IZone[];
}

export interface updateAuthUserActionType {
  type: typeof UPDATE_AUTH_USER;
}
export interface updateAuthUserSuccessActionType {
  type: typeof UPDATE_AUTH_USER_SUCCESS;
  payload: IUser;
}

export type authActionTypes =
  | fetchTenentsActionType
  | fetchTenentsSuccessActionType
  | getAuthUserActionType
  | getAuthUserSuccessActionType
  | updateAuthUserActionType
  | updateAuthUserSuccessActionType
  | resetAuthStateActionType
  | resetTenentsStateActionType
  | getAuthUserZonesSuccessActionType;
