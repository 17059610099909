import { Button } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import EnterFullscreenIcon from "@mui/icons-material/Fullscreen";
import ExitFullscreenIcon from "@mui/icons-material/FullscreenExit";
import PrintIcon from "@mui/icons-material/Print";
import React, { useCallback, useEffect, useRef } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import _ from "lodash";

import * as LOCAL_STORAGE from "utils/localStorage";
import { AppRouteProps } from "interfaces/router.interface";
import { ApplyFloat } from "styled/StylesStyled";
import {
  BoxPadding,
  FlexOne
} from "components/ResizablePanel/SplitPanelStyledComponents";
import { IZone } from "interfaces/zone.interface";
import { SMMenuProps } from "interfaces/menu.interface";
import { ZoneUserRolesEnum } from "constants/userContstants";
import { authorizedZones } from "utils/zone.util";
import { basePath } from "constants/constants";
import { clearLiveMonitoring } from "store/actions/dashboard/monitorActions";
import { getAllZones } from "store/actions/administration/zoneActions";
import { getAuthUser } from "store/actions/auth/authActions";
import { gotTo } from "utils/history.util";
import SMAlertDialog from "components/SMDialogs/SMAlertDialog";
import SMPopoverDialog from "components/SMPopover.tsx/PopoverDialog";
import useFullScreen from "hooks/UseFullScreenHooks";
import { useTheme } from "@mui/material/styles";

import { DashboardContext } from "./DashboardContext";
import DashboardPopup from "./DashboardPopup";
import DateTimeText from "./NightReportV1/components/DateTimeText";
import MonitorScreenMenus from "./DashboardMenus";
import NightReportV1 from "./NightReportV1/NightReportV1Landing";
import SubjectOverview from "./SubjectOverview/SubjectOverview";

export const monitorRoutes: AppRouteProps[] = [
  {
    name: "home",
    path: `${basePath}/monitor`,
    exact: true,
    redirectPath: `${basePath}/monitor/overview`
  },
  {
    name: "overview",
    path: `${basePath}/monitor/overview`,
    exact: true,
    component: SubjectOverview
  },
  {
    name: "Night report",
    path: `${basePath}/monitor/night_report`,
    exact: true,
    component: NightReportV1
  }
];

const ButtonStyled = styled(Button)(
  ({ children }: {children?: React.ReactNode }) => {
    const theme = useTheme();
    return {
      marginRight: 4,
      color: theme.palette.main[60],
      fontWeight: 500,
      cursor: "pointer",
      textTransform: "capitalize",
      fontSize: 16,
      paddingRight: 12,
      paddingLeft: 12,
      marginBottom: 4,
      "&:hover": {
        background: "#FFF"
      },
      "> span": {
        marginLeft: 0
      },
      "& svg": {
        width: 24,
        height: 24
      }
    };
  }
);

export interface ITileSettings {
  showIcon: boolean;
  showSubjectId: boolean;
  showDeviceName: boolean;
  showDuration: boolean;
  showVitals: boolean;
  tileScale: number;
  show_epoch_timestamp: boolean;
  show_tile_warning: boolean;
}

function MonitorScreen(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  // const keyDown = useKeyDown(onKeyPress);
  const componentRef = useRef<any>(null);
  const fullScreenElemRef = useRef<HTMLDivElement>(null);
  const { isFullScreen, enterFullScreen, exitFullScreen } = useFullScreen();

  const settings: ITileSettings = LOCAL_STORAGE.getMonitorSettings() || {
    showIcon: true,
    showSubjectId: true,
    showDeviceName: true,
    showDuration: true,
    showVitals: true,
    show_epoch_timestamp: false,
    show_tile_warning: false,
    tileScale: 50
  };

  const methods = useForm<ITileSettings>({
    defaultValues: {
      showIcon: settings.showIcon,
      showSubjectId: settings.showSubjectId,
      showDeviceName: settings.showDeviceName,
      showDuration: settings.showDuration,
      showVitals: settings.showVitals,
      tileScale: settings.tileScale,
      show_epoch_timestamp: settings.show_epoch_timestamp || false,
      show_tile_warning: settings.show_tile_warning || false
    },
    reValidateMode: "onChange",
    mode: "onChange"
  });

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      LOCAL_STORAGE.setMonitorSettings(value);
    });
    return () => subscription.unsubscribe();
  }, [methods]);

  const [popup, setPopup] = React.useState<boolean>(false);
  const [popupType, setPopupType] = React.useState<
    "select_zones" | "settings" | ""
  >("");
  const [selectedZones, setSelectedZones] = React.useState<IZone[] | undefined>(
    undefined
  );

  const [zoneEntity, setZoneEntity] = React.useState<{ [key: string]: IZone }>(
    {}
  );
  const [currentPath, setCurrentPath] = React.useState<string>();
  const [openNoZoneAlert, setOpenNoZoneAlert] = React.useState<boolean>(false);

  const zoneReducer = useSelector((state: any) => {
    return state?.zoneReducer || {};
  });

  const authUser: any = useSelector((state: any) => {
    return state.authReducerV1?.user || {};
  });

  const pageStyle = `
      @page {
        size:  auto;   
        margin: 0mm; 
      }
      @page:right{
        @bottom-right {
          content: counter(page);
        }
      }
      @media print { 
          body{
            -webkit-print-color-adjust: exact;
          }
          table{
            width:90%;
            page-break-inside: avoid;
             min-width:600px !important; 
            break-inside: avoid;
          }
          th {
            font-size: 8px !important;
            padding: 3px !important;
            max-width:50px;
          }
          td { 
            font-size: 10px !important;
            padding: 5px !important;
            max-width:50px;
          }
          .sortIconParentDiv{
            display:none;
          }
          .printContainer{
            padding:5px;
          }
          .print_css_baseline_night{
            max-width:50px;
            overflow-wrap: break-word;
          }
          .print_table_wrapper{
            break-inside: avoid;
          }
    }
  `;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle
  });

  useEffect(() => {
    const path = location.pathname.split("/").reverse();
    // for to fix issue: when another user changed the state from a different machine/browser
    if (path && path[0] && path[0] === "overview") {
      dispatch(getAuthUser());
    }
    if (path && path[0] && path[0] === "night_report") {
      dispatch(getAuthUser());
      dispatch(clearLiveMonitoring());
    }
    if (path?.length) {
      setCurrentPath(path[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Fetch all zones
  useEffect(() => {
    const adminRoles = [
      ZoneUserRolesEnum.ADMIN,
      ZoneUserRolesEnum.MANAGER,
      ZoneUserRolesEnum.MANAGER_LIMITED,
      ZoneUserRolesEnum.OWNER
    ];
    const intersection = authUser?.roles?.filter(
      (item: ZoneUserRolesEnum) => adminRoles.indexOf(item) !== -1
    );
    // live monitor API calls twice issue fix
    if (!intersection.length || !zoneReducer?.zones?.length) {
      dispatch(getAllZones());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAuthorizedZones = useCallback(
    (zones: IZone[], authZones: IZone[]) => {
      return authorizedZones(zones, authZones);
    },
    []
  );

  const getSelectedZonesFromAuthZones = (
    authZones: IZone[],
    currentUserZones: string[],
    authUserID: string
  ) => {
    let updatedZones: string[] = [];
    const authZoneIds = authZones.map((item) => item.id);
    const difference = currentUserZones.filter(
      (x: string) => !authZoneIds.includes(x)
    );
    updatedZones = currentUserZones.filter(
      (x: string) => !difference.includes(x)
    );
    // set selected
    const selected = authZones.filter((item: IZone) =>
      updatedZones.includes(item.id)
    );
    return { selected, updatedZones };
  };

  useEffect(() => {
    if (zoneReducer?.zones?.length && authUser) {
      const entity: { [key: string]: IZone } = {};
      zoneReducer?.zones.forEach((zone: IZone) => {
        entity[zone.id] = zone;
      });

      // set all zones are selected by default
      // const authZones = authorizedZones(
      //   zoneReducer.zones,
      //   authUser?.zones?.data || []
      // );
      const authZones = getAuthorizedZones(
        zoneReducer.zones,
        authUser?.zones?.data || []
      );
      const activeZones = LOCAL_STORAGE.getDashboardZones() || {};

      const authZoneIds = authZones.map((item) => item.id);
      const selectedIds = selectedZones?.map((item) => item.id);
      let updated: string[] = [];
      if (activeZones[authUser.id]) {
        // const currentUserZones = activeZones[authUser.id];
        // const difference = currentUserZones.filter(
        //   (x: string) => !authZoneIds.includes(x)
        // );
        // updated = currentUserZones.filter(
        //   (x: string) => !difference.includes(x)
        // );

        // // set selected
        // const slctd = authZones.filter((item: IZone) =>
        //   updated.includes(item.id)
        // );
        const currentUserZones = activeZones[authUser.id];
        const { selected, updatedZones } = getSelectedZonesFromAuthZones(
          authZones,
          currentUserZones,
          authUser.id
        );
        updated = updatedZones;
        setSelectedZones(selected);
      } else if (!_.isEqual(authZoneIds, selectedIds)) {
        updated = authZones.map((x) => x.id);
        setSelectedZones(authZones);
      }

      // zone setting in local storage
      activeZones[authUser.id] = updated;
      setZonesInLocalStorage(activeZones);

      setZoneEntity(entity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoneReducer, authUser]);

  const setZonesInLocalStorage = (activeZonesSettings: {
    [key: string]: string[];
  }) => {
    LOCAL_STORAGE.setDashboardZones(activeZonesSettings);
  };

  const onClick = (menu: SMMenuProps) => {
    if (!menu.popup && !menu.customAction) {
      history.push(`${basePath}/monitor/${menu.value}`);
    } else if (menu.customAction) {
      menu.customAction();
    } else {
      setPopupType(menu.value as "select_zones" | "settings" | "");
      setPopup(true);
    }
  };

  const handleFullScreenClick = () => {
    if (isFullScreen) {
      exitFullScreen();
    } else if (fullScreenElemRef.current) {
      enterFullScreen(fullScreenElemRef.current);
    }
  };

  const menus: SMMenuProps[] = [
    {
      label: t("select_zones"),
      value: "select_zones",
      popup: true,
      icon: <AccountTreeIcon />
    },
    {
      label: t("print_report"),
      value: "print_report",
      customAction: () => {
        if (handlePrint) {
          handlePrint();
        }
      },
      icon: <PrintIcon />,
      hide: currentPath === "overview"
    },
    {
      label: t("enter_fullscreen"),
      value: "enter_fullscreen",
      icon: <EnterFullscreenIcon />,
      customAction: handleFullScreenClick,
      hide: isFullScreen || currentPath === "night_report"
    },
    {
      label: t("exit_fullscreen"),
      value: "exit_fullscreen",
      icon: <ExitFullscreenIcon />,
      customAction: handleFullScreenClick,
      hide: !isFullScreen || currentPath === "night_report"
    },
    {
      label: t("Settings"),
      value: "settings",
      icon: <SettingsIcon />,
      popup: true,
      hide: currentPath === "night_report"
    }
  ];

  const onSetSelectedZones = (selected: IZone[]) => {
    if (!selected?.length) {
      setOpenNoZoneAlert(true);
    } else {
      setPopup(false);

      const selectedIds = selected?.map((item) => item.id);

      // set to local storage
      const activeZones = LOCAL_STORAGE.getDashboardZones() || {};
      activeZones[authUser.id] = selectedIds;
      setZonesInLocalStorage(activeZones);

      const authZones = getAuthorizedZones(
        zoneReducer.zones,
        authUser?.zones?.data || []
      );
      const { selected: slectd } = getSelectedZonesFromAuthZones(
        authZones,
        selectedIds,
        authUser.id
      );
      // selected zones in sort order
      setSelectedZones(slectd);

      // set selected
      // setSelectedZones(selected);
    }
  };

  const onCancelCallBack = () => {};

  return (
    <DashboardContext.Provider
      value={{
        selectedZones,
        zoneEntity,
        setSelectedZones,
        handlePrint,
        componentRef
      }}
    >
      <FlexOne
        style={{ overflowX: "hidden", height: "calc(100vh - 55px)" }}
        bg="light"
        ref={fullScreenElemRef}
      >
        <FormProvider {...methods}>
          <BoxPadding pad="18px 24px" sx={{ position: "relative" }}>
            <DateTimeText />
            <ApplyFloat float="right">
              <MonitorScreenMenus menus={menus} onClick={onClick} />
            </ApplyFloat>

            {currentPath !== "overview" && (
              <ApplyFloat float="right">
                <ButtonStyled
                  startIcon={<DashboardIcon />}
                  onClick={() => gotTo(`monitor/overview`)}
                >
                  {t("overview")}
                </ButtonStyled>
              </ApplyFloat>
            )}

            {!isFullScreen && currentPath !== "night_report" && (
              <ApplyFloat float="right">
                <ButtonStyled
                  startIcon={<DescriptionRoundedIcon />}
                  onClick={() => gotTo(`monitor/night_report`)}
                >
                  {t("Night Report")}
                </ButtonStyled>
              </ApplyFloat>
            )}
            <Switch>
              {monitorRoutes.map((route: AppRouteProps) => (
                <Route
                  exact={route.exact}
                  key={route.name}
                  path={route.path}
                  render={() => {
                    const Component = route.component;
                    return Component ? (
                      <Component />
                    ) : (
                      <Redirect to={route.redirectPath || "/"} />
                    );
                  }}
                />
              ))}
            </Switch>
          </BoxPadding>
          {zoneReducer.zones && authUser && (
            <SMPopoverDialog
              open={popup}
              title={
                popupType === "select_zones" ? t("select_zones") : t("Settings")
              }
              maxWidth={popupType === "select_zones" ? "sm" : "sm"}
              onClose={() => setPopup(false)}
            >
              <DashboardPopup
                type={popupType}
                authUser={authUser}
                zones={zoneReducer.zones}
                onSetSelectedZones={onSetSelectedZones}
                selectedZones={selectedZones}
                onCancelCallBack={onCancelCallBack}
              />
            </SMPopoverDialog>
          )}
        </FormProvider>
      </FlexOne>
      <SMAlertDialog
        title={t("no_zone_selected_warning_title")}
        dialogDesc={t("no_zone_selected_warning_text")}
        buttonOk={t("ok")}
        open={openNoZoneAlert}
        setOpen={setOpenNoZoneAlert}
      />
    </DashboardContext.Provider>
  );
}

export default MonitorScreen;