import { combineReducers } from "redux";

import authReducerV1 from "./auth/authReducerV1.ts";
import devicesState from "./administration/deviceReducerNew.ts";
import liveMonitorReducer from "./dashboard/monitorReducer.ts";
import nightReportV1Reducer from "./dashboard/dashboardReducer.ts";
import notificationLogReducer from "./notification-logs/logsReducer.ts";
import notificationReducer from "./notifications/notificationsReducer.ts";
import rulesReducer from "./rules/rulesReducer.ts";
import sleepEpochReducer from "./sleephistory/sleepEpochReducer.ts";
import sleepHistoryReducer from "./sleephistory/sleephistoryReducer.ts";
import subjectReducer from "./administration/subjectReducer.ts";
import tenantReducer from "./administration/tenantReducer.ts";
import usersReducerV2 from "./administration/userReducerV2.ts";
import zoneReducer from "./administration/zoneReducer.ts";

export default combineReducers({
  zoneReducer,
  subjectReducer,
  tenantReducer,
  devicesState,
  usersReducerV2,
  liveMonitorReducer,
  nightReportV1Reducer,
  authReducerV1,
  sleepHistoryReducer,
  sleepEpochReducer,
  notificationReducer,
  rulesReducer,
  notificationLogReducer
});
