import { Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { ApplyMargin } from "styled/StylesStyled";
import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import { ChipStyled, LabelStyled } from "styled/CommonStyled";
import { IDevice } from "interfaces/device.interface";
import { ISubject } from "interfaces/subject.interface";
import { getAllSubjects } from "store/actions/administration/subjectActions";
import { getDeviceByID } from "store/actions/administration/deviceActions";
import { getTimeDifferenceAsHM } from "utils/administration/zone.devices";
import Maintitle from "components/SMTitles/MainTitle";
import SMGoogleAnalytic from "components/GoogleAnalytic/GoogleAnalytic";
import UseAdminContext from "hooks/UseAdminContext";

import DeviceInformation from "./components/DeviceInformation";
import DeviceSettingsComponent from "./components/DeviceSettingsComponent";
import SubjectDropDown from "./components/SubjectDropDown";

export const DeviceViewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [device, setDevice] = useState<IDevice>();
  const [subjects, setSubject] = useState<ISubject[]>([]);
  const [nodevice, setNodevice] = useState<boolean>(false);

  // context state
  const { setCurrentPath, zones, selectedZone, zoneEntity } = UseAdminContext();

  const deviceState = useSelector((state: any) => {
    return state.devicesState || {};
  });

  const subjectState: any = useSelector((state: any) => {
    return state.subjectReducer || {};
  });

  // get all subjects
  useEffect(() => {
    setNodevice(false);
    if (zoneEntity && selectedZone && zoneEntity[selectedZone]) {
      dispatch(getAllSubjects(zoneEntity[selectedZone].path));
      dispatch(getDeviceByID(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZone, zoneEntity]);

  // filter current subject
  useEffect(() => {
    if (deviceState && deviceState?.devices) {
      const dvc: IDevice = deviceState.devices.find(
        (item: IDevice) => item.id === id
      );

      // set automatic
      if (dvc?.settings?.user?.time) {
        dvc.settings.user.time.timezone =
          dvc.settings?.user.time.timezone || "Automatic";
      }

      if (dvc) {
        setDevice(dvc);
      } else {
        setNodevice(true);
      }
      // set breadcump path
      if (dvc) {
        if (setCurrentPath) {
          setCurrentPath({ id: dvc.id, name: dvc.name });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceState]);

  useEffect(() => {
    if (subjectState && subjectState.subjects) {
      const filteredSubject = subjectState.subjects.filter((item: ISubject) => {
        return (
          item.zone_id === selectedZone &&
          (item?.devices?.data?.length === 0 ||
            item?.devices?.data[0].id === id)
        );
      });
      setSubject(filteredSubject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectState]);

  const getStatusElement = (): JSX.Element => {
    if (device) {
      const status: string = device.online ? "online" : "offline";
      return (
        <ChipStyled
          label={t(status)}
          bg={status === "online" ? "green" : "red"}
        />
      );
    }
    return <></>;
  };

  const lastOnline: string | null = getTimeDifferenceAsHM(
    device?.latest_connectivity_event_at || ""
  );

  if (!subjectState?.subjects) {
    return <div />;
  }

  if (nodevice) {
    return (
      <BoxPadding pad="0px 24px 24px 4px">{t("no_device_found")}</BoxPadding>
    );
  }

  return (
    <>
      {/* Add google analytic */}
      <SMGoogleAnalytic title="Device view page" />

      <BoxPadding pad="0px 24px 24px 4px">
        {device && (
          <ApplyMargin bottom={33} top={1}>
            <Maintitle text={device?.name || ""} showBack />
            <ApplyMargin top={8}>
              <Stack spacing={1} direction="row">
                {getStatusElement()}
                <LabelStyled sx={{ alignItems: "center", display: "flex" }}>
                  {`${t("Last online")}:${
                    lastOnline ? `${lastOnline}` : ` ${t("unknown")}`
                  }`}{" "}
                </LabelStyled>
              </Stack>
            </ApplyMargin>
          </ApplyMargin>
        )}

        <Grid container rowSpacing={0} columnSpacing={2.5} marginBottom={3}>
          <Grid item xs={12} md={4}>
            {device && <DeviceInformation device={device} zones={zones} />}
          </Grid>
          <Grid item xs={12} md={8}>
            {device && (
              <ApplyMargin bottom={20}>
                <SubjectDropDown
                  device={device}
                  subjects={subjects}
                  selectedZone={selectedZone}
                />{" "}
              </ApplyMargin>
            )}
            {device && device.settings && (
              <DeviceSettingsComponent device={device} />
            )}
          </Grid>
        </Grid>
        {/* <Grid item xs={12} md={12}>
        {device && (
          <SMConfirmDialog
            title={t("Delete device")}
            dialogDesc={`${t("Are you sure you want to delete this device")}`}
            onDelete={onDelete}
            buttonOk={t("Delete")}
            buttonCancel={t("Cancel")}
            okButtonBg="red"
            disableDelete={
              permissions.indexOf(DevicePermissionEnum["device.delete"]) === -1
            }
          />
        )}
      </Grid> */}
      </BoxPadding>
    </>
  );
};

export default DeviceViewPage;
