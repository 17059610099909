export const PROD_API_BASE_URL = "https://api.health.somnofy.com/";

export const API_SERVICES = {
  zones: "api/v1/zones",
  subjects: "api/v1/subjects",
  tenants: "api/v1/tenants",
  devices: "api/v1/devices",
  users: "api/v1/users",
  userGroups: "api/v1/user-groups",
  liveMonitoring: "api/v1/live-monitoring",
  reports: "api/v1/reports",
  sessions: "api/v1/sessions",
  notificationHandlers: "api/v1/notification-handlers",
  rules: "api/v1/rules",
  rulePresets: "/api/v1/rules/presets",
  notificationLogs: "/api/v1/notification-logs",

  // Auth
  tenents: "/api/auth/v1/tenants",
  authUser: "/api/auth/v1/user",
  authUserZones: "/api/auth/v1/zones",
  passwordReset: "/api/auth/v1/user/reset-password"
};

export const basePath = "";
export const OVERVIEW_PAGE = `monitor/overview`;
export const ADMINSTRATION_DEVICES = `adminstration/zones/devices`;
export const LOGIN_PAGE = `/auth/login`;
export const LOGIN_TENENTS = `tenants`;

export const WIPMessageUrl = "https://static.somnofy.com/messages/honu";
