import { FieldErrors, FieldValues } from "react-hook-form";

// getErrorInputElement
function getErrorInputElement<T extends FieldValues>(
  err: FieldErrors<T>,
  errorFocusOrder: { [key: string]: string }
): { elem: HTMLElement | null; key: string } {
  let errorKeys: string[] = Object.keys(err);
  if (!errorKeys.length) {
    return { elem: null, key: errorKeys[0] };
  }
  errorKeys = errorKeys.sort((a: any, b: any) => {
    const left = errorFocusOrder[a] || "";
    const right = errorFocusOrder[b] || "";
    if (left < right) {
      return -1;
    }
    if (left > right) {
      return 1;
    }
    return 0;
  });
  const elem: NodeListOf<HTMLElement> = document.getElementsByName(
    errorKeys[0]
  );
  if (elem && elem.length) {
    return { elem: elem[0], key: errorKeys[0] };
  }
  return { elem: null, key: errorKeys[0] };
}

export default getErrorInputElement;
