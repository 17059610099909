import { MenuItem, SvgIcon, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import React, { useMemo, useState } from "react";

import * as LOCAL_STORAGE from "utils/localStorage";
import { IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";
import { gotTo } from "utils/history.util";
import { makeZoneTree } from "utils/zone.util";
import { resetDeviceState } from "store/actions/administration/deviceActions";
import { resetSubjectState } from "store/actions/administration/subjectActions";
import TreeViewComponent from "components/TreeView/TreeViewComponent";
import UseAdminContext from "hooks/UseAdminContext";

const MenuItemStyled = styled(MenuItem)(
  ({
    disable_mouse_over
  }: {
    disable_mouse_over?: number;
  }) => {
    const theme = useTheme();
    return {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "0.25",
      paddingLeft: 14,
      color: theme.palette.neutral[80],
      "&:hover": {
        background: disable_mouse_over ? "none" : "inherit"
      }
    };
  }
);

const SvgIconStyled = styled(SvgIcon)(
  () => {
    const theme = useTheme();
    return {
      color: theme.palette.neutral[80],
      marginRight: 14
    };
  }
);

export const ZoneSidebar = ({
  zoneList,
  authUser
}: {
  zoneList: IZone[];
  authUser: IUser;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [zones, setZones] = useState<IZone[]>([]);
  const { selectedZone, setSelectedZone } = UseAdminContext();
  useMemo(() => {
    const zoneTree = makeZoneTree<IZone>(zoneList);
    if (zoneTree) {
      setZones(zoneTree);
    }
  }, [zoneList]);

  // useEffect(() => {
  //  const zoneTree =  makeZoneTree<IZone>(zoneList);
  // if (zoneTree) {
  //   setZones(zoneTree);
  // }
  // }, [JSON.stringify(zoneList)]); // Todo fr@vitalthings.com

  const handleChange = (e: React.MouseEvent<HTMLElement>, zoneType: IZone) => {
    if (zoneType.id) {
      // reset state, for to clear all existing values in the state
      dispatch(resetSubjectState());
      dispatch(resetDeviceState());

      LOCAL_STORAGE.setZone(zoneType.id);
      if (setSelectedZone) {
        setSelectedZone(zoneType.id);
      }
      const currentPath = history.location.pathname.split("/")?.reverse();
      if (
        currentPath.length &&
        currentPath[0] !== "subjects" &&
        currentPath[0] !== "devices" &&
        currentPath[0] !== "users"
      ) {
        if (currentPath.length > 0 && currentPath[0] === "settings") {
          gotTo("adminstration/settings");
        } else if (currentPath.length > 0 && currentPath[1] === "subjects") {
          gotTo("adminstration/zones/subjects");
        } else if (currentPath.length > 0 && currentPath[1] === "devices") {
          gotTo("adminstration/zones/devices");
        } else if (currentPath.length > 0 && currentPath[1] === "users") {
          // gotTo("adminstration/zones/users");
          gotTo("adminstration/zones/users");
        } else {
          gotTo("adminstration/zones/devices");
        }
      }
    }
  };

  return (
    <div>
      <MenuItemStyled
        sx={{ height: "40px", marginTop: 2, cursor: "default" }}
        disable_mouse_over={1}
      >
        <SvgIconStyled>
          <AccountTreeIcon fontSize="small" />
        </SvgIconStyled>
        <Typography variant="inherit">{t("Zones")}</Typography>
      </MenuItemStyled>
      <TreeViewComponent
        data={zones}
        handleChange={handleChange}
        setZones={setZones}
        activeZone={selectedZone || ""}
        authorizedZones={authUser.zones?.data.map((item: IZone) => item.id)}
      />
    </div>
  );
};

export default ZoneSidebar;
