import _ from "lodash";

import { IZone } from "interfaces/zone.interface";

/**
 * This function recieve an array of type T and convert the array to a Tree structure.
 *
 * @param data Array of type T, T should include id,parent_id
 * @return Tree array
 */

function buildTree<
  T extends {
    id: string;
    parent_id: string;
    children?: T[];
    collapsed?: boolean;
  }
>(zones: T[], parentId: string | null = null) {
  const tree = [];
  for (let i = 0; i < zones.length; i += 1) {
    const item = zones[i];
    if (item.parent_id === parentId) {
      const children = buildTree(zones, item.id);
      if (children.length) {
        item.children = children;
      }
      tree.push(item);
    }
  }
  return tree;
}

export const makeZoneTree = <
  T extends {
    id: string;
    parent_id: string;
    children?: T[];
    collapsed?: boolean;
    name: string;
  }
>(
  data: T[]
) => {
  // build data
  return buildTree(
    _.cloneDeep(
      data.sort((a: T, b: T) => {
        return Intl.Collator("nb", { sensitivity: "base" }).compare(
          a.name.toLocaleLowerCase(),
          b.name.toLocaleLowerCase()
        );
      })
    )
  );

  // const tree = data
  //   .map((e: T) => ({ ...e }))
  //   .sort((a: T, b: T) => (a.id > b.id ? 1 : -1))
  //   .reduce((a: { [key: string]: T }, e: T) => {
  //     const copyA = a;
  //     copyA[e.id] = a[e.id] || e;
  //     const parentId = !e.parent_id ? "0" : e.parent_id;
  //     copyA[parentId] = a[parentId] || {};
  //     const parent = a[parentId];
  //     parent.children = parent.children || [];
  //     parent.collapsed = false;
  //     parent.children.push(e);
  //     return a;
  //   }, {});
  // return tree["0"]?.children || [];
};
// memoize zone tree
// export const makeZoneTree = memoize(makeZoneTreeFun);
/**
 * This function convert zone array as options
 *
 * @param data Array of type IZone
 * @return Options array [{id,name,value}[]]
 */
export const convertZonesAsOptions = (
  zoneList: IZone[] = [],
  authorizedZones: string[]
): {
  id: string;
  name: string;
  value: string;
  padding: number;
  disabled?: boolean;
}[] => {
  if (!zoneList.length) {
    return [];
  }
  return zoneList.map((zon: IZone) => {
    return {
      id: zon.id,
      name: zon.name,
      value: zon.id,
      padding: zon.level,
      disabled: !authorizedZones.includes(zon.id)
    };
  });
};

/**
 * This function convert array to datalist
 *
 * @param data interface T | itemsArray of type T | zones - zones in the system | groupBy - key to group elements
 * @return Options array [{id,name,value}[]]
 */
export const getDataList = <T>(
  itemsArray: T[],
  zones: IZone[],
  groupBy: keyof T
) => {
  if (
    itemsArray &&
    Array.isArray(itemsArray) &&
    zones &&
    Array.isArray(zones)
  ) {
    const zonesEntity: { [key: string]: IZone } = {};
    zones.forEach((element: IZone) => {
      zonesEntity[element.id] = element;
    });

    const grouped: { [key: string]: T[] } = _.groupBy(itemsArray, groupBy);
    const arr: { name: string; id: string; items: T[] }[] = [];
    Object.keys(grouped).forEach((key, index) => {
      arr.push({
        name: zonesEntity[key]?.name,
        id: key,
        items: grouped[key]
      });
    });
    return arr;
  }
  return [];
};

/**
 * This function convert array to group by zones
 *
 * @param data interface T | itemsArray of type T | zones - zones in the system | groupBy - key to group elements
 * @return Options array [{id,name,value}[]]
 */
export const groupByKey = <T>(
  itemsArray: T[],
  groupBy: keyof T
): { [key: string]: T[] } => {
  if (itemsArray && Array.isArray(itemsArray)) {
    const grouped: { [key: string]: T[] } = _.groupBy(itemsArray, groupBy);
    return grouped;
  }
  return {};
};

/**
 * This function convert zone array as dropdwon options
 *
 * @param zoneList zone list array
 * @return Option[] dropwdown option list
 */
export const getZoneDropDownOptions = (
  zoneList: IZone[],
  authorizedZones: string[] = []
) => {
  const zoneTree: IZone[] = makeZoneTree<IZone>(zoneList);
  return convertZonesAsOptions(flatten(zoneTree[0], []), authorizedZones);
};

/**
 * This function convert tree array to flatten with omitting children
 *
 * @param item zone tree object
 * @return flattened array
 */
export const flatten = (item: IZone, arr: IZone[] = []) => {
  const childItem = { ...item };
  delete childItem.children;
  arr.push(childItem);
  if (item?.children?.length) {
    item.children.map((child: IZone) => {
      return flatten(child, arr);
    });
  }
  return arr;
};

/**
 * This search through the tree function
 *
 * @param  element:tree item, id: match element id
 * @return tree element or null
 */

export const searchTree = (
  element: IZone,
  matchingID: string
): IZone | null => {
  if (!element) {
    return null;
  }
  if (element.id === matchingID) {
    return element;
  }
  if (element.children != null) {
    let i;
    let result = null;
    for (i = 0; result == null && i < element.children.length; i += 1) {
      result = searchTree(element.children[i], matchingID);
    }
    return result;
  }
  return null;
};

/**
 * This function will update a single tree property
 *
 * @param id - id of the tree node
 *        data - tree node
 *        property - tree property
 *        value - tree property value
 * @return updated tree node
 */
export const updatePropertyById = (
  id: string,
  data: any,
  property: any,
  value: any
) => {
  if (data.id === id) {
    // eslint-disable-next-line no-param-reassign
    data[property] = value;
  }
  if (data.children !== undefined && data.children.length > 0) {
    for (let i = 0; i < data.children.length; i += 1) {
      // eslint-disable-next-line no-param-reassign
      data.children[i] = updatePropertyById(
        id,
        data.children[i],
        property,
        value
      );
    }
  }
  return data;
};

/**
 * This function convert tree array to flatten with omitting children
 * This function will filter out collapsed nodes and childs
 *
 * @param item zone tree object
 * @return flattened array
 */
function flattenWithNoCollapsed(item: IZone, arr: IZone[] = []) {
  const childItem = { ...item };
  delete childItem.children;
  arr.push(childItem);
  if (item?.children?.length) {
    const activeChilds =
      item?.children.filter((elem: IZone) => !elem.collapsed) || [];

    const collapsedParents =
      item?.children.filter((elem: IZone) => elem.collapsed) || [];

    collapsedParents.forEach((elem: IZone, index: number) => {
      const elemCopy = elem;
      delete elemCopy.children;
      arr.push(elemCopy);
    });

    activeChilds.map((child: IZone) => {
      return flattenWithNoCollapsed(child, arr);
    });
  }
  return arr;
}

/**
 * This function will count the number of open child nodes,
 * each node has a proprty collpased[true if node open / false id the node is collpased state]
 *
 * @param item zone tree object
 * @return number of opened child nodes
 */
export const numberOfOpenNodes = (item: IZone): number => {
  const flattenArr = flattenWithNoCollapsed(_.cloneDeep(item), []);
  return flattenArr.length || 0;
};

/**
 * This function will return the zone name and id from id array
 *
 * @param ids - zone ids
 * @return [array] zone name and id array
 */
export const getZonePropsFromID = (
  ids: string[],
  zones: IZone[]
): { id: string; name: string }[] => {
  const zonesEntity: { [key: string]: string } = {};
  zones.forEach((item: IZone) => {
    zonesEntity[item.id] = item.name;
  });
  const names: { id: string; name: string }[] = ids
    .map((item: string) => {
      return { name: zonesEntity[item], id: item };
    })
    .filter((item: { id: string; name: string }) => item.name);
  return names || [];
};

/**
 * This function will return the zone name from id
 *
 * @param zones, id - zones and id
 * @return name of the zone
 */
export const getZoneTitle = (zones: IZone[], id: string) => {
  return zones?.find((item: IZone) => item.id === id)?.name || "";
};

/**
 * This function will search the zone tree
 *
 * @param element: Izone element
 *        matchingTitle: search key
 * @return name of the zone
 */
export function searchTreeByQuery(
  element: IZone,
  matchingTitle: string
): null | IZone {
  if (!element) {
    return null;
  }
  if (element?.name?.toLowerCase().includes(matchingTitle?.toLowerCase())) {
    return element;
  }
  if (element.children != null) {
    let i;
    let result = null;
    for (i = 0; result == null && i < element.children.length; i += 1) {
      result = searchTreeByQuery(element.children[i], matchingTitle);
    }
    return result;
  }
  return null;
}

/**
 * This function will return zone and all the child nodes
 *
 * @param selected: selected zone id
 *        zones: all zones
 * @return selected zone with child zones
 */
export function getZoneWithChildZones(
  zones: IZone[],
  selected: string
): IZone[] {
  const zoneTree = makeZoneTree<IZone>(zones);
  if (zoneTree && zoneTree.length) {
    const nodesWithChilds: IZone | null = searchTree(zoneTree[0], selected);
    if (nodesWithChilds) {
      return flatten(nodesWithChilds);
    }
  }
  return [];
}

// can access
export const canAccessZone = (
  currentUserZones: string[],
  nodePaths: string[]
) => {
  for (let i = 0; i < nodePaths.length; i += 1) {
    if (currentUserZones.includes(nodePaths[i])) {
      return true;
    }
  }
  return false;
};

// get authorized parent zones
export const authorizedZones = (zones: IZone[], authUserZones: IZone[]) => {
  let selected: IZone[] = [];
  const zoneTree = makeZoneTree<IZone>(zones);
  authUserZones.forEach((item) => {
    const parent = searchTree(zoneTree[0], item.id);
    if (parent) {
      const flattenArr = flatten(parent);
      selected = selected.concat(flattenArr);
    }
  });
  return selected;
};
