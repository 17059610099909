import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon
} from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import { useTheme } from "@mui/material/styles"; 

import React from "react";

import { ZoneUserRolesEnum } from "constants/userContstants";

// ListItemIconStyled
export const ListItemIconStyled = styled(ListItemIcon)(
  ({
    children,
    active
  }: {
    children: any;
    active?: number;
  }) => {
    const theme = useTheme();
    return {
      marginRight: 10,
      minWidth: "20px",
      "& > svg": {
        color: active ? theme.palette.main[50] : theme.palette.red[50]
      }
    };
  }
);

export const ListItemTextStyled = styled(ListItemText)(
  ({ active }: { active?: number }) => {
    const theme = useTheme();
    return {
      color: active ? theme.palette.neutral[80] : theme.palette.neutral[30]
    };
  }
);

export const TitleStyled = styled("p")(
  ({ active }: { active?: number }) => {
    const theme = useTheme();
    return {
      margin: 0,
      padding: 0,
      color: theme.palette.neutral[80],
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0.1px",
      fontSize: 14,
      marginBottom: 8
    };
  }
);
export const SvgIconStyled = styled(SvgIcon)(
  ({ active }: { active?: number }) => {
    const theme = useTheme();
    return {
      color: theme.palette.red[50],
      marginRight: "10px"
    };
  }
);
export const TitleNotAllowedStyled = styled("div")(
  ({ children }: { children: React.ReactNode }) => {
    const theme = useTheme();
    return {
      display: "flex",
      alignItems: "center",
      verticalAlign: "middle",
      marginBottom: 34,
      "& p": {
        margin: 0,
        padding: 0,
        color: theme.palette.neutral[80],
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        fontSize: 14
      }
    };
  }
);

function RoleInformation({
  roles,
  title,
  contentRef,
  notAllowedTitle
}: {
  roles: ZoneUserRolesEnum[];
  title?: string;
  contentRef?: any;
  notAllowedTitle?: string;
}) {
  const { t } = useTranslation();

  const isActive = (permissions: ZoneUserRolesEnum[]): number => {
    const filteredArray =
      permissions.filter((item) => roles.includes(item)) || [];
    return filteredArray.length ? 1 : 0;
  };
  const userRoles: {
    name: string;
    value: string;
    permissions: ZoneUserRolesEnum[];
  }[] = [
    {
      name: t("manage_zones"),
      value: "manage_zones",
      permissions: [ZoneUserRolesEnum.OWNER]
    },
    {
      name: t("manage_users_and_assign_roles_to_zones"),
      value: "manage_users_and_assign_roles_to_zones",
      permissions: [ZoneUserRolesEnum.OWNER, ZoneUserRolesEnum.ADMIN]
    },
    {
      name: t("manage_somnofy_devices"),
      value: "manage_somnofy_devices",
      permissions: [ZoneUserRolesEnum.OWNER, ZoneUserRolesEnum.ADMIN]
    },
    {
      name: t("manage_subjects"),
      value: "manage_subjects",
      permissions: [
        ZoneUserRolesEnum.OWNER,
        ZoneUserRolesEnum.ADMIN,
        ZoneUserRolesEnum.MANAGER
      ]
    },

    {
      name: t("assign_somnofy_device_to_subject"),
      value: "assign_somnofy_device_to_subject",
      permissions: [
        ZoneUserRolesEnum.OWNER,
        ZoneUserRolesEnum.ADMIN,
        ZoneUserRolesEnum.MANAGER
      ]
    },
    {
      name: t("change_somnofy_device_settings"),
      value: "change_somnofy_device_settings",
      permissions: [
        ZoneUserRolesEnum.OWNER,
        ZoneUserRolesEnum.ADMIN,
        ZoneUserRolesEnum.MANAGER,
        ZoneUserRolesEnum.MANAGER_LIMITED
      ]
    },
    {
      name: t("manage_notifications"),
      value: "manage_notifications",
      permissions: [
        ZoneUserRolesEnum.OWNER,
        ZoneUserRolesEnum.ADMIN,
        ZoneUserRolesEnum.MANAGER
      ]
    },
    {
      name: t("view_live_dashboard"),
      value: "view_live_dashboard",
      permissions: [
        ZoneUserRolesEnum.DATA_READER,
        ZoneUserRolesEnum.DATA_READER_LIMITED
      ]
    },
    {
      name: t("view_night_report"),
      value: "view_night_report",
      permissions: [
        ZoneUserRolesEnum.DATA_READER,
        ZoneUserRolesEnum.DATA_READER_LIMITED
      ]
    },
    {
      name: t("view_sleep_history"),
      value: "view_sleep_history",
      permissions: [ZoneUserRolesEnum.DATA_READER]
    }
  ];
  return (
    <div ref={contentRef || null}>
      {notAllowedTitle && (
        <TitleNotAllowedStyled>
          <SvgIconStyled>
            <LockIcon />
          </SvgIconStyled>
          <p>{notAllowedTitle}</p>
        </TitleNotAllowedStyled>
      )}
      <TitleStyled>{title || ""}</TitleStyled>
      <List disablePadding>
        {userRoles.map((item) => {
          const active = isActive(item.permissions);
          return (
            <ListItem disablePadding key={item.value}>
              <ListItemIconStyled active={active}>
                {active ? <CheckIcon /> : <CloseIcon />}
              </ListItemIconStyled>
              <ListItemTextStyled primary={item.name} active={active} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
export default RoleInformation;
