import { Controller, useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect } from "react";

import {
  AuthorizationContext,
  DevicePermissionEnum
} from "authorization/AuthorizationContext";
import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled
} from "styled/ContainerStylesStyled";
import {
  ButtonAsTextStlyled,
  InputLabelStyled,
  SubTitleStyled
} from "styled/CommonStyled";
import { IDevice } from "interfaces/device.interface";
import { ISubject } from "interfaces/subject.interface";
import { can } from "authorization/authorization.util";
import { gotTo } from "utils/history.util";
import { patchDevice } from "store/actions/administration/deviceActions";
import AutoSuggestFormDropDown from "components/AutoSuggestSelect/AutoSuggestFormDropDown";
import SMConfirmDialogNoLabel from "components/SMDialogs/SMConfirmDialogNoLabel";

function SubjectDropDown({
  device,
  subjects,
  selectedZone
}: {
  device: IDevice;
  subjects: ISubject[] | [];
  selectedZone: string;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { permissions } = useContext(AuthorizationContext);
  const [openUnAssignDialog, setOpenUnAssignDialog] =
    React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openChangeDialog, setOpenChangeDialog] =
    React.useState<boolean>(false);
  const [subjectDropDowns, setSubjectDropDowns] = React.useState<
    {
      label: string;
      value: string;
      id: string;
    }[]
  >([]);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    resetField,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors }
  } = useForm<IDevice>({
    defaultValues: {
      ...device,
      subject_id: device.subject_id ? device.subject_id : "none"
    },
    reValidateMode: device.id ? "onChange" : "onSubmit",
    mode: device.id ? "onChange" : "onSubmit"
  });

  // update form
  useEffect(() => {
    reset({
      ...device,
      subject_id: device.subject_id ? device.subject_id : "none"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  useEffect(() => {
    getSubjectDropDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjects]);

  const updateForm = (data: IDevice) => {
    if (data.subject_id === "none") {
      setOpenUnAssignDialog(true);
    } else {
      dispatchSubjectAssign(data);
      // setOpenChangeDialog(true);
    }
  };

  const onUnAssignDevice = () => {
    setOpenUnAssignDialog(false);
    const formValues = getValues();
    dispatchSubjectAssign({ ...formValues, subject_id: null });
  };

  // const onChangeDevice = (data: IDevice) => {
  //   setOpenChangeDialog(false);
  //   // @ts-ignore
  //   const formValues = getValues();
  //   // dispatchSubjectAssign({ ...formValues, subject_id: null });
  //   // dispatchSubjectAssign(data);
  // };

  const dispatchSubjectAssign = (data: IDevice) => {
    const requestData = {
      type: "vitalthings-somnofy-sm100",
      subject_id: data.subject_id
    };
    dispatch(patchDevice(data.id, requestData));
  };

  const onCancel = () => {
    resetField("subject_id");
    setOpenUnAssignDialog(false);
  };

  const getSubjectDropDown = () => {
    const options = [
      {
        id: "0",
        label: t("none_unassign_device"),
        value: "none"
      }
    ];
    const subAsOptions =
      subjects.map((item: ISubject) => {
        return { id: item.id, label: item.identifier, value: item.id };
      }) || [];

    setSubjectDropDowns([...options, ...subAsOptions]);
  };

  const viewSelectedSubject = () => {
    if (device.subject_id) {
      gotTo(`adminstration/zones/subjects/${device.subject_id}`);
    }
  };

  return (
    <BoxSubSectionStyled height="auto">
      <SubTitleStyled>{t("subject")}</SubTitleStyled>
      <form onSubmit={handleSubmit(updateForm)}>
        <BoxSubSectionContentStyled>
          <Grid container>
            <Grid item xs={12} md={12}>
              <InputLabelStyled maginbottom={6}>
                {t("Subject ID")}{" "}
              </InputLabelStyled>
              {/* <TextStyled> {t("")} </TextStyled> */}
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container columnSpacing={2.5}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="subject_id"
                    control={control}
                    render={({ field, fieldState, formState }) => (
                      <AutoSuggestFormDropDown
                        disabled={
                          !can(
                            permissions,
                            DevicePermissionEnum["device.assign_subject"]
                          )
                        }
                        autoSelect={false}
                        field={field}
                        formState={formState}
                        options={subjectDropDowns}
                        fieldState={fieldState}
                        renderValueAsObject
                        onUpdate={() => {
                          handleSubmit(updateForm)();
                        }}
                      />
                    )}
                  />
                </Grid>
                {device.subject_id && (
                  <Grid item xs={12} md={6}>
                    <ButtonAsTextStlyled onClick={viewSelectedSubject}>
                      {t("view_selected_subject")}
                    </ButtonAsTextStlyled>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </BoxSubSectionContentStyled>
      </form>
      <SMConfirmDialogNoLabel
        title={t("unassign_device")}
        dialogDesc={`${t("are_you_sure_you_want_to_unassign_this_device")}`}
        onDelete={onUnAssignDevice}
        buttonOk={t("Unassign")}
        buttonCancel={t("Cancel")}
        okButtonBg="green"
        open={openUnAssignDialog}
        setOpen={setOpenUnAssignDialog}
        onCancel={onCancel}
      />
      {/* <SMConfirmDialogNoLabel
        title={t("change_device")}
        dialogDesc={`${t("are_you_sure_you_want_to_change_this_device")}`}
        onDelete={handleSubmit(onChangeDevice)}
        buttonOk={t("Change")}
        buttonCancel={t("Cancel")}
        okButtonBg="green"
        open={openChangeDialog}
        setOpen={setOpenChangeDialog}
        onCancel={onCancel}
      /> */}
    </BoxSubSectionStyled>
  );
}
export default SubjectDropDown;
