import { API_ACTIONS } from "store/actionTypes/apiActionTypes";
import { IUser } from "interfaces/user.interface";

import * as types from "../../actionTypes/administration/userActionTypesNew";

export default function userReducer(
  state: any = {},
  action: types.UsersActionTypes
) {
  switch (action.type) {
    case types.FETCH_USER_SUCCESS:
      return {
        ...state,
        users: action.payload,
        apitStatus: API_ACTIONS.reset
      };
    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        users: state?.users
          ? [...state.users, action.payload]
          : [action.payload],
        apitStatus: API_ACTIONS.created
      };

    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        apitStatus: API_ACTIONS.updated,
        users: state.users.map((user: IUser) =>
          user.id === action.payload.id ? { ...action.payload } : user
        )
      };
    case types.FETCH_USER_BY_ID_SUCCESS:
      return {
        ...state,
        users: state.users.map((user: IUser) =>
          user.id === action.payload.id ? { ...action.payload } : user
        )
      };
    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          (user: IUser) => action.payload.indexOf(user.id) === -1
        )
      };
    default:
      return state;
  }
}
