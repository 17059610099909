import React from "react";

import { ApplyFloat } from "styled/StylesStyled";
import { SMMenuProps } from "interfaces/menu.interface";
import CustomizedMenus from "components/StyledMenu/StyledMenu";

function MonitorScreenMenus({
  onClick,
  menus
}: {
  onClick: (route: SMMenuProps) => void;
  menus: SMMenuProps[];
}) {
  return (
    <div>
      <ApplyFloat float="right" sx={{ marginTop: "3px" }}>
        <CustomizedMenus onClick={onClick} menus={menus} />
      </ApplyFloat>
    </div>
  );
}
export default MonitorScreenMenus;
