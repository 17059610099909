import { ISubject } from "interfaces/subject.interface";
import { subjectServices } from "services/administration/subjectService";

import * as types from "../../actionTypes/administration/subjectActionTypes";
import { getAllRules } from "../rules/rules";

export const restDeviceCurrentAction = (): types.resetSubjectActionType => {
  return {
    type: types.RESET_SUBJECT_ACTIONS
  };
};

/* ************************************************** */
/*                      Actions                       */
/* ************************************************** */

export const resetSubjectStateAction =
  (): types.resetSubjectStateActionType => {
    return {
      type: types.RESET_SUBJECT_STATE
    };
  };

/**
 * Redux action to fetch all zones
 *
 * @return type - redux action type |
 */
export const getAllSubjectAction = (): types.fetchSubjectActionType => {
  return {
    type: types.FETCH_SUBJECT
  };
};

/**
 * Redux action to fetch all subjects
 *
 * @return type - redux action type |
 *         payload - subjects
 */
export const getAllSubjectActionSuccess = (
  data: ISubject[]
): types.fetchSubjectSuccessActionType => {
  return {
    type: types.FETCH_SUBJECT_SUCCESS,
    payload: data
  };
};

/**
 * Create  subject ation
 *
 * @return type - redux action type |
 *
 */
export const createSubjectAction = (): types.createSubjectActionType => {
  return {
    type: types.CREATE_SUBJECT
  };
};

/**
 * Create  subject success action
 *
 * @return type - redux action type |
 *         payload - param
 */
export const createSubjectSuccesAction = (
  data: ISubject
): types.createSubjectSuccessActionType => {
  return {
    type: types.CREATE_SUBJECT_SUCCESS,
    payload: data
  };
};

/**
 * update subject actionType
 * @return type - redux action type
 *
 */
export const updateSubjectAction = (): types.updateSubjectActionType => {
  return {
    type: types.UPDATE_SUBJECT
  };
};

/**
 * update subject successAction
 * @return type - redux action type
 *         response - Created subject
 */
export const updateSubjectSuccessAction = (
  data: ISubject
): types.updateSubjectSuccessActionType => {
  return {
    type: types.UPDATE_SUBJECT_SUCCESS,
    payload: data
  };
};

/**
 * delete subject actionType
 * @return type - redux action type
 *
 */
export const deleteSubjectAction = (): types.deleteSubjectActionType => {
  return {
    type: types.DELETE_SUBJECT
  };
};

/**
 * update subject successAction
 * @return type - redux action type
 *         response - Created subject
 */
export const deleteSubjectSuccessAction = (
  id: string
): types.deleteSubjectSuccessActionType => {
  return {
    type: types.DELETE_SUBJECT_SUCCESS,
    payload: id
  };
};

/* ************************************************** */
/*                      Services                      */
/* ************************************************** */

/**
 *  Function to fetch all the subjects
 *
 * @param none
 * @return all the subjects in the system
 */
export function getAllSubjects(path?: string) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    // dispatch(getAllSubjectAction());
    subjectServices
      .fetchSubjects(path)
      .then((data: { object: "list"; data: ISubject[] }) => {
        if (data?.data) {
          data?.data?.sort((a: ISubject, b: ISubject) => {
            return Intl.Collator("nb", { sensitivity: "base" }).compare(
              a.identifier,
              b.identifier
            );
          });
          dispatch(getAllSubjectActionSuccess(data.data));
        }
      });
  };
}

export function resetSubjectState() {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(resetSubjectStateAction());
  };
}

//
export function getAllSubjectsExcludeAssigned(path: string, exception: string) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    subjectServices
      .getAllSubjectsExcludeAssigned(path, exception)
      .then((response: any) => {
        let subjects: ISubject[] = [];
        response.forEach(
          (item: {
            status: "fullfilled";
            value: { data: ISubject[] | ISubject };
          }) => {
            if (item?.value?.data) {
              if (Array.isArray(item?.value?.data)) {
                subjects = subjects.concat(item.value.data);
              } else {
                subjects.push(item.value.data);
              }
            }
          }
        );
        dispatch(getAllSubjectActionSuccess(subjects));
      });
  };
}

export function getSubject(id?: string) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    // dispatch(getAllSubjectAction);
    subjectServices
      .fetchSubject(id)
      .then((response: { object: "list"; data: ISubject }) => {
        if (response?.data) {
          dispatch(createSubjectSuccesAction(response.data));
        }
      });
  };
}

/**
 *  Function to create subject
 *
 * @param name - data of type ISubject
 * @return created subject
 */
export function createSubject(
  subj: ISubject,
  callback?: (item: ISubject) => void
) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(createSubjectAction());
    const params: any = {
      ...subj,
      birth_year: subj.birth_year ? subj.birth_year : null,
      height: subj.height ? subj.height : null,
      weight: subj.weight ? subj.weight : null
    };
    subjectServices
      .createSubject(params)
      .then((response: { data: ISubject }) => {
        if (response?.data) {
          dispatch(createSubjectSuccesAction(response.data));
          // success callback to show toast
          if (callback) {
            callback(response.data);
          }
        }
      });
  };
}

/**
 *  Function to update subject
 *
 * @param name - data of type ISubject
 * @return created subject
 */
export function updateSubject({
  subject,
  removeDevice,
  updateSubjectErrorCB,
  successCallback,
  refreshRules
}: {
  subject: ISubject;
  removeDevice?: boolean;
  updateSubjectErrorCB?: () => void;
  successCallback?: () => void;
  refreshRules?: boolean;
}) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    dispatch(updateSubjectAction());
    const params: any = {
      ...subject,
      birth_year: subject.birth_year ? subject.birth_year : null,
      height: subject.height ? subject.height : null,
      weight: subject.weight ? subject.weight : null
    };
    subjectServices
      .updateSubject(params)
      .then((response: { data: ISubject }) => {
        if (response?.data) {
          dispatch(
            updateSubjectSuccessAction({
              ...response.data,
              devices: !removeDevice ? subject.devices : { data: [] }
            })
          );
          if (refreshRules) {
            dispatch(getAllRules(`${response?.data?.id}`));
          }
          if (successCallback) {
            successCallback();
          }
        } else if (updateSubjectErrorCB) {
          updateSubjectErrorCB();
        }
      });
  };
}

/**
 *  Function to delete subject
 *
 * @param name - data of type ISubject
 * @return none
 */
export function deleteSubject(data: ISubject, subjectDeleteCB: () => void) {
  // eslint-disable-next-line func-names
  return function (dispatch: (arg0: any) => void) {
    subjectServices.deleteSubject(data).then(() => {
      dispatch(deleteSubjectSuccessAction(data.id));
      // callback to show the feedback
      if (subjectDeleteCB) {
        subjectDeleteCB();
      }
    });
  };
}
