import { API_SERVICES } from "../../constants/constants";
import axios from "axios";

import {
  handleError,
  handleResponse
} from "../responseHandler";


export const tenantServices = {
  fetchTenant
};


async function fetchTenant(id?: string) {
  const url = `${API_SERVICES.tenants}/${id}`;
  return axios({
    method: "get",
    url,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*"
    }
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

