import { AppBar, Box, Button, IconButton, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useContext } from "react";

import { AppMenuProps } from "interfaces/router.interface";
import {
  AuthorizationContext,
  AuthorizationPermissionsType,
  SubjectPermissionEnum,
  UserPermissionEnum,
  ZonePermissionEnum
} from "authorization/AuthorizationContext";
import { ReactComponent as Logo } from "assets/icons/new/logo.svg";
import { SMMenuProps } from "interfaces/menu.interface";
import { User } from "interfaces/user.interface";
import { basePath } from "constants/constants";
import { clearCookies, getLogoutUrl } from "utils/auth/auth.util";
import { clearLiveMonitoring } from "store/actions/dashboard/monitorActions";
import CustomizedMenus from "components/StyledMenu/StyledMenu";

interface Props {
  screens: AppMenuProps[];
  loginedUser: User;
}
const SMLogo = styled(Logo)(() => {
  return {
    marginRight: 35
  };
});

const StyledAppBar = styled(AppBar)(
  ({ children }: {children: React.ReactNode }) => {
    const theme = useTheme(); 
    return {
      background: theme.palette.white,
      height: 55,
      minHeight: 55,
      boxShadow: "none",
      zIndex: 100,
      paddingRight: 38,

      borderBottom: `1px solid ${theme.palette.neutral[5]}`,
      "& .MuiToolbar-root": {
        minHeight: 55,
        paddingLeft: 0,
        paddingRight: 0
      }
    };
  }
);

const StyledButton = styled(Button)(
  ({ active }: { active: number }) => {
    const theme = useTheme(); 
    return {
      // for to fix conflix issue with mui version-4
      "&.MuiButtonBase-root": {
        color: active ? theme.palette.main[60] : theme.palette.neutral[50],
        fontWeight: 500,
        fontSize: 16,
        textTransform: "none",
        marginRight: 43,
        padding: "10px 12px 9px 15px",
        height: 53,
        borderRadius: 0,
        borderBottom: active
          ? `1.5px solid ${theme.palette.main[60]}`
          : `1.5px solid #FFF`
      },
      color: active ? theme.palette.main[60] : theme.palette.neutral[50],
      fontWeight: 500,
      fontSize: 16,
      textTransform: "none",
      marginRight: 43,
      padding: "10px 12px 9px 15px",
      height: 53,
      borderRadius: 0,
      borderBottom: active
        ? `1.5px solid ${theme.palette.main[60]}`
        : `1.5px solid #FFF`,
      "&:hover": {
        background: `${theme.palette.main[2]}`
      }
    };
  }
);

const UserNameWrapped = styled("div")(
  ({ children }: { children: React.ReactNode }) => {
    const theme = useTheme(); 
    return {
      maxWidth: 150,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      float: "right",
      color: theme.palette.neutral[80],
      fontSize: 16,
      marginLeft: 24,
      display: "block"
    };
  }
);
const IconButtonStyled = styled(IconButton)(
  ({ children }: { children: React.ReactNode }) => {
    const theme = useTheme(); 
    return {
      marginLeft: 10,
      marginRight: -5,
      "&:hover": {
        background: theme.palette.main[2]
      },
      "& >div": {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        background: theme.palette.neutral[60],
        height: 26,
        color: "#FFF",
        textAlign: "center",
        width: 26,
        borderRadius: 14,
        fontSize: 16
      }
    };
  }
);

function SMAdminAppBar({ screens, loginedUser }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { permissions } = useContext(AuthorizationContext);

  
  const gotoScreen = (page: AppMenuProps) => {
    dispatch(clearLiveMonitoring());
    history.push(page.path);
  };

  const onClick = (route: SMMenuProps) => {
    if (route.value === "profile") {
      history.push(`${basePath}/${route.value}`);
    } else if (route.value === "logoff") {
      clearCookies();
      // dispatch(resetAuthState());
      window.open(getLogoutUrl(), "_self");
    } else {
      history.push(`${basePath}/adminstration/${route.value}`);
    }
  };

  const isHidden = (policy: AuthorizationPermissionsType): boolean => {
    if (!permissions) {
      return false;
    }
    return permissions.indexOf(policy) === -1;
  };

  const menus: SMMenuProps[] = [
    {
      label: t("subject"),
      value: "create-subject",
      hide: isHidden(SubjectPermissionEnum["subject.create"]),
      hideDivider: true
    },
    {
      label: t("employee"),
      value: "create-user",
      hide: isHidden(UserPermissionEnum["user.create"])
    },
    {
      label: t("Zone"),
      value: "create-zone",
      hide: isHidden(ZonePermissionEnum["zone.create"])
    }
  ];

  const profileMenu: SMMenuProps[] = [
    {
      label: t("employee_profile"),
      value: "profile",
      hideDivider: true
    },
    {
      label: t("Logout"),
      value: "logoff"
    }
  ];

  const isActive = (path: string) => {
    const slug = path.split("/").reverse();
    if (history.location.pathname.includes(slug[0])) {
      return true;
    }
    return false;
  };

  const filteredScreen = (routes: AppMenuProps[]) => {
    return routes.filter((item) => {
      return item.slug !== "employee_profile";
    });
  };

  
  return (
    <div>
      {/* <Shadow /> */}
      <StyledAppBar position="static">
        <Toolbar>
          <SMLogo />
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
            {filteredScreen(screens).map((page: AppMenuProps) => {
              return (
                <StyledButton
                  key={page.slug}
                  onClick={() => gotoScreen(page)}
                  active={isActive(page.path) ? 1 : 0}
                >
                  {t(page.name)}
                </StyledButton>
              );
            })}
          </Box>
          {menus.filter((menu: SMMenuProps) => !menu.hide)?.length && (
            <CustomizedMenus
              onClick={onClick}
              menus={menus}
              menuLabel={t("Create")}
            />
          )}
          <UserNameWrapped>
            {loginedUser.first_name} {loginedUser.last_name}{" "}
          </UserNameWrapped>
          <CustomizedMenus
            onClick={onClick}
            menus={profileMenu}
            manuLabelComponent={
              <IconButtonStyled>
                <div>{loginedUser.first_name[0]}</div>
              </IconButtonStyled>
            }
          />
        </Toolbar>
      </StyledAppBar>
    </div>
  );
}
export default SMAdminAppBar;
