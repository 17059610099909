import { ErrorOutline } from "@mui/icons-material";
import { TFunctionResult } from "i18next";
import { styled } from "@mui/system";
import React from "react";

import { NightReportItemMetric } from "interfaces/nightReport.interface";
import { getMinuteString, secondsToTime } from "utils/date.util";
import {
  getNightReportValueDifference,
  getRoundedNightReportValue
} from "utils/report.util";
import i18n from "i18n/config";

const DivWrapper = styled("div")(
  ({
    children,
    sx
  }: {
    children: React.ReactNode;
    sx?: React.CSSProperties;
  }) => {
    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ...sx
    };
  }
);

const ErrorOutlineWrapperStyled = styled("div")(
  () => {
    return {
      marginRight: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&>svg": {
        color: "rgb(165, 5, 32)",
        width: 28,
        height: 28
      }
    };
  }
);

const ErrorPlaceHolderStyled = styled("div")(() => {
  return {
    width: 28,
    height: 28,
    marginRight: 10
  };
});

const ValueWrapper = styled("div")(() => {
  return {
    display: "inline-block",
    textAlign: "left",
    "&>span": {
      wordSpacing: "-0.125em"
    }
  };
});

const ValueWrapperNoWidth = styled("div")(() => {
  return {
    display: "inline-block",
    textAlign: "left",
    "&>span": {
      wordSpacing: "-0.125em"
    }
  };
});

const getVaueDiff = (
  item: NightReportItemMetric,
  baseline: boolean,
  round: number,
  pct: boolean,
  usecomma: boolean
) => {
  if (item) {
    const valueDiff = getNightReportValueDifference({
      item,
      baseline,
      round,
      pct
    });
    if (usecomma && valueDiff) {
      return valueDiff.replace(".", ",");
    }
    return valueDiff;
  }
  return "";
};

function NightReportColumn({
  item,
  pct,
  round = -1,
  baseline = false,
  valueRound = false,
  valueAsPercentage = false,
  usecomma = false
}: {
  item?: NightReportItemMetric;
  pct: boolean;
  round?: number;
  baseline?: boolean;
  valueRound?: boolean;
  valueAsPercentage?: boolean;
  usecomma?: boolean;
}) {
  return (
    <DivWrapper>
      {item?.deviation_alert ? (
        <ErrorOutlineWrapperStyled>
          {" "}
          <ErrorOutline />{" "}
        </ErrorOutlineWrapperStyled>
      ) : (
        <ErrorPlaceHolderStyled />
      )}
      <ValueWrapper>
        {usecomma
          ? `${getRoundedNightReportValue(item?.value, valueRound)}`?.replace(
              ".",
              ","
            )
          : getRoundedNightReportValue(item?.value, valueRound)}
        {valueAsPercentage ? <span>&nbsp;%</span> : ""}
        {item ? (
          <span>{getVaueDiff(item, baseline, round, pct, usecomma)}</span>
        ) : (
          ""
        )}
      </ValueWrapper>
    </DivWrapper>
  );
}

export default NightReportColumn;

export const getTimeAsleep = (seconds: number): string => {
  if (!seconds) {
    return "";
  }
  // const HH = Math.floor(seconds / 3600);
  // const MM = Math.floor(seconds / 60) % 60;
  // return `${HH}${i18n.t("hours_abbreviation_lc")} ${MM}${i18n.t(
  //   "minutes_abbreviation_lc"
  // )}`;

  const timeAsleep = secondsToTime(seconds);
  return `${
    timeAsleep.h > 0 ? `${timeAsleep.h}${i18n.t("hours_abbreviation_lc")}` : ""
  } ${getMinuteString(timeAsleep.h, timeAsleep.m)}`;
};

// for to render time asleep column
export function NightReportColumnTimeAsleep({
  item,
  t
}: {
  item?: NightReportItemMetric;
  t: any;
}) {
  return (
    <DivWrapper>
      {item?.deviation_alert ? (
        <ErrorOutlineWrapperStyled>
          {" "}
          <ErrorOutline />{" "}
        </ErrorOutlineWrapperStyled>
      ) : (
        <ErrorPlaceHolderStyled />
      )}
      <ValueWrapper>
        {item?.value ? getTimeAsleep(item.value) : t("N/A")}
        {item?.deviation_pct
          ? `(${item?.deviation_pct > 0 ? "+" : ""}${Math.round(
              +item?.deviation_pct
            )}`
          : ""}
        <span>&nbsp;{item?.deviation_pct ? "%)" : ""}</span>
      </ValueWrapper>
    </DivWrapper>
  );
}

export function NightReportColumnNoData({
  message,
  t
}: {
  message: string;
  t: any;
}) {
  return (
    <DivWrapper sx={{ justifyContent: "left" }}>
      <ErrorOutlineWrapperStyled>
        {" "}
        <ErrorOutline />{" "}
      </ErrorOutlineWrapperStyled>
      <div> {message}</div>
    </DivWrapper>
  );
}

export function NightReportColumnCenter({
  item,
  pct,
  round = -1,
  baseline = false,
  valueRound = false,
  valueAsPercentage = false,
  usecomma = false
}: {
  item?: NightReportItemMetric;
  pct: boolean;
  round?: number;
  baseline?: boolean;
  valueRound?: boolean;
  valueAsPercentage?: boolean;
  usecomma?: boolean;
}) {
  return (
    <DivWrapper>
      <ValueWrapperNoWidth>
        {usecomma
          ? `${getRoundedNightReportValue(item?.value, valueRound)}`?.replace(
              ".",
              ","
            )
          : getRoundedNightReportValue(item?.value, valueRound)}
        {valueAsPercentage ? <span>&nbsp;%</span> : ""}
        {item ? getVaueDiff(item, baseline, round, pct, usecomma) : ""}
      </ValueWrapperNoWidth>
    </DivWrapper>
  );
}

export function NightReportColumnNoAlert({
  value
}: {
  value: string | number | TFunctionResult;
}) {
  return (
    <DivWrapper>
      <ErrorPlaceHolderStyled />
      <ValueWrapper dangerouslySetInnerHTML={{ __html: `${value}` || "-" }} />
    </DivWrapper>
  );
}
