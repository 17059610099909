import { IColor } from "interfaces/color.interface";

const Red: Omit<
  IColor,
  0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
> = {
  1: "#FFFAF8",
  5: "#FFE6E1",
  10: "#FBCAC0",
  20: "#E79685",
  30: "#D46751",
  40: "#C03F25",
  50: "#AD1D00"
};

export default Red;
