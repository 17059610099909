import { Box, Popover, SvgIcon, Theme } from "@mui/material";
import { Calendar } from "react-date-range";
import { useTheme } from "@mui/material/styles";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import React, { useEffect, useState } from "react";

import { getLangKey } from "utils/common/locale.util";
import SMTextField from "components/SMTextField/SMTextField";

function DatePicker({
  onSelect,
  defaultValue,
  minDate,
  maxDate
}: {
  onSelect: (val: Date | null) => void;
  defaultValue?: Date | null;
  minDate?: Date;
  maxDate?: Date;
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>();
  const [dateSelected, setDateSelected] = useState<Date | null>(
    defaultValue || null
  );
  const appTheme: Theme = useTheme();

  // onSelect Callbak
  useEffect(() => {
    onSelect(dateSelected || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected]);

  const onClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget);
  };

  const handleSelect = (val: Date) => {
    setDateSelected(val);
    onClose();
  };

  const getFormatedValue = (val: Date | null) => {
    if (!val) {
      return "";
    }
    return Intl.DateTimeFormat(getLangKey(), {
      year: "numeric",
      month: "short",
      day: "numeric"
    }).format(val);
  };

  return (
    <Box>
      <SMTextField
        onClick={handleClick}
        defaultValue={getFormatedValue(dateSelected)}
        width="105px"
        endAdornment={
          <SvgIcon
            htmlColor={appTheme.palette.main[60]}
            sx={{ marginRight: "8px" }}
            onClick={handleClick}
          >
            <InsertInvitationIcon />
          </SvgIcon>
        }
      />
      <Popover
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Calendar
          color="#335E66"
          onChange={(val) => handleSelect(val)}
          date={dateSelected || undefined}
          minDate={minDate}
          maxDate={maxDate || new Date()}
        />
      </Popover>
    </Box>
  );
}
export default DatePicker;
