export interface IWIPMessageContent {
  title: string;
  body: string;
}
export interface IWIPMessage {
  enabled: boolean;
  type: "warning" | "error" | "info";
  enable_from: string;
  enable_to: string;
  message: {
    en?: IWIPMessageContent;
    no: IWIPMessageContent;
  };
}

export const isWIPMessageType = (item: any): item is IWIPMessage[] => {
  if (
    item instanceof Array &&
    item.length > 0 &&
    "enabled" in item[0] &&
    "type" in item[0] &&
    "enable_from" in item[0] &&
    "enable_to" in item[0] &&
    "message" in item[0]
  ) {
    return true;
  }
  return false;
};
