import { encode as base64encode } from "base64-arraybuffer";
import Cookies from "js-cookie";

import * as LOCAL_STORAGE from "utils/localStorage";
import {
  AUTH_BASE,
  CHANGE_PASSWORD_API,
  LOG_OFF_API
} from "constants/auth.constants";

export const generateState = () => {
  const validChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let array = new Uint8Array(56) as any;
  window.crypto.getRandomValues(array);
  array = array.map((x: number) =>
    validChars.codePointAt(x % validChars.length)
  );
  const randomState = String.fromCharCode.apply(null, array);
  return randomState;
};

//
export const getTokenParams = ({
  code,
  client_id,
  code_verifier,
  redirectUri
}: {
  code: string;
  client_id: string;
  code_verifier: string;
  redirectUri: string;
}) => {
  return {
    grant_type: "authorization_code",
    code,
    client_id,
    redirect_uri: `${document.location.origin}${redirectUri}`,
    code_verifier
  };
};

//
export const enhanceAuthorizeUrl = ({
  clientId,
  state,
  code_challenge,
  authorizeUrl,
  redirectUri,
  loginHint
}: {
  clientId: string;
  state: string;
  code_challenge: string;
  authorizeUrl: string;
  redirectUri: string;
  loginHint: string;
}) => {
  const redirect = `${document.location.origin}${redirectUri}`;
  const responseType = "code";
  const code_challenge_method = "S256";
  return `${authorizeUrl}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirect}&state=${state}&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}&login_hint=${encodeURIComponent(
    loginHint
  )}`;
};

//
export async function generateCodeChallenge(codeVerifier: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest("SHA-256", data);
  const base64Digest = base64encode(digest);
  // you can extract this replacing code to a function
  return base64Digest.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

//
export const clearCookies = () => {
  Cookies.remove("access_token");
  Cookies.remove("userId");
  Cookies.remove("logged_user_email");

  LOCAL_STORAGE.removeZone();
};

//
export const getLogoutUrl = () => {
  const client_id = LOCAL_STORAGE.getClientId();
  const url = `${AUTH_BASE + LOG_OFF_API}?client_id=${client_id}`;
  // if (process.env.REACT_APP_PRODUCTION === "false") {
  //   url += `&post_logout_redirect_uri=localhost:3000`;
  // }
  return url;
};

export const getPasswordChangeUrl = () => {
  const client_id = LOCAL_STORAGE.getClientId();
  const url = `${AUTH_BASE + CHANGE_PASSWORD_API}?client_id=${client_id}`;
  return url;
};
